/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useRef } from 'react'
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Tooltip,
  DialogContentText,
  DialogActions,
  Button,
  TextField,
  InputAdornment,
  CircularProgress,
} from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'
import { makeStyles, createStyles } from '@mui/styles'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import {
  DELETE_IMAGE_UPLOAD,
  DELETE_IMAGE_VIEW,
  DELETE_IMAGE_VIEW_TOOLTIP,
  DELETE_IMAGE_UPLOAD_TOOLTIP,
  DELETE_IMAGE,
  UPLOADED_SUCCESSFULLY_MESSAGE,
  INDIVIDUAL_IMAGE_ERROR,
  UPLOAD_IMAGES,
} from '../../globalConstants'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import ImageNotFound from '../../assets/imageNotFound.png'

const useStyles = makeStyles((theme) =>
  createStyles({
    imagesStyle: {
      cursor: 'pointer',
    },
    closeIcon: {
      position: 'absolute',
      right: 8,
      top: 8,
    },
    imageDialog: {
      '& .MuiDialog-paper': {
        [theme.breakpoints.down('sm')]: {
          margin: '0 !important',
        },
      },
      '& .MuiDialogContent-root': {
        [theme.breakpoints.down('sm')]: {
          padding: '20px 5px !important',
        },
      },
    },
    loaderGrid: {
      width: '100%',
      height: '8em',
      backgroundColor: 'rgba(0, 0, 0, 0.11)',
    },
    deleteDialogAction: {
      display: 'flex',
      justifyContent: 'space-around',
      paddingBottom: '20px',
    },
    imageUploadErrorStyle: {
      opacity: 0.25,
    },
  }),
)

const WorkOrderImageList = ({
  images,
  from,
  canDelete,
  onDelete,
  onDescriptionChange,
}) => {
  const fromUpload = from === UPLOAD_IMAGES
  const [showImageDialog, setShowImageDialog] = useState(false)
  const [imageDailogData, setImageDailogData] = useState(null)
  const breakpoint = useMediaQuery('(min-width:600px)')
  const columnCount = breakpoint ? 2 : 1
  const [deleteDialog, setDeleteDialog] = useState(false)
  const [descriptionEditClicked, setDescriptionEditClicked] = useState(false)
  const classes = useStyles()
  const descriptionInputRefs = useRef([])

  function renderDeleteDialog() {
    const deleteImages =
      images && images[deleteDialog] && images[deleteDialog].title

    return (
      <Dialog
        open={!!deleteDialog || deleteDialog === 0}
        onClose={() => setDeleteDialog(false)}
        aria-labelledby="delete-image-dialog-title"
        aria-describedby="delete-image-dialog-description"
      >
        <DialogTitle id="delete-image-dialog-title">{DELETE_IMAGE}</DialogTitle>
        <DialogContent>
          <DialogContentText
            id="delete-image-dialog-description"
            data-testid={`delete-image-dialog-description-${deleteDialog}`}
          >
            {fromUpload
              ? DELETE_IMAGE_UPLOAD
              : `${DELETE_IMAGE_VIEW} ${deleteImages} ?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.deleteDialogAction}>
          <Button variant="outlined" onClick={() => setDeleteDialog(false)}>
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              onDelete(deleteDialog)
              setDeleteDialog(false)
            }}
            autoFocus
            data-testid={`image-list-item-remove-${deleteDialog}`}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  function getImgSrc(imageData) {
    let imgSrc
    if (!fromUpload) {
      imgSrc = `data:image;base64,
 ${imageData?.image ?? ''}`
    } else {
      imgSrc = imageData?.imgUrl
    }
    return imgSrc
  }

  function getImgToRender(imageData, index) {
    const imgSrc = getImgSrc(imageData)
    const fullImageData = imageData?.image
    const imageLoading = imageData?.loading
    const imageUploaded = imageLoading === 'loaded' && fromUpload
    const imageUploadedError = imageLoading === 'error' && fromUpload
    const imageViewError =
      (fullImageData === 'error' ||
        fullImageData === '' ||
        fullImageData === null) &&
      !fromUpload
    function TopBar() {
      return (
        <ImageListItemBar
          sx={{
            background:
              'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
              'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
          }}
          position="top"
          actionIcon={
            canDelete &&
            !imageUploaded && (
              <Tooltip
                title={
                  fromUpload
                    ? DELETE_IMAGE_UPLOAD_TOOLTIP
                    : DELETE_IMAGE_VIEW_TOOLTIP
                }
              >
                <IconButton
                  sx={{ color: 'white' }}
                  aria-label={fromUpload ? 'remove image' : 'delete image'}
                  onClick={() => {
                    setDeleteDialog(index)
                  }}
                  data-testid={`image-list-item-remove-dialog-${index}`}
                >
                  {<DeleteIcon />}
                </IconButton>
              </Tooltip>
            )
          }
          actionPosition="right"
          title={imageData.title}
          subtitle={
            imageUploaded
              ? UPLOADED_SUCCESSFULLY_MESSAGE
              : imageUploadedError
                ? INDIVIDUAL_IMAGE_ERROR
                : undefined
          }
        />
      )
    }

    function bottomBar() {
      return (
        <ImageListItemBar
          position={!fromUpload || imageUploaded ? 'bottom' : 'below'}
          title={
            !fromUpload || imageUploaded ? (
              imageData.description
            ) : (
              <TextField
                fullWidth
                inputRef={(el) => (descriptionInputRefs.current[index] = el)}
                margin="dense"
                className={classes.editDescriptionTextField}
                variant="standard"
                autoFocus={descriptionEditClicked === index}
                value={imageData.description}
                onChange={(e) => onDescriptionChange(e.target.value, index)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="edit image description"
                        onClick={() => {
                          descriptionInputRefs.current[index].focus()
                          setDescriptionEditClicked(index)
                        }}
                        onMouseDown={(event) => event.preventDefault()}
                      >
                        <EditIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            )
          }
        />
      )
    }
    return (
      <ImageListItem key={index} className={classes.imageListItem}>
        {imageViewError ? (
          <img
            src={ImageNotFound}
            data-testid={`img-${imageData.id}`}
            alt={'Error loading image'}
          />
        ) : (
          <img
            src={imgSrc}
            loading="lazy"
            id={!fromUpload ? imageData.id : undefined}
            data-testid={`img-${imageData.id}`}
            alt={imageData.description}
            title={imageData.title}
            onClick={() => openImageModal(imageData)}
            className={
              imageUploadedError
                ? classes.imageUploadErrorStyle
                : classes.imagesStyle
            }
          />
        )}
        {bottomBar()}
        {TopBar()}
      </ImageListItem>
    )
  }

  const openImageModal = (data) => {
    setShowImageDialog(true)
    setImageDailogData(data)
  }

  const handleClose = () => {
    setShowImageDialog(false)
  }

  function getImgInDialog() {
    const imgSrc = getImgSrc(imageDailogData)

    return (
      <img
        src={imgSrc}
        width={breakpoint ? 600 : 330}
        alt={imageDailogData?.title}
        title={imageDailogData?.title}
      />
    )
  }

  function loader(index) {
    return (
      <ImageListItem key={index}>
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          className={classes.loaderGrid}
        >
          <CircularProgress size="2rem" key={index} />
        </Grid>
      </ImageListItem>
    )
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={12}>
          <ImageList gap={30} cols={columnCount} data-testid="image-list">
            {images.map((imageData, index) =>
              imageData.loading === true
                ? loader(index)
                : getImgToRender(imageData, index),
            )}
          </ImageList>
        </Grid>
      </Grid>
      <Dialog
        open={showImageDialog}
        onClose={() => setShowImageDialog(false)}
        aria-labelledby="image-dialog-title"
        aria-describedby="image-dialog-description"
        maxWidth="lg"
        className={classes.imageDialog}
      >
        <DialogTitle id="image-dialog-title">
          {imageDailogData?.description}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            className={classes.closeIcon}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent id="image-dialog-description">
          {getImgInDialog()}
        </DialogContent>
      </Dialog>
      {renderDeleteDialog()}
    </>
  )
}

export default WorkOrderImageList
