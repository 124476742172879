/* eslint-disable react-hooks/exhaustive-deps */
import { Alert } from '@mui/material'
import MatAppBar from '../MatAppBar/MatAppBar'
import { useState, useEffect } from 'react'
import { connect, useSelector } from 'react-redux'
import { makeStyles, createStyles } from '@mui/styles'
import { setShouldRender } from '../ProgressOverlay/store/actionCreator'
import CacheListManager from '../CacheListManager/CacheListManager'
import {
  INPUT_PERFORMANCE_DELAY,
  SCHEDULE_OR_ASSIGNMENT,
  WORK_ORDER_FILTER_PROPERTIES,
  api,
} from '../../globalConstants'
import { fetchData } from '../../service/HttpService'
import { setSafetyWorkorderList } from '../WorkOrderList/WorkOrderListStore/actionCreator'
import { setAssignments } from '../WorkOrderList/WorkOrderListStore/actionCreator'
import { useAuth } from '@praxis/component-auth'
import StatusAlert from '../StatusAlert/StatusAlert'
import CommonCardList from '../CommonCardList/CommonCardList'
import { WORK_ORDERS } from '../../globalConstants'
import { filterAssignmentsBy7Days } from '../../utils'
import useSearch from '../../utils/hooks/useSearch'

const useStyles = makeStyles((theme) =>
  createStyles({
    cardHeader: {
      background: theme.palette.grey[300],
    },
    actionIcon: {
      margin: theme.spacing(1, 0.5, 0, 0),
    },
    cardContent: {
      padding: theme.spacing(1),
    },
    warningError: {
      margin: theme.spacing(1),
    },
  }),
)

const SafetyWorkOrders = ({
  safetyWorkorders,
  setShouldRender,
  lastUpdated,
  storeId,
  setSafetyWorkorderList,
  setAssignments,
  toggle,
}) => {
  const URL_SEARCH_KEY = 'search'
  const classes = useStyles()
  const auth = useAuth()
  const userId = auth.session.userInfo.lanId?.toUpperCase()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const { isDCUser } = useSelector((state) => ({
    ...state.userInfoReducer,
  }))

  const [filteredWorkOrders, onSearch] = useSearch(
    URL_SEARCH_KEY,
    safetyWorkorders || [],
    WORK_ORDER_FILTER_PROPERTIES(isDCUser),
    true,
    INPUT_PERFORMANCE_DELAY,
  )

  const safetyWorkordersLength = safetyWorkorders?.length

  useEffect(() => {
    const getOpenWorkOrders = async () => {
      if (!safetyWorkordersLength && !loading) {
        try {
          setShouldRender(true, `Loading...`, `Safety ${WORK_ORDERS}`)
          setLoading(true)
          setError(false)
          const response = await fetchData(api.openWorkOrders(storeId))
          setSafetyWorkorderList(response.woDetails)
        } catch (error) {
          setError(true)
        } finally {
          setLoading(false)
          setShouldRender(false)
        }
      }
    }
    getOpenWorkOrders()
  }, [safetyWorkordersLength])

  const infoMessage = `${filteredWorkOrders?.length} / ${safetyWorkordersLength} Safety Work Orders`

  const getAssignments = async () => {
    try {
      setShouldRender(true, 'Loading...', SCHEDULE_OR_ASSIGNMENT(isDCUser))
      const response = await fetchData(api.myAssignments(storeId, userId))
      const assigmentsScheduledThisWeek = filterAssignmentsBy7Days(
        response.assignmentsdata,
      )
      setLoading(true)
      setAssignments(assigmentsScheduledThisWeek)
    } catch (error) {
      setError(true)
    } finally {
      setLoading(false)
      setShouldRender(false)
    }
  }

  useEffect(() => {
    return () => setSafetyWorkorderList([])
  }, [])

  useEffect(() => {
    safetyWorkordersLength && getAssignments()
  }, [toggle, safetyWorkordersLength])

  return (
    <>
      <MatAppBar
        arrowBack
        text="Safety Work Orders"
        search={{
          onSearch: (input) => onSearch(input),
          searchKey: URL_SEARCH_KEY,
        }}
      />
      {error && !loading && (
        <Alert severity="error" className={classes.warningError}>
          Unable to connect to {WORK_ORDERS} service.
        </Alert>
      )}

      {!error && safetyWorkordersLength === 0 && !loading && (
        <Alert
          severity="warning"
          data-testid={'no-data-text'}
          className={classes.warningError}
        >
          There are no safety {WORK_ORDERS} to display.
        </Alert>
      )}

      {safetyWorkordersLength > 0 && !loading && (
        <CacheListManager
          lastUpdated={lastUpdated}
          listLength={safetyWorkordersLength}
          clearMethod={() => setSafetyWorkorderList([])}
        />
      )}

      {filteredWorkOrders && !loading ? (
        <>
          <StatusAlert text={infoMessage} />
          <CommonCardList
            listData={filteredWorkOrders}
            filteredWorkOrdersLength={safetyWorkordersLength}
          />
        </>
      ) : null}
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    safetyWorkorders: state.workOrderListReducer.safetyWorkorders,
    storeId: state.storeInfoReducer.storeId,
    lastUpdated: state.workOrderListReducer.lastUpdated.safetyWorkorders,
    toggle: state.workOrderListReducer.toggle,
  }
}

const mapDispatchToProps = {
  setShouldRender,
  setSafetyWorkorderList,
  setAssignments,
}

export default connect(mapStateToProps, mapDispatchToProps)(SafetyWorkOrders)
