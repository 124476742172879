import { SET_AREAS_OF_STORE, SET_SELECTED_AREA } from './actionType'

const storedItem = JSON.parse(sessionStorage.getItem('selectedArea'))

export const initialState = {
  locationdata: [],
  selectedArea: storedItem ? storedItem : {},
}

export default function areaOfStoreReducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_AREAS_OF_STORE: {
      const newState = {
        ...state,
        locationdata: action.areas,
      }
      return newState
    }
    case SET_SELECTED_AREA: {
      const selectedArea = action.area
      const newState = {
        ...state,
        selectedArea: selectedArea,
      }
      sessionStorage.setItem('selectedArea', JSON.stringify(selectedArea))
      return newState
    }

    default:
      return state
  }
}
