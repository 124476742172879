/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Alert } from '@mui/material'
import { fetchData } from '../../service/HttpService'
import { getURLSearchParams } from '../../windowManager'
import {
  setLoadedWonum,
  setLoading,
  setSurveyAnswers,
} from '../../components/WorkOrderDetailsSurvey/store/actionCreator'
import { useAuth } from '@praxis/component-auth'
import { setShouldRender } from '../../components/ProgressOverlay/store/actionCreator'

const useGetSurveyAnswers = () => {
  const urlSearchParams = getURLSearchParams()
  const woNumFromUrl = parseInt(urlSearchParams.get('wonum'))
  const dispatch = useDispatch()
  const [error, setError] = useState(false)
  const { surveyAnswers, loadedWonum, surveyLoading } = useSelector(
    (state) => state.surveyAnswersReducer,
  )
  const auth = useAuth()
  const userId = auth.session.userInfo?.lanId

  useEffect(() => {
    async function loadSurveyAnswers() {
      if (
        (surveyAnswers === null || parseInt(loadedWonum) !== woNumFromUrl) &&
        !surveyLoading
      ) {
        try {
          dispatch(setLoading(true))
          dispatch(setSurveyAnswers({}))
          dispatch(setShouldRender(true, 'Loading Survey Questions...'))
          const answers = await fetchData(
            `/mam/specifications/wonum/${woNumFromUrl}/user/${userId}?`,
            null,
          )
          answers && dispatch(setSurveyAnswers(answers))
          dispatch(setLoadedWonum(woNumFromUrl))
        } catch (error) {
          setError(true)
        } finally {
          dispatch(setShouldRender(false))
          dispatch(setLoading(false))
        }
      } else {
        dispatch(setLoading(false))
      }
    }
    loadSurveyAnswers()
  }, [])

  return error && !surveyLoading ? (
    <Alert severity="error">Couldn't get survey answers</Alert>
  ) : !error && !surveyLoading ? (
    true
  ) : null
}

export default useGetSurveyAnswers
