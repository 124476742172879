import Dialog from '@mui/material/Dialog'
import { Grid, LinearProgress, Typography } from '@mui/material'
import { makeStyles, createStyles } from '@mui/styles'
import InfoIcon from '@mui/icons-material/Info'
import { connect } from 'react-redux'
import {
  COLOR_GREY_CONTRAST_WEAK,
  COLOR_BACKGROUND_DARKER_GRAY,
} from '../../globalColors'
const useStyles = makeStyles((theme) =>
  createStyles({
    gridParent: {
      padding: theme.spacing(3),
    },
    header: {
      marginLeft: theme.spacing(2),
      fontWeight: 'bold',
    },
    gridBody: {
      paddingTop: theme.spacing(2),
    },
    progressColor: {
      backgroundColor: COLOR_BACKGROUND_DARKER_GRAY,
    },
    progressMainColor: {
      backgroundColor: COLOR_GREY_CONTRAST_WEAK,
    },
  }),
)

const ProgressOverlay = ({ headerText, bodyText, shouldRender }) => {
  const classes = useStyles()

  return (
    <Dialog open={shouldRender} data-testid="dialog" transitionDuration={0}>
      <LinearProgress
        classes={{
          colorPrimary: classes.progressMainColor,
          bar1Indeterminate: classes.progressColor,
          bar2Indeterminate: classes.progressColor,
        }}
      />
      <Grid container className={classes.gridParent}>
        <Grid container alignItems="center">
          <InfoIcon />
          <Typography className={classes.header} variant="h6" noWrap>
            {headerText}
          </Typography>
        </Grid>
        {bodyText && (
          <Grid className={classes.gridBody}>
            <Typography>{bodyText}</Typography>
          </Grid>
        )}
      </Grid>
    </Dialog>
  )
}

const mapStateToProps = (state) => {
  return {
    shouldRender: state.progressOverlayReducer.shouldRender,
    headerText: state.progressOverlayReducer.headerText,
    bodyText: state.progressOverlayReducer.bodyText,
  }
}

export default connect(mapStateToProps, null)(ProgressOverlay)
