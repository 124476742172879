/* eslint-disable react-hooks/exhaustive-deps */
import { Alert } from '@mui/material'
import MatAppBar from '../MatAppBar/MatAppBar'
import { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { setCompletedWorkOrderList } from './store/actionCreator'
import { fetchData } from '../../service/HttpService'
import { setShouldRender } from '../ProgressOverlay/store/actionCreator'
import CacheListManager from '../CacheListManager/CacheListManager'
import useSearch from '../../utils/hooks/useSearch'
import { WORK_ORDER_FILTER_PROPERTIES } from '../../globalConstants'
import StatusAlert from '../StatusAlert/StatusAlert'
import CommonCardList from '../CommonCardList/CommonCardList'

const CompleteWorkOrders = ({
  storeId,
  completedWorkOrders,
  setCompletedWorkOrderList,
  setShouldRender,
  lastUpdated,
  isDCUser,
}) => {
  const URL_SEARCH_KEY = 'search'
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const completedWorkOrdersLength = completedWorkOrders.length

  const [filteredWorkOrders, onSearch] = useSearch(
    URL_SEARCH_KEY,
    completedWorkOrders,
    WORK_ORDER_FILTER_PROPERTIES(isDCUser),
  )

  const filteredWorkOrdersLength = filteredWorkOrders?.length
  const infoMessage = `${filteredWorkOrdersLength} / ${completedWorkOrdersLength} Completed Work Order(s).`

  useEffect(() => {
    const getCompWo = async () => {
      if (!completedWorkOrdersLength && !loading) {
        try {
          setShouldRender(true, 'Loading...', 'Completed Work Orders')
          setLoading(true)
          setError(false)
          const dayCount = isDCUser ? 10 : 30
          const response = await fetchData(
            `/mam/workorderscomp/siteId/${storeId}?dayCount=${dayCount}`,
          )
          const woDetailsComp = response.woDetailsComp
          setCompletedWorkOrderList(woDetailsComp)
        } catch (error) {
          setError(true)
        } finally {
          setLoading(false)
          setShouldRender(false)
        }
      }
    }
    getCompWo()
  }, [completedWorkOrdersLength, storeId])

  return (
    <>
      <MatAppBar
        arrowBack
        text="Completed WOs"
        search={{
          onSearch: (input) => onSearch(input),
          searchKey: URL_SEARCH_KEY,
        }}
      />
      <CacheListManager
        lastUpdated={lastUpdated}
        listLength={completedWorkOrdersLength}
        clearMethod={() => setCompletedWorkOrderList([])}
      />
      {filteredWorkOrdersLength && !error ? (
        <>
          <StatusAlert text={infoMessage} />
          <CommonCardList
            listData={filteredWorkOrders}
            filteredWorkOrdersLength={filteredWorkOrdersLength}
          />
        </>
      ) : null}

      {!error && !filteredWorkOrdersLength && !loading && (
        <Alert severity="warning" data-testid={'no-data-text'}>
          There are no completed work orders to display.
        </Alert>
      )}
      {error && !loading && (
        <Alert severity="error">
          Unable to connect to completed work orders service.
        </Alert>
      )}
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    completedWorkOrders: state.completedWorkOrdersReducer.completedWorkOrders,
    storeId: state.storeInfoReducer.storeId,
    lastUpdated: state.completedWorkOrdersReducer.lastUpdated,
    isDCUser: state.userInfoReducer.isDCUser,
  }
}

const mapDispatchToProps = {
  setCompletedWorkOrderList,
  setShouldRender,
}

export default connect(mapStateToProps, mapDispatchToProps)(CompleteWorkOrders)
