import {
  SET_ASSET,
  SET_ASSET_ATTRIBUTES,
  SET_ASSET_WORKORDERS_LIST,
} from './actionType'

export const initialState = { asset: {}, workorders: [], assetAttributes: {} }

export default function assetReducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_ASSET: {
      const newState = {
        ...state,
        asset: action.asset,
      }
      return newState
    }
    case SET_ASSET_WORKORDERS_LIST: {
      const newState = {
        ...state,
        workorders: action.workorders,
      }
      return newState
    }
    case SET_ASSET_ATTRIBUTES: {
      const newState = {
        ...state,
        assetAttributes: action.assetAttributes,
      }
      return newState
    }
    default:
      return state
  }
}
