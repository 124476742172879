export const COLOR_THEME_ACCENT = '#333333'
export const COLOR_TARGET_RED = '#AA0000'
export const COLOR_INDIGO = '#3D70D6'
export const COLOR_GREY_CONTRAST_WEAK = '#FFFFFF'
export const COLOR_BLACK = '#000000'
export const COLOR_BACKGROUND_GREY = '#f7f7f7'
export const COLOR_FONT_BLUE = '#3E72DB'
export const COLOR_BACKGROUND_DARKER_GRAY = '#d6d6d6'
export const COLOR_DEFAULT_GREY = '#B3B3B3'
export const COLOR_TARGET_RED_HIGHLIGHT = '#CC0000'
export const COLOR_WARNING_YELLOW = '#EDB508'
