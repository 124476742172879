/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { useAuth } from '@praxis/component-auth'
import { useSelector, useDispatch } from 'react-redux'
import {
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Checkbox,
  Chip,
  ListItemText,
  Alert,
  Divider,
  IconButton,
} from '@mui/material'
import {
  NOTE_TYPE,
  SELECT_NOTE_TYPE,
  api,
  WORK_LOG_NOTE_TYPE_ERROR,
  CLEAR_FILTERS,
  SELECT_ALL,
  FILTER_NOTE_TYPE,
  ADD_NOTE,
} from '../../../globalConstants'
import { WORKLOG_NOTE_TYPE_OPTIONS } from '../../../globalPermissions'
import { setShouldRender } from '../../ProgressOverlay/store/actionCreator'
import { fetchData } from '../../../service/HttpService'
import { makeStyles, createStyles } from '@mui/styles'
import { KeyboardArrowDown } from '@mui/icons-material'
import { setWorkLogNoteTypes } from '../store/actionCreator'
import ClearIcon from '@mui/icons-material/Clear'
import { Cancel } from '@mui/icons-material'
import { getURLSearchParams } from '../../../windowManager'

const useStyles = makeStyles((theme) =>
  createStyles({
    whiteBackground: {
      backgroundColor: '#FFF',
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      maxWidth: 300,
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: 2,
      backgroundColor: 'rgba(0, 0, 0, 0.12)',
    },
    chipOutside: {
      backgroundColor: 'rgba(0, 0, 0, 0.12)',
      margin: theme.spacing(1),
    },
    noLabel: {
      marginTop: theme.spacing(3),
    },
    overflowEllipsis: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  }),
)

const WorkLogNotesSelect = ({
  filter,
  selectedWorklogNoteType,
  setSelectedWorklogNoteType,
  security,
}) => {
  const auth = useAuth()
  const classes = useStyles()
  const dispatch = useDispatch()
  const { session } = auth
  const userADGroups = session.userInfo?.memberOf ?? []
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [selectOpen, setSelectOpen] = useState(false)
  const userId = auth.session.userInfo.lanId

  const { worklogNoteTypes = null } = useSelector(
    (state) => state.workLogDetailsReducer,
  )
  const { worklogDetails = null } = useSelector((state) => ({
    ...state.workLogDetailsReducer,
  }))

  const { isDCUser } = useSelector((state) => ({
    ...state.userInfoReducer,
  }))

  const urlSearchParams = getURLSearchParams()
  const isAddNoteRoute = urlSearchParams.get('from') === ADD_NOTE
  const noteType = isAddNoteRoute ? 'noteaccess' : 'expectationaccess'

  useEffect(() => {
    try {
      dispatch(setShouldRender(true, 'Loading...'))
      const getWorkLogNoteTypes = async () => {
        const workLogNotesTypes = await fetchData(api.worklogNoteTypes(userId))
        dispatch(setWorkLogNoteTypes(workLogNotesTypes?.prtypedata ?? []))
        isDCUser && setSelectedWorklogNoteType(['DC_LOGNOTE'])
      }
      getWorkLogNoteTypes()
    } catch (error) {
      setError(error)
    } finally {
      setLoading(false)
      dispatch(setShouldRender(false))
    }
  }, [])

  const handleDelete = (e, value) => {
    e.preventDefault()
    setSelectedWorklogNoteType(
      selectedWorklogNoteType.filter((val) => val !== value),
    )
  }

  const handleChange = (e) => {
    const isSelectAll =
      e?.target?.value?.find?.((val) => val === SELECT_ALL) &&
      !selectedWorklogNoteType.find((val) => val === SELECT_ALL)
    const isUnSelectAll =
      !e?.target?.value?.find?.((val) => val === SELECT_ALL) &&
      selectedWorklogNoteType?.find?.((val) => val === SELECT_ALL)
    const isUnSelectIndividualValue =
      e?.target?.value?.find?.((val) => val === SELECT_ALL) &&
      e?.target?.value?.length - 1 < worklogNoteTypes?.length
    if (isSelectAll) {
      setSelectedWorklogNoteType(
        worklogNoteTypes
          ?.filter((val) =>
            filter
              ? worklogDetails?.some((val2) => val.value === val2.logtype)
              : val,
          )
          .map?.((val) => val.value)
          .concat(SELECT_ALL),
      )
    } else if (isUnSelectAll) {
      setSelectedWorklogNoteType([])
    } else {
      if (isUnSelectIndividualValue) {
        setSelectedWorklogNoteType(
          e.target.value?.filter?.((val) => val !== SELECT_ALL),
        )
      } else {
        setSelectedWorklogNoteType(e.target.value)
      }
    }
  }

  const options = () => {
    const filteredWorkLogNoteTypes = !security
      ? worklogNoteTypes
      : worklogNoteTypes?.filter?.((val) =>
          userADGroups.some((adGroup) =>
            (
              WORKLOG_NOTE_TYPE_OPTIONS?.find?.(
                (innerval) => innerval.value === val.value,
              )?.[noteType] ?? []
            )?.includes(adGroup),
          ),
        )
    const filteredList = filteredWorkLogNoteTypes?.filter((val) =>
      filter ? worklogDetails?.some((val2) => val.value === val2.logtype) : val,
    )

    return (
      filteredList?.length > 0 &&
      !error &&
      !loading &&
      filteredList.map?.((worklogNoteType, index) => {
        return [
          index === 0 && filter && (
            <MenuItem key="select-all" value={SELECT_ALL}>
              <Checkbox
                checked={selectedWorklogNoteType.includes(SELECT_ALL)}
              />
              <ListItemText
                primary={'Select All'}
                classes={{ primary: classes.overflowEllipsis }}
              />
              <IconButton
                aria-label="close-select"
                onClick={(e) => {
                  e.stopPropagation()
                  setSelectOpen(false)
                }}
              >
                <ClearIcon />
              </IconButton>
            </MenuItem>
          ),
          index === 0 && filter && <Divider />,
          <MenuItem
            key={index}
            value={worklogNoteType.value}
            data-testid="work-log-note-type-options"
          >
            {filter && (
              <Checkbox
                checked={selectedWorklogNoteType.includes(
                  worklogNoteType.value,
                )}
              />
            )}
            <ListItemText
              primary={`${worklogNoteType.value}${
                worklogNoteType.description
                  ? ` - ${worklogNoteType.description}`
                  : ''
              }`}
              classes={{ primary: classes.overflowEllipsis }}
            />
          </MenuItem>,
        ]
      })
    )
  }
  return (
    <>
      <div>
        <div>
          <FormControl fullWidth>
            <InputLabel>
              {filter ? FILTER_NOTE_TYPE : SELECT_NOTE_TYPE}
            </InputLabel>
            {filter ? (
              <Select
                data-testid="work-log-note-type-select"
                multiple
                value={selectedWorklogNoteType}
                label={filter ? FILTER_NOTE_TYPE : SELECT_NOTE_TYPE}
                aria-label={NOTE_TYPE}
                onChange={handleChange}
                onClose={() => setSelectOpen(false)}
                onOpen={() => setSelectOpen(true)}
                open={selectOpen}
                IconComponent={KeyboardArrowDown}
                renderValue={(selected) => (
                  <div className={classes.chips}>
                    {selected
                      .filter((val) => val !== SELECT_ALL)
                      .map((value) => (
                        <Chip
                          key={value}
                          label={value}
                          clickable
                          deleteIcon={
                            <Cancel
                              onMouseDown={(event) => event.stopPropagation()}
                            />
                          }
                          className={classes.chip}
                          onDelete={(e) => handleDelete(e, value)}
                          onMouseDown={(e) => {
                            e.stopPropagation()
                          }}
                        />
                      ))}
                    {selected.filter((val) => val !== SELECT_ALL)?.length >
                      0 && (
                      <Chip
                        label={CLEAR_FILTERS}
                        clickable
                        className={classes.chip}
                        onDelete={(e) => setSelectedWorklogNoteType([])}
                        onMouseDown={(e) => {
                          e.stopPropagation()
                          setSelectedWorklogNoteType([])
                        }}
                      />
                    )}
                  </div>
                )}
                inputProps={{
                  'data-testid': 'work-log-note-type-select-input',
                }}
              >
                {options()}
              </Select>
            ) : (
              <Select
                value={selectedWorklogNoteType}
                label={SELECT_NOTE_TYPE}
                aria-label={NOTE_TYPE}
                onChange={handleChange}
                data-testid="work-log-note-type-select"
              >
                {options()}
              </Select>
            )}
          </FormControl>
        </div>
      </div>
      {error && !loading && (
        <Alert severity="error">{WORK_LOG_NOTE_TYPE_ERROR}</Alert>
      )}
    </>
  )
}

export default WorkLogNotesSelect
