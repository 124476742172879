import { SET_STORE_REQUESTS_LIST } from './actionType'
import moment from 'moment'

export const initialState = {
  storeRequests: [],
  lastUpdated: null,
}

const getSorted = (data) =>
  data?.sort((a, b) => new Date(b.reportdate) - new Date(a.reportdate))

export default function storeRequestListReducer(
  state = initialState,
  action = {},
) {
  switch (action.type) {
    case SET_STORE_REQUESTS_LIST: {
      const newState = {
        ...state,
        storeRequests: getSorted(action.storeRequests || []),
        lastUpdated: moment().format('LLL'),
      }
      return newState
    }

    default: {
      return state
    }
  }
}
