/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { MapContainer, TileLayer, Marker, Tooltip, Circle } from 'react-leaflet'
import { getURLSearchParams } from '../../windowManager'
import L from 'leaflet'
import { makeStyles, createStyles } from '@mui/styles'
import targetLogo from './icons/target-logo.jpg'
import markerIconGreen from './icons/marker-icon-2x-green.png'
import markerIconRed from './icons/marker-icon-2x-red.png'
import { fetchData } from '../../service/HttpService'
import { api, WORK_ORDER_NUMBER, VISIT_ID } from '../../globalConstants'
import { setShouldRender } from '../ProgressOverlay/store/actionCreator'
import { useAuth } from '@praxis/component-auth'
import { Paper, Typography } from '@mui/material'

const useStyles = makeStyles((theme) =>
  createStyles({
    mapContainer: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    },
    legend: {
      position: 'absolute',
      zIndex: 1000,
      top: theme.spacing(1),
      right: theme.spacing(1),
      padding: theme.spacing(1),
      borderStyle: 'solid',
      borderWidth: 2,
      borderColor: theme.palette.background.default,
    },
  }),
)

const WorkLogVisitMap = () => {
  const CHECK_IN_RADIUS_IN_METERS = 322
  const CHECK_IN = 'Check In'
  const CHECK_OUT = 'Check Out'
  const NO = 'No Coordinates Found'

  const [siteLatitude, setSiteLatitude] = useState(0)
  const [siteLongitude, setSiteLongitude] = useState(0)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const classes = useStyles()
  const auth = useAuth()
  const urlSearchParams = getURLSearchParams()
  const visitId = urlSearchParams.get('visit_id')
  const workOrderNumber = urlSearchParams.get('reference_id')
  const woLocationId = urlSearchParams.get('location_id')
  const checkInLatitude = Number.parseFloat(urlSearchParams.get('check_in_lat'))
  const checkOutLatitude = Number.parseFloat(
    urlSearchParams.get('check_out_lat'),
  )
  const checkInLongitude = Number.parseFloat(
    urlSearchParams.get('check_in_long'),
  )
  const checkOutLongitude = Number.parseFloat(
    urlSearchParams.get('check_out_long'),
  )
  const checkInGeofence = urlSearchParams.get('check_in_geofence')
  const checkOutGeofence = urlSearchParams.get('check_out_geofence')
  const checkIn = [checkInLatitude, checkInLongitude]
  const checkOut = [checkOutLatitude, checkOutLongitude]
  const siteLocation = [siteLatitude, siteLongitude]
  const getLatLngBounds = [checkIn, checkOut, siteLocation]
  const userId = auth.session.userInfo.lanId

  const targetIcon = L.icon({
    iconUrl: targetLogo,
    iconSize: 25,
  })
  const commonIconParams = {
    iconSize: [25, 41],
  }
  const greenIcon = new L.Icon({
    iconUrl: markerIconGreen,
    ...commonIconParams,
  })
  const redIcon = new L.Icon({
    iconUrl: markerIconRed,
    ...commonIconParams,
  })

  const getIcon = (geoFence) => {
    return geoFence === 'Inside' ? greenIcon : redIcon
  }

  const typographyVariant = 'body1'

  const bounds = []
  for (var i = 0; i < getLatLngBounds.length; i++) {
    const getLatLngBound = getLatLngBounds[i]
    if (getLatLngBound[0] !== 0 || getLatLngBound[1] !== 0) {
      bounds.push(getLatLngBounds[i])
    }
  }

  useEffect(() => {
    const getCoordinates = async () => {
      try {
        setShouldRender(true, 'Loading Store Coordinates...')
        const storeCoordResponse = await fetchData(api.storeCoordinates(userId))
        setSiteLatitude(storeCoordResponse.latitude)
        setSiteLongitude(storeCoordResponse.longitude)
      } catch (error) {
        setError(error)
      } finally {
        setLoading(false)
        setShouldRender(false)
      }
    }
    getCoordinates()
  }, [])

  return (
    <>
      {!error && !loading && (
        <MapContainer
          className={classes.mapContainer}
          bounds={bounds}
          boundsOptions={{ padding: [50, 50] }}
        >
          <TileLayer
            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Circle
            center={siteLocation}
            fillColor={'blue'}
            radius={CHECK_IN_RADIUS_IN_METERS}
          />
          <Marker position={siteLocation} icon={targetIcon}>
            <Tooltip permanent direction={'top'}>
              T{woLocationId}
            </Tooltip>
          </Marker>

          {checkIn !== 0 && (
            <Marker position={checkIn} icon={getIcon(checkInGeofence)}>
              <Tooltip permanent direction={'left'}>
                {CHECK_IN}
              </Tooltip>
            </Marker>
          )}

          {checkOut !== 0 && (
            <Marker position={checkOut} icon={getIcon(checkOutGeofence)}>
              <Tooltip permanent direction={'right'}>
                {CHECK_OUT}
              </Tooltip>
            </Marker>
          )}
          <Paper className={classes.legend}>
            <Typography variant={typographyVariant}>
              <strong>{WORK_ORDER_NUMBER}:</strong> {workOrderNumber}
            </Typography>
            <Typography variant={typographyVariant}>
              <strong>{VISIT_ID}:</strong> {visitId}
            </Typography>
            {checkInLongitude === 0 && (
              <Typography variant={typographyVariant}>
                <strong>{CHECK_IN}:</strong> {NO}
              </Typography>
            )}
            {checkOutLongitude === 0 && (
              <Typography variant={typographyVariant}>
                <strong>{CHECK_OUT}:</strong> {NO}
              </Typography>
            )}
          </Paper>
        </MapContainer>
      )}
    </>
  )
}

export default WorkLogVisitMap
