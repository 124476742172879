/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useRef } from 'react'
import { useAuth } from '@praxis/component-auth'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
import {
  Container,
  Grid,
  Alert,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormGroup,
} from '@mui/material'
import {
  DEFAULT_ERROR_MESSAGE,
  LVM,
  SELECT_LVM_OPTION,
  ADD_NOTE,
  ADD_EXPECTATION_NOTE,
  WORKLOG_ENTRY,
  SUMMARY_TEXT,
  DETAILS_TEXT,
  CANCEL_TEXT,
  SUBMIT_TEXT,
  SUMMARY_CHARACTER_LIMIT,
} from '../../../globalConstants'
import { LVM_OPTIONS } from '../../../globalPermissions'
import { setShouldRender } from '../../ProgressOverlay/store/actionCreator'
import useGetWorkOrderDetails from '../../../utils/hooks/useGetWorkOrderDetails'
import { postData, fetchData } from '../../../service/HttpService'
import MatAppBar from '../../MatAppBar/MatAppBar'
import { getURLSearchParams } from '../../../windowManager'
import DynamicTextField from '../../common/DynamicTextField'
import { makeStyles, createStyles } from '@mui/styles'
import WorkLogNotesSelect from './WorkLogNotesSelect'
import { setWorkOrder } from '../../WorkOrderDetails/store/actionCreator'
import { setLvm } from '../../../utils/hooks/workOrderUtil'
import CancelIcon from '@mui/icons-material/Cancel'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
const useStyles = makeStyles((theme) =>
  createStyles({
    cancelButton: {
      marginRight: theme.spacing(3),
    },
    radioButtons: {
      margin: theme.spacing(1),
    },
  }),
)

const CreateWorkLog = () => {
  useNavigate()
  const auth = useAuth()
  const { session } = auth
  const userADGroups = session.userInfo?.memberOf ?? []
  const urlSearchParams = getURLSearchParams()
  const woNum = parseInt(urlSearchParams.get('wonum'))
  const isAddNoteRoute = urlSearchParams.get('from') === ADD_NOTE
  const [error, setError] = useState(false)
  const [errorMsg, setErrorMsg] = useState(false)

  const woDetailFetched = useGetWorkOrderDetails()

  const userId = auth.session.userInfo.lanId
  const [selectedWorklogNoteType, setSelectedWorklogNoteType] = useState('')
  const [selectedLVMOption, setSelectedLVMOption] = useState('')
  const [summaryCount, setSummaryCount] = useState(0)
  const [summaryData, setSummaryData] = useState('')
  const [detailsData, setDetailsData] = useState('')
  const [removeVendorActionPlan, setRemoveVendorActionPlan] = useState('false')

  const { wodetails, isDCUser } = useSelector((state) => ({
    ...state.workOrderDetailsReducer,
    ...state.userInfoReducer,
  }))
  const myRef = useRef(null)
  const classes = useStyles()
  const dispatch = useDispatch()

  const currentLVM = setLvm(
    wodetails?.tgt_lvm1,
    wodetails?.tgt_lvm2,
    wodetails?.tgt_lvm3,
    wodetails?.tgt_lvm4,
    wodetails?.tgt_lvm5,
  )
  const DATA_MIN_LENGTH = 0
  const summaryDataTrimLength = summaryData.trim().length
  const isVendorActionPlan = currentLVM === 'Vendor Action Plan' ? true : false

  const isDisabled =
    !summaryData ||
    summaryDataTrimLength === DATA_MIN_LENGTH ||
    summaryDataTrimLength > SUMMARY_CHARACTER_LIMIT ||
    !detailsData ||
    detailsData.trim().length === DATA_MIN_LENGTH ||
    (!isAddNoteRoute
      ? !selectedLVMOption && !isVendorActionPlan
      : false || !selectedWorklogNoteType)

  const getLvmData = () => {
    const selectedLVM = LVM_OPTIONS.find(
      (val) => val.value === selectedLVMOption,
    )?.lvmSelection
    let lvmData = {}

    if (isVendorActionPlan && removeVendorActionPlan === 'true') {
      lvmData = { ...lvmData, 'tgt-lvm3': false }
    }
    if (selectedLVM) {
      lvmData = { ...lvmData, [selectedLVM]: true }
    }
    return [lvmData]
  }

  const handleChange = (value) => {
    value === 'false' && setSelectedLVMOption('')
    setRemoveVendorActionPlan(value)
  }

  const submitWorkLog = async () => {
    const worklogType = isDCUser
      ? selectedWorklogNoteType[0]
      : selectedWorklogNoteType
    const requestBody = {
      'modify-by': userId,
      'create-date': moment().format(),
      'create-by': userId,
      siteid: wodetails.wositeid,
      orgid: 'TGTSTORE',
      'client-viewable': false,
      'modify-date': moment().format(),
      class: 'WORKORDER',
      'log-type': worklogType,
      description: summaryData,
      'long-description': detailsData,
      'record-key': woNum,
    }

    const lvmData = Object.assign(!isAddNoteRoute ? getLvmData() : [])
    const createData = Object.assign(requestBody, ...lvmData)
    try {
      dispatch(setShouldRender(true, 'Submitting Data...'))

      await postData(
        `/maximo/worklog/${woNum}`,
        JSON.stringify(createData),
      ).catch(async (err) => {
        throw new Error(err)
      })

      dispatch(setShouldRender(false))
      const response = await fetchData(`/mam/workorder/wonum/${woNum}`)
      dispatch(setWorkOrder(response.woDetails[0]))

      window.history.back()
    } catch (error) {
      setErrorMsg(error?.message ? error.message : DEFAULT_ERROR_MESSAGE)
      setError(error)
    } finally {
      dispatch(setShouldRender(false))
    }
  }

  const handleSummaryChange = (input) => {
    setSummaryData(input)
    setSummaryCount(input.length)
  }
  const commonProps = {
    xs: 12,
    sm: 8,
    item: true,
  }

  const summaryHelperText = (
    <>
      <Grid container>
        <Grid item xs={8}>
          {`${summaryCount}/${SUMMARY_CHARACTER_LIMIT}`}
          <div>
            {`${
              summaryCount > SUMMARY_CHARACTER_LIMIT
                ? `Summary must not exceed ${SUMMARY_CHARACTER_LIMIT} characters.`
                : ''
            }
         `}
          </div>
        </Grid>
      </Grid>
    </>
  )

  return (
    <>
      <MatAppBar arrowBack text={`WO ${woNum}`} />
      {error && <Alert severity="error">{errorMsg}</Alert>}

      {woDetailFetched === true && (
        <Container>
          <Grid
            container
            sx={{ marginTop: 2 }}
            spacing={3}
            justifyContent="center"
          >
            <Grid container justifyContent="center">
              <Typography variant="h6">
                {!isAddNoteRoute ? ADD_EXPECTATION_NOTE : WORKLOG_ENTRY}
              </Typography>
            </Grid>
            {!isAddNoteRoute && (
              <>
                <Grid container justifyContent="center">
                  <Typography variant="h6">LVM: {currentLVM}</Typography>
                </Grid>
                {isVendorActionPlan && (
                  <Grid {...commonProps}>
                    <FormGroup row>
                      <Typography className={classes.radioButtons}>
                        Remove vendor action plan?
                      </Typography>
                      <RadioGroup
                        row
                        value={String(removeVendorActionPlan)}
                        onChange={(e) => handleChange(e.target.value)}
                      >
                        <FormControlLabel
                          value="true"
                          control={<Radio />}
                          label={'Yes'}
                        />
                        <FormControlLabel
                          value="false"
                          control={<Radio />}
                          label={'No'}
                        />
                      </RadioGroup>
                    </FormGroup>
                  </Grid>
                )}
                {(!isVendorActionPlan || removeVendorActionPlan === 'true') && (
                  <Grid {...commonProps}>
                    <FormControl fullWidth>
                      <InputLabel>{SELECT_LVM_OPTION}</InputLabel>
                      <Select
                        value={selectedLVMOption}
                        label={SELECT_LVM_OPTION}
                        aria-label={LVM}
                        onChange={(e) => setSelectedLVMOption(e.target.value)}
                        data-testid="lvm-select"
                      >
                        {LVM_OPTIONS.filter((val) =>
                          userADGroups.some((adGroup) =>
                            (val?.access ?? [])?.includes(adGroup),
                          ),
                        ).map((lvmOption, index) => {
                          return (
                            <MenuItem key={index} value={lvmOption.value}>
                              {lvmOption.label}
                            </MenuItem>
                          )
                        })}
                        {removeVendorActionPlan === 'true' && (
                          <MenuItem value={''}>Previous LVM</MenuItem>
                        )}
                      </Select>
                    </FormControl>
                  </Grid>
                )}
              </>
            )}
            <Grid {...commonProps}>
              <WorkLogNotesSelect
                selectedWorklogNoteType={selectedWorklogNoteType}
                setSelectedWorklogNoteType={setSelectedWorklogNoteType}
                security={true}
              />
            </Grid>
            <Grid {...commonProps}>
              <TextField
                label={SUMMARY_TEXT}
                variant="outlined"
                id="summary-textfield"
                multiline
                fullWidth
                rows={2}
                error={summaryCount > SUMMARY_CHARACTER_LIMIT}
                helperText={summaryHelperText ? summaryHelperText : ' '}
                FormHelperTextProps={{
                  component: 'div',
                }}
                onChange={(e) => handleSummaryChange(e.target.value)}
                data-testid="summary-textfield"
              />
            </Grid>
            <Grid {...commonProps}>
              <DynamicTextField
                onChange={(value) => setDetailsData(value)}
                myRef={myRef}
                label={DETAILS_TEXT}
              />
            </Grid>
            <Grid container {...commonProps} justifyContent="flex-end">
              <Button
                variant="outlined"
                onClick={() => window.history.back()}
                aria-label="Cancel"
                className={classes.cancelButton}
                startIcon={<CancelIcon />}
              >
                {CANCEL_TEXT}
              </Button>
              <Button
                variant="contained"
                onClick={() => submitWorkLog()}
                aria-label="Submit"
                disabled={isDisabled}
                startIcon={<CheckCircleIcon />}
              >
                {SUBMIT_TEXT}
              </Button>
            </Grid>
          </Grid>
        </Container>
      )}
    </>
  )
}

export default CreateWorkLog
