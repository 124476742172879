/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import {
  DialogTitle,
  Dialog,
  DialogContent,
  Switch,
  FormControlLabel,
  DialogActions,
  Button,
} from '@mui/material'
import useHashChange from '../../utils/hooks/useHashChange'
import { useDispatch, useSelector } from 'react-redux'

import {
  setCardCollapse,
  setCardPreferences,
} from '../Root/store/actionCreator'
import { deleteURLSearchParams } from '../../windowManager'
import { createStyles, makeStyles } from '@mui/styles'
import { WORK_VALIDATION } from '../../globalConstants'

const useStyles = makeStyles((theme) =>
  createStyles({
    toggleFields: {
      display: 'block',
    },
  }),
)

const CardSettingsDialog = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [resetDisabled, setResetDisabled] = useState(false)
  const [fieldsSelected, setFieldsSelected] = useState(
    JSON.parse(localStorage.getItem('cardPreferences')),
  )
  const { isDCUser, areaOrLocation } = useSelector((state) => ({
    ...state.userInfoReducer,
  }))

  const [cardSettingsOpen, setCardSettingsOpen] = useState(false)
  useHashChange('card_settings_open', setCardSettingsOpen)

  const [collapseSelected, setCollapseSelected] = useState(
    JSON.parse(localStorage.getItem('cardCollapse')) === 'true' ? true : false,
  )

  const onChangeCollapse = (e) => {
    setCollapseSelected(e.target.checked)
    const collapsedToString = e.target.checked.toString()
    dispatch(setCardCollapse(collapsedToString))
  }

  const onClose = () => {
    setCardSettingsOpen(false)
    deleteURLSearchParams([{ name: 'card_settings_open' }], true)
  }

  const onReset = () => {
    setResetDisabled(true)
    setCollapseSelected(false)
    dispatch(setCardCollapse('false'))
    const resetFields = {
      Time: true,
      Type: true,
      AssetName: true,
      AssetNumber: true,
      AssetTag: true,
      Area: true,
      Problem: true,
      Status: true,
      RedirectReason: true,
      LVM: true,
      WorkValidation: true,
      JobPlan: true,
      AssignedToVendor: true,
      CalcPriority: true,
      ReportedDate: true,
    }
    setFieldsSelected(resetFields)
    dispatch(setCardPreferences(resetFields))
  }

  useEffect(() => {
    fieldsSelected === '' && onReset()
  }, [])

  const onFieldChange = (e) => {
    setResetDisabled(false)
    const isChecked = e.target.checked
    setFieldsSelected({
      ...fieldsSelected,
      [e.target.name]: isChecked,
    })
    dispatch(
      setCardPreferences({
        ...fieldsSelected,
        [e.target.name]: isChecked,
      }),
    )
  }
  return (
    <Dialog open={cardSettingsOpen} onClose={() => onClose()}>
      <DialogTitle>Card Settings</DialogTitle>
      <DialogContent>
        <FormControlLabel
          control={
            <Switch
              name="Collapse All"
              checked={collapseSelected}
              onChange={(e) => onChangeCollapse(e)}
            />
          }
          label="Collapse All"
        />
        {!collapseSelected && (
          <>
            <FormControlLabel
              control={
                <Switch
                  name="Time"
                  checked={fieldsSelected?.Time}
                  onChange={(e) => onFieldChange(e)}
                />
              }
              className={classes.toggleFields}
              label="Time"
            />
            <FormControlLabel
              control={
                <Switch
                  name="Type"
                  checked={fieldsSelected?.Type}
                  onChange={(e) => onFieldChange(e)}
                />
              }
              className={classes.toggleFields}
              label="Type"
            />
            <FormControlLabel
              control={
                <Switch
                  name="AssetName"
                  checked={fieldsSelected?.AssetName}
                  onChange={(e) => onFieldChange(e)}
                />
              }
              className={classes.toggleFields}
              label="Asset Name"
            />
            <FormControlLabel
              control={
                <Switch
                  name="AssetNumber"
                  checked={fieldsSelected?.AssetNumber}
                  onChange={(e) => onFieldChange(e)}
                />
              }
              className={classes.toggleFields}
              label="Asset Number"
            />
            <FormControlLabel
              control={
                <Switch
                  name="Area"
                  checked={fieldsSelected?.Area}
                  onChange={(e) => onFieldChange(e)}
                />
              }
              className={classes.toggleFields}
              label={areaOrLocation}
            />
            <FormControlLabel
              control={
                <Switch
                  name="Problem"
                  checked={fieldsSelected?.Problem}
                  onChange={(e) => onFieldChange(e)}
                />
              }
              className={classes.toggleFields}
              label="Problem"
            />
            {isDCUser && (
              <FormControlLabel
                control={
                  <Switch
                    name="CalcPriority"
                    checked={fieldsSelected?.CalcPriority}
                    onChange={(e) => onFieldChange(e)}
                  />
                }
                label="Calculated Priority"
                className={classes.toggleFields}
              />
            )}
            <FormControlLabel
              control={
                <Switch
                  name="Status"
                  checked={fieldsSelected?.Status}
                  onChange={(e) => onFieldChange(e)}
                />
              }
              className={classes.toggleFields}
              label="Status"
            />
            {!isDCUser && (
              <>
                <FormControlLabel
                  control={
                    <Switch
                      name="RedirectReason"
                      checked={fieldsSelected?.RedirectReason}
                      onChange={(e) => onFieldChange(e)}
                    />
                  }
                  className={classes.toggleFields}
                  label="Redirect Reason"
                />
                <FormControlLabel
                  control={
                    <Switch
                      name="LVM"
                      checked={fieldsSelected?.LVM}
                      onChange={(e) => onFieldChange(e)}
                    />
                  }
                  className={classes.toggleFields}
                  label="LVM"
                />
                <FormControlLabel
                  control={
                    <Switch
                      name={'WorkValidation'}
                      checked={fieldsSelected?.WorkValidation}
                      onChange={(e) => onFieldChange(e)}
                    />
                  }
                  className={classes.toggleFields}
                  label={WORK_VALIDATION}
                />
              </>
            )}
            <FormControlLabel
              control={
                <Switch
                  name="JobPlan"
                  checked={fieldsSelected?.JobPlan}
                  onChange={(e) => onFieldChange(e)}
                />
              }
              className={classes.toggleFields}
              label="Job Plan"
            />
            <FormControlLabel
              control={
                <Switch
                  name="AssignedToVendor"
                  checked={fieldsSelected?.AssignedToVendor}
                  onChange={(e) => onFieldChange(e)}
                />
              }
              className={classes.toggleFields}
              label="Assigned to/Vendor"
            />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          disabled={resetDisabled}
          onClick={() => onReset()}
        >
          Reset
        </Button>
        <Button variant="outlined" onClick={() => onClose()}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CardSettingsDialog
