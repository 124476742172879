/* eslint-disable react-hooks/exhaustive-deps */
import { useNavigate } from 'react-router-dom'
import { getURLSearchParams } from '../../windowManager'
import MatAppBar from '../MatAppBar/MatAppBar'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setShouldRender } from '../ProgressOverlay/store/actionCreator'
import { fetchData } from '../../service/HttpService'
import {
  Container,
  Alert,
  Grid,
  Typography,
  Button,
  Stack,
} from '@mui/material'
import DataTable from 'react-data-table-component'
import { makeStyles, createStyles } from '@mui/styles'
import {
  convertTimestampToLocal,
  formattedTime,
  setTimeString,
} from '../../utils'
import StatusAlert from '../StatusAlert/StatusAlert'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import { WO, globalRoutes } from '../../globalConstants'

const AssetDowntime = () => {
  const useStyles = makeStyles((theme) =>
    createStyles({
      assetInfo: {
        padding: theme.spacing(1, 1, 2, 1.7),
        marginBottom: theme.spacing(2),
      },
    }),
  )
  const customStyles = {
    table: {
      style: {
        fontFamily: 'Arial',
      },
    },
    headCells: {
      style: {
        fontSize: 14,
        fontWeight: 'bold',
        paddingLeft: 13,
      },
    },
    cells: {
      style: {
        fontSize: 14,
      },
    },
  }

  const urlSearchParams = getURLSearchParams()
  const woNum = urlSearchParams.get('wonum')
  const assetNum = urlSearchParams.get('asset')
  const isCompleting = urlSearchParams.get('complete')
  const navigate = useNavigate()

  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(true)
  const [assetHistory, setAssetHistory] = useState([])
  const [assetInfo, setAssetInfo] = useState({})
  const { storeId = null } = useSelector((state) => ({
    ...state.storeInfoReducer,
  }))
  const dispatch = useDispatch()
  const classes = useStyles()
  const getTotalHours = (assetHistory) => {
    const totalHours = assetHistory.reduce(
      (a, b) => +a + +b['dcterms:downtime'],
      0,
    )
    return parseFloat(totalHours).toFixed(2)
  }

  const completeMessage = isCompleting
    ? '  Add asset downtime or continue completion without updating'
    : ''

  const infoMessage = `Showing ${
    assetHistory?.length
  } Downtime entries totaling ${getTotalHours(
    assetHistory,
  )} hours for WO #${woNum}. ${completeMessage}`

  const commonGridItemLabelProps = {
    item: true,
    xs: 4,
  }
  const commonGridItemDescriptionProps = {
    item: true,
    xs: 8,
  }

  useEffect(() => {
    const getDowntimeHistory = async () => {
      try {
        setLoading(true)
        dispatch(setShouldRender(true, 'Loading...'))
        const assetHistoryList = await fetchData(
          `/maximo/assethistory/${assetNum}/location/${storeId}/wo/${woNum}`,
        )
        const woAssetDowntimeList = assetHistoryList['dcterms:moddowntimehist']
        setAssetHistory(woAssetDowntimeList)
        setAssetInfo(assetHistoryList)
      } catch (error) {
        setError(error)
      } finally {
        setLoading(false)
        dispatch(setShouldRender(false))
      }
    }
    getDowntimeHistory()
  }, [])

  const assetHistoryColumns = [
    {
      name: 'Start Date',
      sortable: true,
      wrap: true,
      selector: (row) => convertTimestampToLocal(row['dcterms:startdate']),
    },
    {
      name: 'Downtime',
      sortable: false,
      wrap: true,
      selector: (row) =>
        row['dcterms:downtime']
          ? setTimeString(formattedTime(row['dcterms:downtime']))
          : 'N/A',
    },
    {
      name: 'Type',
      sortable: true,
      selector: (row) => row['dcterms:startcode'],
    },
  ]
  const onClick = () => {
    isCompleting
      ? navigate(
          `${globalRoutes.getReportDowntime(woNum, assetNum)}&complete=true`,
        )
      : navigate(`${globalRoutes.getReportDowntime(woNum, assetNum)}`)
  }

  return (
    <>
      <MatAppBar arrowBack text={`${WO} ${woNum}`} minimizeHeader />

      <Container disableGutters>
        {assetHistory && !loading && (
          <>
            <StatusAlert text={infoMessage} />

            <Grid container spacing={2} className={classes.assetInfo}>
              <Grid
                item
                container
                xs={12}
                justifyContent="flex-end"
                data-testid="downtime-button"
              >
                <Stack direction="row" spacing={2}>
                  {isCompleting && (
                    <Button
                      variant="outlined"
                      aria-label="Continue"
                      data-testid="continue-btn"
                      onClick={() =>
                        navigate(
                          `${globalRoutes.completeWorkOrder}#?wonum=${woNum}`,
                        )
                      }
                    >
                      Continue
                    </Button>
                  )}
                  <Button
                    variant="outlined"
                    aria-label="Add Downtime"
                    startIcon={<AddCircleIcon />}
                    onClick={onClick}
                  >
                    Downtime
                  </Button>
                </Stack>
              </Grid>
              <Grid {...commonGridItemLabelProps}>
                <Typography>Asset Num:</Typography>
              </Grid>
              <Grid {...commonGridItemDescriptionProps}>
                <Typography>{assetInfo['dcterms:assetnum']}</Typography>
              </Grid>
              <Grid {...commonGridItemLabelProps}>
                <Typography>Asset:</Typography>
              </Grid>
              <Grid {...commonGridItemDescriptionProps}>
                <Typography>{assetInfo['dcterms:description']}</Typography>
              </Grid>
              <Grid {...commonGridItemLabelProps}>
                <Typography>Asset Tag:</Typography>
              </Grid>
              <Grid {...commonGridItemDescriptionProps}>
                <Typography>{assetInfo['dcterms:assettag']}</Typography>
              </Grid>
            </Grid>
          </>
        )}
        {assetHistory.length > 0 && !error && (
          <DataTable
            customStyles={customStyles}
            columns={assetHistoryColumns}
            data={assetHistory}
            defaultSortAsc={false}
            pointerOnHover
            defaultSortField="row['dcterms:startdate']"
          />
        )}
        {assetHistory.length < 1 && !loading && (
          <Alert severity="warning">
            No downtime history found for this asset.
          </Alert>
        )}

        {error && !loading && (
          <Alert severity="error">Unable to connect to Assets service.</Alert>
        )}
      </Container>
    </>
  )
}

export default AssetDowntime
