import { Alert, Button, Grid, Stack, Typography } from '@mui/material'
import { useDispatch } from 'react-redux'
import MatAppBar from '../MatAppBar/MatAppBar'
import { makeStyles, createStyles } from '@mui/styles'
import ThumbUpIcon from '@mui/icons-material/ThumbUp'
import ThumbDownIcon from '@mui/icons-material/ThumbDown'
import { getURLSearchParams } from '../../windowManager'
import { useNavigate } from 'react-router-dom'
import { setFollowUpFlow } from '../CreateWorkOrder/actionCreator'
import { globalRoutes, STORE_REQUEST_DETAILS } from '../../globalConstants'
import { COLOR_DEFAULT_GREY } from '../../globalColors'

const useStyles = makeStyles((theme) =>
  createStyles({
    alert: {
      marginTop: theme.spacing(1),
      borderColor: COLOR_DEFAULT_GREY,
      '& .MuiAlert-icon > svg': {
        fill: COLOR_DEFAULT_GREY,
      },
    },
    gridParent: {
      padding: theme.spacing(1, 0),
    },
    gridItem: {
      borderWidth: 1,
      borderColor: theme.palette.grey[300],
      borderStyle: 'solid',
      padding: theme.spacing(2),
    },
  }),
)

const StoreRequestDetails = () => {
  const navigate = useNavigate()
  const classes = useStyles()
  const dispatch = useDispatch()
  const urlSearchParams = getURLSearchParams()
  const storeRequestProperties = Object.fromEntries(urlSearchParams)
  const commonAlertProps = {
    severity: 'info',
    variant: 'outlined',
  }

  const commonNameValueProps = {
    item: true,
    className: classes.gridItem,
  }

  const ticketId = urlSearchParams.get('ticketid')
  const longDescription = urlSearchParams.get('longdescription')
  const formattedLongDescription = longDescription?.replaceAll('>', '<br>')

  const navigateToDeclinePage = () => {
    const ticketUId = urlSearchParams.get('ticketuid')
    const ticketClass = urlSearchParams.get('class')
    const siteId = urlSearchParams.get('siteid')
    const storeRequestsLength = urlSearchParams.get('count')
    const path = `/decline-store-requests#?ticketid=${ticketId}&siteid=${siteId}&ticketuid=${ticketUId}&class=${ticketClass}&count=${storeRequestsLength}`
    navigate(path)
  }

  const commonNameProps = {
    xs: 6,
    sm: 3,
    md: 2,
    ...commonNameValueProps,
  }

  const commonValueProps = {
    xs: 6,
    sm: 9,
    md: 10,
    ...commonNameValueProps,
  }

  const commonButtonProps = {
    variant: 'contained',
  }

  return (
    <>
      <MatAppBar arrowBack text="Request Details" />
      <Grid
        container
        item
        xs={12}
        justifyContent="flex-end"
        className={classes.gridParent}
      >
        <Stack direction="row" spacing={2}>
          <Button
            {...commonButtonProps}
            startIcon={<ThumbDownIcon />}
            onClick={navigateToDeclinePage}
          >
            Decline Reason
          </Button>
          <Button
            {...commonButtonProps}
            startIcon={<ThumbUpIcon />}
            onClick={() => {
              dispatch(
                setFollowUpFlow(true, {
                  ...storeRequestProperties,
                  from: STORE_REQUEST_DETAILS,
                }),
              )
              navigate({
                pathname: globalRoutes.startCreateWorkOrder,
              })
            }}
          >
            Accept
          </Button>
        </Stack>
      </Grid>
      <Alert {...commonAlertProps} className={classes.alert}>
        <strong>Ticket ID: </strong>
        {ticketId}
      </Alert>
      <Alert {...commonAlertProps} className={classes.alert}>
        <strong>Description: </strong>
        <span
          dangerouslySetInnerHTML={{ __html: formattedLongDescription }}
        ></span>
      </Alert>
      <Grid container className={classes.gridParent}>
        <Grid {...commonNameProps}>
          <Typography>
            <strong>Status:</strong>
          </Typography>
        </Grid>
        <Grid {...commonValueProps}>
          <Typography>{urlSearchParams.get('status')}</Typography>
        </Grid>
        <Grid {...commonNameProps}>
          <Typography>
            <strong>Status Date:</strong>
          </Typography>
        </Grid>
        <Grid {...commonValueProps}>
          <Typography>{urlSearchParams.get('statusdate')}</Typography>
        </Grid>
        <Grid {...commonNameProps}>
          <Typography>
            <strong>Reported By:</strong>
          </Typography>
        </Grid>
        <Grid {...commonValueProps}>
          <Typography>{urlSearchParams.get('reportedby')}</Typography>
        </Grid>
        <Grid {...commonNameProps}>
          <Typography>
            <strong>Report Date:</strong>
          </Typography>
        </Grid>
        <Grid {...commonValueProps}>
          <Typography>{urlSearchParams.get('reportdate')}</Typography>
        </Grid>
      </Grid>
    </>
  )
}

export default StoreRequestDetails
