import { SET_ASSETS, SET_SELECTED_ASSET, SET_AUTH } from './actionType'

export function setAssets(assets) {
  return {
    type: SET_ASSETS,
    assets: assets,
  }
}
export function setSelectedAsset(selectedAsset) {
  return {
    type: SET_SELECTED_ASSET,
    selectedAsset: selectedAsset,
  }
}

export const setAuth = (userGroups) => {
  return {
    type: SET_AUTH,
    userGroups: userGroups,
  }
}
