/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useState } from 'react'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import { Alert } from '@mui/material'
import { connect } from 'react-redux'
import { setProblemList } from './actionCreator'
import MatAppBar from '../MatAppBar/MatAppBar'
import ListItemDivider from '../ListItemDivider/ListItemDivider'
import { useEffect } from 'react'
import { fetchData } from '../../service/HttpService'
import { getURLSearchParams } from '../../windowManager'
import { useNavigate } from 'react-router-dom'
import { setWorkOrder } from '../CreateWorkOrder/actionCreator'
import { setShouldRender } from '../ProgressOverlay/store/actionCreator'
import {
  PROBLEM_LIST,
  DUPLICATE_WORK_ORDER_ERROR,
  ERROR,
  ASSET_ID_PARAM,
  ASSET_NAME_PARAM,
  PROBLEM_CODE_PARAM,
  LOCATION_PARAM,
  DUPLICATE_WORK_ORDERS,
  globalRoutes,
} from '../../globalConstants'
import useGetOpenDuplicateWorkOrders from '../../utils/hooks/useGetOpenDuplicateWorkOrders'
import { urlBuilder, getCreateWorkOrderLink } from '../../utils'
import { setDuplicateWorkorderList } from '../WorkOrderList/WorkOrderListStore/actionCreator'
const ProblemList = ({
  problems = [],
  setProblemList,
  storeId,
  createWorkOrderData,
  setWorkOrder,
  setShouldRender,
  selectedAsset,
}) => {
  const urlSearchParams = getURLSearchParams()
  const assetId = urlSearchParams.get('asset_id')
  const area = urlSearchParams.get('area')
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const PML_RESTRICTED_PRIORITY = 99
  const [selectedProblem, setSelectedProblem] = useState(null)
  const getDupWoResult = useGetOpenDuplicateWorkOrders(
    DUPLICATE_WORK_ORDERS,
    selectedProblem,
  )
  useEffect(() => {
    async function getProblemList() {
      try {
        setShouldRender(true, 'Loading Problems...')
        setLoading(true)
        setError(false)
        const url = assetId
          ? `/mam/problemcodelist/siteId/${storeId}/assetNum/${assetId}`
          : `/mam/locationproblemlist/siteId/${storeId}`
        const response = await fetchData(url)
        const problemList = (
          assetId
            ? response.problemcodedata
            : response.locationproblemdata.filter(
                (problem) => problem.location === area,
              )
        )?.filter?.((problem) => {
          const problemPriority = problem.priority
          return (
            !problemPriority ||
            Number(problemPriority) !== PML_RESTRICTED_PRIORITY
          )
        })
        setProblemList(problemList)
      } catch {
        setError(true)
      } finally {
        setShouldRender(false)
        setLoading(false)
      }
    }
    getProblemList()
  }, [])

  const navigate = useNavigate()
  const handleProblemClick = (problem) => {
    const problemCode = problem.problemcode
    setSelectedProblem(problem)
    setWorkOrder({
      ...createWorkOrderData,
      problem: problemCode,
      problemLocation: problem.location,
      failureCode: problem.failurecode,
    })
  }

  useEffect(() => {
    if (getDupWoResult !== ERROR && getDupWoResult !== null) {
      const assetName = selectedAsset.assetName
      const { problem: problemCode, areaOfStore: selectedLocation } =
        createWorkOrderData
      if (getDupWoResult === 0) {
        navigate(getCreateWorkOrderLink(createWorkOrderData))
      } else {
        navigate({
          pathname: globalRoutes.duplicateWorkOrderList,
          hash: urlBuilder({
            [ASSET_ID_PARAM]: assetId,
            [ASSET_NAME_PARAM]: assetName,
            [PROBLEM_CODE_PARAM]: problemCode,
            [LOCATION_PARAM]: selectedLocation,
          }),
          state: { from: PROBLEM_LIST },
        })
      }
    }
  }, [getDupWoResult])

  return (
    <>
      <MatAppBar
        arrowBack
        text="Problems"
        renderFollowUpInfoIfExists
        createWorkOrderFlow
      />
      {problems.length && !error && getDupWoResult !== ERROR && !loading ? (
        <List>
          {problems.map((problem, index) => {
            return (
              <Fragment key={index}>
                <ListItem disablePadding>
                  <ListItemButton onClick={() => handleProblemClick(problem)}>
                    <ListItemText primary={problem.problemcode} />
                  </ListItemButton>
                </ListItem>
                <ListItemDivider index={index} listLength={problems.length} />
              </Fragment>
            )
          })}
        </List>
      ) : null}
      {(error || getDupWoResult === ERROR) && !loading && (
        <Alert severity="error">
          {error
            ? 'Unable to connect to Problems service.'
            : DUPLICATE_WORK_ORDER_ERROR}
        </Alert>
      )}

      {!error && !loading && !problems.length && (
        <Alert severity="warning">No problems currently on this asset.</Alert>
      )}
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    problems: state.problemListReducer.problems,
    storeId: state.storeInfoReducer.storeId,
    createWorkOrderData: state.createWorkOrderReducer.createWorkOrderData,
    selectedAsset: state.assetSelectReducer.selectedAsset,
  }
}

const mapDispatchToProps = {
  setProblemList,
  setWorkOrder,
  setShouldRender,
  setDuplicateWorkorderList,
}

export default connect(mapStateToProps, mapDispatchToProps)(ProblemList)
