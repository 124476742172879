import { setURLSearchParams, getURLSearchParams } from '../windowManager'
import {
  DEFAULT_SITE_KEY,
  DEFAULT_STOREROOM_KEY,
  LABOR_KEY,
  PERSON_ID_KEY,
  PERSON_KEY,
  CRAFT_KEY,
  RATE_KEY,
  ORG_ID_KEY,
  LABOR_CODE_KEY,
  LABOR_CRAFT_RATE_KEY,
  MISC_DETAILS_KEY,
  EXTERNAL_WORK,
  INTERNAL_WORK,
  WORK_ORDER,
  HOME,
  globalRoutes,
  WORK_ORDERS,
  VALID_IMAGE_EXTESIONS,
  DATE_FORMAT,
  LOG_MY_TIME,
  TEXTFIELD_LINE_HEIGHT,
} from '../globalConstants'
import moment from 'moment'
import getcounts from '../utils/hooks/workOrderCounts'
import HomeIcon from '@mui/icons-material/Home'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled'
import TuneIcon from '@mui/icons-material/Tune'
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing'
import WorkOrderIcon from '../assets/work-order.svg'
import ManageHistoryIcon from '@mui/icons-material/ManageHistory'
import { Typography } from '@mui/material'
import momentTimeZone from 'moment-timezone'

export function buildUserInfoObject(response, lanId) {
  const laborDetails =
    response?.[PERSON_KEY]?.find?.(
      (val) => val?.[PERSON_ID_KEY]?.string === lanId,
    )?.[LABOR_KEY] ?? []
  const requiredLaborFields =
    laborDetails?.map?.((val) => {
      return {
        [ORG_ID_KEY]: val?.[ORG_ID_KEY] ?? [],
        [LABOR_CODE_KEY]: val?.[LABOR_CODE_KEY] ?? [],
        [LABOR_CRAFT_RATE_KEY]:
          val?.[LABOR_CRAFT_RATE_KEY]?.map?.((innerval) => {
            return {
              [CRAFT_KEY]: innerval[CRAFT_KEY],
              [RATE_KEY]: innerval[RATE_KEY],
            }
          }) ?? [],
      }
    }) ?? []
  return {
    [DEFAULT_SITE_KEY]: response[DEFAULT_SITE_KEY],
    [DEFAULT_STOREROOM_KEY]: response[DEFAULT_STOREROOM_KEY],
    [MISC_DETAILS_KEY]: requiredLaborFields, // mapped and added all required fields as per https://jira.target.com/browse/WOA-222 - more fields can be added here as required in future
  }
}

export function filterAndSetUrlParams(
  data,
  key,
  filterString,
  properties,
  indexOfSearch,
) {
  // set the url search params
  const searchQuery = filterString?.toLowerCase() ?? ''
  setURLSearchParams([{ name: key, value: searchQuery }], true)
  if (!searchQuery) {
    return data
  }
  // Filter function when searching on a single object property
  if (!properties) {
    return data.filter(
      (val) => val[key]?.toLowerCase()?.indexOf(searchQuery) > -1,
    )
  }
  // Filter function when searching on a multiple object properties
  else {
    return data.filter((item) =>
      properties
        .map((val) =>
          val === 'repair_by' || val === 'reportdate'
            ? convertTimestampToLocal(item[val])
            : item[val],
        )
        .some((str) => {
          const exists = indexOfSearch
            ? (str?.toLowerCase?.()?.indexOf?.(searchQuery) ?? -1) !== -1 // added -1 as default case when str is null or undefined
            : str?.toLowerCase?.()?.includes?.(searchQuery)
          return exists
        }),
    )
  }
}

export function checkIsNaN(val) {
  return isNaN(val) ? null : val
}

export function buildNavigationList(
  siteId,
  userRoles,
  cancelCountData,
  compCountData,
  workorders,
  userGroups,
) {
  const counts = workorders && getcounts(workorders, userGroups)
  const isDCUser = userRoles?.indexOf('DCUser') > -1

  const history = window.location.pathname

  const buildCardSettingsUrl = () => {
    const hash = window.location.hash
    const split = hash.split('?')
    let params = new URLSearchParams(split[1])
    params.delete('app_drawer')
    const connectUrl = history === '/home' ? '#?' : '?'
    var newUrl = split[0] + connectUrl + params.toString()
    return `${history}${newUrl}&card_settings_open=true`
  }

  const actionNeededLabel = (count) => {
    return (
      <>
        {count > 0 && (
          <Typography component="span" className="Action">
            Action Needed:
          </Typography>
        )}
        <Typography component="span">
          Due within 24hrs or Past Repair by (Comp by)
        </Typography>
      </>
    )
  }

  const {
    total,
    emcount,
    safetycount,
    cmAndEmCount,
    pmcount,
    fpcount,
    extcount,
    redirectCount,
    extloscount,
    intWoCount,
    intloscount,
    venValidationCount,
    autocount,
  } = counts ?? {}
  return {
    /* Keep sequential numbers as keys if no Sub header is required. We can keep on adding with 1,2,3 and so on in the object. 
    This would be checked when mapping through and rendering the list*/
    1: [
      {
        label: HOME,
        route: '/home',
        primaryNavigation: true,
        icon: <HomeIcon color="primary" />,
        access: true,
      },
      {
        label: `Create a ${WORK_ORDER}`,
        primaryNavigation: true,
        route: globalRoutes.startCreateWorkOrder,
        icon: <WorkOrderIcon />,
        access: true,
      },
      {
        label: LOG_MY_TIME,
        primaryNavigation: true,
        route: globalRoutes.timeTracking,
        icon: <AccessTimeFilledIcon color="primary" />,
        access: true,
      },
      {
        label: isDCUser ? 'Assignments' : 'Schedule',
        primaryNavigation: true,
        route: globalRoutes.schedules,
        icon: <CalendarMonthIcon color="primary" />,
        access: true,
      },
      {
        label: 'Asset List',
        primaryNavigation: true,
        route: `${globalRoutes.assetsSelect}#?asset-list-route=true`,
        icon: <PrecisionManufacturingIcon color="primary" />,
        access: true,
      },
      isDCUser && {
        label: 'Cycle Count',
        primaryNavigation: true,
        route: `${globalRoutes.cyclecount}`,
        icon: <ManageHistoryIcon color="primary" />,
        access: true,
      },
      {
        label: 'Card Settings',
        primaryNavigation: true,
        route: buildCardSettingsUrl(),
        icon: <TuneIcon color="primary" />,
        access: true,
      },

      {
        label: 'Store Requests',
        route: '/store-requests',
        access: isDCUser ? false : true,
      },
    ],
    [WORK_ORDER]: [
      {
        label: `All Open ${WORK_ORDERS}`,
        value: total,
        access: true,
        route: globalRoutes.allOpenWorkOrders, // disabling until WOA-370 is complete . Does not have to be part of RC1 as the page is not fully complete
      },
      {
        label: `Safety Work Orders`,
        value: safetycount,
        access: isDCUser ? false : true,
        route: globalRoutes.safety,
      },
      {
        label: `Auto Work Orders`,
        value: autocount,
        access: isDCUser ? false : true,
        route: globalRoutes.autoWorkOrders,
      },
      {
        label: `Completed ${WORK_ORDERS}`,
        value: compCountData?.compcountdata?.find?.(
          (val) => val?.wositeid === siteId,
        )?.compcount,
        route: '/completed-work-orders',
        access: true,
      },
    ],
    [INTERNAL_WORK]: [
      {
        label: actionNeededLabel(intloscount),
        value: intloscount,
        highlight: intloscount > 0 ? true : false, // highlight Internal action needed only if the count is more than 0
        access: isDCUser ? false : true,
        route: globalRoutes.actionNeeded,
      },
      {
        label: `Internal ${WORK_ORDERS}`,
        value: intWoCount,
        access: true,
        route: globalRoutes.internalWorkOrders,
      },
      {
        label: 'Corrective Maintenance',
        value: cmAndEmCount,
        access: true,
        route: globalRoutes.cmWorkOrders,
      },
      {
        label: 'Preventive Maintenance',
        value: pmcount,
        access: true,
        route: globalRoutes.preventativeMaintenance,
      },
      {
        label: 'Field Projects',
        value: fpcount,
        access: true,
        route: globalRoutes.fieldProjectWorkorders,
      },
    ],
    [EXTERNAL_WORK]: [
      {
        label: actionNeededLabel(extloscount),
        value: extloscount,
        highlight: extloscount > 0 ? true : false, // highlight Vendor action needed only if the count is more than 0
        access: isDCUser ? false : true,
        route: globalRoutes.actionNeededVendorWorkOrders,
      },
      {
        label: `External ${WORK_ORDERS}`,
        value: extcount,
        access: true,
        route: globalRoutes.externalWorkOrders,
      },
      {
        label: 'Emergency Maintenance',
        value: emcount,
        access: true,
        route: globalRoutes.emergencyMaintenance,
      },
      {
        label: 'Vendor Work Validation',
        value: venValidationCount,
        access: true,
        route: globalRoutes.vendorWorkValidation,
      },
      {
        label: `Cancelled External ${WORK_ORDERS}`,
        value: cancelCountData?.cancelcountdata?.find?.(
          (val) => val?.wositeid === siteId,
        )?.cancelcount,
        access: true,
        route: globalRoutes.cancelledExtWorkOrders,
      },
      {
        label: `Redirected ${WORK_ORDERS}`,
        value: redirectCount,
        access: isDCUser ? false : true,
        route: globalRoutes.redirectedWorkOrders,
      },
    ],
  }
}

export function urlBuilder(obj) {
  const paramArray = Object.entries(obj)
  return `?${paramArray.reduce((prev, next, index) => {
    if (next[1] !== null && next[1] !== undefined) {
      return `${prev}${next[0]}=${next[1]}${
        index < paramArray.length - 1 ? '&' : ''
      }`
    }
    return ''
  }, '')}`
}

export function getCreateWorkOrderLink(createWorkOrderData) {
  const urlSearchParams = getURLSearchParams()
  const assetId = urlSearchParams.get('asset_id')
  const assetParams = assetId ? `asset_id=${assetId}&` : ''
  const { problem: problemCode } = createWorkOrderData
  return `${globalRoutes.createWorkOrder}#?${assetParams}problemcode=${problemCode}`
}

export function validateImgFile(files) {
  const fileNames = Array.from(files).map((val) => val.name)
  return fileNames.every((fileName) => {
    return new RegExp(
      '(' + VALID_IMAGE_EXTESIONS.join('|').replace(/\./g, '\\.') + ')$',
      'i',
    ).test(fileName)
  })
}

export function isHoursInvalid(hours) {
  // Checks if hours is an int from 0-99
  return !/^(?:[0-9]|[1-9][0-9])$/.test(hours)
}

// Checks if minutes is an int from 0-59
export function isMinutesInvalid(minutes) {
  return !/^(?:[0-9]|[1-5][0-9])$/.test(minutes)
}

// takes an array of objects, and an associated prop to fix to 2 digits
export const propFromObjectArrayToFixed = (objectArray, propToFixed) => {
  for (const key in objectArray) {
    objectArray[key][propToFixed] = Number(
      objectArray[key][propToFixed],
    ).toFixed(2)
  }
  return objectArray
}

export function checkIfNavItemCountsLoaded(navItems) {
  return navItems === null || navItems === undefined
    ? false
    : Object?.values?.(navItems)
        ?.flat()
        ?.filter(
          (val) => Object.keys(val).findIndex((val) => val === 'value') !== -1,
        )
        ?.every((val) => val.value !== undefined)
}

export function resetnavItemCounts(navItems) {
  return Object?.fromEntries(
    Object?.entries(navItems)?.map(([key, val]) => {
      const navItemResetted = val.map((val) => {
        const valuePropExists =
          Object?.keys(val).findIndex((innerval) => innerval === 'value') !== -1
        return { ...val, ...(valuePropExists ? { value: undefined } : {}) }
      })
      return [key, navItemResetted]
    }),
  )
}

export const convertTimestampToLocal = (
  timestamp,
  format,
  timezone,
  LLLFormat,
) => {
  const returnValue = !timezone
    ? moment(timestamp).local()
    : momentTimeZone
        .tz(timestamp, 'America/Chicago')
        .tz(momentTimeZone.tz.guess())
  const returnDateFormat = timestamp && LLLFormat ? 'LLL' : DATE_FORMAT
  return returnValue.format(format || returnDateFormat)
}

export const formattedTime = (data) => {
  if (data || data === 0) {
    const hrVal = Math.floor(data)
    const minute = data % 1
    const minuteVal = Math.round(Number(minute) * 60)
    return { hr: hrVal, min: minuteVal }
  } else {
    return null
  }
}

export const setTimeString = (data) => {
  const { hr = 0, min = '' } = data
  const hourString = `${hr === 0 ? '' : hr === 1 ? '1 hr ' : `${hr} hrs `}`
  const minuteString =
    min === '' || min === 0 ? '' : min === 1 ? '1 min' : `${min} mins`
  return `${hourString}${minuteString}`
}

export const getStringFromHtml = (html) => {
  let doc = html ? new DOMParser().parseFromString(html, 'text/html') : null
  return doc?.body?.textContent ?? ''
}

export const getNumberOfLinesInTextArea = (el) => {
  if (el) {
    const style = window
      .getComputedStyle(el, null)
      .getPropertyValue('font-size')
    const fontSize = parseFloat(style)

    return Math.round(el.scrollHeight / (fontSize * TEXTFIELD_LINE_HEIGHT))
  } else {
    return 0
  }
}

export const addIndividualTimes = (data, field) => {
  const res = data.map((val) => {
    return formattedTime(val[field])
  })
  let hrs = 0
  let mins = 0

  res.forEach((val) => {
    hrs += val?.hr ?? 0
    mins += val?.min ?? 0
  })
  hrs += Math.floor(mins / 60)
  mins = mins % 60
  return { hr: hrs, min: mins }
}

export const filterAPWorkorders = (workOrders, userGroups) => {
  let APfilteredWorkOrders = workOrders
  const assetsProtection = userGroups.includes('APP-MXE-AP-MGR')
  const admin = userGroups.includes('APP-MXE-HQ-TTS')
  const hqAdmin = userGroups.includes('APP-MXE-HQ-MAXADMIN')

  if (admin || hqAdmin) {
    APfilteredWorkOrders = workOrders
  } else if (assetsProtection) {
    APfilteredWorkOrders = workOrders.filter((wo) => {
      return wo.wolocation === 'ASSETS_PROTECTION'
    })
  } else {
    APfilteredWorkOrders = workOrders.filter((wo) => {
      return wo.wolocation !== 'ASSETS_PROTECTION'
    })
    return APfilteredWorkOrders
  }
  return APfilteredWorkOrders === undefined ? workOrders : APfilteredWorkOrders
}

export const filterAPAssets = (assets, userGroups) => {
  let APfilteredAssets
  const assetsProtection = userGroups.includes('APP-MXE-AP-MGR')
  const admin = userGroups.includes('APP-MXE-HQ-TTS')
  const hqAdmin = userGroups.includes('APP-MXE-HQ-MAXADMIN')

  if (admin || hqAdmin) {
    APfilteredAssets = assets
  } else if (assetsProtection) {
    APfilteredAssets = assets.filter((asset) => {
      return asset.assetLocation === 'ASSETS_PROTECTION'
    })
  } else {
    APfilteredAssets = assets.filter((asset) => {
      return asset.assetLocation !== 'ASSETS_PROTECTION'
    })
    return APfilteredAssets
  }
  return APfilteredAssets === undefined ? assets : APfilteredAssets
}

export const filterAssignmentsBy7Days = (assignments) => {
  if (assignments) {
    return assignments.filter((val) => {
      const woDate = val['scheduledate']
      const today = new Date()
      return (
        new Date(woDate) >= today.setHours(0, 0, 0, 0) &&
        new Date(woDate) <= new Date(today.setDate(today.getDate() + 7))
      )
    })
  }
}
