import { SET_COMPLETED_WORK_ORDER_LIST, SET_COMPLETED_AUTH } from './actionType'
import moment from 'moment'
import { filterAPWorkorders, propFromObjectArrayToFixed } from '../../../utils'
import { getIsDCUser } from '../../../utils/hooks/rolesAndPermissions'

export const initialState = {
  completedWorkOrders: [],
  lastUpdated: null,
  userGroups: [],
}

export default function completedWorkOrdersReducer(
  state = initialState,
  action = {},
) {
  const isDCUser = getIsDCUser(state.userGroups)
  switch (action.type) {
    case SET_COMPLETED_AUTH: {
      const newState = {
        ...state,
        userGroups: action.userGroups,
      }
      return newState
    }
    case SET_COMPLETED_WORK_ORDER_LIST: {
      const workOrders = propFromObjectArrayToFixed(
        action.completedWorkOrders,
        'ltreghrs',
      )
      const sortedWorkOrders = workOrders?.sort(function (a, b) {
        return isDCUser
          ? new Date(a.reportdate) - new Date(b.reportdate)
          : new Date(a.repair_by) - new Date(b.repair_by)
      })
      const APfilteredWorkOrders = filterAPWorkorders(
        sortedWorkOrders,
        state.userGroups,
      )

      const newState = {
        ...state,
        completedWorkOrders: APfilteredWorkOrders,
        lastUpdated: moment().format('LLL'),
      }
      return newState
    }

    default: {
      return state
    }
  }
}
