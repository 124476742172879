import { NOT_AVAILABLE } from '../../globalConstants'

export function checkAttributeForNull(param) {
  return param == null ? false : true
}

export const getRepairByAlertDate = (repairby) => {
  return (new Date(repairby) - new Date()) / 36e5
}

export const setLvm = (tgt_lvm1, tgt_lvm2, tgt_lvm3, tgt_lvm4, tgt_lvm5) => {
  let lvm = NOT_AVAILABLE
  if (tgt_lvm3 === '1') {
    lvm = 'Vendor Action Plan'
  } else if (tgt_lvm5 === '1') {
    lvm = 'HQ Acknowledgement'
  } else if (tgt_lvm4 === '1') {
    lvm = 'FMOC to HQ'
  } else if (tgt_lvm2 === '1') {
    lvm = 'PMBP to HQ'
  } else if (tgt_lvm1 === '1') {
    lvm = 'PML to PMBP'
  }
  return lvm
}
