import { SET_VENDOR_CONTACTS, SET_SAVED_VENDOR } from './actionType'

export const initialState = {
  technicianData: null,
  vendor: null,
}

export default function vendorContactsReducer(
  state = initialState,
  action = {},
) {
  switch (action.type) {
    case SET_VENDOR_CONTACTS: {
      const technicianData = action.technicianData
      const newState = {
        ...state,
        technicianData: technicianData,
      }
      return newState
    }

    case SET_SAVED_VENDOR: {
      const vendor = action.vendor
      const newState = {
        ...state,
        vendor: vendor,
      }
      return newState
    }

    default:
      return state
  }
}
