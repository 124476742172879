import { SET_STORE_REQUEST_DETAILS } from './actionType'

export const initialState = {
  srDetails: null,
}

export default function storeRequestDetailsReducer(
  state = initialState,
  action = {},
) {
  switch (action.type) {
    case SET_STORE_REQUEST_DETAILS: {
      const newState = {
        ...state,
        srDetails: action.srDetails,
      }
      return newState
    }

    default: {
      return state
    }
  }
}
