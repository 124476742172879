/* eslint-disable react-hooks/exhaustive-deps */
import { getURLSearchParams, setURLSearchParams } from '../../windowManager'
import { useState, useEffect, useRef } from 'react'
import {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Table,
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  Stack,
  Typography,
} from '@mui/material'
import { makeStyles, createStyles } from '@mui/styles'
import MatAppBar from '../MatAppBar/MatAppBar'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import { Alert } from '@mui/material'
import { NumericFormat } from 'react-number-format'
import RedoIcon from '@mui/icons-material/Redo'
import UndoIcon from '@mui/icons-material/Undo'
import { useAuth } from '@praxis/component-auth'
import { useDispatch, useSelector } from 'react-redux'
import { fetchData, postData, putData } from '../../service/HttpService'
import { setShouldRender } from '../ProgressOverlay/store/actionCreator'
import ImageNotFound from '../../assets/imageNotFound.png'
import CloseIcon from '@mui/icons-material/Close'
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto'
import useHashChange from '../../utils/hooks/useHashChange'
import { WO } from '../../globalConstants'

const SparePartsDetails = () => {
  const useStyles = makeStyles((theme) =>
    createStyles({
      table: {
        '& .MuiTableCell-root': {
          width: '50%',
        },
      },
      inputField: {
        marginTop: theme.spacing(2.5),
        width: '100%',
      },
      warningError: {
        margin: theme.spacing(1),
      },
      closeIcon: {
        position: 'absolute',
        right: theme.spacing(0.5),
        top: theme.spacing(0.5),
      },
      thumbnail: {
        height: 100,
        cursor: 'pointer',
        marginTop: theme.spacing(1),
      },
      fullImage: {
        maxWidth: '100%',
        maxHeight: '100%',
      },
      dialogTitle: {
        padding: theme.spacing(1, 0),
      },
      imageDialog: {
        textAlign: 'center',
      },
      actionStack: {
        marginTop: theme.spacing(3),
      },
    }),
  )
  const classes = useStyles()
  const commonTableProps = {
    size: 'medium',
    className: classes.table,
  }
  const auth = useAuth()
  const dispatch = useDispatch()

  const userId = auth.session.userInfo.lanId?.toUpperCase()
  const {
    storeId = '',
    userStoreroom,
    shouldRender,
  } = useSelector((state) => ({
    ...state.storeInfoReducer,
    ...state.userInfoReducer,
    ...state.progressOverlayReducer,
  }))
  const [error, setError] = useState(false)
  const [imageError, setImageError] = useState(false)
  const [itemImage, setItemImage] = useState()
  const [toBeIssuedOrReturned, setToBeIssuedOrReturned] = useState()
  const [showImageDialog, setShowImageDialog] = useState(false)
  const inputFile = useRef(null)
  useHashChange('image_open', setShowImageDialog)
  const urlSearchParams = getURLSearchParams()
  const itemnum = urlSearchParams.get('itemnum')
  const issuedQty = parseInt(urlSearchParams.get('issuedqty'))
  const binnum = urlSearchParams.get('binnum')
  const issueunit = urlSearchParams.get('issueunit')
  const description = urlSearchParams.get('description')
  const currentbalance = parseInt(urlSearchParams.get('currentbalance'))
  const wonum = urlSearchParams.get('wonum')
  const location = urlSearchParams.get('location')
  const isStoreRoom = urlSearchParams.get('storeroom')
  const glCreditAccount = urlSearchParams.get('glcreditaccount')
  const itemid = urlSearchParams.get('itemid')
  const glDebitAccount = urlSearchParams.get('gldebitaccount')
  const isFromAssetSpareParts =
    false || (urlSearchParams.get('assetsparepart') === '1' && true)

  useEffect(() => {
    async function loadImage() {
      dispatch(setShouldRender(true, 'Loading Image...'))
      setImageError(false)
      try {
        const response = await fetchData(`/mam/image/itemnum/${itemnum}`)
        const image = response.image_data[0]?.image
        setItemImage(image)
      } catch (error) {
        setImageError(true)
      } finally {
        dispatch(setShouldRender(false))
      }
    }
    loadImage()
  }, [])

  const wostatus = urlSearchParams.get('wostatus')
  const forbiddenStatuses = ['WAPPR', 'CAN', 'CANCEL', 'CLOSE', 'COMP']
  const isForbiddenStatus = forbiddenStatuses.includes(wostatus)
  const issueReturnPart = async (type) => {
    const message =
      type === 'ISSUE' ? 'Issuing Spare Part...' : 'Returning Spare Part...'
    const quantity =
      type === 'ISSUE' ? -Math.abs(toBeIssuedOrReturned) : toBeIssuedOrReturned

    const requestBody = {
      actualDate: new Date(),
      binNum: binnum,
      enterBy: userId,
      issueType: type,
      issueUnit: 'EA',
      itemNum: itemnum,
      location: location,
      ownerSysId: 'MOBILE',
      quantity: quantity,
      refWo: wonum,
      siteId: storeId,
      storeLoc: userStoreroom,
      toSiteId: storeId,
      woNum: wonum,
      ...(isStoreRoom && {
        glCreditAcct: glCreditAccount,
        glDebitAcct: glDebitAccount,
      }),
    }

    try {
      dispatch(setShouldRender(true, message))
      await postData(`/maximo/item/issue-return`, JSON.stringify(requestBody))

      window.history.back()
    } catch (error) {
      setError(error)
    } finally {
      dispatch(setShouldRender(false))
    }
  }

  const openImageModal = (data) => {
    setURLSearchParams([{ name: 'image_open', value: true }])
  }

  const handleClose = () => {
    window.history.back()
  }
  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader()
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result.replace(/^data:image\/[a-z]+;base64,/, ''))
      }
      fileReader.onerror = (error) => {
        reject(error)
      }
    })
  }

  const onUploadPhotoClick = () => {
    inputFile.current.click()
  }

  const addOrUpdateImage = async (e) => {
    const file = e?.target?.files[0] ?? {}
    const convertedFile = await convertToBase64(file)
    const url = `/maximo/image/`

    const dataObject = {
      itemnum: itemnum,
      image: convertedFile,
      itemid: itemid ?? '',
      userid: userId,
    }
    var objectJson = JSON.stringify(dataObject)

    try {
      dispatch(
        setShouldRender(true, itemImage ? 'Updating Image' : 'Adding Image'),
      )
      !itemImage
        ? await postData(url, objectJson)
        : await putData(url, objectJson)

      setItemImage(convertedFile)
    } catch (error) {
      setImageError(true)
    } finally {
      dispatch(setShouldRender(false))
    }
  }

  return (
    <>
      <MatAppBar arrowBack text={`${WO} ${wonum}`} />
      {!shouldRender && currentbalance === 0 && (
        <Alert
          severity="warning"
          data-testid={'no-data-text'}
          className={classes.warningError}
        >
          Item not on-hand. Please order more!
        </Alert>
      )}
      {error && !shouldRender && (
        <Alert severity="error" className={classes.warningError}>
          There was an error issuing/returning parts
        </Alert>
      )}
      {imageError && !shouldRender && (
        <Alert severity="error" className={classes.warningError}>
          Error retrieving/saving part image
        </Alert>
      )}
      {isForbiddenStatus && !shouldRender && (
        <Alert severity="error" className={classes.warningError}>
          The work order is not in a valid status for issuing or returning parts
        </Alert>
      )}
      {!shouldRender && (
        <>
          {!imageError && (
            <Stack spacing={2} justifyContent="center" alignItems="center">
              <img
                className={classes.thumbnail}
                src={
                  itemImage
                    ? `data:image/jpeg;base64,${itemImage}`
                    : ImageNotFound
                }
                alt="test"
                onClick={() => openImageModal(itemImage)}
              />
              {itemid && (
                <Button
                  variant="outlined"
                  aria-label="Add Photo"
                  size="small"
                  startIcon={<AddAPhotoIcon />}
                  onClick={onUploadPhotoClick}
                >
                  {!itemImage ? 'Add Photo' : 'Update Photo'}
                </Button>
              )}
            </Stack>
          )}
          <Table {...commonTableProps}>
            <TableHead>
              <TableRow>
                <TableCell colSpan={2}>
                  <strong>{description}</strong>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>Bin</TableCell>
                <TableCell>{binnum}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Unit</TableCell>
                <TableCell>{issueunit}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Current Balance</TableCell>
                <TableCell>{currentbalance}</TableCell>
              </TableRow>
              {!isStoreRoom && !isFromAssetSpareParts && (
                <TableRow>
                  <TableCell>Issued Quantity</TableCell>
                  <TableCell>{issuedQty}</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <NumericFormat
            className={classes.inputField}
            autoFocus
            label={
              isStoreRoom || isFromAssetSpareParts
                ? `Issue Part ${itemnum}`
                : `Issue/Return Part ${itemnum}`
            }
            customInput={TextField}
            value={toBeIssuedOrReturned}
            onFocus={(event) => {
              event.target.select()
            }}
            type="number"
            onChange={(e) => setToBeIssuedOrReturned(Number(e.target.value))}
            decimalScale={2}
            allowNegative={false}
            allowLeadingZeros={false}
            variant="outlined"
            inputProps={{ 'data-testid': 'input-field' }}
          />
          <Stack
            direction="row"
            spacing={3}
            justifyContent="flex-end"
            className={classes.actionStack}
          >
            <Button
              data-testid="cancel-button"
              variant="outlined"
              onClick={() => window.history.back()}
            >
              Cancel
            </Button>
            {!isStoreRoom && !isFromAssetSpareParts && (
              <Button
                data-testid="return-button"
                variant="contained"
                onClick={() => issueReturnPart('RETURN')}
                disabled={
                  issuedQty === 0 ||
                  toBeIssuedOrReturned > issuedQty ||
                  !toBeIssuedOrReturned ||
                  isForbiddenStatus
                }
              >
                <UndoIcon fontSize="small" />
                Return
              </Button>
            )}

            <Button
              data-testid="issue-button"
              variant="contained"
              onClick={() => issueReturnPart('ISSUE')}
              disabled={
                currentbalance === 0 ||
                toBeIssuedOrReturned > currentbalance ||
                !toBeIssuedOrReturned ||
                isForbiddenStatus
              }
            >
              &nbsp;&nbsp;Issue
              <RedoIcon fontSize="small" /> &nbsp;&nbsp;
            </Button>
          </Stack>
        </>
      )}

      <Dialog
        open={showImageDialog}
        onClose={() => setShowImageDialog(false)}
        aria-labelledby="image-dialog-title"
        aria-describedby="image-dialog-description"
        fullScreen
        className={classes.imageDialog}
      >
        <DialogTitle id="image-dialog-title">
          <IconButton
            aria-label="close"
            onClick={handleClose}
            className={classes.closeIcon}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent id="image-dialog-description">
          <Typography className={classes.dialogTitle}>{description}</Typography>

          <img
            src={
              itemImage ? `data:image/jpeg;base64,${itemImage}` : ImageNotFound
            }
            alt="test"
            className={classes.fullImage}
          />
        </DialogContent>
      </Dialog>
      <input
        type="file"
        accept="image/png, image/jpg, image/jpeg"
        id="image-upload"
        data-testid="image-upload-input"
        ref={inputFile}
        className={classes.photoUploadInput}
        onChange={addOrUpdateImage}
        hidden
        multiple={false}
      />
    </>
  )
}

export default SparePartsDetails
