import { SET_TIME_ENTRIES } from './actionType'

export const initialState = {
  timeEntries: [],
}

export default function timeTrackingReducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_TIME_ENTRIES: {
      const newState = {
        ...state,
        timeEntries: action.timeEntries,
      }
      return newState
    }

    default:
      return state
  }
}
