import {
  SET_FROM_DATE,
  SET_TO_DATE,
  SET_COLLAPSED,
  SET_MY_SHIFTS,
  SET_OTHER_SHIFTS,
  SET_SHIFT_KEYS,
} from './actionType'

export function setFromDate(inp) {
  return {
    type: SET_FROM_DATE,
    data: inp,
  }
}

export function setToDate(inp) {
  return {
    type: SET_TO_DATE,
    data: inp,
  }
}

export function setCollapsedInStore(inp) {
  return {
    type: SET_COLLAPSED,
    data: inp,
  }
}

export function setMyShifts(myShifts) {
  return {
    type: SET_MY_SHIFTS,
    myShifts: myShifts,
  }
}
export function setOtherShifts(otherShifts) {
  return {
    type: SET_OTHER_SHIFTS,
    otherShifts: otherShifts,
  }
}
export function setShiftKeys(shiftKeys) {
  return {
    type: SET_SHIFT_KEYS,
    shiftKeys: shiftKeys,
  }
}
