/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { getURLSearchParams } from '../../windowManager'
import { fetchData } from '../../service/HttpService'
import { setShouldRender } from '../../components/ProgressOverlay/store/actionCreator'
import {
  CHECK_DUPLICATE_WO_HEADER,
  ERROR,
  api,
  ASSET_ID_PARAM,
  PROBLEM_CODE_PARAM,
  LOCATION_PARAM,
  CHECK_DUPLICATE_WO_BODY,
} from '../../globalConstants'
import { setDuplicateWorkorderList } from '../../components/WorkOrderList/WorkOrderListStore/actionCreator'

export default function useGetOpenDuplicateWorkOrders(
  source,
  selectedProblem,
  directUrl,
) {
  const dispatch = useDispatch()
  const { storeId, createWorkOrderData } = useSelector(
    (state) => ({
      ...state.createWorkOrderReducer,
      ...state.storeInfoReducer,
    }),
    shallowEqual, // Added shallowEqual here so that opening of the Hamburger menu doesnt re-render this custom hook
  )
  const { problemcode } = selectedProblem ?? {}
  const [returnVal, setReturnVal] = useState(null)
  const urlSearchParams = getURLSearchParams()
  const selectedAsset = urlSearchParams.get(ASSET_ID_PARAM)?.toString()
  const selectedProblemCode = directUrl
    ? urlSearchParams.get(PROBLEM_CODE_PARAM)
    : problemcode
  const selectedLocation = directUrl
    ? urlSearchParams.get(LOCATION_PARAM)
    : createWorkOrderData?.areaOfStore
  async function getOpenDupWorkOrders() {
    try {
      const result = await fetchData(
        api.duplicateWorkOrders(
          storeId,
          selectedProblemCode,
          selectedAsset,
          selectedLocation,
        ),
      )
      const { woDetails = [] } = result
      return {
        duplicateWorkOrders: woDetails,
      }
    } catch (error) {
      return ERROR
    }
  }

  useEffect(() => {
    async function onLoad() {
      dispatch(
        setShouldRender(
          true,
          CHECK_DUPLICATE_WO_HEADER,
          CHECK_DUPLICATE_WO_BODY,
        ),
      )
      const result = await getOpenDupWorkOrders()
      if (result !== ERROR) {
        const { duplicateWorkOrders } = result
        dispatch(setDuplicateWorkorderList(duplicateWorkOrders))
        dispatch(setShouldRender(false))
        setReturnVal(duplicateWorkOrders?.length)
      } else {
        setReturnVal(ERROR)
        dispatch(setShouldRender(false))
      }
    }
    if (problemcode || directUrl) {
      onLoad()
    }
  }, [problemcode, directUrl])

  return returnVal
}
