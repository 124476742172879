import { Helmet } from 'react-helmet'
import { Routes, Route, BrowserRouter } from 'react-router-dom'
import { AuthProvider, ProtectedElement, useAuth } from '@praxis/component-auth'
import { useSelector } from 'react-redux'
import { EnvProvider } from '@praxis/component-runtime-env'
import AssetSelect from './components/AssetsSelect/AssetsSelect'
import Home from './components/Home/Home'
import commonConfig from './config/commonConfig'
import ProblemList from './components/ProblemList/ProblemList'
import AreaOfStore from './components/AreaOfStore/AreaOfStore'
import Root from './components/Root/Root'
import AssetInformation from './components/AssetInformation/AssetInformation'
import AssetDetail from './components/AssetInformation/AssetDetail'
import CreateWorkOrder from './components/CreateWorkOrder/CreateWorkOrder'
import DuplicateWorkOrder from './components/DuplicateWorkOrder/DuplicateWorkOrder'
import StoreRequests from './components/StoreRequests/StoreRequests'
import AllOpenWorkOrders from './components/AllOpenWorkOrders/AllOpenWorkOrders'
import StartCreateWorkOrder from './components/StartCreateWorkOrder/StartCreateWorkOrder'
import WorkOrderLanding from './components/WorkOrderLanding/WorkOrderLanding'
import ProgressOverlay from './components/ProgressOverlay/ProgressOverlay'
import { globalRoutes } from './globalConstants'
import { ALLOWED_AD_GROUPS, DC_GROUP } from './globalPermissions'
import AssetHistoryWorkOrderList from './components/AssetInformation/AssetHistoryWorkOrderList'
import AssetSpecifications from './components/AssetSpecifications/AssetSpecifications'
import DeclineStoreRequests from './components/StoreRequestsDelined/DeclineStoreRequests'
import StoreRequestDetails from './components/StoreRequestDetails/StoreRequestDetails'
import WorkOrderDetails from './components/WorkOrderDetails/WorkOrderDetails'
import WorkOrderDetailsImages from './components/WorkOrderDetailsImages/WorkOrderDetailsImages'
import WorkOrderDetailsPlans from './components/WorkOrderDetailsPlans/WorkOrderDetailsPlans'
import WorkOrderDetailsRelatedRecords from './components/WorkOrderDetailsRelatedRecords/WorkOrderDetailsRelatedRecords'
import WorkOrderDetailsSchedules from './components/WorkOrderDetailsSchedules/WorkOrderDetailsSchedules'
import WorkOrderDetailsSurvey from './components/WorkOrderDetailsSurvey/WorkOrderDetailsSurvey'
import WorkOrderDetailsWorkLog from './components/WorkOrderDetailsWorkLog/WorkOrderDetailsWorkLog'
import WorkOrderDetailsBilling from './components/WorkOrderDetailsBilling/WorkOrderDetailsBilling'
import CompleteWorkOrder from './components/CompleteWorkOrder/CompleteWorkOrder'
import CompletedWorkOrders from './components/CompletedWorkOrders/CompleteWorkOrders'
import ValidateWorkOrder from './components/ValidateWorkOrder/ValidateWorkOrder'
import InternalWorkOrders from './components/InternalWorkOrders/InternalWorkOrders'
import UploadPhoto from './components/UploadPhoto/UploadPhoto'
import ActionNeededVendorWorkOrders from './components/ActionNeededVendorWorkOrders/ActionNeededVendorWorkOrders'
import ActionNeededWorkOrders from './components/ActionNeededWorkOrders/ActionNeededWorkOrders'
import TimeTracking from './components/TimeTracking/TimeTracking'
import EmergencyMaintenance from './components/EmergencyMaintenance/EmergencyMaintenance'
import ExternalWorkOrders from './components/ExternalWorkOrders/ExternalWorkOrders'
import RedirectedWorkOrders from './components/RedirectedWorkOrders/RedirectedWorkOrders'
import VendorWorkValidation from './components/VendorWorkValidation/VendorWorkValidation'
import CMWorkOrders from './components/CMWorkOrders/CMWorkOrders'
import FieldProjectWorkOrders from './components/FieldProjectWorkOders/FieldProjectWorkOrders'
import PreventativeMaintenanceWorkOrders from './components/PreventativeMaintenance/PreventativeMaintenance'
import CreateWorkLog from './components/WorkOrderDetailsWorkLog/WorkLogNotes/CreateWorkLogNotes'
import CancelledExtWorkOrders from './components/CancelledExtWorkOrders/CancelledExtWorkOrders'
import SafetyWorkOrders from './components/SafetyWorkOrders/SafetyWorkOrders'
import WorkLogVisitMap from './components/WorkOrderDetailsWorkLog/WorkLogVisitMap'
import LogoutDialog from './components/LogoutDialog/LogoutDialog'
import Schedules from './components/Schedules/Schedules'
import AutoWorkOrders from './components/AutoWorkOrders/AutoWorkOrders'
import { useDispatch } from 'react-redux'
import { setAuth } from '../src/components/WorkOrderList/WorkOrderListStore/actionCreator'
import { setCompletedAuth } from '../src/components/CompletedWorkOrders/store/actionCreator'
import AssetDowntime from './components/AssetDowntime/AssetDowntime'
import WorkOrderDetailsAssetSpareParts from './components/WorkOrderDetailsAssetSpareParts/WorkOrderDetailsAssetSpareParts'
import ReportAssetDowntime from './components/AssetDowntime/ReportAssetDowntime'
import SparePartsDetails from './components/SparePartsDetails/SparePartsDetails'
import WorkOrderTabMenu from './components/WorkOrderTabMenu/WorkOrderTabMenu'
import WorkOrderDetailsIssuedParts from './components/WorkOrderDetailsIssuedParts/WorkOrderDetailsIssuedParts'
import {
  setUserRoles,
  setIsDCUser,
  setAreaOrLocation,
} from './components/Root/store/actionCreator'
import {
  getIsDCUser,
  getUserRoles,
  getAreaOrLocation,
} from './utils/hooks/rolesAndPermissions'
import CycleCount from './components/CycleCount/CycleCount'
import CycleCountDetail from './components/CycleCount/CycleCountDetail'
import React, { Suspense } from 'react'
import AppRestrictedDialog from './components/common/AppRestrictedDialog/AppRestrictedDialog'

const LazyCycleCountList = React.lazy(
  () => import('./components/CycleCount/CycleCountList'),
)
const LazyStoreroomItems = React.lazy(
  () =>
    import(
      './components/WorkOrderDetailsStoreroomItems/WorkOrderDetailsStoreroomItems'
    ),
)

const App = () => {
  const { storeId = '' } = useSelector((state) => state.storeInfoReducer)
  localStorage.removeItem('assetList')

  return (
    <>
      <Helmet>
        <title>Work Order Management</title>
      </Helmet>
      <EnvProvider
        commonConfig={commonConfig}
        configPath={
          process.env.NODE_ENV === 'development'
            ? '/config.json'
            : '/app-environment'
        }
      >
        {({ env }) => {
          return (
            <AuthProvider
              clientId={env.auth.clientId}
              authorizationUrl={`${env.auth.host}${env.auth.authorizationPath}`}
              logoutUrl={env.auth.logoutHost + env.auth.logoutPath}
              logoutRedirect={window.location.origin}
              hideLoadingIndicator={true}
            >
              {/* Do not load the routes until storeId is set in the redux
                  store. In error scenarios, the <Root /> component will handle
                  the same. Restrict access to the App only to the allowed AD groups */}
              {!storeId ? (
                <BrowserRouter>
                  <Routes>
                    <Route
                      path="/*"
                      allowed={ALLOWED_AD_GROUPS}
                      element={
                        <ProtectedElement>
                          <Root />
                        </ProtectedElement>
                      }
                    ></Route>
                  </Routes>
                </BrowserRouter>
              ) : (
                <AppRoutes />
              )}
            </AuthProvider>
          )
        }}
      </EnvProvider>
    </>
  )
}

const AppRoutes = () => {
  const auth = useAuth()
  const userGroups = auth.session.userInfo.memberOf
  const userRoles = getUserRoles(userGroups)
  const isDCUser = getIsDCUser(userGroups)
  const areaOrLocation = getAreaOrLocation(userGroups)
  const dispatch = useDispatch()
  const { isAuthorized } = auth
  const renderElement = (element) => {
    return <ProtectedElement>{element}</ProtectedElement>
  }
  dispatch(setAuth(userGroups))
  dispatch(setCompletedAuth(userGroups))
  dispatch(setUserRoles(userRoles))
  dispatch(setIsDCUser(isDCUser))
  dispatch(setAreaOrLocation(areaOrLocation))

  return (
    <>
      {isAuthorized() ? (
        <>
          <ProgressOverlay />
          <LogoutDialog />
          <AppRestrictedDialog />
          <BrowserRouter>
            <Routes>
              <Route path="/home" element={renderElement(<Home />)} />
              <Route
                path={globalRoutes.assetsSelect}
                allowed={ALLOWED_AD_GROUPS}
                unauthorizedRoute={'/home'}
                element={renderElement(<AssetSelect />)}
              />
              <Route
                path={globalRoutes.problems}
                allowed={ALLOWED_AD_GROUPS}
                unauthorizedRoute={'/home'}
                element={renderElement(<ProblemList />)}
              />
              <Route
                path={globalRoutes.areaOfStore}
                allowed={ALLOWED_AD_GROUPS}
                unauthorizedRoute={'/home'}
                element={renderElement(<AreaOfStore />)}
              />
              <Route
                path="/asset-information"
                element={renderElement(
                  <>
                    <AssetInformation />
                    <AssetDetail />
                  </>,
                )}
              />
              <Route
                path="/asset-information/history"
                element={renderElement(
                  <>
                    <AssetInformation />
                    <AssetHistoryWorkOrderList />
                  </>,
                )}
              />
              <Route
                path="/asset-information/specifications"
                element={renderElement(
                  <>
                    <AssetInformation />
                    <AssetSpecifications />
                  </>,
                )}
              />
              <Route
                path={globalRoutes.createWorkOrder}
                allowed={ALLOWED_AD_GROUPS}
                unauthorizedRoute={'/home'}
                element={renderElement(<CreateWorkOrder />)}
              />
              <Route
                path={globalRoutes.duplicateWorkOrderList}
                element={renderElement(<DuplicateWorkOrder />)}
              />
              <Route
                path="/store-requests"
                element={renderElement(<StoreRequests />)}
              />
              <Route
                path="/decline-store-requests"
                element={renderElement(<DeclineStoreRequests />)}
              />
              <Route
                path="/completed-work-orders"
                element={renderElement(<CompletedWorkOrders />)}
              />
              <Route
                path={globalRoutes.allOpenWorkOrders}
                element={renderElement(<AllOpenWorkOrders />)}
              />
              <Route
                path={globalRoutes.startCreateWorkOrder}
                allowed={ALLOWED_AD_GROUPS}
                unauthorizedRoute={'/home'}
                element={renderElement(<StartCreateWorkOrder />)}
              />
              <Route
                path={globalRoutes.workOrderDetails}
                element={renderElement(
                  <>
                    <WorkOrderLanding />
                    <WorkOrderDetails />
                  </>,
                )}
              />
              <Route
                path="/store-request-details"
                element={renderElement(<StoreRequestDetails />)}
              />
              <Route
                path={globalRoutes.workOrderDetailsPlans}
                element={renderElement(
                  <>
                    <WorkOrderLanding />
                    <WorkOrderDetailsPlans />
                  </>,
                )}
              />
              <Route
                path={globalRoutes.workOrderDetailsWorkLog}
                element={renderElement(
                  <>
                    <WorkOrderLanding />
                    <WorkOrderDetailsWorkLog />
                  </>,
                )}
              />
              <Route
                path={globalRoutes.createWorkLog}
                element={renderElement(<CreateWorkLog />)}
              />
              <Route
                path={globalRoutes.workOrderDetailsSchedules}
                element={renderElement(
                  <>
                    <WorkOrderLanding />
                    <WorkOrderDetailsSchedules />
                  </>,
                )}
              />
              <Route
                path={globalRoutes.workorderDetailsImages}
                element={renderElement(
                  <>
                    <WorkOrderLanding />
                    <WorkOrderDetailsImages />
                  </>,
                )}
              />
              <Route
                path={globalRoutes.workorderDetailsSurvey}
                element={renderElement(
                  <>
                    <WorkOrderLanding />
                    <WorkOrderDetailsSurvey />
                  </>,
                )}
              />
              <Route
                path={globalRoutes.workorderDetailsRelatedRecords}
                element={renderElement(
                  <>
                    <WorkOrderLanding />
                    <WorkOrderDetailsRelatedRecords />
                  </>,
                )}
              />
              <Route
                path={globalRoutes.workOrderDetailsBilling}
                element={renderElement(
                  <>
                    <WorkOrderLanding />
                    <WorkOrderDetailsBilling />
                  </>,
                )}
              />
              <Route
                path={globalRoutes.workOrderAssetSpareParts}
                element={renderElement(
                  <>
                    <WorkOrderLanding />
                    <WorkOrderDetailsAssetSpareParts />
                  </>,
                )}
                allowed={DC_GROUP}
              />
              <Route
                path="/spare-parts-details"
                element={renderElement(<SparePartsDetails />)}
                allowed={DC_GROUP}
              />
              <Route
                path={globalRoutes.completeWorkOrder}
                element={renderElement(<CompleteWorkOrder />)}
              />
              <Route
                path={globalRoutes.actionNeeded}
                element={renderElement(<ActionNeededWorkOrders />)}
              />
              <Route
                path={globalRoutes.internalWorkOrders}
                element={renderElement(<InternalWorkOrders />)}
              />
              <Route
                path={globalRoutes.emergencyMaintenance}
                element={renderElement(<EmergencyMaintenance />)}
              />
              <Route
                path={globalRoutes.externalWorkOrders}
                element={renderElement(<ExternalWorkOrders />)}
              />
              <Route
                path={globalRoutes.redirectedWorkOrders}
                element={renderElement(<RedirectedWorkOrders />)}
              />
              <Route
                path={globalRoutes.cmWorkOrders}
                element={renderElement(<CMWorkOrders />)}
              />
              <Route
                path="/validate-work-order"
                element={renderElement(<ValidateWorkOrder />)}
              />
              <Route
                path={globalRoutes.uploadPhoto}
                element={renderElement(<UploadPhoto />)}
              />
              <Route
                path={globalRoutes.timeTracking}
                element={renderElement(<TimeTracking />)}
              />
              <Route
                path={globalRoutes.actionNeededVendorWorkOrders}
                element={renderElement(<ActionNeededVendorWorkOrders />)}
              />
              <Route
                path={globalRoutes.vendorWorkValidation}
                element={renderElement(<VendorWorkValidation />)}
              />
              <Route
                path={globalRoutes.fieldProjectWorkorders}
                element={renderElement(<FieldProjectWorkOrders />)}
              />
              <Route
                path={globalRoutes.cancelledExtWorkOrders}
                element={renderElement(<CancelledExtWorkOrders />)}
              />
              <Route
                path={globalRoutes.preventativeMaintenance}
                element={renderElement(<PreventativeMaintenanceWorkOrders />)}
              />
              <Route
                path={globalRoutes.safety}
                element={renderElement(<SafetyWorkOrders />)}
              />
              <Route
                path={globalRoutes.schedules}
                element={renderElement(<Schedules />)}
              />
              <Route
                path={globalRoutes.workLogVisitsMap}
                element={renderElement(<WorkLogVisitMap />)}
              />
              <Route
                path={globalRoutes.autoWorkOrders}
                element={renderElement(<AutoWorkOrders />)}
              />
              <Route
                path={globalRoutes.assetDowntime}
                element={renderElement(<AssetDowntime />)}
                allowed={DC_GROUP}
              />
              <Route
                path={globalRoutes.reportDowntime}
                element={renderElement(<ReportAssetDowntime />)}
                allowed={DC_GROUP}
              />
              <Route
                path={globalRoutes.storeroomItems}
                element={renderElement(
                  <>
                    <WorkOrderTabMenu storeroom />
                    <Suspense fallback={<div>Loading...</div>}>
                      <LazyStoreroomItems />
                    </Suspense>
                  </>,
                )}
                allowed={DC_GROUP}
              />
              <Route
                path={globalRoutes.workOrderDetailsIssuedParts}
                element={renderElement(
                  <>
                    <WorkOrderLanding />
                    <WorkOrderDetailsIssuedParts />
                  </>,
                )}
                allowed={DC_GROUP}
              />
              <Route
                path={globalRoutes.cyclecount}
                element={renderElement(<CycleCount />)}
                allowed={DC_GROUP}
              />
              <Route
                path={globalRoutes.cyclecountlist}
                allowed={DC_GROUP}
                element={renderElement(
                  <Suspense fallback={<div>Loading...</div>}>
                    <LazyCycleCountList />
                  </Suspense>,
                )}
              />

              <Route
                path={globalRoutes.cyclecountdetail}
                element={renderElement(<CycleCountDetail />)}
                allowed={DC_GROUP}
              />
            </Routes>
          </BrowserRouter>
        </>
      ) : (
        auth.login()
      )}
    </>
  )
}
export default App
