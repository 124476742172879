import { SET_WORK_ORDER_DETAIL, SET_STICKY_DIV_HEIGHT } from './actionType'

export function setWorkOrder(workOrderData) {
  return {
    type: SET_WORK_ORDER_DETAIL,
    workOrderData: workOrderData,
  }
}

export function setStickyDivHeight(height) {
  return {
    type: SET_STICKY_DIV_HEIGHT,
    height: height,
  }
}
