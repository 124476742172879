import { styled } from '@mui/material/styles'
import { makeStyles, createStyles } from '@mui/styles'
// import { useDispatch, useSelector } from 'react-redux'
import { Divider, Drawer, IconButton } from '@mui/material'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import NavigationItems from '../NavigationItems/NavigationItems'
import { APP_DRAWER, DRAWER_WIDTH } from '../../globalConstants'
import TargetLogo from '../../assets/targetLogo.svg'
// import CacheListManager from '../CacheListManager/CacheListManager'
// import { resetnavItemCounts, checkIfNavItemCountsLoaded } from '../../utils'
// import { setNavItems } from '../NavigationItems/store/actionCreator'
// import { APP_DRAWER_REFRESH_TEXT } from '../../globalConstants'
const useStyles = makeStyles((theme) =>
  createStyles({
    logo: {
      width: theme.spacing(4),
      height: theme.spacing(4),
      flexShrink: 0,
    },
  }),
)

// Vijay Menon - 09/07 - All code for CacheListManager has been commented out, so that it can be re-enabled if required in future
const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'space-between',
  minHeight: 'auto !important',
}))

export default function AppDrawer({ open, setAppDrawerOpen }) {
  const classes = useStyles()
  // const { navItems, lastUpdated } = useSelector((state) => ({
  //   ...state.navItemsReducer,
  //   ...state.storeInfoReducer,
  // }))
  // const dispatch = useDispatch()
  // const navItemsLoaded = checkIfNavItemCountsLoaded(navItems)

  const handleDrawerClose = () => {
    window.history.back()
  }
  return (
    <Drawer
      open={open}
      onClose={handleDrawerClose}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
      sx={{
        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: DRAWER_WIDTH },
      }}
    >
      <DrawerHeader>
        <TargetLogo className={classes.logo} />
        {/* <CacheListManager
          lastUpdated={lastUpdated}
          listLength={navItemsLoaded}
          clearMethod={() =>
            dispatch(setNavItems(resetnavItemCounts(navItems)))
          }
          customText={`${APP_DRAWER_REFRESH_TEXT} ${lastUpdated}`}
          buttonWrap
        /> */}
        <IconButton onClick={handleDrawerClose}>
          <ChevronLeftIcon />
        </IconButton>
      </DrawerHeader>
      <Divider />
      {open && (
        <NavigationItems
          from={APP_DRAWER}
          setAppDrawerOpen={setAppDrawerOpen}
        />
      )}
    </Drawer>
  )
}
