import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import LogoutIcon from '@mui/icons-material/Logout'
import { Divider, ListItem, ListItemText } from '@mui/material'
import { setURLSearchParams } from '../../windowManager'

const onClick = () => {
  setURLSearchParams([{ name: 'logout_dialog_open', value: true }])
}

const LogoutListItem = () => {
  return (
    <>
      <Divider />
      <ListItem disablePadding>
        <ListItemButton onClick={onClick}>
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItemButton>
      </ListItem>
    </>
  )
}

export default LogoutListItem
