import { SET_WORK_LOG_DETAILS, SET_WORK_LOG_NOTE_TYPES } from './actionType'

export const initialState = {
  worklogDetails: null,
  worklogNoteTypes: null,
}

export default function workLogDetailsReducer(
  state = initialState,
  action = {},
) {
  switch (action.type) {
    case SET_WORK_LOG_DETAILS: {
      const newState = {
        ...state,
        worklogDetails: action.worklogDetails,
      }
      return newState
    }
    case SET_WORK_LOG_NOTE_TYPES: {
      const newState = {
        ...state,
        worklogNoteTypes: action.worklogNoteTypes,
      }
      return newState
    }
    default:
      return state
  }
}
