import {
  SET_WORK_ORDER,
  RESET_WORK_ORDER,
  SET_FOLLOW_UP_FLOW,
} from './actionType'

export function setWorkOrder(createWorkOrderData) {
  return {
    type: SET_WORK_ORDER,
    createWorkOrderData: createWorkOrderData,
  }
}

export function resetWorkOrder() {
  return {
    type: RESET_WORK_ORDER,
  }
}

export function setFollowUpFlow(bool, properties) {
  return {
    type: SET_FOLLOW_UP_FLOW,
    value: bool,
    properties,
  }
}
