/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import { useAuth } from '@praxis/component-auth'
import { fetchData, postData, putData } from '../../service/HttpService'
import { LocalizationProvider, DesktopDatePicker } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import {
  Button,
  Grid,
  Typography,
  Alert,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import { getURLSearchParams } from '../../windowManager'
import { setShouldRender } from '../ProgressOverlay/store/actionCreator'
import { setWorkOrder } from '../WorkOrderDetails/store/actionCreator'
import {
  DEFAULT_ERROR_MESSAGE,
  LABOR_ENTRIES_KEY,
  TIME_TRACKING_MIN_TIME,
  api,
} from '../../globalConstants'
import { isHoursInvalid, isMinutesInvalid } from '../../utils'
import AddTime from '../AddTime/AddTime'
import useHashChange from '../../utils/hooks/useHashChange'
import { setTimeEntries } from '../TimeTracking/store/actionCreator'
import { formattedTime } from '../../utils'
import { makeStyles, createStyles } from '@mui/styles'

const useStyles = makeStyles((theme) =>
  createStyles({
    timeWarning: {
      margin: theme.spacing(0, 3, 1, 3),
    },
    dialogActions: {
      padding: theme.spacing(0, 3, 2, 0),
    },
  }),
)

const AddTimeModal = ({
  timeTracking = false,
  isEditing = false,
  timeToEdit,
  dateToEdit,
  setIsEditing,
  laborId,
}) => {
  const classes = useStyles()
  const navigate = useNavigate()
  const location = useLocation()
  const auth = useAuth()
  const userId = auth.session.userInfo?.lanId?.toUpperCase()
  const urlSearchParams = getURLSearchParams()
  const woNum = parseInt(urlSearchParams.get('wonum'))
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  useHashChange('add_time_open', setOpen)
  const [error, setError] = useState(false)
  const timeInHoursAndMinutes = formattedTime(timeToEdit)
  const [hours, setHours] = useState(0)
  const [minutes, setMinutes] = useState(0)
  const [userLoggedTime, setUserLoggedTime] = useState('')
  const [isUserTimeLimitReached, setIsUserTimeLimitReached] = useState(false)
  const [laborTypeList, setLaborTypeList] = useState([])
  const [selectedLaborType, setSelectedLaborType] = useState('')
  const [dateValue, setDateValue] = useState(moment())
  const [isOneMin, setIsOneMin] = useState(false)
  const { storeId = null, isDCUser } = useSelector((state) => ({
    ...state.storeInfoReducer,
    ...state.userInfoReducer,
  }))

  useEffect(() => {
    try {
      getUserLoggedTime(storeId)
      getLaborTypes()
    } catch (error) {
      setError(true)
    }
  }, [])

  useEffect(() => {
    setIsOneMin(!hours && minutes === '1' ? false : true)
  }, [hours, minutes])

  useEffect(() => {
    setDateValue(isEditing ? moment(dateToEdit) : moment())
    setHours(isEditing ? timeInHoursAndMinutes?.hr : 0)
    setMinutes(isEditing ? timeInHoursAndMinutes?.min : 0)
  }, [isEditing])

  const saveTime = async () => {
    const requestBody = {
      'regular-hrs': moment.duration(`${hours}:${minutes}`).asHours(),
      'trans-type': timeTracking
        ? laborTypeList.find((labor) => labor.description === selectedLaborType)
            ?.value
        : 'WORK',
      wonum: woNum,
      siteid: storeId,
      'labor-code': userId,
      'start-date':
        timeTracking || isEditing
          ? dateValue.format('YYYY-MM-DD')
          : moment().format('YYYY-MM-DD'),
      ...((isUserTimeLimitReached || isEditing) && { 'api-update': 8 }),
    }

    const editRequestBody = {
      'labortrans-id': laborId,
      'labor-code': userId,
      'regular-hrs': moment.duration(`${hours}:${minutes}`).asHours(),
      'api-update': 8,
      'start-date': dateValue.format('YYYY-MM-DD'),
    }

    try {
      dispatch(setShouldRender(true, 'Saving Time..'))
      isEditing
        ? await putData(
            '/maximo/labortransaction',
            JSON.stringify(editRequestBody),
          )
        : await postData(
            '/maximo/labortransaction',
            JSON.stringify(requestBody),
          )

      if (timeTracking) {
        const laborResponse = await fetchData(
          api.laborEntries(storeId, userId?.toUpperCase()),
        )
        const myTimeEntries = laborResponse?.[LABOR_ENTRIES_KEY] ?? []
        dispatch(setTimeEntries(myTimeEntries))
        setSelectedLaborType('')
        setDateValue(moment())
      } else {
        const woResponse = await fetchData(`/mam/workorder/wonum/${woNum}`)
        dispatch(setWorkOrder(woResponse.woDetails[0]))
      }

      await getUserLoggedTime(storeId)
      setIsUserTimeLimitReached(false)

      closeModal()

      setHours(0)
      setMinutes(0)
      setError(false)
      isEditing && setIsEditing(false)
    } catch (error) {
      error.response?.data?.includes('BMXZZ2087E')
        ? setIsUserTimeLimitReached(true)
        : setError(true)
    } finally {
      dispatch(setShouldRender(false))
    }
  }

  const getLaborTypes = async () => {
    const laborTypeResp = await fetchData('/mam/timetypelist')
    const orgId = isDCUser ? 'TGTDC' : 'TGTSTORE'
    const filteredLaborType = laborTypeResp.timetypedata.filter(
      (obj) => obj.orgid === orgId && obj.value !== 'WORK',
    )
    setLaborTypeList(filteredLaborType)
  }

  const getUserLoggedTime = async (locationId) => {
    const userTimeResp = await fetchData(
      api.userLaborEntries(locationId, userId),
    )
    const formattedUserTime = userTimeResp.totalTime
      .replace('(Total Today: ', '')
      .slice(0, -1)
      .replace(',', '')
    setUserLoggedTime(formattedUserTime)
  }

  const closeModal = () => {
    const path = timeTracking
      ? '/time-tracking'
      : `${location.pathname}#?wonum=${woNum}&showBack=true`
    navigate(path)
    setError(false)
  }

  const handleLaborType = async (e) => {
    setSelectedLaborType(e.target.value)
  }

  const handleDateChange = (newValue) => {
    setDateValue(newValue)
  }

  const handleCancel = () => {
    if (isUserTimeLimitReached) {
      setIsUserTimeLimitReached(false)
    } else if (isEditing) {
      try {
        window.history.back()
      } catch (error) {
        console.log('error')
      } finally {
        setIsEditing(false)
      }
    } else {
      window.history.back()
    }
    setError(false)
  }

  return (
    <Dialog open={open} onClose={handleCancel}>
      <DialogTitle>Add Time</DialogTitle>
      {error && <Alert severity="error">{DEFAULT_ERROR_MESSAGE}</Alert>}
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography>Total Today:</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography data-testid="total-hours">
              {userLoggedTime ?? '0 Hrs 0 Mins'}
            </Typography>
          </Grid>
          {isUserTimeLimitReached && (
            <Grid item>
              <Typography>
                You have exceeded 8 hours for this date. Do you want to
                Continue?
              </Typography>
              <Typography>If No, this record will not be saved.</Typography>
            </Grid>
          )}
        </Grid>
        {!isUserTimeLimitReached && (
          <AddTime
            hours={hours}
            minutes={minutes}
            onHoursChange={setHours}
            onMinutesChange={setMinutes}
          />
        )}
        {!isUserTimeLimitReached && (
          <>
            <Grid container spacing={2} rowSpacing={1}>
              {timeTracking && !isEditing && (
                <Grid item xs={12}>
                  <FormControl fullWidth size="small" data-testid="labor-form">
                    <InputLabel>Labor Type</InputLabel>
                    <Select
                      value={error ? '' : selectedLaborType}
                      inputProps={{ 'data-testid': 'labor-type' }}
                      label="Labor Type"
                      aria-label="Labor Type"
                      onChange={(e) => handleLaborType(e)}
                    >
                      {laborTypeList.length > 0 &&
                        !error &&
                        laborTypeList.map((laborType, index) => {
                          return (
                            <MenuItem key={index} value={laborType.description}>
                              {laborType.description}
                            </MenuItem>
                          )
                        })}
                    </Select>
                  </FormControl>
                </Grid>
              )}
              {timeTracking && (
                <Grid item xs={12}>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DesktopDatePicker
                      data-testid="date-picker"
                      inputFormat="MM/DD/YYYY"
                      value={dateValue}
                      onChange={handleDateChange}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          helperText={
                            dateValue &&
                            dateValue.isBefore(moment().subtract(11, 'days')) &&
                            'Cannot be more than 10 days in the past.'
                          }
                        />
                      )}
                      minDate={moment().subtract(10, 'days')}
                    />
                  </LocalizationProvider>
                </Grid>
              )}
            </Grid>
          </>
        )}
      </DialogContent>
      {!isOneMin && (
        <Alert severity="warning" className={classes.timeWarning}>
          {TIME_TRACKING_MIN_TIME}
        </Alert>
      )}
      <DialogActions className={classes.dialogActions}>
        <Button variant="outlined" onClick={handleCancel}>
          {isUserTimeLimitReached ? 'No' : 'Cancel'}
        </Button>
        <Button
          data-testid="save-time-button"
          disabled={
            (!hours && !minutes) ||
            !isOneMin ||
            isHoursInvalid(hours) ||
            isMinutesInvalid(minutes) ||
            (timeTracking && selectedLaborType === '' && !isEditing) ||
            (timeTracking && dateValue === null) ||
            (timeTracking && !dateValue.isValid()) ||
            (timeTracking &&
              dateValue.isBefore(moment().subtract(11, 'days'))) ||
            (timeInHoursAndMinutes?.hr === hours &&
              timeInHoursAndMinutes?.min === minutes &&
              moment(dateToEdit).isSame(dateValue))
          }
          variant="contained"
          onClick={saveTime}
        >
          {isUserTimeLimitReached ? 'Yes' : 'Save'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AddTimeModal
