import { useEffect, useState, Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  Alert,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from '@mui/material'
import { setShouldRender } from '../../components/ProgressOverlay/store/actionCreator'
import { fetchData } from '../../service/HttpService'
import { makeStyles, createStyles } from '@mui/styles'
import { COLOR_DEFAULT_GREY } from '../../globalColors'
import { globalRoutes, WO } from '../../globalConstants'
import MatAppBar from '../MatAppBar/MatAppBar'
import { getURLSearchParams } from '../../windowManager'

const useStyles = makeStyles((theme) =>
  createStyles({
    list: {
      padding: theme.spacing(0),
    },
    alertInfo: {
      marginTop: theme.spacing(2),
      borderColor: COLOR_DEFAULT_GREY,
      '& .MuiAlert-icon > svg': {
        fill: COLOR_DEFAULT_GREY,
      },
    },
    warningError: {
      margin: theme.spacing(1),
    },
  }),
)

const WorkOrderDetailsIssuedParts = () => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [issuedParts, setIssuedParts] = useState([])
  const classes = useStyles()
  const navigate = useNavigate()

  const { wodetails } = useSelector((state) => ({
    ...state.workOrderDetailsReducer,
    ...state.userInfoReducer,
  }))

  const { assettag, woassetnum, wositeid, wonum } = wodetails ?? {}
  const dispatch = useDispatch()

  useEffect(() => {
    const getIssuedParts = async () => {
      try {
        setLoading(true)
        dispatch(setShouldRender(true, 'Loading...', `Issued Parts`))
        const response = await fetchData(
          `/mam/itemsissued/siteid/${wositeid}/wonum/${wonum}`,
        )
        setIssuedParts(response.itemsissueddata)
      } catch (error) {
        setError(true)
        setLoading(false)
      } finally {
        dispatch(setShouldRender(false))
        setLoading(false)
      }
    }
    getIssuedParts()
  }, [wodetails, assettag, woassetnum, dispatch, wositeid, wonum])

  return (
    <>
      <MatAppBar
        arrowBack
        text={`${WO} ${getURLSearchParams().get('wonum')}`}
        minimizeHeader
        storeroom
      />
      {error && !loading && (
        <Alert
          severity="error"
          className={classes.warningError}
          data-testid={'error-header'}
        >
          Validate WO `There was a problem retrieving issued parts!`
        </Alert>
      )}
      {issuedParts?.length === 0 && !loading ? (
        <Alert
          severity="warning"
          data-testid={'no-data-text'}
          className={classes.warningError}
        >
          There are no parts issued for this work order.
        </Alert>
      ) : (
        <>
          <Alert
            className={classes.alertInfo}
            severity="info"
            variant="outlined"
            data-testid={'info-header'}
          >
            Parts currently issued for {wonum}
          </Alert>
          <List className={classes.list}>
            {issuedParts.map((val, index) => {
              const itemnum = val.itemnum || 'N/A'
              const description = val.description || 'N/A'
              const currentbalance = val.currbalance || 0
              const binnum = val.binnum || 'N/A'
              const issueunit = val.issueunit || 'N/A'
              const issuedqty = Math.abs(val.quantity) || 0
              return (
                <Fragment key={index}>
                  <ListItem key={index} disablePadding>
                    <ListItemButton
                      onClick={() =>
                        navigate(
                          `${globalRoutes.sparePartsDetails}#?binnum=${binnum}&issueunit=${issueunit}&itemnum=${itemnum}&description=${description}&currentbalance=${currentbalance}&issuedqty=${issuedqty}&wonum=${wonum}&location=${wodetails.wolocation}&wostatus=${wodetails.wostatus}`,
                        )
                      }
                    >
                      <ListItemText
                        primary={
                          <Typography component="span">
                            <div>{description}</div>
                          </Typography>
                        }
                        secondary={
                          <Fragment>
                            <Typography
                              sx={{ display: 'block' }}
                              component={'span'}
                            >
                              {itemnum}
                            </Typography>
                            <Typography
                              sx={{ display: 'block' }}
                              component="span"
                            >
                              Issued Quantity: {issuedqty}
                            </Typography>
                            <Typography
                              sx={{ display: 'block' }}
                              component="span"
                            >
                              Current Balance: {currentbalance}
                            </Typography>
                            <Typography
                              sx={{ display: 'block' }}
                              component="span"
                            >
                              Bin: {binnum}
                            </Typography>
                          </Fragment>
                        }
                      />
                    </ListItemButton>
                  </ListItem>
                  <Divider />
                </Fragment>
              )
            })}
          </List>
        </>
      )}
    </>
  )
}

export default WorkOrderDetailsIssuedParts
