/* eslint-disable react-hooks/exhaustive-deps */
import { useAuth } from '@praxis/component-auth'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import {
  Alert,
  Button,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
  Snackbar,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
} from '@mui/material'

import { WORK_ORDER, globalRoutes, api } from '../../globalConstants'
import { setShouldRender } from '../ProgressOverlay/store/actionCreator'
import AddTime from '../AddTime/AddTime'
import { fetchData, putData, postData } from '../../service/HttpService'
import MatAppBar from '../MatAppBar/MatAppBar'
import useGetWorkOrderDetails from '../../utils/hooks/useGetWorkOrderDetails'
import {
  isHoursInvalid,
  isMinutesInvalid,
  formattedTime,
  setTimeString,
} from '../../utils'
import useHashChange from '../../utils/hooks/useHashChange'
import { getURLSearchParams, setURLSearchParams } from '../../windowManager'
import { setCompletedWorkOrderList } from '../CompletedWorkOrders/store/actionCreator'
import StatusAlert from '../StatusAlert/StatusAlert'
import { setWorkOrder } from '../WorkOrderDetails/store/actionCreator'

const CompleteWorkOrder = () => {
  const [open, setOpen] = useState(false)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const auth = useAuth()
  const userId = auth.session.userInfo?.lanId.toUpperCase()
  const { storeId = null } = useSelector((state) => state.storeInfoReducer)
  const urlSearchParams = getURLSearchParams()
  const woNum = parseInt(urlSearchParams.get('wonum'))
  useHashChange('time_over_limit_open', setOpen)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [completeWOError, setCompleteWOError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [selectedCause, setSelectedCause] = useState('')
  const [causeList, setCauseList] = useState([])
  const [selectedRemedy, setSelectedRemedy] = useState('')
  const [remedyList, setRemedyList] = useState([])
  const [hours, setHours] = useState(0)
  const [minutes, setMinutes] = useState(0)
  const [loggedTime, setLoggedTime] = useState(0)
  const [loggedTimeText, setLoggedTimeText] = useState('0 Hrs 0 Mins')
  const [isUserTimeLimitReached, setIsUserTimeLimitReached] = useState(false)
  const [userLoggedTime, setUserLoggedTime] = useState('')
  const woDetailFetched = useGetWorkOrderDetails()
  const { wodetails = null } = useSelector(
    (state) => state.workOrderDetailsReducer,
  )
  const [isSuccess, setIsSuccess] = useState(false)
  const [successMessage, setSuccessMessage] = useState('')
  const isWOTypePMorFP =
    wodetails?.wotype === 'PM' ||
    wodetails?.wotype === 'FP' ||
    wodetails?.wotype === 'PMPRINT'

  useEffect(() => {
    async function getData() {
      try {
        dispatch(setShouldRender(true, 'Loading...'))
        setError(false)
        const time = wodetails && wodetails?.ltreghrs
        setLoggedTime(time)

        if (time || time === 0) {
          const loggedTimeObj = formattedTime(time)
          const loggedTime = setTimeString(loggedTimeObj)
          setLoggedTimeText(loggedTime)
        }
        if (!isWOTypePMorFP) {
          dispatch(setShouldRender(true, 'Loading...'))
          const causeResponse = await fetchData(
            `/mam/failurelist/parent/${wodetails.failurelist}`,
          )
          causeResponse && setCauseList(causeResponse.failuredata)
        }
      } catch (error) {
        setErrorMessage('Unable to load logged time and or Cause/Remedy')
        setError(true)
      } finally {
        setLoading(false)
        dispatch(setShouldRender(false))
      }
    }
    getData()
  }, [loggedTime])

  const getUserLoggedTime = async (locationId) => {
    const userTimeResp = await fetchData(
      api.userLaborEntries(locationId, userId.toUpperCase()),
    )
    const formattedUserTime = userTimeResp.totalTime
      .replace('(Total Today: ', '')
      .slice(0, -1)
      .replace(',', '')
    setUserLoggedTime(formattedUserTime)
  }
  const timeLimitReached = () => {
    setIsUserTimeLimitReached(true)
    setURLSearchParams([{ name: 'time_over_limit_open', value: true }])
  }

  const errorAddingTime = () => {
    setErrorMessage(
      'Add Time failure. Work Order status complete failure. Please retry.',
    )
    setCompleteWOError(true)
  }

  const saveTime = async () => {
    try {
      await getUserLoggedTime(storeId)
      const requestBody = {
        'regular-hrs': moment.duration(`${hours}:${minutes}`).asHours(),
        'trans-type': 'WORK',
        wonum: woNum,
        siteid: wodetails.wositeid,
        'labor-code': userId,
        'start-date': moment().format(),
        'finish-date': moment()
          .add(hours, 'hours')
          .add(minutes, 'minutes')
          .format(),
        ...(isUserTimeLimitReached && { 'api-update': 8 }),
      }

      await postData('/maximo/labortransaction', JSON.stringify(requestBody))
      isUserTimeLimitReached && window.history.back()
      setIsUserTimeLimitReached(false)

      setSuccessMessage('Add time successful!')
      setIsSuccess(true)
      setLoggedTime(0)
      setHours(0)
      setMinutes(0)
      await completeWorkOrder()
      return true
    } catch (error) {
      error.response?.data?.includes('BMXZZ2087E')
        ? timeLimitReached()
        : errorAddingTime()
      return false
    } finally {
      dispatch(setShouldRender(false))
    }
  }

  const saveCauseAndRemedy = async () => {
    try {
      await putData(`/maximo/workorder/${woNum}`, {
        cause: selectedCause,
        remedy: selectedRemedy,
      })
      setSuccessMessage('Add cause/remedy successful!')
      setIsSuccess(true)

      return true
    } catch (error) {
      setErrorMessage(
        'Unable to save cause/remedy. Work Order status complete failure. Please retry.',
      )
      setCompleteWOError(true)
      return false
    }
  }

  const cancelCompleteWO = () => {
    setIsUserTimeLimitReached(false)
    window.history.back()
  }

  const completeWorkOrder = async () => {
    try {
      const requestBody = {
        status: 'COMP',
        'change-by': userId,
        'workorder-id': wodetails.workorderid,
        wonum: woNum,
      }

      await putData(
        '/maximo/workorderstatusupdate',
        JSON.stringify(requestBody),
      )
      setIsSuccess(true)
      setSuccessMessage('Change status successful!')
      dispatch(setWorkOrder(null))
      navigate(`/home`)
    } catch (error) {
      setErrorMessage('Unable to complete WO. Please retry.')
      setCompleteWOError(true)
    } finally {
      dispatch(setShouldRender(false))
      dispatch(setCompletedWorkOrderList([]))
    }
  }

  const handleClick = async () => {
    dispatch(setShouldRender(true, 'Completing Work Order'))
    setCompleteWOError(false)

    await saveCauseAndRemedy()
    if (hours > 0 || minutes > 0) {
      await saveTime()
    } else {
      await completeWorkOrder()
    }
  }

  const handleCause = async (e) => {
    setSelectedCause(e.target.value)
    const causeId = causeList.find(
      (causeObj) => causeObj.failureCode === e.target.value,
    )?.id
    try {
      const remedyResponse = await fetchData(
        `/mam/failurelist/parent/${causeId}`,
      )
      remedyResponse && setRemedyList(remedyResponse.failuredata)
    } catch (error) {
      setError(error)
    }
  }

  return (
    <>
      <MatAppBar arrowBack={true} text={`Complete WO ${woNum}`}></MatAppBar>
      {(error || completeWOError) && !open && (
        <Alert severity="error">{errorMessage}</Alert>
      )}
      <StatusAlert
        text="Please add time to work order if time was not previously recorded
              by you."
      />
      <Container>
        <Grid container alignItems="center" spacing={3}>
          <Grid item xs={2}>
            <Typography>Add Time:</Typography>
          </Grid>
          <Grid item xs={10}>
            <AddTime
              hours={hours}
              minutes={minutes}
              onHoursChange={setHours}
              onMinutesChange={setMinutes}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography>Total Logged Time:</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>{loggedTimeText}</Typography>
          </Grid>
          {!isWOTypePMorFP && woDetailFetched && (
            <>
              <Grid item xs={12}>
                <Typography component={'span'} color="red">
                  Cause and Remedy is required.
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <Typography>Cause</Typography>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth size="small">
                  <InputLabel>Provide Cause</InputLabel>
                  <Select
                    value={error || loading ? '' : selectedCause}
                    label="Provide Cause"
                    aria-label="Cause"
                    onChange={(e) => handleCause(e)}
                  >
                    {causeList.length > 0 &&
                      !error &&
                      !loading &&
                      causeList.map((cause, index) => {
                        return (
                          <MenuItem key={index} value={cause.failureCode}>
                            {cause.failureCode}
                          </MenuItem>
                        )
                      })}
                  </Select>
                </FormControl>
              </Grid>
            </>
          )}
          {selectedCause.length > 0 && !isWOTypePMorFP && (
            <>
              <Grid item xs={6}>
                <Typography>Remedy</Typography>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth size="small">
                  <InputLabel>Provide Remedy</InputLabel>
                  <Select
                    value={error || loading ? '' : selectedRemedy}
                    label="Provide Remedy"
                    onChange={(e) => setSelectedRemedy(e.target.value)}
                  >
                    {remedyList.length > 0 &&
                      !error &&
                      !loading &&
                      remedyList.map((remedy, index) => {
                        return (
                          <MenuItem key={index} value={remedy.failureCode}>
                            {remedy.failureCode}
                          </MenuItem>
                        )
                      })}
                  </Select>
                </FormControl>
              </Grid>
            </>
          )}

          <Grid item container justifyContent="flex-end">
            <Stack direction="row" spacing={2}>
              <Button
                variant="outlined"
                onClick={() =>
                  navigate(`${globalRoutes.workOrderDetails}#?wonum=${woNum}`)
                }
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                disabled={
                  isHoursInvalid(hours) ||
                  isMinutesInvalid(minutes) ||
                  (!isWOTypePMorFP &&
                    (selectedCause.length === 0 ||
                      selectedRemedy.length === 0)) ||
                  (!loggedTime && !hours && !minutes)
                }
                onClick={handleClick}
              >
                {`Complete ${WORK_ORDER}`}
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Container>
      <Snackbar
        open={isSuccess}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={6000}
        onClose={() => setIsSuccess(false)}
        severity="success"
      >
        <Alert
          onClose={() => setIsSuccess(false)}
          severity="success"
          sx={{ width: '100%' }}
        >
          {successMessage}
        </Alert>
      </Snackbar>

      <Dialog open={open} onClose={cancelCompleteWO}>
        <DialogTitle>Add Time</DialogTitle>
        {(error || completeWOError) && (
          <Alert severity="error">{errorMessage}</Alert>
        )}
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography>Total Today:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>{userLoggedTime ?? '0 Hrs 0 Mins'}</Typography>
            </Grid>
            <Grid item>
              <Typography>
                You have exceeded 8 hours for this date. Do you want to
                Continue?
              </Typography>
              <Typography>If No, this record will not be saved.</Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={cancelCompleteWO}>
            No
          </Button>
          <Button variant="contained" onClick={saveTime}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default CompleteWorkOrder
