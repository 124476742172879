import { useState } from 'react'
import TargetLogo from '../../assets/targetlogo.png'
import {
  Container,
  IconButton,
  AppBar,
  Toolbar,
  Typography,
  Button,
  styled,
  useMediaQuery,
  Badge,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { makeStyles, createStyles } from '@mui/styles'
import {
  WORK_ORDER,
  MAT_UI_LG_WIDTH,
  MAT_UI_LG_WIDTH_HALF,
  HOME_ROUTE,
  globalRoutes,
} from '../../globalConstants'
import BackButton from '../BackButton/BackButton'
import { connect } from 'react-redux'
import { setURLSearchParams } from '../../windowManager'
import SiteChangeDialog from './SiteChangeDialog'
import SearchBar from '../SearchBar/SearchBar'
import MenuIcon from '@mui/icons-material/Menu'
import AppDrawer from '../AppDrawer/AppDrawer'
import useHashChange from '../../utils/hooks/useHashChange'
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto'
import FollowUpWoForWorkOrders from '../FollowUpWoForWorkOrders/FollowUpWoForWorkOrders'
import FollowUpWoForStoreRequests from '../FollowUpWoForStoreRequests/FollowUpWoForStoreRequests'
import { COLOR_THEME_ACCENT } from '../../globalColors'
import CreateWorkOrderInfo from '../CreateWorkOrderInfo/CreateWorkOrderInfo'
import CardSettingsDialog from '../CardSettingsDialog/CardSettingsDialog'
import { useEnv } from '@praxis/component-runtime-env'

const useStyles = makeStyles((theme) =>
  createStyles({
    logo: {
      marginRight: theme.spacing(2.5),
      height: theme.spacing(3),
    },
    appBar: {
      maxWidth: MAT_UI_LG_WIDTH,
      left: '50%',
      marginLeft: -MAT_UI_LG_WIDTH_HALF,
      [theme.breakpoints.down('lg')]: {
        marginLeft: 'auto',
      },
      backgroundColor: COLOR_THEME_ACCENT,
    },
    toolbarEndDiv: {
      display: 'flex',
      alignItems: 'center',
    },
    smallerFont: {
      fontSize: '1rem',
    },
    alertMessage: {
      margin: theme.spacing(0, '8px'),
    },
  }),
)

const Offset = styled('div')(({ theme }) => theme.mixins.toolbar)

const MatAppBar = ({
  text = `Create a ${WORK_ORDER}`,
  arrowBack = false,
  storeId,
  changeSite = false,
  search = { onSearch: null, searchKey: null },
  storeroom,
  uploadPhoto = '',
  renderFollowUpInfoIfExists,
  minimizeHeader, // reduce font size for headers in smaller form factors if required
  createWorkOrderFlow,
}) => {
  const env = useEnv()

  const inAppMessage = env?.inAppMessage

  const classes = useStyles()
  const navigate = useNavigate()
  const smallScreen = useMediaQuery('(max-width:600px)')
  const { onSearch, searchKey } = search
  const [open, setOpen] = useState(false)
  useHashChange('app_drawer', setOpen)
  const isHomePage = window.location.pathname === HOME_ROUTE
  const handleDrawerOpen = () => {
    setOpen(true)
    setURLSearchParams([{ name: 'app_drawer', value: true }])
  }

  const onClick = () => {
    setURLSearchParams([{ name: 'site_change_open', value: true }])
  }

  return (
    <>
      <AppBar position="fixed" className={classes.appBar}>
        <Container maxWidth="lg" disableGutters>
          <Toolbar>
            {!isHomePage && (
              <>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawerOpen}
                  edge="start"
                  sx={{
                    marginRight: 2,
                    ...(open && { display: 'none' }),
                  }}
                >
                  <Badge
                    color="warning"
                    variant="dot"
                    invisible={!inAppMessage}
                  >
                    <MenuIcon />
                  </Badge>
                </IconButton>
                <AppDrawer open={open} setAppDrawerOpen={setOpen} />
              </>
            )}
            {arrowBack ? (
              <BackButton />
            ) : (
              <img
                src={TargetLogo}
                alt={'Target Logo'}
                className={classes.logo}
              />
            )}
            <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 1 }}
              className={
                minimizeHeader && smallScreen ? classes.smallerFont : undefined
              }
            >
              {text}
            </Typography>

            <div className={classes.toolbarEndDiv}>
              {uploadPhoto && (
                <IconButton
                  color="inherit"
                  aria-label="upload photo for work order"
                  onClick={() =>
                    navigate(`${globalRoutes.uploadPhoto}${uploadPhoto}`)
                  }
                  edge="start"
                  sx={{
                    marginRight: 1,
                    marginLeft: 1,
                  }}
                >
                  <AddAPhotoIcon />
                </IconButton>
              )}
              {changeSite ? (
                <Button variant="outlined" color="inherit" onClick={onClick}>
                  {storeId}
                </Button>
              ) : (
                <Typography>{storeId}</Typography>
              )}
            </div>
          </Toolbar>
          {onSearch && <SearchBar onSearch={onSearch} searchKey={searchKey} />}
        </Container>
      </AppBar>
      {!storeroom && <Offset />}
      {onSearch && !storeroom && <Offset />}
      <SiteChangeDialog />
      {renderFollowUpInfoIfExists && (
        <>
          <FollowUpWoForWorkOrders
            renderFollowUpInfoIfExists={renderFollowUpInfoIfExists}
          />
          <FollowUpWoForStoreRequests
            renderFollowUpInfoIfExists={renderFollowUpInfoIfExists}
          />
        </>
      )}
      <CreateWorkOrderInfo createWorkOrderFlow={createWorkOrderFlow} />
      <CardSettingsDialog />
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    storeId: state.storeInfoReducer.storeId,
  }
}

export default connect(mapStateToProps, null)(MatAppBar)
