import React from 'react'
import { styled } from '@mui/material/styles'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import {
  COLOR_TARGET_RED,
  COLOR_THEME_ACCENT,
  COLOR_FONT_BLUE,
} from '../../globalColors'
const WOATabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: COLOR_TARGET_RED,
    height: 4,
  },
  '& .MuiTabs-indicatorSpan': {
    backgroundColor: COLOR_TARGET_RED,
  },
})

const WOATab = styled(
  React.forwardRef((props, ref) => <Tab {...props} ref={ref} />),
)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightBold,
  marginRight: theme.spacing(1),
  textTransform: 'none',
  color: COLOR_FONT_BLUE,
  '&.Mui-selected': {
    color: COLOR_THEME_ACCENT,
    fontWeight: theme.typography.fontWeightBold,
  },
}))

export { WOATabs, WOATab }
