import { useEffect, useState, Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  Alert,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from '@mui/material'
import { setShouldRender } from '../../components/ProgressOverlay/store/actionCreator'
import { fetchData } from '../../service/HttpService'
import { makeStyles, createStyles } from '@mui/styles'
import { COLOR_DEFAULT_GREY } from '../../globalColors'
import { globalRoutes } from '../../globalConstants'

const useStyles = makeStyles((theme) =>
  createStyles({
    list: {
      padding: theme.spacing(0),
    },
    alertInfo: {
      marginTop: theme.spacing(2),
      borderColor: COLOR_DEFAULT_GREY,
      '& .MuiAlert-icon > svg': {
        fill: COLOR_DEFAULT_GREY,
      },
    },
    warningError: {
      margin: theme.spacing(1),
    },
  }),
)

const WorkOrderDetailsAssetSpareParts = () => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [spareParts, setSpareParts] = useState([])
  const classes = useStyles()
  const navigate = useNavigate()

  const { wodetails = null } = useSelector(
    (state) => state.workOrderDetailsReducer,
  )
  const { assettag, woassetnum, wositeid, woassetname } = wodetails ?? {}

  const dispatch = useDispatch()

  useEffect(() => {
    const getAssetSpareParts = async () => {
      try {
        setLoading(true)
        dispatch(setShouldRender(true, 'Loading...', `Asset Spare Parts`))
        const response = await fetchData(
          `/mam/buildofmaterials/siteId/${wositeid}/assetNum/${woassetnum}`,
        )
        setSpareParts(response.bomdata)
      } catch (error) {
        setError(true)
        setLoading(false)
      } finally {
        dispatch(setShouldRender(false))
        setLoading(false)
      }
    }
    getAssetSpareParts()
  }, [wodetails, assettag, woassetnum, dispatch, wositeid])

  return (
    <>
      {error && !loading && (
        <Alert severity="error" className={classes.warningError}>
          `There was a problem retrieving Asset Spare Parts!`
        </Alert>
      )}
      {spareParts?.length === 0 && !loading ? (
        <Alert
          severity="warning"
          data-testid={'no-data-text'}
          className={classes.warningError}
        >
          There are no defined spare parts for this asset.
        </Alert>
      ) : (
        <>
          <Alert
            className={classes.alertInfo}
            severity="info"
            variant="outlined"
          >
            Spare Parts for Asset {woassetname} Tag {assettag}
          </Alert>
          <List className={classes.list}>
            {spareParts.map((val, index) => {
              const itemnum = val.itemnum || 'N/A'
              const description = val.description || 'N/A'
              const currentbalance = val.curbal || 0
              const binnum = val.binnum || 'N/A'
              const issueunit = val.issueunit || 'N/A'
              const assetquantity = val.quantity || 0
              return (
                <Fragment key={index}>
                  <ListItem key={index} disablePadding>
                    <ListItemButton
                      onClick={() =>
                        navigate(
                          `${globalRoutes.sparePartsDetails}#?binnum=${binnum}&issueunit=${issueunit}&itemnum=${itemnum}&description=${description}&currentbalance=${currentbalance}&wonum=${wodetails.wonum}&location=${wodetails.wolocation}&assetsparepart=1&wostatus=${wodetails.wostatus}`,
                        )
                      }
                    >
                      <ListItemText
                        primary={
                          <Typography component="span">
                            <div>{description}</div>
                          </Typography>
                        }
                        secondary={
                          <Fragment>
                            <Typography
                              sx={{ display: 'block' }}
                              component={'span'}
                            >
                              {itemnum}
                            </Typography>
                            <Typography
                              sx={{ display: 'block' }}
                              component="span"
                            >
                              Asset Quantity: {assetquantity}
                            </Typography>
                            <Typography
                              sx={{ display: 'block' }}
                              component="span"
                            >
                              Current Balance: {currentbalance}
                            </Typography>
                            <Typography
                              sx={{ display: 'block' }}
                              component="span"
                            >
                              Bin: {binnum}
                            </Typography>
                          </Fragment>
                        }
                      />
                    </ListItemButton>
                  </ListItem>
                  <Divider />
                </Fragment>
              )
            })}
          </List>
        </>
      )}
    </>
  )
}

export default WorkOrderDetailsAssetSpareParts
