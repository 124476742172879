import {
  SET_CARD_COLLAPSE,
  SET_CARD_PREFERENCES,
  SET_USER_INFO,
  SET_USER_STOREROOM,
  SET_USER_ROLES,
  SET_IS_DC_USER,
  SET_AREA_OR_LOCATION,
} from './actionType'

export const initialState = {
  userInfo: {},
  collapse: JSON.parse(localStorage.getItem('cardCollapse')),
  cardPreferences: JSON.parse(localStorage.getItem('cardPreferences')),
  userRoles: [],
  isDCUser: false,
  userStoreroom: '',
}

export default function userInfoReducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_USER_INFO: {
      const newState = {
        ...state,
        userInfo: action.userInfo,
      }
      return newState
    }
    case SET_USER_STOREROOM: {
      const newState = {
        ...state,
        userStoreroom: action.userStoreroom,
      }
      return newState
    }
    case SET_USER_ROLES: {
      const newState = {
        ...state,
        userRoles: action.userRoles,
      }
      return newState
    }
    case SET_IS_DC_USER: {
      const isDCUser = action.isDCUser
      const newState = {
        ...state,
        isDCUser: isDCUser,
      }
      return newState
    }
    case SET_AREA_OR_LOCATION: {
      const newState = {
        ...state,
        areaOrLocation: action.areaOrLocation,
      }
      return newState
    }
    case SET_CARD_COLLAPSE: {
      const collapse = action.collapse
      const newState = {
        ...state,
        collapse: collapse,
      }
      localStorage.setItem('cardCollapse', JSON.stringify(collapse))
      return newState
    }
    case SET_CARD_PREFERENCES: {
      const cardPreferences = action.cardPreferences
      const newState = {
        ...state,
        cardPreferences: cardPreferences,
      }
      localStorage.setItem('cardPreferences', JSON.stringify(cardPreferences))
      return newState
    }

    default:
      return state
  }
}
