/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setShouldRender } from '../../components/ProgressOverlay/store/actionCreator'
import { makeStyles, createStyles } from '@mui/styles'
import {
  Alert,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  Divider,
  Grid,
} from '@mui/material'
import { getURLSearchParams } from '../../windowManager'
import WorkOrderItem from '../WorkOrderItem/WorkOrderItem'
import { fetchData } from '../../service/HttpService'
import { Link } from 'react-router-dom'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'

const useStyles = makeStyles((theme) =>
  createStyles({
    cardHeader: {
      background: theme.palette.grey[300],
    },
    actionIcon: {
      margin: theme.spacing(1, 0.5, 0, 0),
    },
    cardContent: {
      padding: theme.spacing(1),
    },
  }),
)

const WorkOrderDetailsRelatedRecords = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [relatedRecords, setRelatedRecords] = useState([])
  const urlSearchParams = getURLSearchParams()
  const woNum = parseInt(urlSearchParams.get('wonum'))

  useEffect(() => {
    try {
      dispatch(setShouldRender(true, 'Loading...', `Related Records`))
      const getRelatedRecords = async () => {
        const response = await fetchData(`/mam/relatedrecords/wonum/${woNum}`)
        setRelatedRecords(response.relatedrecords)
      }
      getRelatedRecords()
    } catch (error) {
      setError(true)
      setLoading(false)
    } finally {
      dispatch(setShouldRender(false))
      setLoading(false)
    }
  }, [])

  const renderCard = (record, index) => {
    const relatedRecClass = record.relatedrecclass
    const relatedRecKey = record.relatedreckey
    return (
      <Grid
        item
        xs={12}
        sm={6}
        md={4}
        className={classes.cardContent}
        key={index}
      >
        <Card data-testid="related-records-card">
          <CardActionArea
            component={relatedRecClass === 'WORKORDER' ? Link : null}
            to={`/work-order-details#?wonum=${relatedRecKey}&showBack=true`}
          >
            <CardHeader
              className={classes.cardHeader}
              action={
                relatedRecClass === 'WORKORDER' && (
                  <ChevronRightIcon className={classes.actionIcon} />
                )
              }
              title={
                (relatedRecClass === 'SR' ? 'Ticket Id #' : 'WO #') +
                relatedRecKey
              }
            />
            <Divider />
            <CardContent>
              <WorkOrderItem label="Description" value={record.description} />
              <WorkOrderItem label="Class" value={relatedRecClass} />
              <WorkOrderItem label="Status" value={record.status} />
              <WorkOrderItem label="Relationship" value={record.relatetype} />
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
    )
  }

  return (
    <>
      {relatedRecords && !error ? (
        <Grid container>
          {relatedRecords.map((record, index) => renderCard(record, index))}
        </Grid>
      ) : null}

      {!error && relatedRecords?.length === 0 && !loading && (
        <Alert severity="warning" data-testid={'no-data-text'}>
          There are no related records to display.
        </Alert>
      )}
      {error && !loading && (
        <Alert severity="error">
          Unable to connect to related records service.
        </Alert>
      )}
    </>
  )
}

export default WorkOrderDetailsRelatedRecords
