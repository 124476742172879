import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material'
import { useEnv } from '@praxis/component-runtime-env'
import { useState } from 'react'
import { COLOR_DEFAULT_GREY } from '../../../globalColors'

const AppRestrictedDialog = () => {
  const env = useEnv()
  const restrictedMessage = env?.restrictedMessage
  const allowRestrictedAccessBypass = env?.allowRestrictedAccessBypass
  const [accessRestricted, setAccessRestricted] = useState(
    env?.accessRestricted,
  )

  return (
    <>
      {accessRestricted && (
        <Dialog
          BackdropProps={{ style: { backgroundColor: COLOR_DEFAULT_GREY } }}
          severity="error"
          open={accessRestricted}
        >
          <DialogTitle>{restrictedMessage}</DialogTitle>
          {allowRestrictedAccessBypass && (
            <DialogActions>
              <Button onClick={() => setAccessRestricted(false)}>
                Continue
              </Button>
            </DialogActions>
          )}
        </Dialog>
      )}
    </>
  )
}

export default AppRestrictedDialog
