/* eslint-disable react-hooks/exhaustive-deps */
import MatAppBar from '../MatAppBar/MatAppBar'
import { useEffect, useState, Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setShouldRender } from '../../components/ProgressOverlay/store/actionCreator'
import { fetchData } from '../../service/HttpService'
import {
  Alert,
  Chip,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from '@mui/material'
import { makeStyles, createStyles } from '@mui/styles'
import { COLOR_TARGET_RED } from '../../globalColors'
import { setAllCycleCountItems } from './store/actionCreator'
import CacheListManager from '../CacheListManager/CacheListManager'
import { useNavigate } from 'react-router-dom'
import { globalRoutes } from '../../globalConstants'

const useStyles = makeStyles((theme) =>
  createStyles({
    list: {
      padding: theme.spacing(0),
    },
    warningError: {
      margin: theme.spacing(1),
    },
    chip: {
      backgroundColor: COLOR_TARGET_RED,
      color: 'white',
    },
  }),
)

const CycleCount = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [error, setError] = useState(false)
  const { allCycleCountItems, userStoreroom, lastUpdated, shouldRender } =
    useSelector((state) => ({
      ...state.cycleCountReducer,
      ...state.userInfoReducer,
      ...state.progressOverlayReducer,
    }))

  useEffect(() => {
    const getCycleCountItems = async () => {
      try {
        dispatch(setShouldRender(true, 'Loading...', `Cycle Count Items`))
        const response = await fetchData(`/mam/items/storenum/${userStoreroom}`)
        dispatch(setAllCycleCountItems(response.itemDetails))
      } catch (error) {
        setError(true)
      } finally {
        dispatch(setShouldRender(false))
      }
    }
    getCycleCountItems()
  }, [])

  const getChip = (count) => {
    return count > 0 ? (
      <Chip label={count} className={classes.chip} data-testid="chip" />
    ) : (
      <Chip variant="outlined" label={0} />
    )
  }

  return (
    <>
      <MatAppBar
        arrowBack
        text={`Cycle Count ${userStoreroom}`}
        minimizeHeader={true}
      />
      {error && !shouldRender && (
        <Alert severity="error" className={classes.warningError}>
          `There was a problem retrieving Cycle Count Items!`
        </Alert>
      )}
      {!shouldRender && !error && (
        <>
          {!shouldRender && (
            <CacheListManager
              lastUpdated={lastUpdated.allCycleCountItems}
              listLength={setAllCycleCountItems.length}
              clearMethod={() => dispatch(setAllCycleCountItems([]))}
            />
          )}
          <List className={classes.list}>
            <>
              <ListItem disablePadding>
                <ListItemButton
                  data-testid="all-items-btn"
                  onClick={() =>
                    navigate(`${globalRoutes.cyclecountlist}#?list=allItems`)
                  }
                >
                  <ListItemText
                    primary={
                      <Typography>
                        {`${allCycleCountItems.allItems?.length} Items Total`}
                      </Typography>
                    }
                    secondary={
                      <Fragment>
                        <Typography
                          component={'span'}
                          sx={{ display: 'block' }}
                        >
                          All items in storeroom
                        </Typography>
                      </Fragment>
                    }
                  />
                </ListItemButton>
              </ListItem>
              <Divider />
              <ListItem
                disablePadding
                secondaryAction={getChip(
                  allCycleCountItems.allItemsDue?.length,
                )}
              >
                <ListItemButton
                  onClick={() =>
                    navigate(`${globalRoutes.cyclecountlist}#?list=allItemsDue`)
                  }
                >
                  <ListItemText
                    primary={<Typography>All Due Items</Typography>}
                    secondary={
                      <Fragment>
                        <Typography
                          component={'span'}
                          sx={{ display: 'block' }}
                        >
                          All Items due to be counted
                        </Typography>
                      </Fragment>
                    }
                  />
                </ListItemButton>
              </ListItem>
              <Divider />
              <ListItem
                disablePadding
                secondaryAction={getChip(allCycleCountItems.sevenDays?.length)}
              >
                <ListItemButton
                  onClick={() =>
                    navigate(`${globalRoutes.cyclecountlist}#?list=sevenDays`)
                  }
                >
                  <ListItemText
                    primary={<Typography>I Type Items Due</Typography>}
                    secondary={
                      <Fragment>
                        <Typography
                          component={'span'}
                          sx={{ display: 'block' }}
                        >
                          Not Counted in Last 7 Days
                        </Typography>
                      </Fragment>
                    }
                  />
                </ListItemButton>
              </ListItem>
              <Divider />
              <ListItem
                disablePadding
                secondaryAction={getChip(allCycleCountItems.thirtyDays?.length)}
              >
                <ListItemButton
                  onClick={() =>
                    navigate(`${globalRoutes.cyclecountlist}#?list=thirtyDays`)
                  }
                >
                  <ListItemText
                    primary={<Typography>A Type Items Due</Typography>}
                    secondary={
                      <Fragment>
                        <Typography
                          component={'span'}
                          sx={{ display: 'block' }}
                        >
                          Not Counted in Last 30 Days
                        </Typography>
                      </Fragment>
                    }
                  />
                </ListItemButton>
              </ListItem>
              <Divider />
              <ListItem
                disablePadding
                secondaryAction={getChip(allCycleCountItems.sixtyDays?.length)}
              >
                <ListItemButton
                  onClick={() =>
                    navigate(`${globalRoutes.cyclecountlist}#?list=sixtyDays`)
                  }
                >
                  <ListItemText
                    primary={<Typography>B Type Items Due</Typography>}
                    secondary={
                      <Fragment>
                        <Typography
                          component={'span'}
                          sx={{ display: 'block' }}
                        >
                          Not Counted in Last 60 Days
                        </Typography>
                      </Fragment>
                    }
                  />
                </ListItemButton>
              </ListItem>
              <Divider />
              <ListItem
                disablePadding
                secondaryAction={getChip(allCycleCountItems.ninetyDays?.length)}
              >
                <ListItemButton
                  onClick={() =>
                    navigate(`${globalRoutes.cyclecountlist}#?list=ninetyDays`)
                  }
                >
                  <ListItemText
                    primary={<Typography>C Type Items Due</Typography>}
                    secondary={
                      <Fragment>
                        <Typography
                          component={'span'}
                          sx={{ display: 'block' }}
                        >
                          Not Counted in Last 90 Days
                        </Typography>
                      </Fragment>
                    }
                  />
                </ListItemButton>
              </ListItem>
              <Divider />
            </>
          </List>
        </>
      )}
    </>
  )
}

export default CycleCount
