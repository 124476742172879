/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  Button,
  Grid,
  TextField,
  Alert,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Stack,
} from '@mui/material'
import { DateTimePicker } from '@mui/x-date-pickers'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import moment from 'moment'
import { makeStyles, createStyles } from '@mui/styles'
import { useDispatch } from 'react-redux'
import { setShouldRender } from '../ProgressOverlay/store/actionCreator'
import { formattedTime, setTimeString } from '../../utils'
import MatAppBar from '../MatAppBar/MatAppBar'
import {
  DATE_TIME_PICKER_FORMAT,
  WO,
  globalRoutes,
} from '../../globalConstants'
import { getURLSearchParams } from '../../windowManager'
import StatusAlert from '../StatusAlert/StatusAlert'
import { putData } from '../../service/HttpService'

const useStyles = makeStyles((theme) =>
  createStyles({
    dialogContent: {
      padding: theme.spacing(2),
    },
    formField: {
      padding: theme.spacing(2, 1),
    },
  }),
)

const ReportAssetDowntime = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const urlSearchParams = getURLSearchParams()
  const assetNum = urlSearchParams.get('asset')
  const woNum = urlSearchParams.get('wonum')

  const infoMessage = `Record Downtime For Asset #${assetNum}`

  const [startDateValue, setStartDateValue] = useState(new Date())
  const [validEndDate, setValidEndDate] = useState(false)
  const [validStartDate, setValidStartDate] = useState(false)
  const [dateError, setDateError] = useState(false)
  const [duration, setDuration] = useState('1 Hr 0 Mins')
  const [downtimeType, setDowntimeType] = useState('')
  const [error, setError] = useState(false)
  const currentDate = new Date()
  const [endDateValue, setEndDateValue] = useState(
    new Date(currentDate.setHours(currentDate.getHours())),
  )
  const isCompleting = urlSearchParams.get('complete')
  const commonGridProps = {
    item: true,
    xs: 12,
    sm: 6,
  }

  const calculateTime = () => {
    const start = moment(startDateValue, DATE_TIME_PICKER_FORMAT)
    const end = moment(endDateValue, DATE_TIME_PICKER_FORMAT)
    const totalHours = end.diff(start, 'hours')
    const totalMinutes = end.diff(start, 'minutes')
    const minutesInHr = (totalMinutes % 60) / 60
    const totalTimeInHour = (totalHours + minutesInHr).toFixed(2)
    setDateError(Math.sign(moment.duration(end.diff(start))) < 0)
    setDuration(totalTimeInHour)
  }

  useEffect(() => {
    calculateTime()
  }, [startDateValue, endDateValue])

  const handleStartDateChange = (newValue) => {
    setStartDateValue(newValue)
    setValidStartDate(!moment(newValue).isValid())
  }
  const handleEndDateChange = (newValue) => {
    setEndDateValue(newValue)
    setValidEndDate(!moment(newValue).isValid())
  }

  const onClose = () => {
    window.history.back()
  }

  const reportDowntime = async () => {
    try {
      dispatch(setShouldRender(true, `Reporting Downtime...`))
      setError(false)
      const start = moment(startDateValue, DATE_TIME_PICKER_FORMAT)
      const end = moment(endDateValue, DATE_TIME_PICKER_FORMAT)

      if (setDateError(Math.sign(moment.duration(end.diff(start))) < 1)) {
        setDateError(true)
        return
      }
      const requestBody = {
        code: downtimeType,
        'end-date': endDateValue,
        'start-date': startDateValue,
        woNum: woNum,
      }

      await putData(`/maximo/reportdowntime`, JSON.stringify(requestBody))

      isCompleting
        ? navigate(`${globalRoutes.completeWorkOrder}#?wonum=${woNum}`)
        : onClose()
    } catch (error) {
      setError(true)
    } finally {
      dispatch(setShouldRender(false))
    }
  }
  return (
    <>
      <MatAppBar arrowBack text={`${WO} ${woNum}`} />
      <StatusAlert text={infoMessage} />
      {error && <Alert severity="error">Unable to report downtime.</Alert>}

      <Grid container>
        <Grid {...commonGridProps} className={classes.formField}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DateTimePicker
              label="Start Date"
              inputProps={{ 'data-testid': 'start-date-picker' }}
              value={startDateValue}
              onChange={handleStartDateChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={dateError || validStartDate}
                  helperText={
                    validStartDate
                      ? `Enter Valid Start Date`
                      : dateError &&
                        `Start Date cannot be greater that End Date`
                  }
                />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid {...commonGridProps} className={classes.formField}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DateTimePicker
              label="End Date"
              inputProps={{ 'data-testid': 'end-date-picker' }}
              value={endDateValue}
              onChange={handleEndDateChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={dateError || validEndDate}
                  helperText={
                    validEndDate
                      ? `Enter Valid End Date`
                      : dateError &&
                        `End Date should be greater that Start Date`
                  }
                />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid
          container
          data-testid="end-datepicker-id"
          className={classes.formField}
        >
          <Grid item xs={6}>
            <Typography>Duration:</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography> {setTimeString(formattedTime(duration))}</Typography>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          data-testid="end-datepicker-id"
          className={classes.formField}
        >
          <FormControl margin="normal" fullWidth>
            <InputLabel>Select Type</InputLabel>
            <Select
              value={downtimeType}
              label="Select Type"
              onChange={(e) => setDowntimeType(e.target.value)}
              inputProps={{ 'data-testid': 'downtime-type-dropdown' }}
            >
              <MenuItem key={'Planned'} value={'Planned'}>
                Planned
              </MenuItem>
              <MenuItem key={'Unplanned'} value={'Unplanned'}>
                Unplanned
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container justifyContent="flex-end" className={classes.formField}>
        <Stack direction="row" spacing={2}>
          <Button
            variant="outlined"
            onClick={onClose}
            data-testid="report-cancel-button"
          >
            Cancel
          </Button>

          <Button
            disabled={
              dateError ||
              validEndDate ||
              validStartDate ||
              !downtimeType ||
              !(duration > 0)
            }
            variant="contained"
            onClick={reportDowntime}
            data-testid="report-save-button"
          >
            {isCompleting ? 'Update Downtime' : 'Save'}
          </Button>
        </Stack>
      </Grid>
    </>
  )
}

export default ReportAssetDowntime
