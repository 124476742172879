/* eslint-disable react-hooks/exhaustive-deps */
import { Alert } from '@mui/material'
import MatAppBar from '../MatAppBar/MatAppBar'
import { useState, useEffect } from 'react'
import { connect, useSelector } from 'react-redux'
import { makeStyles, createStyles } from '@mui/styles'
import { setShouldRender } from '../ProgressOverlay/store/actionCreator'
import CacheListManager from '../CacheListManager/CacheListManager'
import {
  api,
  INPUT_PERFORMANCE_DELAY,
  SCHEDULE_OR_ASSIGNMENT,
  WORK_ORDER_FILTER_PROPERTIES,
} from '../../globalConstants'
import { fetchData } from '../../service/HttpService'
import { setFieldProjectWorkorderList } from '../WorkOrderList/WorkOrderListStore/actionCreator'
import { setAssignments } from '../WorkOrderList/WorkOrderListStore/actionCreator'
import { useAuth } from '@praxis/component-auth'
import StatusAlert from '../StatusAlert/StatusAlert'
import CommonCardList from '../CommonCardList/CommonCardList'
import { filterAssignmentsBy7Days } from '../../utils'
import useSearch from '../../utils/hooks/useSearch'

const useStyles = makeStyles((theme) =>
  createStyles({
    cardHeader: {
      background: theme.palette.grey[300],
    },
    actionIcon: {
      margin: theme.spacing(1, 0.5, 0, 0),
    },
    cardContent: {
      padding: theme.spacing(1),
    },
    warningError: {
      margin: theme.spacing(1),
    },
  }),
)

const FieldProjectWorkOrders = ({
  fieldProjectWorkorders,
  setShouldRender,
  lastUpdated,
  storeId,
  setFieldProjectWorkorderList,
  setAssignments,
  toggle,
}) => {
  const URL_SEARCH_KEY = 'search'
  const classes = useStyles()
  const auth = useAuth()
  const userId = auth.session.userInfo.lanId?.toUpperCase()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const { isDCUser } = useSelector((state) => ({
    ...state.userInfoReducer,
  }))

  const [filteredWorkOrders, onSearch] = useSearch(
    URL_SEARCH_KEY,
    fieldProjectWorkorders || [],
    WORK_ORDER_FILTER_PROPERTIES(isDCUser),
    true,
    INPUT_PERFORMANCE_DELAY,
  )

  const fieldProjectWorkordersLength = fieldProjectWorkorders?.length

  useEffect(() => {
    return () => setFieldProjectWorkorderList([])
  }, [])

  useEffect(() => {
    const getOpenWorkOrders = async () => {
      if (!fieldProjectWorkordersLength && !loading) {
        try {
          setShouldRender(true, 'Loading...', 'FP Work Orders')
          setLoading(true)
          setError(false)

          const response = await fetchData(api.openWorkOrders(storeId))
          setFieldProjectWorkorderList(response.woDetails)
        } catch (error) {
          setError(true)
        } finally {
          setLoading(false)
          setShouldRender(false)
        }
      }
    }
    getOpenWorkOrders()
  }, [fieldProjectWorkordersLength])

  const infoMessage = `${filteredWorkOrders.length} / ${fieldProjectWorkordersLength} Field Project Work Orders`

  const getAssignments = async () => {
    try {
      setShouldRender(true, 'Loading...', SCHEDULE_OR_ASSIGNMENT(isDCUser))
      const response = await fetchData(api.myAssignments(storeId, userId))
      const assigmentsScheduledThisWeek = filterAssignmentsBy7Days(
        response.assignmentsdata,
      )
      setAssignments(assigmentsScheduledThisWeek)
    } catch (error) {
      setError(true)
    } finally {
      setLoading(false)
      setShouldRender(false)
    }
  }

  useEffect(() => {
    fieldProjectWorkordersLength && getAssignments()
  }, [toggle, fieldProjectWorkordersLength])

  return (
    <>
      <MatAppBar
        arrowBack
        text="FP Work Orders"
        search={{
          onSearch: (input) => onSearch(input),
          searchKey: URL_SEARCH_KEY,
        }}
      />
      {fieldProjectWorkordersLength > 0 && !loading && (
        <CacheListManager
          lastUpdated={lastUpdated}
          listLength={fieldProjectWorkordersLength}
          clearMethod={() => setFieldProjectWorkorderList([])}
        />
      )}

      {!filteredWorkOrders?.length && !loading && !error && (
        <Alert
          severity="warning"
          data-testid={'no-data-text'}
          className={classes.warningError}
        >
          There are no field project work orders to display.
        </Alert>
      )}
      {error && !loading && (
        <Alert severity="error" className={classes.warningError}>
          Unable to connect to field project work orders service.
        </Alert>
      )}
      {filteredWorkOrders && !loading ? (
        <>
          <StatusAlert text={infoMessage} />
          <CommonCardList
            listData={filteredWorkOrders}
            filteredWorkOrdersLength={fieldProjectWorkordersLength}
          />
        </>
      ) : null}
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    fieldProjectWorkorders: state.workOrderListReducer.fieldProjectWorkorders,
    storeId: state.storeInfoReducer.storeId,
    lastUpdated: state.workOrderListReducer.lastUpdated.fieldProjectWorkorders,
    toggle: state.workOrderListReducer.toggle,
  }
}

const mapDispatchToProps = {
  setShouldRender,
  setFieldProjectWorkorderList,
  setAssignments,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FieldProjectWorkOrders)
