/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Alert } from '@mui/material'
import { useAuth } from '@praxis/component-auth'
import { useLocation } from 'react-router-dom'
import { api } from '../../globalConstants'
import FullPageInterstitial from '../FullPageInterstitial/FullPageInterstitial'
import { fetchData } from '../../service/HttpService'
import {
  setCardCollapse,
  setCardPreferences,
  setUserInfo,
  setUserStoreroom,
} from './store/actionCreator'
import { buildUserInfoObject } from '../../utils'
import WarningIcon from '@mui/icons-material/Warning'
import {
  DEFAULT_SITE_KEY,
  DEFAULT_STOREROOM_KEY,
  DEFAULT_ERROR_MESSAGE,
  NO_DEFAULT_SITE_HEADER,
  NO_DEFAUT_SITE_BODY,
  INTERSTITIAL_DEFAULT_BODY,
  INTERSTITIAL_DEFAULT_BODY_HOME,
} from '../../globalConstants'

export default function Root() {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState({ main: false, defaultStore: false })
  const { defaultStore: noDefaultStoreError } = error
  const data = useAuth()
  const dispatch = useDispatch()
  const { userInfo } = data?.session ?? {}
  const location = useLocation()
  useEffect(() => {
    async function fetchUserData() {
      const { lanId = '' } = userInfo
      if (lanId) {
        try {
          const response = await fetchData(api.userInfo(lanId))
          const userInfo = buildUserInfoObject(response, lanId)
          const defaultStore = userInfo?.[DEFAULT_SITE_KEY]?.string
          const defaultStoreroom = userInfo?.[DEFAULT_STOREROOM_KEY]?.string
          const cardCollapse = localStorage.getItem('cardCollapse')
          const cardPreferences = localStorage.getItem('cardPreferences')

          !cardCollapse && dispatch(setCardCollapse('false'))
          !cardPreferences && dispatch(setCardPreferences(''))

          if (defaultStore) {
            // Set user Info in redux
            dispatch(setUserInfo(userInfo))
          } else {
            // error condition to show error feedback when no default store is set
            setError((error) => ({ ...error, defaultStore: true }))
          }
          if (defaultStoreroom) {
            dispatch(setUserStoreroom(defaultStoreroom))
          }
        } catch (error) {
          setError((error) => ({ ...error, main: true }))
        } finally {
          setLoading(false)
        }
      } else {
        // No lanId found in API
        setError((error) => ({ ...error, main: true }))
      }
    }
    fetchUserData()
  }, [])

  return (
    <>
      {error.main ? (
        //    This is a generic error message for now for all scenarios like no access, network error etc. Can be customized for specific error messages in future
        <Alert severity="error">{DEFAULT_ERROR_MESSAGE}</Alert>
      ) : (
        <FullPageInterstitial
          loading={loading}
          error={noDefaultStoreError}
          headerText={noDefaultStoreError ? NO_DEFAULT_SITE_HEADER : undefined}
          bodyText={
            noDefaultStoreError
              ? NO_DEFAUT_SITE_BODY
              : location.pathname === '/'
                ? INTERSTITIAL_DEFAULT_BODY_HOME
                : INTERSTITIAL_DEFAULT_BODY
          }
          linearProgress={loading}
          icon={
            noDefaultStoreError ? <WarningIcon color="warning" /> : undefined
          }
        />
      )}
    </>
  )
}
