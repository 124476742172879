/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import { Alert, Button, Grid } from '@mui/material'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { makeStyles, createStyles } from '@mui/styles'
import { Link, useLocation } from 'react-router-dom'
import MatAppBar from '../MatAppBar/MatAppBar'
import WorkOrderList from '../WorkOrderList/WorkOrderList'
import useGetOpenDuplicateWorkOrders from '../../utils/hooks/useGetOpenDuplicateWorkOrders'
import {
  DUPLICATE_WORK_ORDERS,
  ERROR,
  PROBLEM_LIST,
  DUPLICATE_WORK_ORDER_ERROR,
  DUPLICATE_WORK_ORDER_EMPTY,
  ASSET_ID_PARAM,
  ASSET_NAME_PARAM,
  PROBLEM_CODE_PARAM,
  LOCATION_PARAM,
  WORK_ORDER,
  FOLLOW_UP_WO_URL_PARAM,
  CHECK_DUPLICATE_WO_HEADER,
  CHECK_DUPLICATE_WO_BODY,
} from '../../globalConstants'
import { getURLSearchParams } from '../../windowManager'
import { resetWorkOrder } from '../CreateWorkOrder/actionCreator'
import { getCreateWorkOrderLink } from '../../utils'
import { setShouldRender } from '../ProgressOverlay/store/actionCreator'
import StatusAlert from '../StatusAlert/StatusAlert'

const useStyles = makeStyles((theme) =>
  createStyles({
    cardContent: {
      padding: theme.spacing(1),
    },
    typography: {
      padding: theme.spacing(2),
    },
    createNewButton: {
      marginLeft: theme.spacing(2),
    },
    showingInfo: {
      margin: theme.spacing(1),
    },
  }),
)
const DuplicateWorkOrder = () => {
  const location = useLocation()
  const classes = useStyles()
  const dispatch = useDispatch()
  const { duplicateWorkorders, createWorkOrderData, wodetails } = useSelector(
    (state) => ({
      ...state.workOrderListReducer,
      ...state.createWorkOrderReducer,
      ...state.progressOverlayReducer,
      ...state.workOrderDetailsReducer,
    }),
    shallowEqual, // Added shallowEqual here so that opening of the Hamburger menu doesnt re-render this custom hook
  )
  const { from } = location?.state ?? {}
  const urlSearchParams = getURLSearchParams()
  const [directUrl] = useState(from === PROBLEM_LIST ? false : true)
  let getDupWoResult = useGetOpenDuplicateWorkOrders(
    DUPLICATE_WORK_ORDERS,
    null,
    directUrl,
  )
  const isFollowUpWo = urlSearchParams.get(FOLLOW_UP_WO_URL_PARAM)
  // If not the direct Url, the duplicate work orders are already set from problem list page and hence using it here so that the null value returned from custom hook called above is not set
  !directUrl && (getDupWoResult = duplicateWorkorders?.length)

  // This useEffect is to avoid a small delay in the setShouldRender trigger in the follow up WO path where the screen doesnt show any feedback for a bit
  useEffect(() => {
    if (wodetails !== null && isFollowUpWo && getDupWoResult !== ERROR) {
      dispatch(
        setShouldRender(
          true,
          CHECK_DUPLICATE_WO_HEADER,
          CHECK_DUPLICATE_WO_BODY,
        ),
      )
    }
  }, [wodetails])

  function renderSubHeader() {
    const selectedAsset = urlSearchParams.get(ASSET_ID_PARAM)
    const selectedAssetName = urlSearchParams.get(ASSET_NAME_PARAM)
    const selectecProblemCode = urlSearchParams.get(PROBLEM_CODE_PARAM)
    const selectedLocation = urlSearchParams.get(LOCATION_PARAM)
    const subheaderTitle = () => {
      let subheaderText = `No Duplicate ${WORK_ORDER}s`
      const numberOfWorkOrders = getDupWoResult
      const workOrderFoundText = `${numberOfWorkOrders} open ${WORK_ORDER}${
        numberOfWorkOrders > 1 ? 's' : ''
      }  found for `
      if (numberOfWorkOrders) {
        subheaderText = `${workOrderFoundText}
        ${selectedAsset}, ${selectedAssetName},
        ${selectedLocation} and ${selectecProblemCode}.`
        if (selectedAsset === null) {
          subheaderText = `${workOrderFoundText}
          ${selectedLocation} and ${selectecProblemCode}.`
        }
      }
      return <StatusAlert text={subheaderText} />
    }
    return (
      <>
        {subheaderTitle()}
        <Grid
          className={classes.cardContent}
          container
          justifyContent="flex-end"
        >
          {/* Hide the Cancel button if in Follow Up WO route as there is already a Cancel Button at the top Alert component for Follow Up WO */}
          {!isFollowUpWo && (
            <Button
              data-testid="duplicate-wo-flow-cancel"
              variant="outlined"
              component={Link}
              to="/home"
              onClick={() => {
                dispatch(resetWorkOrder())
              }}
            >
              Cancel
            </Button>
          )}
          <Button
            variant="contained"
            className={classes.createNewButton}
            component={Link}
            to={getCreateWorkOrderLink(createWorkOrderData)}
          >
            Create New
          </Button>
        </Grid>
      </>
    )
  }
  function renderDuplicateWorkOrdersFromProblemList() {
    return (
      <WorkOrderList
        data={duplicateWorkorders}
        wolength={getDupWoResult}
        showInfo={false}
      />
    )
  }

  function renderDuplicateWorkOrdersDirectUrl() {
    return (
      <>
        {getDupWoResult === ERROR && (
          <Alert severity="error">{DUPLICATE_WORK_ORDER_ERROR}</Alert>
        )}
        {getDupWoResult !== ERROR && getDupWoResult === 0 && (
          <Alert severity="warning">{DUPLICATE_WORK_ORDER_EMPTY}</Alert>
        )}
        {getDupWoResult > 0 && (
          <WorkOrderList
            data={duplicateWorkorders}
            wolength={getDupWoResult}
            showInfo={false}
          />
        )}
      </>
    )
  }
  return (
    <>
      <MatAppBar
        arrowBack
        text={DUPLICATE_WORK_ORDERS}
        renderFollowUpInfoIfExists
        createWorkOrderFlow
      />

      {getDupWoResult > 0 && renderSubHeader()}

      {!directUrl
        ? renderDuplicateWorkOrdersFromProblemList()
        : renderDuplicateWorkOrdersDirectUrl()}
    </>
  )
}

export default DuplicateWorkOrder
