/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import { CardActionArea } from '@mui/material'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'
import { connect } from 'react-redux'
import { Alert } from '@mui/material'
import { makeStyles, createStyles } from '@mui/styles'
import { setShouldRender } from '../ProgressOverlay/store/actionCreator'
import { fetchData } from '../../service/HttpService'
import { setStoreRequestsList } from './StoreRequestsListStore/actionCreator'
import moment from 'moment'
import WorkOrderItem from '../WorkOrderItem/WorkOrderItem'
import { Link } from 'react-router-dom'
import CacheListManager from '../CacheListManager/CacheListManager'
import { deleteURLSearchParams, getURLSearchParams } from '../../windowManager'

const useStyles = makeStyles((theme) =>
  createStyles({
    cardHeader: {
      background: theme.palette.grey[300],
    },
    actionIcon: {
      margin: theme.spacing(1, 0.5, 0, 0),
    },
    cardContent: {
      padding: theme.spacing(1),
    },
  }),
)

const StoreRequestsList = ({
  storeRequests,
  storeId,
  setShouldRender,
  setStoreRequestsList,
  lastUpdated,
}) => {
  const classes = useStyles()
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const storeRequestsLength = storeRequests.length
  const forceUpdate = getURLSearchParams().get('force_update')

  useEffect(() => {
    const getServiceRequest = async () => {
      if (!loading || forceUpdate) {
        try {
          setShouldRender(
            true,
            'Loading...',
            `Fetching your request list for Store: ${storeId}`,
          )
          setLoading(true)
          setError(false)
          const response = await fetchData(
            `/maximo/servicerequest/siteid/${storeId}`,
          )
          setStoreRequestsList(response)
        } catch (error) {
          setError(true)
        } finally {
          setShouldRender(false)
          setLoading(false)
          deleteURLSearchParams([{ name: 'force_update' }], true)
        }
      }
    }
    getServiceRequest()
  }, [])

  const renderCard = (request, index) => {
    const ticketId = request['dcterms:ticketid']
    const description = request['dcterms:description']
    const longDescription = request['dcterms:description_longdescription']
    const reportedBy = request['dcterms:reportedby']
    const status = request['dcterms:status']
    const siteId = request['dcterms:siteid']
    const ticketUId = request['dcterms:ticketuid']
    const ticketClass = request['dcterms:class']

    const dateFormat = 'MM/DD/YYYY'
    const reportDate = moment(request['dcterms:reportdate']).format(dateFormat)
    const statusDate = moment(request['dcterms:statusdate']).format(dateFormat)
    return (
      <Grid
        item
        xs={12}
        sm={6}
        md={4}
        className={classes.cardContent}
        key={index}
        data-testid="store-request-list"
      >
        <Card>
          <CardActionArea
            component={Link}
            to={`/store-request-details#?ticketid=${ticketId}&description=${description}&longdescription=${longDescription}&reportedby=${reportedBy}&reportdate=${reportDate}&statusdate=${statusDate}&status=${status}&siteid=${siteId}&ticketuid=${ticketUId}&class=${ticketClass}&count=${storeRequests.length}`}
          >
            <CardHeader
              className={classes.cardHeader}
              action={<ChevronRightIcon className={classes.actionIcon} />}
              title={`Ticket Id # ${ticketId}`}
            />
            <Divider />
            <CardContent>
              <WorkOrderItem label="Description" value={description} />
              <WorkOrderItem label="Reported By" value={reportedBy} />
              <WorkOrderItem label="Reported Date" value={reportDate} />
              <WorkOrderItem label="Status Date" value={statusDate} />
              <WorkOrderItem label="Status" value={status} />
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
    )
  }

  return (
    <>
      <CacheListManager
        lastUpdated={lastUpdated}
        listLength={storeRequestsLength}
        clearMethod={() => setStoreRequestsList([])}
      />

      {storeRequestsLength && !error ? (
        <Grid container>
          {storeRequests.map((request, index) => renderCard(request, index))}
        </Grid>
      ) : null}

      {!error && !storeRequestsLength && !loading && (
        <Alert severity="warning" data-testid={'no-data-text'}>
          There are no store requests to display.
        </Alert>
      )}
      {error && !loading && (
        <Alert severity="error">
          Unable to connect to Store Requests service.
        </Alert>
      )}
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    storeRequests: state.storeRequestListReducer.storeRequests,
    storeId: state.storeInfoReducer.storeId,
    lastUpdated: state.storeRequestListReducer.lastUpdated,
  }
}

const mapDispatchToProps = {
  setShouldRender,
  setStoreRequestsList,
}

export default connect(mapStateToProps, mapDispatchToProps)(StoreRequestsList)
