/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Alert,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from '@mui/material'
import { connect } from 'react-redux'
import { fetchData } from '../../service/HttpService'
import { setAreasOfStore } from './actionCreator'
import ListItemDivider from '../ListItemDivider/ListItemDivider'
import MatAppBar from '../MatAppBar/MatAppBar'
import { AREA_OF_STORE_FILTER_KEY, globalRoutes } from '../../globalConstants'
import { setShouldRender } from '../ProgressOverlay/store/actionCreator'
import { useNavigate } from 'react-router-dom'
import { setSelectedArea } from './actionCreator'
import { setWorkOrder } from '../CreateWorkOrder/actionCreator'
import useSearch from '../../utils/hooks/useSearch'

const AreaOfStore = ({
  setSelectedArea,
  setWorkOrder,
  createWorkOrderData,
}) => {
  const [error, setError] = useState(false)
  const { locationdata: areas = [] } = useSelector(
    (state) => state.areaOfStoreReducer,
  )
  const {
    storeId = '',
    areaOrLocation,
    shouldRender,
  } = useSelector((state) => ({
    ...state.storeInfoReducer,
    ...state.userInfoReducer,
    ...state.progressOverlayReducer,
  }))
  const [listData, onSearch] = useSearch(AREA_OF_STORE_FILTER_KEY, areas)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    const getLocationList = async () => {
      try {
        dispatch(setShouldRender(true, `Loading ${areaOrLocation}...`))
        const response = await fetchData(`/mam/locationlist/siteId/${storeId}`)
        const areaList = response.locationdata
        areaList.sort((a, b) => {
          const aLocation = a?.location?.toUpperCase() ?? ''
          const bLocation = b?.location?.toUpperCase() ?? ''
          return aLocation < bLocation ? -1 : aLocation > bLocation ? 1 : 0
        })
        dispatch(setAreasOfStore(areaList))
      } catch (error) {
        setError(true)
      } finally {
        dispatch(setShouldRender(false))
      }
    }
    getLocationList()
  }, [])

  const handleRowClick = (area) => {
    const location = area.location
    setSelectedArea(area)
    setWorkOrder({
      ...createWorkOrderData,
      areaOfStore: location,
    })
    navigate(`${globalRoutes.problems}#?area=${location}`)
  }

  return (
    <>
      <MatAppBar
        arrowBack
        text={areaOrLocation}
        search={{
          onSearch: (inp) => onSearch(inp),
          searchKey: AREA_OF_STORE_FILTER_KEY,
        }}
        renderFollowUpInfoIfExists
        createWorkOrderFlow
      />
      {listData.length && !error && !shouldRender ? (
        <List>
          {listData.map((area, index) => {
            return (
              <Fragment key={index}>
                <ListItem disablePadding>
                  <ListItemButton onClick={() => handleRowClick(area)}>
                    <ListItemText primary={area.location} />
                  </ListItemButton>
                </ListItem>
                <ListItemDivider index={index} listLength={areas.length} />
              </Fragment>
            )
          })}
        </List>
      ) : null}
      {error && !shouldRender && (
        <Alert severity="error">
          `Unable to connect to {areaOrLocation} service.`
        </Alert>
      )}
      {!error && !shouldRender && !areas.length && (
        <Alert severity="warning">No Areas currently on this location.</Alert>
      )}
    </>
  )
}
const mapStateToProps = (state) => {
  return {
    createWorkOrderData: state.createWorkOrderReducer.createWorkOrderData,
  }
}
const mapDispatchToProps = {
  setSelectedArea,
  setWorkOrder,
}

export default connect(mapStateToProps, mapDispatchToProps)(AreaOfStore)
