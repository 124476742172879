/* eslint-disable react-hooks/exhaustive-deps */
import {
  Alert,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Typography,
} from '@mui/material'
import { connect } from 'react-redux'
import { makeStyles, createStyles } from '@mui/styles'
import { useEffect, useState } from 'react'
import LinkIcon from '@mui/icons-material/Link'
import { fetchData } from '../../service/HttpService'
import { getURLSearchParams } from '../../windowManager'
import { setShouldRender } from '../ProgressOverlay/store/actionCreator'

const useStyles = makeStyles(() =>
  createStyles({
    planLink: { wordWrap: 'break-word' },
  }),
)

const WorkOrderDetailsPlans = ({ setShouldRender }) => {
  const classes = useStyles()
  const urlSearchParams = getURLSearchParams()
  const woNum = parseInt(urlSearchParams.get('wonum'))
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [longDescription, setLongDescription] = useState([])
  const urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g

  useEffect(() => {
    async function getData() {
      try {
        setShouldRender(true, 'Loading Plans...')
        const result = await fetchData(
          `/mam/tasklongdescription/parent/${woNum}`,
        )
        setLongDescription(result.taskLongDescriptiondata)
      } catch {
        setError(true)
      } finally {
        setShouldRender(false)
        setLoading(false)
      }
    }
    getData()
  }, [])
  return (
    <>
      {longDescription.length && !error && !loading ? (
        <List>
          {longDescription.map((ld, index) => (
            <ListItem key={index}>
              {ld.ldtext.includes('<a href=') && (
                <ListItemIcon>
                  <LinkIcon />
                </ListItemIcon>
              )}
              <ListItemText
                primary={
                  <Typography
                    className={classes.planLink}
                    variant="body1"
                    dangerouslySetInnerHTML={{
                      __html:
                        ld.ldtext.match(urlRegex) &&
                        ld.ldtext
                          .match(urlRegex)[0]
                          .includes('workordermanagement')
                          ? ld.ldtext
                          : ld.ldtext.replace(/href/g, "target='_blank' href"),
                    }}
                  />
                }
              />
            </ListItem>
          ))}
        </List>
      ) : null}

      {error && !loading && (
        <Alert severity="error">'Unable to connect to Plans service.'</Alert>
      )}

      {!error && !loading && !longDescription.length && (
        <Alert severity="warning">No plans currently on this asset.</Alert>
      )}
    </>
  )
}

const mapDispatchToProps = {
  setShouldRender,
}

export default connect(null, mapDispatchToProps)(WorkOrderDetailsPlans)
