/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useMemo, useRef } from 'react'
import clsx from 'clsx'
import {
  MenuItem,
  Typography,
  Grid,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuList,
  useMediaQuery,
  styled,
} from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import MatAppBar from '../MatAppBar/MatAppBar'
import { getURLSearchParams } from '../../windowManager'
import { Link, useLocation } from 'react-router-dom'
import {
  globalRoutes,
  WORK_ORDER_NUMBER_FIELD,
  WORK_ORDER_ID_FIELD,
  UPLOAD_IMAGES,
  WORK_LOG,
} from '../../globalConstants'
import { makeStyles, createStyles } from '@mui/styles'
import { setStickyDivHeight } from '../WorkOrderDetails/store/actionCreator'
import {
  COLOR_BACKGROUND_GREY,
  COLOR_TARGET_RED,
  COLOR_THEME_ACCENT,
  COLOR_FONT_BLUE,
} from '../../globalColors'
import { WOATabs as Tabs, WOATab as Tab } from '../common/WOATabs'
import SurveyWarningIcon from './SurveyWarningIcon'
import { useEnv } from '@praxis/component-runtime-env'

const useStyles = makeStyles((theme) =>
  createStyles({
    header: {
      padding: theme.spacing(1, 0, 0, 2),
    },
    menuItem: {
      minHeight: `${theme.spacing(6)}`,
      color: COLOR_FONT_BLUE,
    },
    menuItemActive: {
      color: COLOR_THEME_ACCENT,
    },
    stickyHeader: {
      position: 'sticky',
      top: 0,
      zIndex: theme.zIndex.appBar,
      background: theme.palette.common.white,
      '@media (orientation: landscape)': {
        [theme.breakpoints.down(1281)]: {
          position: 'relative',
        },
      },
    },
    noWrapTabLabel: {
      whiteSpace: 'nowrap',
    },
    moreHighlight: {
      color: COLOR_THEME_ACCENT,
      '&:after': {
        content: "''",
        position: 'absolute',
        height: '4px',
        width: '80%',
        background: COLOR_TARGET_RED,
        bottom: '0',
      },
    },
    highPriority: {
      color: `${COLOR_TARGET_RED}`,
    },
  }),
)

const WorkOrderTabMenu = ({ storeroom }) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const dispatch = useDispatch()
  const anchorRef = useRef(null)
  const stickyDivRef = useRef(null)
  const stickyDivElem = stickyDivRef?.current
  const urlSearchParams = getURLSearchParams()
  const woNum = parseInt(urlSearchParams.get('wonum'))
  const showBackButton = urlSearchParams.get('showBack') === 'true'
  const location = useLocation()
  const { pathname } = location
  //Consider isUploadPhoto check refactor to useMatch - but require route change react-router V5 to V6
  const isUploadPhotoRoute = useLocation().pathname === '/upload-photo'
  const { wodetails = null } = useSelector((state) => ({
    ...state.workOrderDetailsReducer,
  }))
  const smallScreen = useMediaQuery('(max-width:400px)')
  const { [WORK_ORDER_NUMBER_FIELD]: woNumber, [WORK_ORDER_ID_FIELD]: woId } =
    wodetails ?? {}
  const urlParams = `?wonum=${woNum}${
    showBackButton ? `&showBack=${showBackButton}` : ''
  }`
  const env = useEnv()
  const showBillingFeature = env?.showBillingFeature

  const { isDCUser } = useSelector((state) => ({
    ...state.userInfoReducer,
  }))

  const workOrderDetailsTabs = useMemo(() => {
    const menuArray = [
      {
        label: 'Details',
        value: 0,
        route: globalRoutes.workOrderDetails,
      },
      {
        label: 'Plans',
        value: 1,
        route: globalRoutes.workOrderDetailsPlans,
      },
      {
        label: WORK_LOG,
        value: 2,
        route: globalRoutes.workOrderDetailsWorkLog,
      },
      {
        label: isDCUser ? 'Assignments' : 'Schedule',
        value: 3,
        route: globalRoutes.workOrderDetailsSchedules,
      },
      {
        label: 'Images',
        value: 4,
        route: globalRoutes.workorderDetailsImages,
      },
      {
        label: 'Related Records',
        value: 5,
        route: globalRoutes.workorderDetailsRelatedRecords,
      },
    ]
    !isDCUser &&
      menuArray.push({
        label: 'Survey (Specifications)',
        value: 6,
        route: globalRoutes.workorderDetailsSurvey,
      })
    showBillingFeature &&
      menuArray.push({
        label: 'Billing',
        value: !isDCUser ? 7 : 6,
        route: globalRoutes.workOrderDetailsBilling,
      })
    isDCUser &&
      menuArray.push({
        label: 'Asset Spare Parts',
        value: showBillingFeature ? 7 : 6,
        route: globalRoutes.workOrderAssetSpareParts,
      })
    isDCUser &&
      menuArray.push({
        label: 'Storeroom Items',
        value: showBillingFeature ? 8 : 7,
        route: globalRoutes.storeroomItems,
      })
    isDCUser &&
      menuArray.push({
        label: 'Issued Parts',
        value: showBillingFeature ? 9 : 8,
        route: globalRoutes.workOrderDetailsIssuedParts,
      })

    return menuArray
  }, [])

  const mainTabs = workOrderDetailsTabs.slice(0, 3)
  const menuTabs = workOrderDetailsTabs.slice(3)

  const [activeTab, setActiveTab] = useState(0)
  const [moreClicked, setMoreClicked] = useState(false)

  const handleClick = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }

    setOpen(false)
    setMoreClicked(activeTab <= 2 || isUploadPhotoRoute ? false : true)
  }

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    } else if (event.key === 'Escape') {
      setOpen(false)
    }
  }

  const commonNavProps = {
    replace: true,
    component: Link,
  }
  const header = isUploadPhotoRoute
    ? UPLOAD_IMAGES
    : workOrderDetailsTabs[activeTab]?.label ?? ''

  useEffect(() => {
    const tabIndex = workOrderDetailsTabs.findIndex(
      (val) => val.route === pathname,
    )
    setActiveTab(isUploadPhotoRoute ? false : tabIndex === -1 ? 0 : tabIndex)
    setMoreClicked(activeTab <= 2 || isUploadPhotoRoute ? false : true)
    if (stickyDivElem) {
      dispatch(setStickyDivHeight(stickyDivElem?.clientHeight))
    }
  }, [workOrderDetailsTabs, pathname, stickyDivElem])

  const Offset = styled('div')(({ theme }) => theme.mixins.toolbar)

  return (
    <div className={classes.stickyHeader} ref={stickyDivRef}>
      <MatAppBar
        arrowBack={showBackButton}
        text={`WO ${woNum}`}
        uploadPhoto={
          isUploadPhotoRoute?.isExact
            ? ''
            : `#?${WORK_ORDER_NUMBER_FIELD}=${woNumber}&${WORK_ORDER_ID_FIELD}=${woId}&showBack=true`
        }
        minimizeHeader
      ></MatAppBar>
      {storeroom && <Offset />}
      {!storeroom && (
        <Grid container className={classes.header}>
          <Typography variant="h6" gutterBottom component="div">
            {header}
          </Typography>
        </Grid>
      )}
      <Tabs
        value={activeTab > 2 ? false : activeTab}
        variant="fullWidth"
        centered
        onChange={(e, newValue) => {
          if (newValue === 'more') {
            return
          }
          setMoreClicked(false)
          setActiveTab(newValue)
        }}
      >
        {mainTabs.map((val) => {
          return (
            <Tab
              key={val.value}
              {...commonNavProps}
              className={classes.noWrapTabLabel}
              label={val.label}
              to={{
                pathname: val?.route,
                hash: urlParams,
              }}
              sx={{ minWidth: smallScreen ? 80 : undefined }}
            />
          )
        })}
        <Tab
          ref={anchorRef}
          icon={
            <>
              <ArrowDropDownIcon /> <SurveyWarningIcon />
            </>
          }
          iconPosition="end"
          value="more"
          label="More"
          onClick={handleClick}
          className={moreClicked ? classes.moreHighlight : undefined}
        ></Tab>
      </Tabs>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        placement="bottom"
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'left top' : 'left bottom',
            }}
          >
            <Paper style={{ background: COLOR_BACKGROUND_GREY }}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="work-order-more-menu"
                  aria-labelledby="more-button-work-order-details-tab"
                  onKeyDown={handleListKeyDown}
                >
                  {menuTabs.map((val) => {
                    return (
                      <MenuItem
                        key={val.value}
                        className={clsx(
                          classes.menuItem,
                          activeTab === val.value && classes.menuItemActive,
                        )}
                        onClick={(e) => {
                          setActiveTab(val.value)
                          handleClose(e)
                        }}
                        {...commonNavProps}
                        to={{ pathname: val?.route, hash: urlParams }}
                      >
                        {val.label}{' '}
                        {val.value === 6 && !isDCUser && <SurveyWarningIcon />}
                      </MenuItem>
                    )
                  })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  )
}

export default WorkOrderTabMenu
