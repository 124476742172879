import { IconButton } from '@mui/material'
import { Alert } from '@mui/material'
import { makeStyles, createStyles } from '@mui/styles'
import { COLOR_DEFAULT_GREY } from '../../globalColors'
import RefreshIcon from '@mui/icons-material/Refresh'

const useStyles = makeStyles((theme) =>
  createStyles({
    alertInfo: {
      margin: theme.spacing(1),
      borderColor: COLOR_DEFAULT_GREY,
      '& .MuiAlert-icon > svg': {
        fill: COLOR_DEFAULT_GREY,
      },
      alignItems: 'center',
      wordBreak: 'break-word',
    },
    action: {
      paddingTop: 0,
      wordBreak: 'normal',
    },
  }),
)

const CacheListManager = ({
  lastUpdated,
  listLength,
  clearMethod,
  customText,
  customButtonText = 'Refresh',
  noAction,
}) => {
  const classes = useStyles()

  return listLength ? (
    <Alert
      severity="info"
      variant="outlined"
      className={classes.alertInfo}
      data-testid="info-alert"
      classes={{ action: classes.action }}
      action={
        noAction ? null : (
          <IconButton onClick={clearMethod}>
            <RefreshIcon color="primary" />
          </IconButton>
        )
      }
    >
      {customText ? customText : lastUpdated}
    </Alert>
  ) : null
}

export default CacheListManager
