import { SET_PROBLEM_LIST } from './actionType'

export const initialState = {
  problems: [],
}

export default function problemListReducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_PROBLEM_LIST: {
      const newState = {
        ...state,
        problems: action.problems,
      }
      return newState
    }

    default:
      return state
  }
}
