/* eslint-disable react-hooks/exhaustive-deps */
import { Alert } from '@mui/material'
import MatAppBar from '../MatAppBar/MatAppBar'
import { useState, useEffect } from 'react'
import { connect, useSelector } from 'react-redux'
import { makeStyles, createStyles } from '@mui/styles'
import { setShouldRender } from '../ProgressOverlay/store/actionCreator'
import { setCancelledExtWorkOrderList } from '../WorkOrderList/WorkOrderListStore/actionCreator'
import CacheListManager from '../CacheListManager/CacheListManager'
import { fetchData } from '../../service/HttpService'
import StatusAlert from '../StatusAlert/StatusAlert'
import CommonCardList from '../CommonCardList/CommonCardList'
import useSearch from '../../utils/hooks/useSearch'
import {
  INPUT_PERFORMANCE_DELAY,
  WORK_ORDER_FILTER_PROPERTIES,
} from '../../globalConstants'

const useStyles = makeStyles((theme) =>
  createStyles({
    cardHeader: {
      background: theme.palette.grey[300],
    },
    actionIcon: {
      margin: theme.spacing(1, 0.5, 0, 0),
    },
    cardContent: {
      padding: theme.spacing(1),
    },
    warningError: {
      margin: theme.spacing(1),
    },
  }),
)

const CancelledExtWorkOrders = ({
  cancelledExtWorkOrders,
  storeId,
  lastUpdated,
  setShouldRender,
  setCancelledExtWorkOrderList,
}) => {
  const classes = useStyles()
  const URL_SEARCH_KEY = 'search'
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const { isDCUser } = useSelector((state) => ({
    ...state.userInfoReducer,
  }))
  const [filteredWorkOrders, onSearch] = useSearch(
    URL_SEARCH_KEY,
    cancelledExtWorkOrders || [],
    WORK_ORDER_FILTER_PROPERTIES(isDCUser),
    true,
    INPUT_PERFORMANCE_DELAY,
  )
  const cancelledExtWorkOrdersLength = cancelledExtWorkOrders.length

  useEffect(() => {
    return () => setCancelledExtWorkOrderList([])
  }, [])

  useEffect(() => {
    const getCancelledWo = async () => {
      if (!cancelledExtWorkOrdersLength && !loading) {
        try {
          setShouldRender(true, 'Loading...', 'Cancelled External Work Orders')
          setLoading(true)
          setError(false)
          const response = await fetchData(
            `/mam/workorderscancel/siteId/${storeId}`,
          )
          setCancelledExtWorkOrderList(response.woDetailsCancel)
        } catch (error) {
          setError(true)
        } finally {
          setLoading(false)
          setShouldRender(false)
        }
      }
    }
    getCancelledWo()
  }, [cancelledExtWorkOrdersLength])

  const infoMessage = `${filteredWorkOrders.length} / ${cancelledExtWorkOrdersLength} Cancelled Ext WO(s)`

  return (
    <>
      <MatAppBar
        arrowBack
        text="Cancelled Ext WOs"
        search={{
          onSearch: (input) => onSearch(input),
          searchKey: URL_SEARCH_KEY,
        }}
      />
      {!loading && cancelledExtWorkOrdersLength > 0 && (
        <CacheListManager
          lastUpdated={lastUpdated}
          listLength={cancelledExtWorkOrdersLength}
          clearMethod={() => setCancelledExtWorkOrderList([])}
        />
      )}

      {!filteredWorkOrders?.length && !loading && !error && (
        <Alert
          severity="warning"
          data-testid={'no-data-text'}
          className={classes.warningError}
        >
          There are no cancelled external work orders to display.
        </Alert>
      )}
      {error && !loading && (
        <Alert severity="error" className={classes.warningError}>
          Unable to connect to work orders service.
        </Alert>
      )}

      {filteredWorkOrders && !loading ? (
        <>
          <StatusAlert text={infoMessage} />
          <CommonCardList
            listData={filteredWorkOrders}
            filteredWorkOrdersLength={cancelledExtWorkOrdersLength}
          />
        </>
      ) : null}
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    cancelledExtWorkOrders: state.workOrderListReducer.cancelledExtWorkOrders,
    storeId: state.storeInfoReducer.storeId,
    lastUpdated: state.workOrderListReducer.lastUpdated.cancelledExtWorkOrders,
  }
}

const mapDispatchToProps = {
  setShouldRender,
  setCancelledExtWorkOrderList,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CancelledExtWorkOrders)
