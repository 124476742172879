import { SET_NAV_ITEMS } from './actionType'
import moment from 'moment'
export const initialState = {
  navItems: null,
  lastUpdated: null,
}

export default function navItemsReducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_NAV_ITEMS: {
      return {
        ...state,
        navItems: action.navItems,
        lastUpdated: moment().format('LLL'),
      }
    }
    default:
      return state
  }
}
