/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useRef, useEffect } from 'react'
import clsx from 'clsx'
import ClickAwayListener from '@mui/base/ClickAwayListener'
import { makeStyles, createStyles } from '@mui/styles'
import parse from 'react-html-parser'

const useStyles = makeStyles((theme) =>
  createStyles({
    mainDiv: {
      overflow: 'auto',
      fontFamily: `"Roboto","Helvetica","Arial",sans-serif`,
    },
    dynamicDiv: {
      maxHeight: 200,
    },
    dynamicDivExpanded: {
      maxHeight: 'none',
    },
    clickable: {
      cursor: 'pointer',
    },
    breakWordIfOverflow: {
      whiteSpace: 'pre-wrap',
      wordBreak: 'break-word',
    },
  }),
)
export default function ExpandableDiv({ text }) {
  const [expanded, setExpanded] = useState(false)
  const [hasVerticalScrollbar, setHasVerticalScrollbar] = useState(false)
  const classes = useStyles()
  const rootRef = useRef(null)

  useEffect(() => {
    if (rootRef?.current) {
      setHasVerticalScrollbar(
        rootRef?.current?.scrollHeight > rootRef?.current?.clientHeight,
      )
    }
  }, [rootRef?.current])

  return (
    <>
      <ClickAwayListener onClickAway={() => setExpanded(false)}>
        <div
          onClick={(e) => {
            if (hasVerticalScrollbar) {
              if (!expanded) {
                e.preventDefault()
              }
              setExpanded(true)
            }
          }}
          className={clsx(
            classes.mainDiv,
            expanded ? classes.dynamicDivExpanded : classes.dynamicDiv,
            hasVerticalScrollbar ? classes.clickable : undefined,
            classes.breakWordIfOverflow,
          )}
          ref={rootRef}
        >
          {parse(text)}
        </div>
      </ClickAwayListener>
    </>
  )
}
