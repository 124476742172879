import moment from 'moment'
import {
  SET_FROM_DATE,
  SET_TO_DATE,
  SET_COLLAPSED,
  SET_MY_SHIFTS,
  SET_OTHER_SHIFTS,
  SET_SHIFT_KEYS,
} from './actionType'
import { DEFAULT_DATE_FORMAT } from '../../../globalConstants'
export const initialState = {
  fromDate: moment().format(DEFAULT_DATE_FORMAT),
  toDate: moment().format(DEFAULT_DATE_FORMAT),
  collapsedInStore: null,
  myShifts: [],
  otherShifts: [],
}

export default function schedulesReducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_FROM_DATE: {
      const newState = {
        ...state,
        fromDate: action.data,
      }
      return newState
    }
    case SET_TO_DATE: {
      const newState = {
        ...state,
        toDate: action.data,
      }
      return newState
    }
    case SET_COLLAPSED: {
      const newState = {
        ...state,
        collapsedInStore: action.data,
      }
      return newState
    }
    case SET_MY_SHIFTS: {
      const newState = {
        ...state,
        myShifts: action.myShifts,
      }
      return newState
    }
    case SET_OTHER_SHIFTS: {
      const newState = {
        ...state,
        otherShifts: action.otherShifts,
      }
      return newState
    }
    case SET_SHIFT_KEYS: {
      const newState = {
        ...state,
        shiftKeys: action.shiftKeys,
      }
      return newState
    }
    default:
      return state
  }
}
