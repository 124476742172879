/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import { getURLSearchParams } from '../../windowManager'
import { filterAndSetUrlParams } from '..'

/*This custom hook is used to filter a list based on the string entered in the search bar. It has both single field search and multi field search. It reuses the same logic used
in the individual components earlier.

Argument descriptions below:
 > data - this is the original data that has to be filtered
 > urlSearchKey - this is the key to be used . Would be the property for single field search and a custom field for multi property search
 > filterString - this is the actual search string entered in the search bar
 > properties (optional) - this is used for multi field search and is an array of all the properties which have to be searched for the 'filterString'
 > indexOfSearch - this specifies the search method used for multi property filter. 'indexOfSearch' uses the indexOf method. Else, use the includes method.
 > inputDelay - this specifies the inputDelay which uses setTimeOut to set a delay for the results to be returned. It is implemented same as in the original logix
*/
export default function useSearch(
  urlSearchKey,
  data,
  properties,
  indexOfSearch,
  inputDelay,
) {
  const urlSearchParams = getURLSearchParams()
  const urlSearchValue = urlSearchParams.get(urlSearchKey)
  const [filteredData, setFilteredData] = useState(data)
  useEffect(() => {
    onSearch(urlSearchValue)
  }, [data])

  function onSearch(value) {
    if (inputDelay) {
      setTimeout(() => {
        return setFilteredData(
          filterAndSetUrlParams(
            data,
            urlSearchKey,
            value,
            properties,
            indexOfSearch,
            inputDelay,
          ),
        )
      }, inputDelay)
    } else {
      return setFilteredData(
        filterAndSetUrlParams(
          data,
          urlSearchKey,
          value,
          properties,
          indexOfSearch,
          inputDelay,
        ),
      )
    }
  }

  return [filteredData, onSearch]
}
