import { SET_SURVEY_ANSWERS, SET_LOADED_WONUM, SET_LOADING } from './actionType'

export function setSurveyAnswers(surveyAnswers) {
  return {
    type: SET_SURVEY_ANSWERS,
    surveyAnswers: surveyAnswers,
  }
}

export function setLoadedWonum(wonum) {
  return {
    type: SET_LOADED_WONUM,
    loadedWonum: wonum,
  }
}

export function setLoading(loading) {
  return {
    type: SET_LOADING,
    surveyLoading: loading,
  }
}
