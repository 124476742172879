/* eslint-disable react-hooks/exhaustive-deps */
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import { CardActionArea } from '@mui/material'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'
import { connect } from 'react-redux'
import { Alert } from '@mui/material'
import { makeStyles, createStyles } from '@mui/styles'
import { useState, useEffect } from 'react'
import { getURLSearchParams } from '../../windowManager'
import { setShouldRender } from '../ProgressOverlay/store/actionCreator'
import { fetchData } from '../../service/HttpService'
import { setAssetWorkOrderList } from './actionCreator'
import { WORK_ORDER, globalRoutes } from '../../globalConstants'
import { Link } from 'react-router-dom'
import moment from 'moment'
import StatusAlert from '../StatusAlert/StatusAlert'

const useStyles = makeStyles((theme) =>
  createStyles({
    cardContent: {
      padding: theme.spacing(1),
    },
    cardHeader: {
      background: theme.palette.grey[300],
    },
    actionIcon: {
      margin: theme.spacing(1, 0.5, 0, 0),
    },
  }),
)

const AssetHistoryWorkOrderList = ({
  storeId,
  workorders,
  setAssetWorkOrderList,
  setShouldRender,
}) => {
  const urlSearchParams = getURLSearchParams()
  const classes = useStyles()
  const [error, setError] = useState(false)
  const assetId = urlSearchParams.get('asset_id')
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    try {
      setShouldRender(true, `Loading WOs...`)
      setLoading(true)
      setError(false)
      const getAssetData = async () => {
        const response = await fetchData(
          `/mam/wofromassets/siteId/${storeId}/assetNum/${assetId}`,
        )
        setAssetWorkOrderList(response.woFromAssetsdata)
      }
      getAssetData()
    } catch (error) {
      setError(true)
    } finally {
      setShouldRender(false)
      setLoading(false)
    }
  }, [])

  const commonGridChildProps = {
    item: true,
    xs: 5,
  }

  const subheaderTitle = () => {
    const workorder = workorders[0]
    const assetNumber = workorder.woassetnum
    const assetName = workorder.woassetname
    const areaOfStore = workorder.location
    let subheaderText = `No ${WORK_ORDER}s found for ${assetName}`
    const numberOfWorkOrders = workorders.length
    if (numberOfWorkOrders) {
      subheaderText = `${numberOfWorkOrders} ${WORK_ORDER}s found for
      ${assetNumber}, ${assetName},
      ${areaOfStore}.`
    }
    return subheaderText
  }

  return (
    <>
      {workorders?.length && !error && !loading ? (
        <>
          <StatusAlert text={subheaderTitle()} />
          <Grid container>
            {workorders.map((workorder, index) => {
              const wonum = workorder.wonum

              return (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  className={classes.cardContent}
                  key={index}
                  data-testid="workorder-list"
                >
                  <Card>
                    <CardActionArea
                      component={Link}
                      to={`${globalRoutes.workOrderDetails}#?wonum=${wonum}&showBack=true`}
                    >
                      <CardHeader
                        className={classes.cardHeader}
                        action={
                          <ChevronRightIcon className={classes.actionIcon} />
                        }
                        title={`WO # ${wonum}`}
                      />
                      <Divider />
                      <CardContent>
                        <Grid container>
                          <Grid {...commonGridChildProps}>
                            <Typography>
                              <strong>Description:</strong>
                            </Typography>
                          </Grid>
                          <Typography>{workorder.wodesc}</Typography>
                        </Grid>
                        <Grid container>
                          <Grid {...commonGridChildProps}>
                            <Typography>
                              <strong>Status:</strong>
                            </Typography>
                          </Grid>
                          <Typography>{workorder.wostatus}</Typography>
                        </Grid>
                        <Grid container>
                          <Grid {...commonGridChildProps}>
                            <Typography>
                              <strong>Problem:</strong>
                            </Typography>
                          </Grid>
                          <Typography>{workorder.woproblemcode}</Typography>
                        </Grid>
                        <Grid container>
                          <Grid {...commonGridChildProps}>
                            <Typography>
                              <strong>Created Date:</strong>
                            </Typography>
                          </Grid>
                          <Typography>
                            {moment(workorder.reportDate).format('MM/DD/YYYY')}
                          </Typography>
                        </Grid>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
              )
            })}
          </Grid>
        </>
      ) : null}
      {!error && !workorders?.length && !loading && (
        <Alert severity="warning">
          There are no Work Orders to display for Asset selected.
        </Alert>
      )}
      {error && (
        <Alert severity="error">
          Unable to connect to Work Orders service.
        </Alert>
      )}
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    workorders: state.assetReducer.workorders,
    storeId: state.storeInfoReducer.storeId,
  }
}

const mapDispatchToProps = {
  setAssetWorkOrderList,
  setShouldRender,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AssetHistoryWorkOrderList)
