import { SET_SHOULD_RENDER } from './actionType'

export const initialState = {
  shouldRender: false,
  headerText: 'Loading data...',
  bodyText: null,
}

export default function progressOverlayReducer(
  state = initialState,
  action = {},
) {
  switch (action.type) {
    case SET_SHOULD_RENDER: {
      const actionHeaderText = action.headerText
      const actionBodyText = action.bodyText
      const newState = {
        ...state,
        shouldRender: action.shouldRender,
        headerText: actionHeaderText
          ? actionHeaderText
          : initialState.headerText,
        bodyText: actionBodyText ? actionBodyText : initialState.bodyText,
      }
      return newState
    }
    default:
      return state
  }
}
