/* eslint-disable react-hooks/exhaustive-deps */
import Dialog from '@mui/material/Dialog'
import { Grid, LinearProgress, Typography } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles, createStyles } from '@mui/styles'
import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import InfoIcon from '@mui/icons-material/Info'
import {
  INTERSTITIAL_DEFAULT_BODY,
  INTERSTITIAL_DEFAULT_HEADER,
  DEFAULT_SITE_KEY,
} from '../../globalConstants'
import { setStoreId } from '../MatAppBar/store/actionCreator'
import {
  COLOR_GREY_CONTRAST_WEAK,
  COLOR_BACKGROUND_DARKER_GRAY,
} from '../../globalColors'

const useStyles = makeStyles((theme) =>
  createStyles({
    backdrop: {
      backgroundColor: theme.palette.common.white,
    },
    gridParent: {
      padding: theme.spacing(3),
    },
    header: {
      marginLeft: theme.spacing(2),
      fontWeight: 'bold',
    },
    gridBody: {
      paddingTop: theme.spacing(2),
    },
    headerNoWrap: {
      flexWrap: 'nowrap',
    },
    progressColor: {
      backgroundColor: COLOR_BACKGROUND_DARKER_GRAY,
    },
    progressMainColor: {
      backgroundColor: COLOR_GREY_CONTRAST_WEAK,
    },
  }),
)

const FullPageInterstitial = ({
  headerText = INTERSTITIAL_DEFAULT_HEADER,
  bodyText = INTERSTITIAL_DEFAULT_BODY,
  icon = <InfoIcon />,
  error = false,
  loading = true,
  linearProgress = true,
}) => {
  const location = useLocation()
  const navigate = useNavigate()
  const classes = useStyles()
  const routerLocation = location
  const currPath = routerLocation.pathname + routerLocation.hash
  const dispatch = useDispatch()
  const defaultStore = useSelector(
    (state) => state.userInfoReducer.userInfo?.[DEFAULT_SITE_KEY]?.string,
  )

  useEffect(() => {
    // Redirect to the route
    if (!loading && !error) {
      // set Default store in redux
      dispatch(setStoreId(defaultStore))
      navigate(currPath === '/' ? '/home' : currPath)
    }
  }, [loading])

  return (
    <Dialog
      open
      BackdropProps={{
        classes: {
          root: classes.backdrop,
        },
      }}
      transitionDuration={0}
      data-testid="dialog"
    >
      {linearProgress && (
        <LinearProgress
          classes={{
            colorPrimary: classes.progressMainColor,
            bar1Indeterminate: classes.progressColor,
            bar2Indeterminate: classes.progressColor,
          }}
        />
      )}
      <Grid container className={classes.gridParent}>
        <Grid container alignItems="center" className={classes.headerNoWrap}>
          {icon}
          <Typography className={classes.header} variant="h6" noWrap>
            {headerText}
          </Typography>
        </Grid>
        {bodyText && (
          <Grid className={classes.gridBody}>
            <Typography>{bodyText}</Typography>
          </Grid>
        )}
      </Grid>
    </Dialog>
  )
}

export default FullPageInterstitial
