/* eslint-disable react-hooks/exhaustive-deps */
import { makeStyles, createStyles } from '@mui/styles'
import { useEffect, useState } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Button,
  FormControl,
  Alert,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material'
import { CANCEL_TEXT } from '../../globalConstants'
import useHashChange from '../../utils/hooks/useHashChange'
import { fetchData, putData } from '../../service/HttpService'
import { setShouldRender } from '../ProgressOverlay/store/actionCreator'
import { useDispatch, useSelector } from 'react-redux'
import { setWorkOrder } from '../WorkOrderDetails/store/actionCreator'

const useStyles = makeStyles((theme) =>
  createStyles({
    dialogActions: {
      padding: theme.spacing(0, 3, 2, 0),
    },
    dialogContent: {
      marginTop: theme.spacing(2),
    },
    radioDescription: {
      margin: theme.spacing(1, 0),
    },
  }),
)

const ChangePriorityDialog = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { wodetails = null } = useSelector((state) => ({
    ...state.workOrderDetailsReducer,
  }))
  const [loading, setLoading] = useState(true)
  const [open, setOpen] = useState(false)
  const [priorityList, setPriorityList] = useState([])
  const [priority, setPriority] = useState('')
  const woNum = wodetails?.wonum
  useHashChange('change_priority_open', setOpen)
  const [error, setError] = useState(false)
  const errorMessage = 'There was an error updating priority'
  useEffect(() => {
    async function getPriorites() {
      try {
        setShouldRender(true, 'Loading...')
        const result = await fetchData(`/mam/priority/dc`)
        setPriorityList(result.PriorityData)
        setPriority(wodetails?.wopriorityy)
      } catch (error) {
        setError(error)
      } finally {
        setLoading(false)
        setShouldRender(false)
      }
    }
    getPriorites()
  }, [wodetails])

  const handleCancel = () => {
    window.history.back()
    setError(false)
  }

  const handleSave = async (value) => {
    const priorityData = {
      priority: value,
    }
    setError(false)
    try {
      dispatch(setShouldRender(true, 'Saving Priority...'))
      await putData(`/maximo/workorder/${woNum}`, JSON.stringify(priorityData))
      const response = await fetchData(`/mam/workorder/wonum/${woNum}`)
      dispatch(setWorkOrder(response.woDetails[0]))
      handleCancel()
    } catch (error) {
      setError(true)
    } finally {
      dispatch(setShouldRender(false))
    }
  }

  return (
    <Dialog open={open} onClose={handleCancel}>
      <DialogTitle>Change Priority</DialogTitle>
      {error && <Alert severity="error">{errorMessage}</Alert>}

      <DialogContent>
        <Grid item xs={12} className={classes.dialogContent}>
          <FormControl fullWidth size="small">
            <RadioGroup
              value={loading || !priority ? '' : priority}
              onChange={(e) => handleSave(e.target.value)}
            >
              {priorityList.map(({ value, description }, index) => {
                return (
                  <FormControlLabel
                    value={parseInt(value).toString()}
                    key={index}
                    control={<Radio data-testid={`radio-button-${index}`} />}
                    label={parseInt(value) + ' - ' + description}
                    className={classes.radioDescription}
                  />
                )
              })}
            </RadioGroup>
          </FormControl>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleCancel}>
          {CANCEL_TEXT}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ChangePriorityDialog
