import {
  PendingActions,
  FlightLand,
  AssignmentTurnedIn,
} from '@mui/icons-material'

export const WO = 'WO'
export const WORK_ORDER = 'Work Order'
export const WORK_ORDERS = 'Work Orders'
export const MAT_UI_LG_WIDTH = 1200
export const MAT_UI_LG_WIDTH_HALF = MAT_UI_LG_WIDTH * 0.5
export const SEARCH = 'Search'
export const CONTINUE = 'Continue'

export const api = {
  userInfo: (lanId) => `/mam/login/lanid/${lanId}`,
  userAssignments: (siteId) => `/mam/srcount/siteId/${siteId}`,
  userAssignmentsCancelCount: (siteId) => `/mam/cancelcount/siteId/${siteId}`,
  userAssignmentCompWoCount: (siteId, dayCount) =>
    `/mam/compcount/siteId/${siteId}?dayCount=${dayCount}`,
  serviceRequests: (siteId) => `/maximo/servicerequest/siteid/${siteId}`,
  openWorkOrders: (siteId) => `/mam/wo/workorders/siteid/${siteId}`,
  duplicateWorkOrders: (siteId, problemCode, assetNum, location) =>
    `/mam/wo/workorders/duplicates/siteid/${siteId}?problemCode=${problemCode}${
      assetNum ? `&assetNum=${assetNum}` : ``
    }${location ? `&location=${location}` : ``}`,
  autoWorkOrders: (siteId) => `/mam/wo/workorders/siteid${siteId}`,
  uploadImage: `/maximo/createattachment/`,
  userLaborEntries: (siteId, userId) =>
    `/mam/mytime/siteId/${siteId}/id/${userId}`,
  myAssignments: (storeId, userId) =>
    `/mam/mylistassignments/ios/siteId/${storeId}/zid/${userId}`,
  otherShifts: (userId) => `/mam/othershifts/?userid=${userId}`,
  myShift: (userId) => `/mam/myshift/?userid=${userId}`,
  todaysAssignments: (storeId, userId) =>
    `/mam/mylistassignments/siteId/${storeId}/zid/${userId}/today`,
  addTime: `/maximo/labortransaction`,
  statusUpdate: `/maximo/srstatusupdate`,
  laborEntries: (siteId, userId) =>
    `/mam/laborentries/siteId/${siteId}/id/${userId}`,
  worklogDetails: (siteId, woNum) =>
    `/mam/Worklogdetails/recordKey/${woNum}/siteId/${siteId}`,
  worklogNoteTypes: (userId) =>
    `/mam/PrLookup/domainid/LOGTYPE?userid=${userId}`,
  assignments: (woNum) => `/mam/assignments/wonum/${woNum}`,
  worklogVisits: (userId, wonum, vendorNbr) =>
    `/mam/visits?reference_id=${wonum}&vendor_id=${vendorNbr}&userid=${userId}`,
  storeCoordinates: (userId) => `/mam/location/lanid/${userId}`,
  getWorkOrderAssetSpareParts: (woNum, assetNum) =>
    `/asset-spare-parts#?wonum=${woNum}/assetNum/${assetNum}`,
  getStoreroomItems: (storeNum) => `/mam/items/storenum/${storeNum}`,
}

export const globalRoutes = {
  allOpenWorkOrders: '/all-open-work-orders',
  completeWorkOrder: '/complete-work-order',
  workOrderDetails: '/work-order-details',
  workOrderDetailsPlans: '/work-order-details/plans',
  workorderDetailsSurvey: '/work-order-details/survey',
  workorderDetailsRelatedRecords: '/work-order-details/related-records',
  workorderDetailsImages: '/work-order-details/images',
  workOrderDetailsSchedules: '/work-order-details/schedules',
  workOrderDetailsWorkLog: '/work-order-details/work-log',
  workOrderDetailsBilling: '/work-order-details/billing',
  workOrderDetailsIssuedParts: '/work-order-details/issuedparts',
  workOrderAssetSpareParts: '/work-order-details/asset-spare-parts',
  createWorkLog: '/create-work-log',
  internalWorkOrders: '/internal-work-orders',
  uploadPhoto: '/upload-photo',
  actionNeededVendorWorkOrders: '/action-needed-vendor-work-orders',
  getWoDetailsRoute: (wonum) =>
    `/work-order-details#?wonum=${wonum}&showBack=true`,
  getSrDetailsRoute: (params) => `/store-request-details#?${params}`,
  startCreateWorkOrder: '/start-create-work-order',
  assetsSelect: `/assets-select`,
  areaOfStore: `/area-of-store`,
  actionNeeded: '/action-needed-work-orders',
  timeTracking: `/time-tracking`,
  emergencyMaintenance: '/emergency-maintenance-work-orders',
  externalWorkOrders: '/external-work-orders',
  redirectedWorkOrders: '/redirected-work-orders',
  vendorWorkValidation: '/vendor-work-validation',
  validateWorkOrder: '/validate-work-order',
  cmWorkOrders: '/cm-work-orders',
  fieldProjectWorkorders: '/field-project-work-orders',
  preventativeMaintenance: '/preventative-maintenance-work-orders',
  storeRequestDetails: (siteId, ticketId) =>
    `/maximo/servicerequest/siteid/${siteId}/ticketid/${ticketId}`,
  duplicateWorkOrderList: `/duplicate-work-order-list`,
  cancelledExtWorkOrders: '/cancelled-external-work-orders',
  safety: `/safety-work-orders`,
  schedules: '/my-assignments',
  sparePartsDetails: '/spare-parts-details',
  problems: '/problems',
  createWorkOrder: '/create-work-order',
  workLogVisitsMap: '/work-log-visits-map',
  autoWorkOrders: '/auto-work-orders',
  getAssetDowntimeRoute: (woNum, assetNum) =>
    `/downtime#?wonum=${woNum}&asset=${assetNum}`,
  assetDowntime: '/downtime',
  getReportDowntime: (woNum, asset) =>
    `/report-downtime#?asset=${asset}&wonum=${woNum}`,
  reportDowntime: '/report-downtime',
  storeroomItems: '/storeroom-items',
  cyclecount: '/cycle-count',
  cyclecountlist: '/cycle-count-list',
  cyclecountdetail: '/cycle-count-detail',
}
export const MIN_NOTE_LENGTH = 3
export const DATE_NOT_SET = 'Date not set'
export const DEFAULT_SITE_KEY = 'dcterms:defsite'
export const DEFAULT_STOREROOM_KEY = 'dcterms:defstoreroom'
export const PERSON_KEY = 'dcterms:person'
export const PERSON_ID_KEY = 'dcterms:personid'
export const LABOR_KEY = 'dcterms:labor'
export const LABOR_CODE_KEY = 'dcterms:laborcode'
export const LABOR_CRAFT_RATE_KEY = 'dcterms:laborcraftrate'
export const CRAFT_KEY = 'dcterms:craft'
export const RATE_KEY = 'dcterms:rate'
export const ORG_ID_KEY = 'dcterms:orgid'
export const MISC_DETAILS_KEY = 'miscDetails'
export const DEFAULT_ERROR_MESSAGE = 'Error while performing action'
export const DEFAULT_EMPTY_MESSAGE = 'No records found'
export const NO_DEFAULT_SITE_HEADER = 'Default insert site error '
export const WORKORDER_ERROR =
  'There was an issue getting work order information.'
export const NO_DEFAUT_SITE_BODY =
  'Did not find a default insert site. Please update your default insert site in Maximo.'
export const INTERSTITIAL_DEFAULT_HEADER = 'Gathering User Info'
export const INTERSTITIAL_DEFAULT_BODY =
  'You will be redirected in a few moments.'
export const INTERSTITIAL_DEFAULT_BODY_HOME =
  'You will be redirected to the Homepage in a few moments.'
export const AREA_OF_STORE_FILTER_KEY = 'location'
export const INTERNAL_WORK = 'Internal Work'
export const EXTERNAL_WORK = 'External Work'

export const HOME_PAGE_ERROR = (isDCUser) =>
  `Unable to get User ${isDCUser ? 'Assignments' : 'Schedule'}`
export const HOME = 'Home'
export const HOME_ROUTE = '/home'
export const APP_DRAWER = 'app-drawer'
export const DRAWER_WIDTH = 360
export const NO_SCHEDULES = (isDCUser) =>
  `There are no ${isDCUser ? 'Assignments' : 'Schedules'} to display`
export const ADD_TO_SCHEDULE = (isDCUser) =>
  `Add to ${isDCUser ? 'Assignments' : 'Schedule'}`
export const COMPLETE_WORK_ORDER = 'Complete Work Order'
export const CREATE_FOLLOW_UP = 'Create Follow Up'
export const ADD_TIME = 'Add Time'
export const CANCEL_WORK_ORDER = 'Cancel Work Order'
export const ERROR = 'error'
export const ASSET_ID_PARAM = 'asset_id'
export const CHECK_DUPLICATE_WO_HEADER = 'Duplicate check'
export const CHECK_DUPLICATE_WO_BODY = 'Checking for Duplicate Work orders'
export const DUPLICATE_WORK_ORDERS = 'Duplicates'
export const PROBLEM_LIST = 'Problem List'
export const DUPLICATE_WORK_ORDER_ERROR = 'Error getting duplicate work orders'
export const DUPLICATE_WORK_ORDER_EMPTY = 'No duplicate Work orders found'
export const DUPLICATE_WORK_ORDER_DIRECT_URL = 'duplicate-wo-direct-url'
export const ASSET_NAME_PARAM = 'assetname'
export const PROBLEM_CODE_PARAM = 'problemcode'
export const LOCATION_PARAM = 'location'
export const OPEN_WORK_ORDERS = 'Open Work Orders'
export const STOREROOM_ITEMS = (userStoreroom) => `Storeroom ${userStoreroom}`
export const OPEN_WORK_ORDER_ERROR = 'Error getting open work orders'
export const OPEN_WORK_ORDER_EMPTY = 'No Open Work Orders Found'
export const AUTO_WORK_ORDERS = 'Auto Work Orders'
export const AUTO_WORK_ORDER_ERROR = 'Error getting auto work orders'
export const AUTO_WORK_ORDER_EMPTY = 'No Auto Work Orders Found'
export const LOAD_OPEN_WORK_ORDERS_HEADER = 'Loading'
export const LOAD_OPEN_WORK_ORDERS_BODY = 'Work Orders'
export const REMOVE_SCHEDULE = 'REMOVE'
export const SCHEDULED = 'SCHEDULED'
export const ADD_SCHEDULE = `ADD`
export const VALIDATE_WORK_ORDER = 'Validate Work Order'
export const CHANGE_PRIORITY = 'Change Priority'
export const SHORT_DESCRIPTION_CHARACTER_LIMIT = 100
export const INPUT_PERFORMANCE_DELAY = 100
export const DATE_FORMAT = 'MM/DD/YY h:mma'
export const DATE_TIME_PICKER_FORMAT = 'hh:mm:ss a'
export const WORK_ORDER_FILTER_PROPERTIES = (isDCUser) =>
  !isDCUser
    ? [
        'wonum',
        'wodesc',
        'ltreghrs',
        'wotype',
        'woassetnum',
        'woassetname',
        'wolocation',
        'wostatus',
        'woproblemcode',
        'repair_by',
        'jpnum',
        'redirectreason',
        'wovendworkval',
        'woassigncraft',
        'name',
      ]
    : [
        'wonum',
        'wodesc',
        'ltreghrs',
        'wotype',
        'assettag',
        'woassetnum',
        'woassetname',
        'wolocation',
        'wostatus',
        'woproblemcode',
        'reportdate',
        'calcpriority',
        'jpnum',
        'woassigncraft',
        'name',
      ]
export const STOREROOM_ITEM_FILTER_PROPERTIES = [
  'avblbalance',
  'binnum',
  'itemNum',
  'itemdes',
  'quantity',
]

export const CYCLE_COUNT_ITEM_FILTER_PROPERTIES = [
  'binnum',
  'itemNum',
  'itemdes',
]
export const APP_DRAWER_REFRESH_TEXT = 'Last updated at'
export const DEFAULT_REFRESH_TEXT = 'Last updated'
export const WORK_ORDER_NUMBER_FIELD = 'wonum'
export const WORK_ORDER_NUMBER = 'Work Order Number'
export const STORE_REQUEST_ID_FIELD = 'ticketid'
export const WORK_ORDER_ID_FIELD = 'workorderid'
export const UPLOAD_IMAGES = 'Upload Images'
export const HOW_TO_UPLOAD_IMAGES = 'How to Upload Images'
export const UPLOAD_IMAGE_COUNT_WARNING =
  'You can upload only 5 images at a time'
export const VIEW_IMAGE = 'VIEW_IMAGE'
export const SELECT_IMAGES = 'Select Images'
export const DELETE_IMAGE_UPLOAD =
  'Do you want to remove the image from upload list?'
export const DELETE_IMAGE_VIEW = 'Are you sure you want to delete'
export const DELETE_IMAGE_UPLOAD_TOOLTIP = 'Remove Image from upload'
export const DELETE_IMAGE_VIEW_TOOLTIP = 'Delete image'
export const DELETE_IMAGE = 'Delete Image?'
export const VALID_IMAGE_EXTESIONS = ['jpg', 'jpeg', 'png']
export const UPLOAD_IMAGE_TYPE_WARNING = 'Please select only JPG and PNG files'
export const LOADING_MESSAGE =
  'Please stay on this page until your images have finished uploading'
export const WORK_ORDER_DETAIL_ERROR = 'Error getting Work Order details'
export const WORK_ORDER_IMAGE_ERROR =
  'Error while getting images for Work order.'
export const WORK_ORDER_NO_IMAGES = '0 Images for Work Order'
export const CLEAR_IMAGES = 'Clear'
export const UPLOADED_SUCCESSFULLY_MESSAGE = 'Uploaded successfully'
export const UPLOADED_ERROR_MESSAGE = 'Error occured while uploading image'
export const RETRY_UPLOAD = 'Retry Upload'
export const INDIVIDUAL_IMAGE_ERROR = 'Image upload error'
export const OPEN_WORK_ORDERS_PROP = 'allOpenWorkOrders'
export const AUTO_WORK_ORDERS_PROP = 'autoWorkOrders'
export const SCHEDULE_OR_ASSIGNMENT = (isDCUser) =>
  `${isDCUser ? 'Assignments' : 'Schedule'}`
export const ASSIGNMENTS_ERROR = (isDCUser) =>
  `Error getting ${isDCUser ? 'assignments' : 'schedule'}`
export const ADD_MORE = 'Add More'
export const LOCATION = 'Location'
export const FOLLOW_UP_WO_URL_PARAM = 'follow_up_wo'
export const FOLLOW_UP_SR_URL_PARAM = 'follow_up_sr'
export const FOLLOW_UP_WO_TEXT = (wo, asset, location, areaOrLocation) =>
  `Creating follow-up from WO #${wo}${asset && location ? ',' : ''} ${
    asset ? `Asset ${asset}` : ''
  } ${location ? `and ${areaOrLocation} ${location}` : ''}`
export const FOLLOW_UP_WO_SR_TEXT = (description) =>
  `Creating Work Order from ${description}`
export const NOT_AVAILABLE = 'N/A'
export const LOADING_TIME_ENTRIES = 'Loading Time Entries...'
export const TIME_TRACKING = 'Time Tracking'
export const LABOR_ENTRIES_KEY = 'laborentries'
export const TIME_TRACKING_SERVICE_ERROR =
  ' Unable to connect to Time Tracking service'
export const TIME_TRACKING_EMPTY = 'No Time Tracking entries found'
export const TIME_TRACKING_MIN_TIME =
  'Two minute minimum required for time entry.'
export const REG_HOURS = 'Time'
export const ASSET_DESC_OR_LOCATION = 'Asset/Area'
export const DESCRIPTION = 'Description'
export const WO_TIMELINE_ARRAY = [
  { label: 'Earliest start', key: 'earliest_start', icon: PendingActions },
  { label: 'Arrive by', key: 'arrive_by', icon: FlightLand },
  {
    label: 'Repair by (Comp by)',
    key: 'repair_by',
    icon: AssignmentTurnedIn,
  },
]
export const DC_WO_TIMELINE_ARRAY = [
  {
    label: 'Reported',
    key: 'reportdate',
    icon: PendingActions,
  },
]
export const WORK_LOG = 'Work Log'
export const COMPLETE = 'Complete'
export const VALIDATE = 'Validate'
export const WO_FAIL_STATUS = ['VREPAIR', 'VCOMP']
export const WO_FAIL_MESSAGE =
  'Log note required when failing, optional when passing. Work Order must be in VREPAIR or VCOMP to move to FAIL'
export const LOCAL_VENDOR_MANAGEMENT = 'Local Vendor Management (LVM)'
export const WORK_LOG_NOTES = 'Work Log Notes'
export const CREATE_DATE = 'Create Date'
export const WORK_LOG_NOTES_EMPTY = 'No Work log notes found.'
export const VISITS_EMPTY = 'No Visits found.'
export const XS_CUSTOM_BREAKPOINT = '@media(max-width:550px)'
export const XS_CARD_MEDIA_QUERY = {
  flexGrow: 0,
  maxWidth: '100%',
  flexBasis: '100%',
}
export const XS_MEDIA_QUERY = { width: '(max-width:600px)', count: 1 }
export const SM_MEDIA_QUERY = { width: '(max-width:900px)', count: 2 }
export const MD_MEDIA_QUERY = { width: '(min-width:900px)', count: 3 }
export const NOTE_TYPE = 'Note Type'
export const LVM = 'Local Vendor Management(LVM)'
export const SELECT_LVM_OPTION = 'Select LVM Option'
export const SELECT_NOTE_TYPE = 'Select Note Type'
export const FILTER_NOTE_TYPE = 'Filter Work Log Notes'
export const ADD_EXPECTATION_NOTE = 'Add Expectation Note'
export const WORKLOG_ENTRY = 'Worklog Entry'
export const SUMMARY_TEXT = 'Summary'
export const DETAILS_TEXT = 'Details'
export const CANCEL_TEXT = 'Cancel'
export const SUBMIT_TEXT = 'Submit'
export const SUMMARY_CHARACTER_LIMIT = 100
export const VENDOR = 'Vendor'
export const WO_TYPE = 'Work Type'
export const ASSET_NAME = 'Asset Name'
export const ASSET_NUMBER = 'Asset Num'
export const ASSET_TAG = 'Asset Tag'
export const AREA_OF_STORE = 'Area'
export const STATUS = 'Status'
export const REDIRECT_REASON = 'Redirect Reason'
export const PROBLEM = 'Problem'
export const REPAIR_BY_COMP_BY = 'Repair by'
export const REPORTED_DATE = 'Reported'
export const CALCULATED_PRIORITY = 'Calc Priority'
export const JOB_PLAN = 'Job Plan'
export const LOGGED_HOURS = 'Logged Hours'
export const ASSIGNED_TO = 'Assigned to'
export const WORK_VALIDATION = 'Work Validation'

export const ADD_NOTE = 'add_note'
export const LVM_NOTE = 'add_vendor_expectation_note'
export const LOG_MY_TIME = 'Log My Time '
export const FOLLOW_UP_WO_SESSION_STORAGE_KEY = 'followUpWoProperties'
export const SR_PAYLOAD_TICKETID_FIELD = 'dcterms:ticketid'
export const WO_DETAILS = 'wo_details'
export const STORE_REQUEST_DETAILS = 'sr_details'
export const SR_DETAILS_ERROR = 'Error getting Store Request Details'
export const ADD_ASSIGNMENT = 'Add Assignment'
export const CHECK_OUT = 'Check Out'
export const CHECK_IN = 'Check In'
export const ETA = 'ETA'
export const EVENT_TYPE = 'Event'
export const DATE_TIME = 'Date/Time'
export const GEOFENCE = 'Geo'
export const VISITS_ERROR_MESSAGE = 'Error getting Work Log Visits'
export const VISIT_ID = 'Visit ID'
export const FOLLOW_UP = 'Follow-up'
export const WORK_ORDER_EXPECTATIONS = 'Work Order Expectations'
export const MONTH_DAY_YEAR_FORMAT = 'MM/DD/YYYY'
export const HOUR_MINUTE_PERIOD_FORMAT = 'hh:mm a'
export const TEXTFIELD_LINE_HEIGHT = '1.4375'
export const WORK_LOG_NOTE_TYPE_ERROR = 'Error getting Work Log note types'
export const CLEAR_FILTERS = 'Clear filters'
export const SELECT_ALL = 'select-all'
export const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD'
export const SCHEDULES = 'schedules'
export const STORE_TECH_INT = 'STORE-TECH-INT'
export const VENDOR_CONTACT_OPEN_WOD = 'vendor_contact_open_wod'
export const VENDOR_CONTACT_OPEN_VISITS = 'vendor_contact_open_visits'
export const VENDOR_CONTACT_OPEN_WORKLOG = 'vendor_contact_open_worklog'
