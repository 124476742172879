import MatAppBar from '../MatAppBar/MatAppBar'
import StoreRequestsList from '../StoreRequestsList/StoreRequestsList'

const StoreRequests = () => {
  return (
    <>
      <MatAppBar arrowBack text="Store Requests" />
      <StoreRequestsList />
    </>
  )
}

export default StoreRequests
