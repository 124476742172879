import axios from 'axios'
import config from '../config/appConfig'

let controller

axios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    return response
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    if (
      // Checking for error message instead of 401 as bad API key can also trigger 401
      error?.response?.status === 401 &&
      error?.response?.data?.errors?.some(
        (e) =>
          e?.reason ===
          "[ID2] local token validation failed, error: 'Token is expired'",
      )
    ) {
      window.location.reload()
    }
    return Promise.reject(error)
  },
)

export const getBearer = () => {
  let bearerToken = localStorage?.getItem('access_token')
  bearerToken = !bearerToken?.includes('Bearer ')
    ? `Bearer ${bearerToken}`
    : bearerToken
  return bearerToken
}

const getConfig = () => {
  // create new instance on every call
  controller = new AbortController()
  return {
    headers: {
      Authorization: getBearer(),
      'Content-Type': 'application/json',
    },
    signal: controller.signal,
    crossDomain: true,
  }
}

const getDeleteConfig = (body) => {
  return {
    headers: {
      Authorization: getBearer(),
      'Content-Type': 'application/json',
    },
    crossDomain: true,
    data: body,
  }
}

const encodeUrlParams = (url) => {
  const splitUrl = url.split('?')
  let newUrl = `${splitUrl[0]}?`
  const entries = new URLSearchParams(splitUrl[1]).entries()
  for (const entry of entries) {
    newUrl += `${entry[0]}=${window.encodeURIComponent(entry[1])}&`
  }
  newUrl = newUrl.slice(0, -1)
  return newUrl
}

const configureUrl = async (url, newBaseUrl) => {
  const configuration = await config()
  const apiKey = `${configuration.auth.apiKey}`
  const baseUrl = newBaseUrl
    ? `${configuration[newBaseUrl]}`
    : `${configuration.baseURL}`
  const encodedUrl = `${baseUrl}${encodeUrlParams(url)}`
  const separator = encodedUrl.indexOf('?') === -1 ? '?' : '&'
  return `${encodedUrl}${separator}key=${apiKey}`
}

export const fetchData = async (url, noAsync, newBaseUrl) => {
  const finalUrl = await configureUrl(url, newBaseUrl)
  if (noAsync) {
    return axios.get(finalUrl, getConfig())
  } else {
    const response = await axios.get(finalUrl, getConfig())
    return response.data
  }
}

export const postData = async (url, body, newBaseUrl) => {
  const finalUrl = await configureUrl(url, newBaseUrl)
  const response = await axios.post(finalUrl, body, getConfig())
  return response.data
}

export const putData = async (url, body, newBaseUrl) => {
  const finalUrl = await configureUrl(url, newBaseUrl)
  const response = await axios.put(finalUrl, body, getConfig())
  return response.data
}

export const deleteData = async (url, body) => {
  const finalUrl = await configureUrl(url)
  const response = await axios.delete(finalUrl, getDeleteConfig(body))
  return response.data
}

export const fetchDataParallel = async (urls) => {
  const response = await Promise.all(
    urls.map(async (url) => axios.get(await configureUrl(url), getConfig())),
  )
  return response.map((val) => val.data)
}

export const fetchDataParallelWithCb = async (getApis, cb) => {
  await Promise.all(
    getApis.map(
      async (api, idx) =>
        await fetchData(api.url)
          .then((res) => {
            cb(api.id, res)
          })
          .catch((e) => {
            cb(api.id, 'error')
          }),
    ),
  )
}
export const postDataParallel = async (postApis) => {
  const response = await Promise.all(
    postApis.map(
      async (api) => await postData(api.url, api.body).then((res) => {}),
    ),
  )
  return response
}

export const postDataParallelWithCb = async (postApis, cb) => {
  await Promise.all(
    postApis.map(
      async (api, idx) =>
        await postData(api.url, api.body)
          .then((res) => {
            cb(idx)
          })
          .catch((e) => {
            cb(idx, 'error')
          }),
    ),
  )
}

/* This function can be used to cancel http requests on component unmount if reqired - 
specifically added for the API calls which build the navigation items with count, but can be used anywhere*/
export const cancelHttpRequest = () => {
  controller.abort()
}

const HttpService = {
  getBearer,
  fetchData,
  putData,
  postData,
  postDataParallel,
  fetchDataParallel,
  fetchDataParallelWithCb,
}

export default HttpService
