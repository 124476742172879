/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Alert } from '@mui/material'
import { fetchData } from '../../service/HttpService'
import { getURLSearchParams } from '../../windowManager'
import { setShouldRender } from '../../components/ProgressOverlay/store/actionCreator'
import { setWorkOrder } from '../../components/WorkOrderDetails/store/actionCreator'
import {
  WORK_ORDER_ID_FIELD,
  WORK_ORDER_DETAIL_ERROR,
} from '../../globalConstants'

const useGetWorkOrderDetails = () => {
  const urlSearchParams = getURLSearchParams()
  const woNumFromUrl = parseInt(urlSearchParams.get('wonum'))
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const { wodetails = null } = useSelector(
    (state) => state.workOrderDetailsReducer,
  )
  const reload = woNumFromUrl !== parseInt(wodetails?.wonum)
  useEffect(() => {
    async function loadWoDetails() {
      if (wodetails === null || reload) {
        try {
          dispatch(setWorkOrder(null))
          dispatch(setShouldRender(true, 'Loading Work Order Details...'))
          const response = await fetchData(
            `/mam/workorder/wonum/${woNumFromUrl}`,
          )
          dispatch(setWorkOrder(response.woDetails[0]))
        } catch (error) {
          setError(true)
        } finally {
          dispatch(setShouldRender(false))
          setLoading(false)
        }
      } else {
        setLoading(false)
      }
    }
    loadWoDetails()
  }, [])

  return error && !loading ? (
    <Alert severity="error">{WORK_ORDER_DETAIL_ERROR}</Alert>
  ) : !error && !loading && wodetails?.[WORK_ORDER_ID_FIELD] ? (
    true
  ) : null
}

export default useGetWorkOrderDetails
