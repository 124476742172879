/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { Alert } from '@mui/material'
import { connect, useSelector } from 'react-redux'
import { setShouldRender } from '../ProgressOverlay/store/actionCreator'
import { fetchData } from '../../service/HttpService'
import { useAuth } from '@praxis/component-auth'
import { setAssignments } from './WorkOrderListStore/actionCreator'
import {
  api,
  OPEN_WORK_ORDERS,
  SCHEDULE_OR_ASSIGNMENT,
  WORK_ORDERS,
} from '../../globalConstants'
import StatusAlert from '../StatusAlert/StatusAlert'
import CommonCardList from '../CommonCardList/CommonCardList'
import { filterAssignmentsBy7Days } from '../../utils'

const WorkOrderList = ({
  setShouldRender,
  storeId,
  setAssignments,
  toggle,
  data,
  from,
  wolength,
  showInfo = true,
}) => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const auth = useAuth()
  const userId = auth.session.userInfo.lanId?.toUpperCase()
  const filteredWorkOrdersLength = data?.length
  const infoMessage = `${filteredWorkOrdersLength} / ${wolength} ${WORK_ORDERS}`
  const { isDCUser } = useSelector((state) => ({
    ...state.userInfoReducer,
  }))

  useEffect(() => {
    async function getAssignments() {
      // open work orders assignments logic is handled in the parent component. Handling it here complicates the cache feature.
      if (from !== OPEN_WORK_ORDERS) {
        try {
          setShouldRender(true, 'Loading...', SCHEDULE_OR_ASSIGNMENT(isDCUser))
          const response = await fetchData(api.myAssignments(storeId, userId))
          const assigmentsScheduledThisWeek = filterAssignmentsBy7Days(
            response.assignmentsdata,
          )
          setAssignments(assigmentsScheduledThisWeek)
        } catch (error) {
          setError(true)
        } finally {
          setLoading(false)
          setShouldRender(false)
        }
      }
    }
    getAssignments()
  }, [toggle])

  return (
    <>
      {error && !loading && (
        <Alert severity="error">Unable to connect to assignment service.</Alert>
      )}
      {filteredWorkOrdersLength > 0 && (
        <>
          {showInfo && <StatusAlert text={infoMessage} />}
          <CommonCardList
            listData={data}
            filteredWorkOrdersLength={filteredWorkOrdersLength}
          />
        </>
      )}
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    storeId: state.storeInfoReducer.storeId,
    toggle: state.workOrderListReducer.toggle,
  }
}
const mapDispatchToProps = {
  setShouldRender,
  setAssignments,
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkOrderList)
