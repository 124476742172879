/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { Alert } from '@mui/material'
import { setShouldRender } from '../../components/ProgressOverlay/store/actionCreator'
import { useDispatch } from 'react-redux'

const WorkOrderDetailsBilling = () => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [billingRecords, setBillingRecords] = useState([])

  const dispatch = useDispatch()

  useEffect(() => {
    try {
      dispatch(setShouldRender(true, 'Loading...', `Billing Information`))
      //   const response = await fetchData(`/mam/relatedrecords/wonum/${woNum}`)
      setBillingRecords([]) //temp setting this to empty array - to be replaced with response
    } catch (error) {
      setError(true)
      setLoading(false)
    } finally {
      dispatch(setShouldRender(false))
      setLoading(false)
    }
  }, [])

  return (
    <>
      {!error && billingRecords?.length === 0 && !loading && (
        <Alert severity="warning" data-testid={'no-data-text'}>
          There is no billing information to display.
        </Alert>
      )}
    </>
  )
}

export default WorkOrderDetailsBilling
