import { combineReducers } from 'redux'
import areaOfStoreReducer from '../components/AreaOfStore/reducer'
import assetSelectReducer from '../components/AssetsSelect/AssetsSelectStore/reducer'
import createWorkOrderReducer from '../components/CreateWorkOrder/reducer'
import problemListReducer from '../components/ProblemList/reducer'
import storeInfoReducer from '../components/MatAppBar/store/reducer'
import assetReducer from '../components/AssetInformation/reducer'
import workOrderListReducer from '../components/WorkOrderList/WorkOrderListStore/reducer'
import progressOverlayReducer from '../components/ProgressOverlay/store/reducer'
import userInfoReducer from '../components/Root/store/reducer'
import storeRequestListReducer from '../components/StoreRequestsList/StoreRequestsListStore/reducer'
import completedWorkOrdersReducer from '../components/CompletedWorkOrders/store/reducer'
import navItemsReducer from '../components/NavigationItems/store/reducer'
import workOrderDetailsReducer from '../components/WorkOrderDetails/store/reducer'
import timeTrackingReducer from '../components/TimeTracking/store/reducer'
import workLogDetailsReducer from '../components/WorkOrderDetailsWorkLog/store/reducer'
import storeRequestDetailsReducer from '../components/StoreRequestDetails/store/reducer'
import vendorContactsReducer from '../components/VendorContacts/store/reducer'
import surveyAnswersReducer from '../components/WorkOrderDetailsSurvey/store/reducer'
import schedulesReducer from '../components/Schedules/store/reducer'
import cycleCountReducer from '../components/CycleCount/store/reducer'
//import reducers here and add all reducers in combineReducers so that the app has access to them
const rootReducer = combineReducers({
  areaOfStoreReducer,
  assetSelectReducer,
  createWorkOrderReducer,
  problemListReducer,
  storeInfoReducer,
  assetReducer,
  workOrderListReducer,
  progressOverlayReducer,
  userInfoReducer,
  storeRequestListReducer,
  completedWorkOrdersReducer,
  navItemsReducer,
  workOrderDetailsReducer,
  timeTrackingReducer,
  workLogDetailsReducer,
  storeRequestDetailsReducer,
  vendorContactsReducer,
  surveyAnswersReducer,
  schedulesReducer,
  cycleCountReducer,
})

export default rootReducer
