/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from 'react'
import { useSelector } from 'react-redux'
import {
  Grid,
  Typography,
  Alert,
  Card,
  CardContent,
  CardHeader,
  Button,
  useMediaQuery,
} from '@mui/material'
import {
  CREATE_DATE,
  DATE_FORMAT,
  MD_MEDIA_QUERY,
  SM_MEDIA_QUERY,
  WORK_LOG_NOTES_EMPTY,
  XS_MEDIA_QUERY,
} from '../../../globalConstants'
import { makeStyles, createStyles } from '@mui/styles'
import moment from 'moment-timezone'
import ExpandableDiv from '../../common/ExpandableDiv'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import WorkLogNotesSelect from './WorkLogNotesSelect'

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      margin: theme.spacing(2, 0),
    },
    headerBackground: {
      background: '#e3e5e8',
    },
    cardContent: {
      padding: theme.spacing(1),
    },
    dialogTitleContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    closeIcon: {
      marginLeft: 'auto',
    },
    dynamicFont: {
      '@media(max-width:600px)': {
        fontSize: '1.1rem',
      },
    },
    rightEndButton: {
      marginLeft: 'auto',
    },
    dynamicDiv: {
      overflow: 'auto',
      maxHeight: 200,
    },
    dynamicDivExpanded: {
      maxHeight: 'none',
    },
    breakWordIfOverflow: {
      whiteSpace: 'pre-wrap',
      wordBreak: 'break-word',
    },
  }),
)

const WorkLogNotes = () => {
  const classes = useStyles()
  const { worklogDetails: worklogDetailsFromStore = null } = useSelector(
    (state) => ({
      ...state.workLogDetailsReducer,
    }),
  )
  const [selectedWorklogNoteType, setSelectedWorklogNoteType] = useState([])
  const [moreNotes, setMoreNotes] = useState(false)

  const isXs = useMediaQuery(XS_MEDIA_QUERY.width)
  const isSm = useMediaQuery(SM_MEDIA_QUERY.width)
  const isMd = useMediaQuery(MD_MEDIA_QUERY.width)
  const sliceCount = isXs
    ? XS_MEDIA_QUERY.count
    : isSm
      ? SM_MEDIA_QUERY.count
      : isMd
        ? MD_MEDIA_QUERY.count
        : 1 // default case if not able to use the media query
  const worklogDetails = worklogDetailsFromStore?.filter?.((val) => {
    return selectedWorklogNoteType?.length === 0
      ? true
      : selectedWorklogNoteType.includes(val.logtype)
  })
  const workLogNotesToShow = worklogDetails?.length
    ? worklogDetails.slice(0, sliceCount)
    : []
  const remainingWorkLogNotes = worklogDetails?.length
    ? worklogDetails?.length - sliceCount
    : 0

  function renderMore() {
    return remainingWorkLogNotes && moreNotes ? (
      <div>
        <Grid container>
          {worklogDetails
            ?.slice?.(sliceCount)
            ?.map?.((val, idx) => renderCard(val, idx))}
        </Grid>
      </div>
    ) : null
  }

  function renderCard(data) {
    const createDate = data?.createdate
    const timeZone = moment.tz.guess()
    const formattedCreateDate = createDate
      ? moment
          .tz(createDate, 'America/Chicago')
          .tz(timeZone)
          .format(DATE_FORMAT)
      : ''
    const commonGridItemProps = {
      item: true,
      xs: 5,
    }

    return (
      <Grid
        item
        xs={12}
        sm={6}
        md={4}
        className={classes.cardContent}
        data-testid="worklog-notes-list"
        key={data.worklogid}
      >
        <Card>
          <CardHeader
            title={
              <Typography variant="body1">
                {formattedCreateDate}
                {` (${CREATE_DATE})`}
              </Typography>
            }
            className={classes.headerBackground}
          />
          <CardContent>
            <Grid container>
              <Grid {...commonGridItemProps}>
                <Typography>
                  <strong>Type:</strong>
                </Typography>
              </Grid>
              <Typography>{data.logtype}</Typography>
            </Grid>
            <Grid container>
              <Grid {...commonGridItemProps}>
                <Typography>
                  <strong>Summary:</strong>
                </Typography>
              </Grid>
              <Typography className={classes.breakWordIfOverflow}>
                {data.description}
              </Typography>
            </Grid>
            <Grid container>
              <Grid {...commonGridItemProps}>
                <Typography>
                  <strong>Created By:</strong>
                </Typography>
              </Grid>
              <Typography>{data.displayname}</Typography>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <Typography sx={{ paddingBottom: 1 }}>
                  <strong>Details:</strong>
                </Typography>
              </Grid>
              <ExpandableDiv text={data?.ldtext ?? null} />
              {/* Keep this commneted here as it would be required for a future story when the text has to be updated */}
              {/* <DynamicTextField text={data.ldtext} /> */}
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    )
  }
  return (
    <>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        sx={{ padding: 1 }}
      >
        <Grid item xs={12}>
          <WorkLogNotesSelect
            filter
            selectedWorklogNoteType={selectedWorklogNoteType}
            setSelectedWorklogNoteType={setSelectedWorklogNoteType}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {worklogDetails && (
          <Grid container>
            {!worklogDetails?.length ? (
              <Alert severity="warning">{WORK_LOG_NOTES_EMPTY}</Alert>
            ) : (
              workLogNotesToShow.map((val, idx) => renderCard(val))
            )}
            {remainingWorkLogNotes > 0 && !moreNotes && (
              <div className={classes.rightEndButton}>
                <Button
                  size="small"
                  onClick={() => setMoreNotes(true)}
                  endIcon={<ArrowDropDownIcon />}
                  data-testid="show-more-notes-button"
                >
                  {`Show All Notes (${worklogDetails?.length})`}
                </Button>
              </div>
            )}
          </Grid>
        )}
      </Grid>
      {renderMore()}
      {moreNotes && (
        <Grid container>
          <div className={classes.rightEndButton}>
            <Button
              size="small"
              onClick={() => setMoreNotes(false)}
              endIcon={<ArrowDropDownIcon />}
              data-testid="collapse-notes-button"
            >
              {`Collapse Notes`}
            </Button>
          </div>
        </Grid>
      )}
    </>
  )
}

export default WorkLogNotes
