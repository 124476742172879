/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchData } from '../../service/HttpService'
import { Alert, Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { postData } from '../../service/HttpService'
import { getURLSearchParams } from '../../windowManager'
import { setShouldRender } from '../ProgressOverlay/store/actionCreator'
import WorkOrderImageList from '../WorkOrderImageList/WorkOrderImageList'
import { makeStyles, createStyles } from '@mui/styles'
import {
  VIEW_IMAGE,
  WORK_ORDER_ID_FIELD,
  WORK_ORDER_IMAGE_ERROR,
  WORK_ORDER_NO_IMAGES,
  globalRoutes,
  WORK_ORDER_NUMBER_FIELD,
  ADD_MORE,
} from '../../globalConstants'
import useGetWorkOrderDetails from '../../utils/hooks/useGetWorkOrderDetails'
import { COLOR_DEFAULT_GREY } from '../../globalColors'

const useStyles = makeStyles((theme) =>
  createStyles({
    alertInfo: {
      marginTop: theme.spacing(2),
      borderColor: COLOR_DEFAULT_GREY,
      '& .MuiAlert-icon > svg': {
        fill: COLOR_DEFAULT_GREY,
      },
    },
  }),
)

const WorkOrderDetailsImages = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [woImages, setWOImages] = useState(null)
  const [renderFirstImages, setRenderFirstImages] = useState(false)
  const urlSearchParams = getURLSearchParams()
  const woNum = parseInt(urlSearchParams.get('wonum'))
  const { wodetails = null } = useSelector(
    (state) => state.workOrderDetailsReducer,
  )
  const woDetailFetched = useGetWorkOrderDetails()

  const { [WORK_ORDER_NUMBER_FIELD]: woNumber, [WORK_ORDER_ID_FIELD]: woId } =
    wodetails ?? {}
  const uploadRoute = `#?${WORK_ORDER_NUMBER_FIELD}=${woNumber}&${WORK_ORDER_ID_FIELD}=${woId}&showBack=true`
  const dispatch = useDispatch()

  // useeffect to get the initial list of images in the work order, which is used to get the full image through the useeffect below
  useEffect(() => {
    async function loadImages() {
      if (woDetailFetched === true) {
        dispatch(setShouldRender(true, 'Loading Images...'))
        try {
          //Fetch Work Order Images
          const response = await fetchData(`/maximo/getattachment/${woId}`)
          setWOImages(response)
        } catch (error) {
          setError(true)
        }
        setLoading(false)
        dispatch(setShouldRender(false))
      }
    }
    loadImages()
  }, [woDetailFetched, renderFirstImages])

  const handleDeleteImage = async (index) => {
    const data = woImages[index]
    dispatch(setShouldRender(true, 'Deleting Image...'))
    await postData(
      `/maximo/deleteattachment/`,
      JSON.stringify({
        docid: `${data.id}`,
        woid: `${woId}`,
      }),
    )

    dispatch(setShouldRender(false))
    setRenderFirstImages(!renderFirstImages)
  }

  const addMoreButton = () => {
    return (
      <Button
        onClick={() => navigate(`${globalRoutes.uploadPhoto}${uploadRoute}`)}
        variant="outlined"
      >
        {ADD_MORE}
      </Button>
    )
  }

  return woImages?.length > 0 ? (
    <>
      <Alert
        className={classes.alertInfo}
        severity="info"
        variant="outlined"
        action={addMoreButton()}
      >
        {woImages.length} Images for Work Order #{woNum}
      </Alert>
      <WorkOrderImageList
        images={woImages}
        from={VIEW_IMAGE}
        canDelete
        onDelete={handleDeleteImage}
      />
    </>
  ) : error && !loading ? (
    <Alert severity="error">{WORK_ORDER_IMAGE_ERROR}</Alert>
  ) : !loading && woImages?.length === 0 ? (
    <Alert
      className={classes.alertInfo}
      severity="info"
      variant="outlined"
      action={addMoreButton()}
    >
      {WORK_ORDER_NO_IMAGES} #{woNum}
    </Alert>
  ) : null
}

export default WorkOrderDetailsImages
