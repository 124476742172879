import { SET_SURVEY_ANSWERS, SET_LOADED_WONUM, SET_LOADING } from './actionType'

export const initialState = {
  surveyAnswers: null,
  loadedWonum: null,
  surveyLoading: null,
}

export default function surveyAnswersReducer(
  state = initialState,
  action = {},
) {
  switch (action.type) {
    case SET_SURVEY_ANSWERS: {
      const newState = {
        ...state,
        surveyAnswers: action.surveyAnswers,
      }
      return newState
    }
    case SET_LOADED_WONUM: {
      const newState = {
        ...state,
        loadedWonum: action.loadedWonum,
      }
      return newState
    }
    case SET_LOADING: {
      const newState = {
        ...state,
        surveyLoading: action.surveyLoading,
      }
      return newState
    }
    default:
      return state
  }
}
