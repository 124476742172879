import { Button, Grid, Typography } from '@mui/material'
import { makeStyles, createStyles } from '@mui/styles'
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing'
import RoomIcon from '@mui/icons-material/Room'
import { Link } from 'react-router-dom'
import { globalRoutes } from '../../globalConstants'
import {
  COLOR_TARGET_RED,
  COLOR_TARGET_RED_HIGHLIGHT,
} from '../../globalColors'
import { useSelector } from 'react-redux'

const useStyles = makeStyles((theme) =>
  createStyles({
    gridParent: {
      height: '80vh',
    },
    bigButton: {
      height: '20vh',
      backgroundColor: COLOR_TARGET_RED,
      '&:hover': {
        backgroundColor: COLOR_TARGET_RED_HIGHLIGHT,
      },
    },
    gridChild: {
      marginTop: theme.spacing(1),
      padding: theme.spacing(0, 1.5),
    },
    icon: {
      marginRight: theme.spacing(2),
    },
  }),
)

const BigButtons = () => {
  const classes = useStyles()
  const { areaOrLocation } = useSelector((state) => ({
    ...state.userInfoReducer,
  }))

  const commonButtonProps = {
    fullWidth: true,
    variant: 'contained',
    className: classes.bigButton,
    component: Link,
  }

  const commonGridChildProps = {
    item: true,
    xs: 12,
    md: 6,
    className: classes.gridChild,
  }

  const commonIconProps = {
    fontSize: 'large',
    className: classes.icon,
  }

  const commonTypographyProps = {
    variant: 'h5',
  }

  return (
    <Grid
      className={classes.gridParent}
      container
      justifyContent="center"
      alignContent="center"
    >
      <Grid container>
        <Grid {...commonGridChildProps}>
          <Button {...commonButtonProps} to={globalRoutes.assetsSelect}>
            <PrecisionManufacturingIcon {...commonIconProps} />
            <Typography {...commonTypographyProps}>Asset</Typography>
          </Button>
        </Grid>
        <Grid {...commonGridChildProps}>
          <Button {...commonButtonProps} to={globalRoutes.areaOfStore}>
            <RoomIcon {...commonIconProps} />
            <Typography {...commonTypographyProps}>{areaOrLocation}</Typography>
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default BigButtons
