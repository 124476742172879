/* eslint-disable react-hooks/exhaustive-deps */
import { useAuth } from '@praxis/component-auth'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { COLOR_DEFAULT_GREY } from '../../globalColors'
import { fetchData, putData } from '../../service/HttpService'
import DOMPurify from 'dompurify'
import {
  Button,
  Container,
  Grid,
  TextField,
  Typography,
  Alert,
  Stack,
  useMediaQuery,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
} from '@mui/material'
import clsx from 'clsx'
import { makeStyles, createStyles } from '@mui/styles'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import TimerIcon from '@mui/icons-material/Timer'
import SaveSharpIcon from '@mui/icons-material/SaveSharp'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { getURLSearchParams, setURLSearchParams } from '../../windowManager'
import { setShouldRender } from '../ProgressOverlay/store/actionCreator'
import { setWorkOrder } from './store/actionCreator'
import useGetWorkOrderDetails from '../../utils/hooks/useGetWorkOrderDetails'
import {
  WO_TIMELINE_ARRAY,
  VALIDATE_WORK_ORDER,
  COMPLETE,
  VALIDATE,
  NOT_AVAILABLE,
  DEFAULT_ERROR_MESSAGE,
  SHORT_DESCRIPTION_CHARACTER_LIMIT,
  globalRoutes,
  VENDOR_CONTACT_OPEN_WOD,
  DC_WO_TIMELINE_ARRAY,
  WO_TYPE,
} from '../../globalConstants'

import {
  formattedTime,
  setTimeString,
  convertTimestampToLocal,
} from '../../utils'
import WorkOrderTimeLine from '../TimeLine/TimeLine'
import VendorContacts from '../VendorContacts/VendorContacts'
import DynamicTextField from '../common/DynamicTextField'
import CancelDialog from '../CancelDialog/CancelDialog'

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      margin: theme.spacing(2, 0),
      // wordBreak: 'break-word',  //vmenon 07/19 - commenting the wordBreak for now as the fields look good on smaller form factors
      '& .MuiGrid-item': {
        paddingTop: theme.spacing(2),
      },
    },
    addTimeButtonNoWrap: {
      minWidth: 'max-content',
    },
    iconStyle: {
      minWidth: '35px',
      padding: '6px',
    },
    iconGridStyle: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    wordBreakStyle: {
      wordBreak: 'keep-all',
    },
    shortDescriptionField: {
      paddingTop: `${theme.spacing(3.5)} !important`,
    },
    assignedStyle: {
      display: 'inline-block',
    },
    containerDivThreeFields: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      paddingLeft: theme.spacing(2),
      '& div:nth-child(2)': {
        paddingLeft: theme.spacing(0.5),
      },
    },
    containerDivThreeFieldsChildDivPadding: {
      '@media(max-width:600px)': {
        '& div:nth-child(2)': {
          paddingLeft: theme.spacing(4),
        },
      },
    },
    showingInfo: {
      borderColor: COLOR_DEFAULT_GREY,
      '& .MuiAlert-icon > svg': {
        fill: COLOR_DEFAULT_GREY,
      },
      marginTop: theme.spacing(1),
    },
    iconTimer: {
      padding: theme.spacing(0),
    },
    assetTag: {
      wordWrap: 'break-word',
    },
  }),
)

const WorkOrderDetails = () => {
  const navigate = useNavigate()
  const auth = useAuth()
  const userId = auth.session.userInfo?.lanId?.toUpperCase()
  const [error, setError] = useState(false)
  const [partsIssuedError, setPartsIssuedError] = useState(false)
  const [statusUpdateError, setUpdateStatusError] = useState(false)
  const [shortDescriptionError, setShortDescriptionError] = useState(false)
  const [shortDescription, setShortDescription] = useState('')
  const woDetailFetched = useGetWorkOrderDetails()
  const myRef = useRef(null)
  const [shortDescriptionCount, setShortDescriptionCount] = useState(0)
  const [isShortDescriptionBlank, setIsShortDescriptionBlank] = useState(false)
  const [partsIssued, setPartsIssued] = useState('')

  const {
    wodetails,
    surveyAnswers = null,
    isDCUser,
    areaOrLocation,
    storeId,
  } = useSelector((state) => ({
    ...state.workOrderDetailsReducer,
    ...state.surveyAnswersReducer,
    ...state.userInfoReducer,
    ...state.storeInfoReducer,
  }))

  const isInternal = Number?.(wodetails?.external) === 0
  const urlSearchParams = getURLSearchParams()
  const woNum = parseInt(urlSearchParams.get('wonum'))
  const loadOtherDataBasedOnWoDetails = woNum === parseInt(wodetails?.wonum)
  const dispatch = useDispatch()
  const woOriginPersonList = wodetails?.woassigncode
  const assetNum = wodetails?.woassetnum
  const woPersonList =
    woOriginPersonList &&
    woOriginPersonList !== null &&
    woOriginPersonList.split('-')
  const personListIncludesUserId = woPersonList && woPersonList.includes(userId)
  const timelineData =
    isDCUser && isInternal
      ? DC_WO_TIMELINE_ARRAY.map((val) => ({
          ...val,
          value: convertTimestampToLocal(wodetails?.[val.key]) || NOT_AVAILABLE,
        }))
      : WO_TIMELINE_ARRAY.map((val) => ({
          ...val,
          value: convertTimestampToLocal(wodetails?.[val.key]) || NOT_AVAILABLE,
        })).filter((val) =>
          isInternal && val.key === 'arrive_by' ? false : true,
        )

  const smallScreen = useMediaQuery('(max-width:600px)')

  const statusChoices = [
    'APPR',
    'SCHD',
    'WAITSCH',
    'WMATL',
    'WAITOPS',
    'INPRG',
    'WAPPR',
    'CANCEL',
    ...(isInternal ? ['COMP'] : []),
  ]

  useEffect(() => {
    try {
      if (wodetails && loadOtherDataBasedOnWoDetails) {
        setShortDescriptionCount(wodetails?.wodesc?.length)
      }
    } catch (error) {
      setShortDescriptionCount(0)
      setError(true)
    }
  }, [wodetails])

  const calculateTotalIssuedParts = (issuedParts) => {
    const sum = issuedParts?.reduce(
      (a, o) => a + parseInt(o.quantity.replace('-', '')),
      0,
    )
    setPartsIssued(sum)
  }

  useEffect(() => {
    const getPartsIssued = async () => {
      try {
        setError(false)
        dispatch(setShouldRender(true, 'Loading...', 'Parts Issued'))
        const response = await fetchData(
          `/mam/itemsissued/siteid/${storeId}/wonum/${woNum}`,
        )
        calculateTotalIssuedParts(response?.itemsissueddata)
      } catch (error) {
        setPartsIssuedError(true)
      } finally {
        dispatch(setShouldRender(false))
      }
    }
    getPartsIssued()
  }, [])

  const classes = useStyles()
  const loggedTimeData = wodetails && wodetails.ltreghrs

  let loggedTime = null
  if (loggedTimeData || loggedTimeData === 0) {
    loggedTime = setTimeString(formattedTime(loggedTimeData))
  }

  const commonAlertProps = {
    severity: 'info',
    variant: 'outlined',
  }
  const commonGridChildPropsDescription = {
    item: true,
    xs: 7,
    sm: 8,
  }
  const commonGridChildPropsLabel = {
    item: true,
    xs: 5,
    sm: 4,
  }

  const saveDescription = async (field, value) => {
    let descriptionData = null

    if (field === 'shortDes' && shortDescription !== wodetails.wodesc) {
      descriptionData = {
        'short-description': shortDescription.trim(),
      }
    }

    if (field === 'longDes' && value !== wodetails.wolongdesc) {
      descriptionData = {
        'long-description': value.trim(),
      }
    }

    if (descriptionData) {
      try {
        dispatch(setShouldRender(true, 'Saving Work Order Details...'))
        await putData(
          `/maximo/workorder/${woNum}`,
          JSON.stringify(descriptionData),
        )
        const response = await fetchData(`/mam/workorder/wonum/${woNum}`)
        dispatch(setWorkOrder(response.woDetails[0]))
        setShortDescriptionError(false)
        field !== 'longDes' && setShortDescription('')
      } catch (error) {
        setShortDescriptionError(true)
      } finally {
        dispatch(setShouldRender(false))
      }
    }
  }

  const handleShortDescriptionChange = (input) => {
    setShortDescription(input)
    setShortDescriptionCount(input.length)
    setIsShortDescriptionBlank(!/\S/.test(input))
  }

  const lineBreak = (textToBreak) => {
    const html = textToBreak
      ? DOMPurify.sanitize(textToBreak.replace(/_/g, '_<wbr/>'))
      : ''
    return {
      __html: html,
    }
  }

  const getStatusDisabled = (status) => {
    return statusChoices.includes(status) ? false : true
  }

  const getStatusList = (status) => {
    return statusChoices.includes(status) ? statusChoices : [status]
  }
  const handleStatusChange = async (status) => {
    if (status === 'CANCEL') {
      return setURLSearchParams([{ name: 'cancel_wo_open', value: true }])
    } else if (status === 'COMP') {
      navigate(
        `${globalRoutes.getAssetDowntimeRoute(woNum, assetNum)}&complete=true`,
      )
    } else {
      setUpdateStatusError(false)
      try {
        dispatch(setShouldRender(true, 'Saving Status Change...'))
        const requestBody = {
          status: status,
          'change-by': userId,
          'workorder-id': wodetails.workorderid,
          wonum: woNum,
        }

        await putData(
          '/maximo/workorderstatusupdate',
          JSON.stringify(requestBody),
        )
        const response = await fetchData(`/mam/workorder/wonum/${woNum}`)
        const workorder = response.woDetails[0]
        dispatch(setWorkOrder(workorder))
      } catch (error) {
        setUpdateStatusError(true)
        window.scroll({ top: 0, behavior: 'smooth' })
      } finally {
        dispatch(setShouldRender(false))
      }
    }
  }

  function statusField() {
    const status = wodetails?.wostatus
    const showMarkCompleteButton =
      isInternal &&
      !isDCUser &&
      personListIncludesUserId &&
      ((wodetails?.classstructureid && surveyAnswers?.completedflag) ||
        !wodetails?.classstructureid)
    return (
      <div
        className={clsx(
          classes.containerDivThreeFields,
          showMarkCompleteButton
            ? classes.containerDivThreeFieldsChildDivPadding
            : undefined,
        )}
      >
        <Grid item xs={showMarkCompleteButton ? 3 : 5} sm={4}>
          <Typography>Status:</Typography>
        </Grid>
        <Grid
          item
          xs={showMarkCompleteButton ? 4 : isDCUser ? 8 : 5}
          sm={showMarkCompleteButton ? 3 : 8}
          md={showMarkCompleteButton ? 4 : 8}
        >
          {!isDCUser ? (
            <Typography component="span">{status}</Typography>
          ) : (
            <FormControl fullWidth>
              <InputLabel>Status</InputLabel>
              <Select
                value={status}
                label="Status"
                disabled={getStatusDisabled(status)}
                onChange={(e) => handleStatusChange(e.target.value)}
                data-testid="status-dropdown-parent"
                inputProps={{ 'data-testid': 'status-dropdown' }}
              >
                {getStatusList(status).map((status, index) => {
                  return (
                    <MenuItem
                      key={index}
                      value={status}
                      data-testid={`status-option-${index}`}
                    >
                      {status}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          )}
        </Grid>
        {showMarkCompleteButton && (
          <Grid item xs={5} md={4} className={classes.iconGridStyle}>
            <Button
              startIcon={<CheckCircleIcon />}
              onClick={() =>
                navigate(`${globalRoutes.completeWorkOrder}#?wonum=${woNum}`)
              }
              size={smallScreen ? 'small' : undefined}
            >
              <span
                data-testid="mark-complete-button-text"
                className={classes.wordBreakStyle}
              >
                {smallScreen ? COMPLETE : 'Mark Complete'}
              </span>
            </Button>
          </Grid>
        )}
      </div>
    )
  }

  function workValidationField() {
    return (
      <div className={clsx(classes.containerDivThreeFields)}>
        <Grid item xs={6} sm={4}>
          <Typography component="span">Work Validation:</Typography>
        </Grid>
        <Grid item xs={isInternal ? 6 : 3} sm={isInternal ? 8 : 3} md={4}>
          <Typography>{wodetails.wovendworkval}</Typography>
        </Grid>
        {!isInternal && (
          <Grid item xs={3} md={4} sm={5} className={classes.iconGridStyle}>
            <Button
              startIcon={<CheckCircleIcon />}
              onClick={() =>
                navigate(`${globalRoutes.validateWorkOrder}#?wonum=${woNum}`)
              }
              size={smallScreen ? 'small' : undefined}
            >
              <span
                data-testid="mark-complete-button-text"
                className={classes.wordBreakStyle}
              >
                {smallScreen ? VALIDATE : VALIDATE_WORK_ORDER}
              </span>
            </Button>
          </Grid>
        )}
      </div>
    )
  }

  const shortDescriptionHelperText = (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          {`${shortDescriptionCount}/${SHORT_DESCRIPTION_CHARACTER_LIMIT}`}
          <div>
            {`${
              isShortDescriptionBlank
                ? 'Short Description cannot be blank.'
                : ''
            }`}
          </div>
          <div>
            {`${
              shortDescriptionCount > SHORT_DESCRIPTION_CHARACTER_LIMIT
                ? 'Short Description must not exceed 100 characters.'
                : ''
            }
         `}
          </div>
        </Grid>
        <Grid item xs={6} justify="flex-end">
          <Stack direction="row" justifyContent="end">
            <Button
              startIcon={<SaveSharpIcon />}
              onClick={() => saveDescription('shortDes')}
              aria-label="Save Short Description"
              disabled={
                !shortDescription ||
                shortDescription.trim().length === 0 ||
                shortDescription.trim() === wodetails.wodesc ||
                shortDescriptionCount > SHORT_DESCRIPTION_CHARACTER_LIMIT
              }
            >
              Save
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </>
  )

  return woDetailFetched === true ? (
    <>
      <Container className={classes.container} ref={myRef}>
        <Grid container spacing={2}>
          {shortDescriptionError && (
            <Grid item xs={12}>
              <Alert severity="error">
                There was an error saving the description.
              </Alert>
            </Grid>
          )}
          {statusUpdateError && (
            <Grid item xs={12}>
              <Alert severity="error">
                There was an error updating the status.
              </Alert>
            </Grid>
          )}
          {partsIssuedError && (
            <Grid item xs={12}>
              <Alert severity="error">
                There was an error updating parts issued.
              </Alert>
            </Grid>
          )}
          <Grid item xs={4}>
            <Typography>Time Logged:</Typography>
          </Grid>
          <Grid item xs={4} sm={6}>
            <Typography>{loggedTime ?? '0 Hrs 0 Mins'}</Typography>
          </Grid>
          <Grid item xs={4} sm={2} className={classes.iconGridStyle}>
            <Button
              startIcon={<AddCircleIcon />}
              className={classes.addTimeButtonNoWrap}
              onClick={() =>
                setURLSearchParams([{ name: 'add_time_open', value: true }])
              }
            >
              Add Time
            </Button>
          </Grid>
          <Grid item xs={12} className={classes.shortDescriptionField}>
            <TextField
              label="Short Description"
              variant="outlined"
              id="short-description-textfield"
              multiline
              fullWidth
              rows={2}
              defaultValue={wodetails.wodesc || ''}
              error={
                shortDescriptionCount > SHORT_DESCRIPTION_CHARACTER_LIMIT ||
                shortDescriptionCount === 0 ||
                isShortDescriptionBlank
              }
              helperText={
                shortDescriptionHelperText ? shortDescriptionHelperText : ' '
              }
              FormHelperTextProps={{
                component: 'div',
              }}
              onChange={(e) => handleShortDescriptionChange(e.target.value)}
              data-testid="short-description-textfield"
              inputProps={{ 'data-testid': 'short-description' }}
            />
          </Grid>
          <Grid item xs={12}>
            <DynamicTextField
              text={wodetails?.wolongdesc ?? ''}
              myRef={myRef}
              label="Long Description"
              save={(field, value) => saveDescription(field, value)}
              buttonAriaLabel="Save Long Description"
              id="long-description-textfield"
              shouldScrollTo
            />
          </Grid>
          <WorkOrderTimeLine flexValue={0.2} data={timelineData} />
          {statusField()}
          {workValidationField()}
          <Grid {...commonGridChildPropsLabel}>
            <Typography>{WO_TYPE}: </Typography>
          </Grid>
          <Grid {...commonGridChildPropsDescription}>
            <Typography>{wodetails.wotype}</Typography>
          </Grid>
          {wodetails.woassetname === null &&
          assetNum === null &&
          wodetails.assetdesc === null ? null : (
            <>
              <Grid {...commonGridChildPropsLabel}>
                <Typography>Asset: </Typography>
              </Grid>
              <Grid {...commonGridChildPropsDescription}>
                <Typography
                  dangerouslySetInnerHTML={lineBreak(wodetails.woassetname)}
                />
              </Grid>
              <Grid {...commonGridChildPropsLabel}>
                <Typography>Asset Number: </Typography>
              </Grid>
              <Grid {...commonGridChildPropsDescription}>
                <Typography>{assetNum}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography className={classes.textFieldLabel}>
                  Asset Description
                </Typography>
                <Alert className={classes.showingInfo} {...commonAlertProps}>
                  {wodetails.assetdesc || ''}
                </Alert>
              </Grid>
              {isDCUser && (
                <>
                  <Grid item xs={4}>
                    <Typography>Asset Tag: </Typography>
                  </Grid>
                  <Grid item xs={4} sm={6}>
                    <Typography className={classes.assetTag}>
                      {wodetails.assettag}{' '}
                    </Typography>
                  </Grid>
                  <Grid item xs={4} sm={2}>
                    <Button
                      startIcon={<TimerIcon />}
                      className={classes.iconTimer}
                      onClick={() =>
                        navigate(
                          `${globalRoutes.getAssetDowntimeRoute(
                            woNum,
                            assetNum,
                          )}`,
                        )
                      }
                    >
                      Downtime
                    </Button>
                  </Grid>
                </>
              )}
            </>
          )}
          <Grid {...commonGridChildPropsLabel}>
            <Typography>{`${areaOrLocation}:`}</Typography>
          </Grid>
          <Grid {...commonGridChildPropsDescription}>
            <Typography
              dangerouslySetInnerHTML={lineBreak(wodetails.wolocation)}
            />
          </Grid>
          {isDCUser && (
            <>
              <Grid {...commonGridChildPropsLabel}>
                <Typography>Parts Issued: </Typography>
              </Grid>
              <Grid {...commonGridChildPropsDescription}>
                <Typography>{partsIssued}</Typography>
              </Grid>
              <Grid {...commonGridChildPropsLabel}>
                <Typography>Calc Priority: </Typography>
              </Grid>
              <Grid {...commonGridChildPropsDescription}>
                <Typography
                  dangerouslySetInnerHTML={lineBreak(wodetails.calcpriority)}
                />
              </Grid>
            </>
          )}
          <Grid {...commonGridChildPropsLabel}>
            <Typography>Problem: </Typography>
          </Grid>
          <Grid {...commonGridChildPropsDescription}>
            <Typography
              dangerouslySetInnerHTML={lineBreak(wodetails.woproblemcode)}
            />
          </Grid>
          <Grid {...commonGridChildPropsLabel}>
            <Typography>Job Plan: </Typography>
          </Grid>
          <Grid {...commonGridChildPropsDescription}>
            <Typography dangerouslySetInnerHTML={lineBreak(wodetails.jpnum)} />
          </Grid>
          <Grid {...commonGridChildPropsLabel}>
            <Typography>Assigned To: </Typography>
          </Grid>
          <Grid {...commonGridChildPropsDescription}>
            <Typography className={classes.assignedStyle}>
              {!isInternal ? wodetails.name : wodetails.woassigncraft}
            </Typography>
            <VendorContacts param={VENDOR_CONTACT_OPEN_WOD} />
          </Grid>
          {!isInternal && (
            <>
              <Grid {...commonGridChildPropsLabel}>
                <Typography>Not to Exceed: </Typography>
              </Grid>
              <Grid {...commonGridChildPropsDescription}>
                <Typography>{wodetails.wonte}</Typography>
              </Grid>
            </>
          )}
        </Grid>
      </Container>
      <CancelDialog />
    </>
  ) : (
    error && <Alert severity="error">{DEFAULT_ERROR_MESSAGE}</Alert>
  )
}

export default WorkOrderDetails
