/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setShouldRender } from '../ProgressOverlay/store/actionCreator'
import {
  Container,
  Button,
  Stack,
  TextField,
  Card,
  CardContent,
  Radio,
  RadioGroup,
  FormControlLabel,
  Typography,
  MenuItem,
  Alert,
} from '@mui/material'
import { makeStyles, createStyles } from '@mui/styles'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import {
  LocalizationProvider,
  TimePicker,
  DesktopDatePicker,
} from '@mui/x-date-pickers'
import { fetchData, postData, putData } from '../../service/HttpService'
import { useAuth } from '@praxis/component-auth'
import moment from 'moment'
import clsx from 'clsx'
import {
  MONTH_DAY_YEAR_FORMAT,
  HOUR_MINUTE_PERIOD_FORMAT,
} from '../../globalConstants'
import useGetWorkOrderDetails from '../../utils/hooks/useGetWorkOrderDetails'
import { setLoadedWonum, setSurveyAnswers } from './store/actionCreator'
import useAppBarHeight from '../../utils/hooks/useGetAppbarHeight'

const useStyles = makeStyles((theme) =>
  createStyles({
    stickyHeader: {
      zIndex: 1,
      position: 'sticky',
      padding: theme.spacing(2, 0),
      top: ({ stickyDivHeight }) => stickyDivHeight,
      background: theme.palette.common.white,
      '@media (orientation: landscape)': {
        [theme.breakpoints.down(1281)]: {
          top: ({ appbarheight }) => appbarheight,
        },
      },
    },
    finishButton: {
      marginLeft: theme.spacing(3),
    },
    cardStyle: {
      padding: theme.spacing(3, 0),
      margin: theme.spacing(2, 0),
    },
    highlightCard: {
      padding: theme.spacing(3, 0),
      margin: theme.spacing(2),
      background: '#FFF8DC',
    },
    questionsStyle: {
      marginBottom: theme.spacing(2),
    },
    questionContainer: {
      padding: theme.spacing(0),
    },
  }),
)

const WorkOrderDetailsSurvey = () => {
  const woDetailFetched = useGetWorkOrderDetails()
  const {
    stickyDivHeight = 0,
    wodetails = null,
    surveyAnswers,
    surveyLoading,
  } = useSelector((state) => ({
    ...state.workOrderDetailsReducer,
    ...state.surveyAnswersReducer,
  }))
  const [answers, setAnswers] = useState({})
  const classStructureId = wodetails?.classstructureid
  const auth = useAuth()
  const userId = auth.session.userInfo?.lanId
  const woNum = wodetails?.wonum
  const [alertError, setAlertError] = useState(false)
  const [questionArray, setQuestionArray] = useState([])
  const [isFinished, setIsFinished] = useState(false)
  const dispatch = useDispatch()
  const appbarheight = useAppBarHeight()
  const classes = useStyles({ appbarheight, stickyDivHeight })
  const [validateAnswers, setValidateAnswers] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const isInternal = Number(wodetails?.external) === 0
  const stickyHeader = () => {
    return (
      <Stack
        direction="row"
        justifyContent="end"
        className={classes.stickyHeader}
      >
        {!isFinished && (
          <Button
            aria-label="Clear"
            variant="outlined"
            onClick={() => clearAnswers()}
          >
            Clear
          </Button>
        )}
        <Button
          aria-label="Finish"
          variant="contained"
          className={classes.finishButton}
          onClick={() => handleFinishClick()}
        >
          {isFinished ? 'Save' : 'Finish'}
        </Button>
      </Stack>
    )
  }

  const submitAnswers = async () => {
    try {
      dispatch(setShouldRender(true, 'Submitting answers...'))
      const answerBodyArray = Object.keys(answers)
        .map((key) => {
          return {
            assetattrid: key,
            alnvalue:
              answers[key].type && answers[key].type === 'DATE'
                ? answers[key].value.format(MONTH_DAY_YEAR_FORMAT)
                : answers[key].type && answers[key].type === 'TIME'
                  ? answers[key].value.format(HOUR_MINUTE_PERIOD_FORMAT)
                  : answers[key].value,
          }
        })
        .sort((a, b) => {
          return a.assetattrid.localeCompare(b.assetattrid) < 0
        })
      const updateSpecificationsBody = {
        user: userId,
        wonum: woNum.toString(),
        answers: answerBodyArray,
      }
      const specificationsBody = {
        wonum: woNum.toString(),
        completedflag: true,
        answers: JSON.stringify(answerBodyArray),
        userid: userId,
      }
      await postData('/maximo/updateSpecifications/', updateSpecificationsBody)
      isFinished
        ? await putData('/mam/specifications', specificationsBody)
        : await postData('/mam/specifications', specificationsBody)
      const newAnswers = await fetchData(
        `/mam/specifications/wonum/${woNum}/user/${userId}?`,
        null,
      )
      dispatch(setSurveyAnswers(newAnswers))
      dispatch(setLoadedWonum(woNum))
      setIsFinished(true)
    } catch (err) {
      setAlertError(true)
      setErrorMessage('Error submitting answers')
    } finally {
      dispatch(setShouldRender(false))
    }
  }

  const checkForEmptyResponse = (finishButtonClick) => {
    let isFinished = true
    questionArray.forEach((question) => {
      if (!answers[question.assetattrid].value) {
        isFinished = false
        finishButtonClick && setAlertError(true)
        finishButtonClick &&
          setErrorMessage(
            'Please complete highlighted questions before submitting the survey',
          )
        return
      }
    })
    return isFinished
  }

  useEffect(() => {
    if (checkForEmptyResponse()) {
      setErrorMessage(false)
      setAlertError(false)
    }
  }, [answers])

  const handleFinishClick = () => {
    setValidateAnswers(false)
    setAlertError(false)
    if (checkForEmptyResponse(true)) {
      submitAnswers()
    } else {
      setValidateAnswers(true)
    }
  }

  const clearAnswers = () => {
    const clearedAnswers = { ...answers }
    for (let i = 0; i < questionArray.length; i++) {
      const question = questionArray[i]
      clearedAnswers[question.assetattrid] = {
        type: question.type,
        value:
          question.type === 'DATE' || question.type === 'TIME' ? moment() : '',
      }
    }
    setAnswers(clearedAnswers)
  }

  const onChange = (id, data) => {
    setAnswers((prevState) => ({ ...prevState, [id]: data }))
  }

  const renderDateFields = (data) => {
    return (
      <>
        <Typography className={classes.questionsStyle}>
          {data.question}
        </Typography>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DesktopDatePicker
            inputFormat={MONTH_DAY_YEAR_FORMAT}
            value={answers[data.assetattrid].value || ''}
            onChange={(newValue) =>
              onChange(data.assetattrid, { type: data.type, value: newValue })
            }
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      </>
    )
  }

  const renderTimeFields = (data) => {
    return (
      <>
        <Typography className={classes.questionsStyle}>
          {data.question}
        </Typography>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <TimePicker
            id={data.assetattrid}
            onChange={(newValue) =>
              onChange(data.assetattrid, { type: data.type, value: newValue })
            }
            renderInput={(params) => <TextField {...params} />}
            value={answers[data.assetattrid].value || ''}
          />
        </LocalizationProvider>
      </>
    )
  }

  const renderDecimalFields = (data) => {
    return (
      <>
        <Typography className={classes.questionsStyle}>
          {data.question}
        </Typography>
        <TextField
          id={data.assetattrid}
          type="number"
          inputProps={{ step: '0.1' }}
          value={answers[data.assetattrid].value}
          onChange={(event) =>
            onChange(data.assetattrid, {
              type: data.type,
              value: event.target.value,
            })
          }
        />
      </>
    )
  }

  const renderDropDownFields = (data) => {
    return (
      <>
        <Typography className={classes.questionsStyle}>
          {data.question}
        </Typography>
        <TextField
          id={data.assetattrid}
          select
          sx={{ m: 1, width: '30ch' }}
          value={answers[data.assetattrid].value}
          onChange={(event) =>
            onChange(data.assetattrid, {
              type: data.type,
              value: event.target.value,
            })
          }
        >
          {data.domain.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.value}
            </MenuItem>
          ))}
        </TextField>
      </>
    )
  }

  const renderTextFields = (data) => {
    return (
      <>
        <Typography className={classes.questionsStyle}>
          {data.question}
        </Typography>
        <TextField
          data-testid="text-field"
          id={data.assetattrid}
          onChange={(event) =>
            onChange(data.assetattrid, {
              type: data.type,
              value: event.target.value,
            })
          }
          value={answers[data.assetattrid].value}
          fullWidth
          rows={4}
          multiline
        />
      </>
    )
  }
  const renderRadioButtonFields = (data) => {
    return (
      <>
        <Typography className={classes.questionsStyle} id={data.assetattrid}>
          {data.question}
        </Typography>
        <RadioGroup
          aria-labelledby={data.assetattrid}
          name="radio-buttons-group"
          value={answers[data.assetattrid].value || ''}
          onChange={(event) =>
            onChange(data.assetattrid, {
              type: data.type,
              value: event.target.value,
            })
          }
        >
          {data.domain.map((option) => (
            <FormControlLabel
              key={option.value}
              value={option.value}
              control={<Radio />}
              label={option.value}
            />
          ))}
        </RadioGroup>
      </>
    )
  }

  const setSurveyData = (surveyData) => {
    if (surveyAnswers && JSON.stringify(surveyAnswers) !== '{}') {
      setIsFinished(true)
      const answerArray = JSON.parse(surveyAnswers.answers || [])
      for (let i = 0; i < surveyData.getQuestionList.length; i++) {
        const question = surveyData.getQuestionList[i]
        const answer = answerArray[i].alnvalue || ''
        answer &&
          setAnswers((prevState) => ({
            ...prevState,
            [question.assetattrid]:
              question.type === 'DATE'
                ? {
                    type: question.type,
                    value: moment(answer, MONTH_DAY_YEAR_FORMAT),
                  }
                : question.type === 'TIME'
                  ? {
                      type: question.type,
                      value: moment(answer, HOUR_MINUTE_PERIOD_FORMAT),
                    }
                  : { type: question.type, value: answer },
          }))
      }
    } else {
      for (let i = 0; i < surveyData.getQuestionList.length; i++) {
        const question = surveyData.getQuestionList[i]
        setAnswers((prevState) => ({
          ...prevState,
          [question.assetattrid]: {
            type: question.type,
            value:
              question.type === 'DATE' || question.type === 'TIME'
                ? moment()
                : '',
          },
        }))
      }
    }
    setQuestionArray(surveyData.getQuestionList)
    dispatch(setShouldRender(false))
  }

  useEffect(() => {
    if (!surveyLoading && classStructureId && isInternal) {
      try {
        dispatch(setShouldRender(true, 'Loading survey questions...'))
        const getSpecifications = async () => {
          const surveyData = await fetchData(
            `/maximo/specifications/class_structure_id/${classStructureId}`,
          )
          setSurveyData(surveyData.data)
        }
        getSpecifications()
      } catch {
        setAlertError(true)
        setErrorMessage('Error submitting answers')
      } finally {
        dispatch(setShouldRender(false))
      }
    }
  }, [surveyLoading, classStructureId])

  return (
    <>
      {surveyLoading === false &&
      woDetailFetched === true &&
      (!classStructureId || questionArray.length === 0) ? (
        <Alert severity="warning">No specifications found</Alert>
      ) : surveyLoading === false &&
        woDetailFetched === true &&
        classStructureId ? (
        <>
          <Container>
            {stickyHeader()}
            {isFinished && (
              <Alert severity="success" sx={{ width: '100%' }}>
                Survey has been submitted
              </Alert>
            )}
            {alertError && <Alert severity="error">{errorMessage}</Alert>}
            {questionArray.map((data, index) => (
              <Container
                key={index}
                data-testid="question-container"
                className={classes.questionContainer}
              >
                {data.type === 'DATE' && (
                  <>
                    <Card
                      className={clsx(
                        !answers[data.assetattrid].value && validateAnswers
                          ? classes.highlightCard
                          : classes.cardStyle,
                      )}
                    >
                      <CardContent>{renderDateFields(data)}</CardContent>
                    </Card>
                  </>
                )}
                {data.type === 'TIME' && (
                  <>
                    <Card
                      className={clsx(
                        !answers[data.assetattrid].value && validateAnswers
                          ? classes.highlightCard
                          : classes.cardStyle,
                      )}
                    >
                      <CardContent>{renderTimeFields(data)}</CardContent>
                    </Card>
                  </>
                )}
                {data.type === 'DECIMAL' && (
                  <>
                    <Card
                      className={clsx(
                        !answers[data.assetattrid].value && validateAnswers
                          ? classes.highlightCard
                          : classes.cardStyle,
                      )}
                    >
                      <CardContent>{renderDecimalFields(data)}</CardContent>
                    </Card>
                  </>
                )}
                {data.type === 'DROPDOWN' && (
                  <>
                    <Card
                      className={clsx(
                        !answers[data.assetattrid].value && validateAnswers
                          ? classes.highlightCard
                          : classes.cardStyle,
                      )}
                    >
                      <CardContent>
                        {renderDropDownFields(data, index)}
                      </CardContent>
                    </Card>
                  </>
                )}
                {data.type === 'TEXT' && (
                  <>
                    <Card
                      className={clsx(
                        !answers[data.assetattrid].value && validateAnswers
                          ? classes.highlightCard
                          : classes.cardStyle,
                      )}
                    >
                      <CardContent>{renderTextFields(data, index)}</CardContent>
                    </Card>
                  </>
                )}
                {data.type === null && (
                  <>
                    <Card
                      className={clsx(
                        !answers[data.assetattrid].value && validateAnswers
                          ? classes.highlightCard
                          : classes.cardStyle,
                      )}
                    >
                      <CardContent>{renderRadioButtonFields(data)}</CardContent>
                    </Card>
                  </>
                )}
              </Container>
            ))}
          </Container>
        </>
      ) : null}
    </>
  )
}

export default WorkOrderDetailsSurvey
