/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import { InputAdornment, OutlinedInput, Grid } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import CloseIcon from '@mui/icons-material/Close'
import { SEARCH } from '../../globalConstants'
import { makeStyles, createStyles } from '@mui/styles'
import { getURLSearchParams, deleteURLSearchParams } from '../../windowManager'
const useStyles = makeStyles((theme) =>
  createStyles({
    grid: {
      padding: theme.spacing(1, 2, 2, 2),
    },
    textfield: {
      background: theme.palette.common.white,
      borderRadius: theme.spacing(1),
      height: theme.spacing(5),
    },
    closeIcon: {
      cursor: 'pointer',
    },
  }),
)
export default function SearchBar({ onSearch, searchKey }) {
  const classes = useStyles()
  // get the value of the url search key , so that it can be set as the search bar input value
  const urlSearchValue = getURLSearchParams().get(searchKey)
  const [input, setInput] = useState('')

  useEffect(() => {
    // Delete the url search param key if the value is empty string('') , else set the value as search bar input. The null check is to make sure that the url search param key exists
    if (urlSearchValue !== null) {
      if (urlSearchValue === '') {
        deleteURLSearchParams([{ name: searchKey }], true)
      } else {
        setInput(urlSearchValue)
      }
    }
  }, [urlSearchValue])

  return (
    <Grid className={classes.grid}>
      <OutlinedInput
        fullWidth
        className={classes.textfield}
        placeholder={SEARCH}
        startAdornment={
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        }
        value={input}
        endAdornment={
          input && (
            <InputAdornment position="end">
              <CloseIcon
                className={classes.closeIcon}
                data-testid="search-bar-close-icon"
                onClick={() => {
                  setInput('')
                  onSearch('')
                }}
              />
            </InputAdornment>
          )
        }
        onChange={(e) => {
          const { value } = e.target
          const onlySpacesInInput = value?.length ? /^\s*$/.test(value) : false
          setInput(value)
          // use the trimmed searchbar input value to search . Do not trigger the search if only spaces are entered
          !onlySpacesInInput && value?.length !== 1 && onSearch(value.trim())
        }}
      />
    </Grid>
  )
}
