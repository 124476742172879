/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react'
import {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Table,
  Alert,
} from '@mui/material'
import { makeStyles, createStyles } from '@mui/styles'
import { connect } from 'react-redux'
import { setWorkOrder } from '../CreateWorkOrder/actionCreator'
import { useSelector } from 'react-redux'

const AssetDetail = ({
  asset,
  setWorkOrder,
  createWorkOrderData,
  selectedAsset,
}) => {
  const useStyles = makeStyles((theme) =>
    createStyles({
      table: {
        marginTop: theme.spacing(2),
        '& .MuiTableCell-root': {
          width: '50%',
        },
      },
    }),
  )

  const classes = useStyles()
  const { isDCUser } = useSelector((state) => ({
    ...state.userInfoReducer,
  }))

  const commonTableProps = {
    size: 'small',
    className: classes.table,
  }
  useEffect(() => {
    // reset Problem properties when navigating back to asset details
    asset &&
      setWorkOrder({
        ...createWorkOrderData,
        problem: '',
        problemLocation: '',
        failureCode: '',
        asset: asset.woassetnum,
        assetDescription: asset.assetdescription,
        areaOfStore: selectedAsset.assetLocation,
      })
  }, [asset])

  return asset ? (
    <>
      <Table {...commonTableProps}>
        <TableHead>
          <TableRow>
            <TableCell colSpan={2}>
              <strong>Details</strong>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>{asset.assetdescription}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Number</TableCell>
            <TableCell>{asset.woassetnum}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Description</TableCell>
            <TableCell>{asset.attributedescription}</TableCell>
          </TableRow>
          {isDCUser && (
            <TableRow>
              <TableCell>Tag</TableCell>
              <TableCell>{asset.assettag}</TableCell>
            </TableRow>
          )}
          <TableRow>
            <TableCell>Priority</TableCell>
            <TableCell>{asset.assetpriority}</TableCell>
          </TableRow>
        </TableBody>
      </Table>

      <Table {...commonTableProps}>
        <TableHead>
          <TableRow>
            <TableCell colSpan={2}>
              <strong>Specifications</strong>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>Make</TableCell>
            <TableCell>{asset.assetmake}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Model</TableCell>
            <TableCell>{asset.assetmodel}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Serial Number</TableCell>
            <TableCell>{asset.assetserial}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Install Date</TableCell>
            <TableCell>{asset.installdate}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </>
  ) : (
    <Alert severity="warning">No information found for this asset.</Alert>
  )
}

const mapStateToProps = (state) => {
  return {
    asset: state.assetReducer.asset,
    createWorkOrderData: state.createWorkOrderReducer.createWorkOrderData,
    selectedAsset: state.assetSelectReducer.selectedAsset,
  }
}

const mapDispatchToProps = {
  setWorkOrder,
}

export default connect(mapStateToProps, mapDispatchToProps)(AssetDetail)
