import IconButton from '@mui/material/IconButton'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { makeStyles, createStyles } from '@mui/styles'

const useStyles = makeStyles((theme) =>
  createStyles({
    backButton: {
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(-1.5),
    },
  }),
)

const BackButton = () => {
  const classes = useStyles()
  const handleArrowBackClick = () => {
    window.history.back()
  }

  return (
    <IconButton
      onClick={handleArrowBackClick}
      className={classes.backButton}
      color="inherit"
    >
      <ArrowBackIcon />
    </IconButton>
  )
}

export default BackButton
