import React, { useState, useRef } from 'react'
import {
  Grid,
  Typography,
  Button,
  Snackbar,
  Container,
  Alert as MuiAlert,
} from '@mui/material'
import { makeStyles, createStyles } from '@mui/styles'
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto'
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate'
import { getURLSearchParams } from '../../windowManager'
import WorkOrderlanding from '../WorkOrderLanding/WorkOrderLanding'
import {
  WORK_ORDER_NUMBER_FIELD,
  WORK_ORDER_ID_FIELD,
  HOW_TO_UPLOAD_IMAGES,
  UPLOAD_IMAGES,
  SELECT_IMAGES,
  UPLOAD_IMAGE_COUNT_WARNING,
  UPLOAD_IMAGE_TYPE_WARNING,
  api,
  LOADING_MESSAGE,
  UPLOADED_SUCCESSFULLY_MESSAGE,
  UPLOADED_ERROR_MESSAGE,
  RETRY_UPLOAD,
} from '../../globalConstants'
import WorkOrderImageList from '../WorkOrderImageList/WorkOrderImageList'
import { validateImgFile } from '../../utils'
import { postDataParallelWithCb } from '../../service/HttpService'
import { useAuth } from '@praxis/component-auth'
import useGetWorkOrderDetails from '../../utils/hooks/useGetWorkOrderDetails'
import imageCompression from 'browser-image-compression'

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      padding: theme.spacing(1, 0, 0, 2),
    },
    iconFontSize: {
      fontSize: '2rem',
    },
    uploadButtonDiv: {
      marginLeft: 'auto',
      display: 'flex',
      gap: theme.spacing(1),
    },
    photoUploadInput: {
      display: 'none',
    },
  }),
)

export default function UploadPhoto() {
  const classes = useStyles()
  const auth = useAuth()
  const woDetailFetched = useGetWorkOrderDetails()
  const [fileCountError, setFileCountError] = useState(false)
  const [fileTypeError, setFileTypeError] = useState(false)
  const [uploadedSnackbar, setUploadedSnackbar] = useState(false)
  const [errorSnackbar, setErrorSnackbar] = useState(false)
  const [imagesToUpload, setImagesToUpload] = useState([])
  const woNumber = getURLSearchParams().get(WORK_ORDER_NUMBER_FIELD)
  const woId = getURLSearchParams().get(WORK_ORDER_ID_FIELD)
  const inputFile = useRef(null)
  const userId = auth.session.userInfo?.lanId
  const [uploaded, setUploaded] = useState(false)
  const hasUploadErrorImages = imagesToUpload?.filter(
    (val) => val.loading === 'error',
  )?.length
  const totalImages = imagesToUpload?.length
  function onUploadPhotoClick() {
    inputFile.current.click()
  }

  function handleFileUpload(e) {
    const files = e?.target?.files ?? []
    const fileLength = files?.length
    // throw file count error if the number of images selected is more than 5 (it can also be the sum of already seleted images and the current selected images)
    if (
      fileLength > 5 ||
      (uploaded !== 'loaded' && imagesToUpload.length + fileLength > 5)
    ) {
      setFileCountError(true)
    } else {
      if (!validateImgFile(files)) {
        setFileTypeError(true)
        return
      }
      // Clear any uploaded images , if exists
      if (imagesToUpload?.length > 0 && fileLength > 0) {
        if (uploaded === 'loaded') {
          setImagesToUpload([])
          setUploaded(false)
        } else {
          if (uploaded === 'error') {
            setImagesToUpload(
              imagesToUpload.filter((val) => val.loading !== 'loaded'),
            )
            setUploaded(false)
          }
        }
      }
      const imgArray = Array.from(files).map((file) => ({
        fileData: file,
        imgUrl: URL.createObjectURL(file),
        title: file.name,
        loading: false,
        description: file.name.replace(/\.[^/.]+$/, ''),
      }))
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      }
      imgArray.forEach(async (file, index) => {
        try {
          const compressedFile = await imageCompression(file.fileData, options)
          getImgSrc(compressedFile, index, imgArray)
        } catch (error) {
          setErrorSnackbar(true)
        }
      })
    }
  }

  async function uploadImagesToServer() {
    setUploaded('start')
    try {
      const imgUploadDataArray = imagesToUpload
        .filter((val) => val.loading !== 'loaded') // filter out already uploaded files - during error condition
        .map((img, idx) => {
          return {
            url: api.uploadImage,
            body: JSON.stringify({
              woid: woId,
              attachment: img.src,
              name: img.title,
              description: img.description,
              userid: userId,
            }),
          }
        })
      setImagesToUpload(
        imagesToUpload
          .filter((val) => val.loading !== 'loaded') // clear already uploaded files - during error condition
          .map((val) => ({
            ...val,
            loading: true,
          })),
      )
      await postDataParallelWithCb(
        imgUploadDataArray,
        (uploadedIndex, error) => {
          if (error) {
            setImagesToUpload((imagesToUpload) =>
              imagesToUpload.map((val, idx) => ({
                ...val,
                loading: uploadedIndex === idx ? 'error' : val.loading,
              })),
            )
            throw Error()
          } else {
            setImagesToUpload((imagesToUpload) =>
              imagesToUpload.map((val, idx) => ({
                ...val,
                loading: uploadedIndex === idx ? 'loaded' : val.loading,
              })),
            )
          }
        },
      )
      setUploaded('loaded')
      setUploadedSnackbar(true)
    } catch (e) {
      setUploaded('error')
      setErrorSnackbar(true)
    }
  }

  function getImgSrc(Blob, index, imgArray) {
    const reader = new FileReader()
    reader.onloadend = () => {
      // Use a regex to remove data url part
      const base64String = reader.result.replace(
        /^data:image\/[a-z]+;base64,/,
        '',
      )
      imgArray[index].src = base64String
      // set the state when the last element in array is set
      if (index === imgArray.length - 1) {
        setImagesToUpload((imagesToUpload) => imagesToUpload?.concat(imgArray))
      }
    }
    reader.readAsDataURL(Blob)
  }

  const closeFn = (event, reason, setOpen) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
  }

  function renderSnackbar(
    open,
    setOpen,
    message,
    dontHide,
    noAction,
    severity,
  ) {
    return (
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={open}
        autoHideDuration={dontHide ? null : 3000}
        onClose={(e, reason) => closeFn(e, reason, setOpen)}
      >
        <Alert
          onClose={(e, reason) => closeFn(e, reason, setOpen)}
          severity={severity || 'warning'}
          sx={{ width: '100%' }}
          data-testid="file-upload-alert"
          action={noAction ? '' : undefined}
        >
          {message}
        </Alert>
      </Snackbar>
    )
  }

  function getImageUploadStatus() {
    const imageUploadedCount = hasUploadErrorImages
      ? `${totalImages - hasUploadErrorImages}/${totalImages}`
      : totalImages

    return uploaded === 'loaded' || uploaded === 'error' ? (
      <Grid item xs={12} md={12}>
        <Typography
          variant="h6"
          data-testid="subheader"
          className={classes.typography}
        >
          {`${imageUploadedCount} Image${
            totalImages > 1 ? 's' : ''
          } uploaded successfully`}
        </Typography>
      </Grid>
    ) : null
  }

  return woDetailFetched === true ? (
    <>
      <WorkOrderlanding />
      <Container>
        <Grid container rowSpacing={1} className={classes.container}>
          <Grid container columnSpacing={2} rowSpacing={1} alignItems="center">
            <Grid item>
              <AddPhotoAlternateIcon className={classes.addFirstIcon} />
            </Grid>
            <Grid item>
              <Typography variant="body1" component="span">
                {HOW_TO_UPLOAD_IMAGES}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" align="justify">
              {`Please click the Select Images button to add images to Work Order `}
              <strong>{`${woNumber}`}</strong>
              {`. You may select up to `}
              <strong>5</strong>
              {` images at a time, but you can select more after uploading. Please remain on the page while the images are uploading.`}
            </Typography>
          </Grid>
          <Grid item className={classes.uploadButtonDiv}>
            <>
              {((imagesToUpload?.length > 0 && uploaded === 'loaded') ||
                ((uploaded === false || uploaded === 'error') &&
                  imagesToUpload?.length < 5)) && (
                <Button
                  startIcon={<AddAPhotoIcon className={classes.iconFontSize} />}
                  onClick={onUploadPhotoClick}
                  data-testid="select-image-button"
                >
                  {SELECT_IMAGES}
                </Button>
              )}
              {imagesToUpload?.length > 0 &&
                uploaded !== 'start' &&
                uploaded !== 'loaded' && (
                  <Button
                    variant="contained"
                    onClick={uploadImagesToServer}
                    data-testid="upload-image-button"
                  >
                    {hasUploadErrorImages ? RETRY_UPLOAD : UPLOAD_IMAGES}
                  </Button>
                )}
            </>
          </Grid>
          {getImageUploadStatus()}
          {imagesToUpload && (
            <Grid item xs={12}>
              <WorkOrderImageList
                images={imagesToUpload}
                from={UPLOAD_IMAGES}
                canDelete
                onDelete={(deleteIndex) => {
                  setImagesToUpload(
                    imagesToUpload.filter(
                      (val, index) => index !== deleteIndex,
                    ),
                  )
                }}
                onDescriptionChange={(newDescription, descUpdateIndex) => {
                  setImagesToUpload(
                    imagesToUpload.map((val, index) => ({
                      ...val,
                      description:
                        index === descUpdateIndex
                          ? newDescription
                          : val.description,
                    })),
                  )
                }}
              />
            </Grid>
          )}
        </Grid>
      </Container>
      <input
        type="file"
        accept="image/png, image/jpg, image/jpeg"
        id="image-upload"
        data-testid="image-upload-input"
        ref={inputFile}
        className={classes.photoUploadInput}
        multiple
        onChange={handleFileUpload}
      />
      {renderSnackbar(
        fileCountError,
        setFileCountError,
        UPLOAD_IMAGE_COUNT_WARNING,
      )}
      {renderSnackbar(
        fileTypeError,
        setFileTypeError,
        UPLOAD_IMAGE_TYPE_WARNING,
      )}
      {renderSnackbar(
        uploaded === 'start',
        () => {},
        LOADING_MESSAGE,
        true,
        true,
      )}
      {renderSnackbar(
        uploadedSnackbar,
        setUploadedSnackbar,
        UPLOADED_SUCCESSFULLY_MESSAGE,
        '',
        '',
        'success',
      )}
      {renderSnackbar(
        errorSnackbar,
        setErrorSnackbar,
        UPLOADED_ERROR_MESSAGE,
        '',
        '',
        'error',
      )}
    </>
  ) : null
}
