import { ADMIN_GROUP, DC_GROUP } from '../../globalPermissions'

export const getUserRoles = (adgroups) => {
  //leaving in although similar to get DC user.  In the future we coul have more than 1 role here
  const groups = []
  const isAdmin =
    adgroups.filter((element) => ADMIN_GROUP.includes(element)).length > 0
  const isDCUser =
    adgroups.filter((element) => DC_GROUP.includes(element)).length > 0

  isDCUser && !isAdmin && groups.push('DCUser')
  return groups
}

export const getIsDCUser = (adgroups) => {
  const isAdmin =
    adgroups.filter((element) => ADMIN_GROUP.includes(element)).length > 0
  const isDCUser =
    adgroups.filter((element) => DC_GROUP.includes(element)).length > 0
  return Boolean(isDCUser && !isAdmin)
}

export const getAreaOrLocation = (adgroups) => {
  return adgroups.filter((element) => DC_GROUP.includes(element)).length > 0
    ? 'Location'
    : 'Area'
}
