import { SET_VENDOR_CONTACTS, SET_SAVED_VENDOR } from './actionType'

export function setVendorContacts(technicianData) {
  return {
    type: SET_VENDOR_CONTACTS,
    technicianData: technicianData,
  }
}

export function setSavedVendor(vendor) {
  return {
    type: SET_SAVED_VENDOR,
    vendor: vendor,
  }
}
