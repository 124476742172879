/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import MatAppBar from '../MatAppBar/MatAppBar'
import { Grid, Button } from '@mui/material'
import { getURLSearchParams } from '../../windowManager'
import { setAsset, setAssetAttributes } from './actionCreator'
import { fetchData } from '../../service/HttpService'
import { Link } from 'react-router-dom'
import { CONTINUE, globalRoutes } from '../../globalConstants'
import { makeStyles, createStyles } from '@mui/styles'
import { setShouldRender } from '../ProgressOverlay/store/actionCreator'
import { Alert } from '@mui/material'
import {
  FOLLOW_UP_WO_URL_PARAM,
  WORK_ORDER_NUMBER_FIELD,
} from '../../globalConstants'
import { WOATabs as Tabs, WOATab as Tab } from '../common/WOATabs'

const useStyles = makeStyles((theme) =>
  createStyles({
    grid: {
      padding: theme.spacing(1),
    },
  }),
)

const AssetInformation = ({
  storeId,
  setAsset,
  setShouldRender,
  setAssetAttributes,
}) => {
  const classes = useStyles()
  const urlSearchParams = getURLSearchParams()
  const isFollowUpWo = urlSearchParams.get(FOLLOW_UP_WO_URL_PARAM)
  const woNumFromUrl = parseInt(urlSearchParams.get(WORK_ORDER_NUMBER_FIELD))
  const followUpWoParams = isFollowUpWo
    ? `&${WORK_ORDER_NUMBER_FIELD}=${woNumFromUrl}&${FOLLOW_UP_WO_URL_PARAM}=true`
    : ''
  const assetId = urlSearchParams.get('asset_id')
  const assetTabIndex = parseInt(urlSearchParams.get('asset_tab_index')) || 0
  const assetRouteFromHome = urlSearchParams.get('asset-list-route')
  const [selectedTab, setSelectedTab] = useState(assetTabIndex)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  let detailsLink = `/asset-information#?asset_id=${assetId}${followUpWoParams}`
  let historyLink = `/asset-information/history#?asset_id=${assetId}&asset_tab_index=1${followUpWoParams}`
  let specificationsLink = `/asset-information/specifications#?asset_id=${assetId}&asset_tab_index=2${followUpWoParams}`
  const routeFromHome = `&asset-list-route=${assetRouteFromHome}`

  useEffect(() => {
    try {
      setShouldRender(true, 'Loading Asset Info...')
      setLoading(true)
      setError(false)
      const getAssetData = async () => {
        const response = await fetchData(
          `/mam/asset/siteid/${storeId}/assetnum/${assetId}`,
        )
        setAsset(response.assetsdata[0])
        setAssetAttributes(response.assetsdata)
      }
      getAssetData()
    } catch (error) {
      setError(true)
    } finally {
      setShouldRender(false)
      setLoading(false)
    }
  }, [])

  const commonTabProps = {
    replace: true,
    component: Link,
  }

  if (assetRouteFromHome) {
    detailsLink = `${detailsLink}${routeFromHome}`
    historyLink = `${historyLink}${routeFromHome}`
    specificationsLink = `${specificationsLink}${routeFromHome}`
  }

  return (
    <>
      <MatAppBar
        arrowBack
        text="Asset Information"
        renderFollowUpInfoIfExists
        createWorkOrderFlow={!assetRouteFromHome}
      />
      {error && !loading && (
        <Alert severity="error">Unable to connect to Asset service.</Alert>
      )}
      <Grid
        className={classes.grid}
        container
        justifyContent="flex-end"
        alignItems="center"
      >
        {!assetRouteFromHome && (
          <Button
            variant="contained"
            component={Link}
            to={`${globalRoutes.problems}#?asset_id=${assetId}`}
          >
            {CONTINUE}
          </Button>
        )}
      </Grid>
      <Tabs
        variant="fullWidth"
        value={selectedTab}
        onChange={(event, value) => setSelectedTab(value)}
        centered
      >
        {/* components shown by tab controlled by routing need to add params to route with tab index*/}
        <Tab {...commonTabProps} label="Details" to={detailsLink}></Tab>
        <Tab {...commonTabProps} label="History" to={historyLink}></Tab>
        <Tab
          {...commonTabProps}
          label="Specifications"
          to={specificationsLink}
        ></Tab>
      </Tabs>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    storeId: state.storeInfoReducer.storeId,
  }
}
const mapDispatchToProps = {
  setAsset,
  setShouldRender,
  setAssetAttributes,
}

export default connect(mapStateToProps, mapDispatchToProps)(AssetInformation)
