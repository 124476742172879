import React from 'react'
import { makeStyles, createStyles } from '@mui/styles'
import { useMediaQuery, IconButton } from '@mui/material'
import Timeline from '@mui/lab/Timeline'
import TimelineItem from '@mui/lab/TimelineItem'
import TimelineSeparator from '@mui/lab/TimelineSeparator'
import TimelineConnector from '@mui/lab/TimelineConnector'
import TimelineContent from '@mui/lab/TimelineContent'
import TimelineDot from '@mui/lab/TimelineDot'
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent'
import {
  COLOR_THEME_ACCENT,
  COLOR_BACKGROUND_GREY,
  COLOR_FONT_BLUE,
} from '../../globalColors'
import MapIcon from '@mui/icons-material/Map'
import { GEOFENCE } from '../../globalConstants'

const useStyles = makeStyles((theme) =>
  createStyles({
    timelineRoot: {
      width: '100%',
      padding: theme.spacing(1, 0),
      margin: theme.spacing(0, 'auto'),
    },
    timelineRootPopper: {
      background: COLOR_BACKGROUND_GREY,
      padding: 0,
      margin: 0,
    },
    icon: {
      padding: 0,
      margin: theme.spacing(0, 0, 0, 0.5),
      color: COLOR_FONT_BLUE,
    },
    header: {
      minHeight: '42px',
    },
  }),
)

// flexValue prop - this can be used to left align the timeline component . It is a prop so that the value can be increased or decreased based on the length of the timneline data
export default function WorkOrderTimeLine({
  data,
  flexValue = 1,
  header,
  popper,
  mapUrl,
  from,
}) {
  const classes = useStyles()
  const smallScreen = useMediaQuery('(max-width:600px)')
  const isVisitTimeline = from === 'VisitTimeline' ? true : false
  const timelineIcon = (val) => {
    return val?.icon ? (
      <TimelineDot sx={{ background: COLOR_THEME_ACCENT }}>
        <val.icon
          sx={isVisitTimeline ? { height: '16px', width: '16px' } : {}}
        />
      </TimelineDot>
    ) : (
      <TimelineDot color="info" />
    )
  }
  return (
    <Timeline
      data-testid="timeline-component"
      align="left"
      className={popper ? classes.timelineRootPopper : classes.timelineRoot}
    >
      {header && (
        <TimelineItem className={classes.header}>
          {header.map((val, index) => {
            return index === 0 ? (
              <TimelineOppositeContent
                sx={{
                  flex: smallScreen ? 1 : flexValue,
                  paddingLeft: 0,
                }}
                key={index}
                variant={isVisitTimeline ? 'body2' : 'body1'}
                style={{ textAlign: 'left' }}
              >
                {val}
              </TimelineOppositeContent>
            ) : (
              <React.Fragment key={index}>
                <TimelineSeparator></TimelineSeparator>
                <TimelineContent
                  variant={isVisitTimeline ? 'body2' : 'body1'}
                  style={
                    val === GEOFENCE
                      ? { textAlign: 'right', paddingRight: 0 }
                      : {}
                  }
                >
                  {val}
                  {val === GEOFENCE && (
                    <IconButton
                      className={classes.icon}
                      onClick={() => window.open(mapUrl)}
                    >
                      <MapIcon />
                    </IconButton>
                  )}
                </TimelineContent>
              </React.Fragment>
            )
          })}
        </TimelineItem>
      )}
      {data.map((val, idx) => (
        <TimelineItem
          data-testid={`timeline-item-${idx}`}
          key={val?.label}
          xs={isVisitTimeline ? { textAlign: 'right' } : {}}
        >
          <TimelineOppositeContent
            sx={{
              flex: smallScreen ? 1 : flexValue,
              paddingLeft: 0,
              textAlign: isVisitTimeline ? 'left' : 'right',
            }}
            variant={isVisitTimeline ? 'body2' : 'body1'}
          >
            {val.label}
          </TimelineOppositeContent>
          {Array.isArray(val.value) ? (
            val.value.map((innerval, index) => {
              return index === 0 ? (
                <React.Fragment key={index}>
                  <TimelineSeparator>
                    {timelineIcon(val)}
                    {idx !== data.length - 1 && <TimelineConnector />}
                  </TimelineSeparator>
                  <TimelineContent
                    variant={isVisitTimeline ? 'body2' : 'body1'}
                  >
                    {innerval}
                  </TimelineContent>
                </React.Fragment>
              ) : (
                //this is visit timeline right content specific
                <React.Fragment key={index}>
                  <TimelineSeparator></TimelineSeparator>
                  <TimelineContent
                    variant="body2"
                    style={{ textAlign: 'right' }}
                  >
                    {innerval}
                  </TimelineContent>
                </React.Fragment>
              )
            })
          ) : (
            <>
              <TimelineSeparator>
                {timelineIcon(val)}
                {idx !== data.length - 1 && <TimelineConnector />}
              </TimelineSeparator>
              <TimelineContent>{val.value}</TimelineContent>
            </>
          )}
        </TimelineItem>
      ))}
    </Timeline>
  )
}
