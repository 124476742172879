/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { getURLSearchParams } from '../../windowManager'
import {
  globalRoutes,
  STORE_REQUEST_ID_FIELD,
  FOLLOW_UP_WO_SESSION_STORAGE_KEY,
  FOLLOW_UP_WO_SR_TEXT,
  FOLLOW_UP_SR_URL_PARAM,
  STORE_REQUEST_DETAILS,
  SR_DETAILS_ERROR,
} from '../../globalConstants'
import { setFollowUpFlow } from '../CreateWorkOrder/actionCreator'
import moment from 'moment'
import { setShouldRender } from '../ProgressOverlay/store/actionCreator'
import { setStoreRequestsDetails } from '../StoreRequestDetails/store/actionCreator'
import { Alert, CircularProgress, Box } from '@mui/material'
import { fetchData } from '../../service/HttpService'
import { makeStyles, createStyles } from '@mui/styles'

export default function FollowUpWoForStoreRequests(renderFollowUpInfoIfExists) {
  const navigate = useNavigate()
  const urlLocation = useLocation()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const routePathname = urlLocation?.pathname
  const routeHash = urlLocation?.hash
  const searchParams = getURLSearchParams()
  const isFollowUpSr = searchParams.get(FOLLOW_UP_SR_URL_PARAM)
  const ticketIdFromUrl = searchParams.get(STORE_REQUEST_ID_FIELD)
  const { followUpFlow, srDetails, storeId } = useSelector((state) => ({
    ...state.createWorkOrderReducer,
    ...state.storeRequestDetailsReducer,
    ...state.storeInfoReducer,
  }))
  const propertiesFromSessionStorage =
    JSON?.parse?.(
      sessionStorage?.getItem?.(FOLLOW_UP_WO_SESSION_STORAGE_KEY) ?? '{}',
    ) ?? {}
  const {
    description = '',
    ticketid,
    reportedby,
    status,
    siteid,
    ticketuid,
    ticketclass,
    reportdate,
    statusdate,
    from,
  } = propertiesFromSessionStorage ?? {}
  const fromSRDetails = from === STORE_REQUEST_DETAILS
  const sessionStorageExists =
    Object.keys(propertiesFromSessionStorage).length !== 0
  const sessionStorageCorrectSr =
    sessionStorageExists && ticketid === ticketIdFromUrl ? true : false
  const shouldCallSrDetails = !sessionStorageCorrectSr && isFollowUpSr
  const useStyles = makeStyles((theme) =>
    createStyles({
      showingInfo: {
        margin: theme.spacing(1),
      },
    }),
  )
  const classes = useStyles()

  // this useEffect is to set the follow up WO URL for all pages in create Work order flow
  useEffect(() => {
    if (
      renderFollowUpInfoIfExists &&
      followUpFlow &&
      !isFollowUpSr &&
      !ticketIdFromUrl &&
      fromSRDetails
    ) {
      navigate({
        replace: true,
        pathname: routePathname,
        hash: `${routeHash}${routeHash ? '&' : '?'}${STORE_REQUEST_ID_FIELD}=${
          ticketid ?? ''
        }&${FOLLOW_UP_SR_URL_PARAM}=true`,
      })
    }
  }, [followUpFlow])

  async function loadSrDetails() {
    try {
      setLoading(true)
      dispatch(setStoreRequestsDetails(null))
      dispatch(setShouldRender(true, 'Loading Store Request Details...'))
      const response = await fetchData(
        globalRoutes.storeRequestDetails(storeId, ticketIdFromUrl),
      )
      const srDetails = response[0]
      dispatch(setStoreRequestsDetails(srDetails))
      const ticketid = srDetails['dcterms:ticketid']
      const description = srDetails['dcterms:description']
      const reportedby = srDetails['dcterms:reportedby']
      const status = srDetails['dcterms:status']
      const siteid = srDetails['dcterms:siteid']
      const ticketuid = srDetails['dcterms:ticketuid']
      const ticketclass = srDetails['dcterms:class']

      const dateFormat = 'MM/DD/YYYY'
      const reportdate = moment(srDetails['dcterms:reportdate']).format(
        dateFormat,
      )
      const statusdate = moment(srDetails['dcterms:statusdate']).format(
        dateFormat,
      )
      dispatch(
        setFollowUpFlow(true, {
          ticketid,
          description,
          reportedby,
          status,
          siteid,
          ticketuid,
          ticketclass,
          reportdate,
          statusdate,
          from: STORE_REQUEST_DETAILS,
        }),
      )
    } catch (error) {
      setError(true)
    } finally {
      // do not setshouldrender to false as there is a delay in the follow up wo flow
      urlLocation.pathname !== globalRoutes.duplicateWorkOrderList &&
        dispatch(setShouldRender(false))
      setLoading(false)
    }
  }

  useEffect(() => {
    if (shouldCallSrDetails) {
      loadSrDetails()
    }
  }, [shouldCallSrDetails])

  // this useEffect is for the case when the page wih follow up work order is reloaded - this specifically handles flow from Store request details
  useEffect(() => {
    if (
      renderFollowUpInfoIfExists &&
      sessionStorageCorrectSr &&
      fromSRDetails
    ) {
      dispatch(
        setFollowUpFlow(true, {
          ticketid,
          description,
          reportedby,
          status,
          siteid,
          ticketuid,
          ticketclass,
          reportdate,
          statusdate,
          from: STORE_REQUEST_DETAILS,
        }),
      )
    }
  }, [srDetails, sessionStorageCorrectSr])

  return (
    <>
      {loading && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <CircularProgress size="2rem" />
        </Box>
      )}
      {ticketIdFromUrl && isFollowUpSr && followUpFlow && fromSRDetails ? (
        <Alert
          severity="info"
          variant="outlined"
          className={classes.showingInfo}
        >
          {FOLLOW_UP_WO_SR_TEXT(description)}
        </Alert>
      ) : null}
      {error && !loading && <Alert severity="error">{SR_DETAILS_ERROR}</Alert>}
    </>
  )
}
