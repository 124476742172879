/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react'
import BigButtons from './BigButtons'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import MatAppBar from '../MatAppBar/MatAppBar'
import { resetWorkOrder } from '../CreateWorkOrder/actionCreator'
import { setSelectedArea } from '../AreaOfStore/actionCreator'
import { setSelectedAsset } from '../AssetsSelect/AssetsSelectStore/actionCreator'
import { setAsset } from '../AssetInformation/actionCreator'

const StartCreateWorkOrder = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(resetWorkOrder())
    dispatch(setSelectedArea({}))
    dispatch(setSelectedAsset({}))
    dispatch(setAsset({}))
  }, [])

  useParams()
  return (
    <>
      <MatAppBar renderFollowUpInfoIfExists createWorkOrderFlow />
      <BigButtons />
    </>
  )
}

export default StartCreateWorkOrder
