import { TextField, Table, TableBody, TableRow, TableCell } from '@mui/material'
import { makeStyles, createStyles } from '@mui/styles'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import IconButton from '@mui/material/IconButton'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'
import { isHoursInvalid, isMinutesInvalid } from '../../utils'
import { NumericFormat } from 'react-number-format'

const useStyles = makeStyles((theme) =>
  createStyles({
    timeTable: {
      '& .MuiTableCell-root': {
        borderWidth: 0,
        padding: theme.spacing(0.4),
      },
    },
  }),
)

const commonProps = {
  fullWidth: true,
  type: 'number',
  size: 'small',
}

const PlannedTime = ({ hours, minutes, onHoursChange, onMinutesChange }) => {
  const classes = useStyles()
  const MAX_HOURS = 99
  const MAX_MINUTES = 59
  const MINUTES_INTERVAL = 5
  const MAX_MINUTES_INTERVAL = 55

  return (
    <Table className={classes.timeTable}>
      <TableBody>
        <TableRow>
          <TableCell align="center">
            <IconButton
              color="primary"
              onClick={() =>
                onHoursChange(hours < MAX_HOURS ? Number(hours) + 1 : MAX_HOURS)
              }
            >
              <AddCircleIcon data-testid="increase-hours" fontSize="large" />
            </IconButton>
          </TableCell>
          <TableCell align="center">
            <IconButton
              color="primary"
              onClick={() => {
                onMinutesChange(
                  minutes < MAX_MINUTES_INTERVAL
                    ? Number(minutes) + MINUTES_INTERVAL
                    : MAX_MINUTES,
                )
              }}
            >
              <AddCircleIcon data-testid="increase-minutes" fontSize="large" />
            </IconButton>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <NumericFormat
              {...commonProps}
              customInput={TextField}
              allowNegative={false}
              inputProps={{ 'data-testid': 'hours-field' }}
              label="Hours"
              value={parseInt(hours)}
              error={isHoursInvalid(hours)}
              onChange={(event) => {
                const formattedValue = event.target.value.replace(/^0+/, '')
                onHoursChange(formattedValue === '' ? 0 : formattedValue)
              }}
            />
          </TableCell>
          <TableCell>
            <NumericFormat
              {...commonProps}
              customInput={TextField}
              allowNegative={false}
              inputProps={{ 'data-testid': 'minutes-field' }}
              label="Mins"
              value={minutes}
              error={isMinutesInvalid(minutes)}
              onChange={(event) => {
                const formattedValue = event.target.value.replace(/^0+/, '')
                onMinutesChange(formattedValue === '' ? 0 : formattedValue)
              }}
            />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="center">
            <IconButton
              color="primary"
              onClick={() => {
                hours > 0 && onHoursChange(Number(hours) - 1)
              }}
            >
              <RemoveCircleIcon data-testid="decrease-hours" fontSize="large" />
            </IconButton>
          </TableCell>
          <TableCell align="center">
            <IconButton
              color="primary"
              onClick={() => {
                onMinutesChange(
                  minutes > 4 ? Number(minutes) - MINUTES_INTERVAL : 0,
                )
              }}
            >
              <RemoveCircleIcon
                data-testid="decrease-minutes"
                fontSize="large"
              />
            </IconButton>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}

export default PlannedTime
