import { connect } from 'react-redux'
import Table from '@mui/material/Table'
import { makeStyles, createStyles } from '@mui/styles'
import { TableBody, TableCell, TableHead, TableRow, Alert } from '@mui/material'

const AssetSpecifications = ({ assetAttributes }) => {
  const useStyles = makeStyles((theme) =>
    createStyles({
      table: {
        '& .MuiTableCell-root': {
          width: '50%',
        },
      },
      lastColumn: {
        textAlign: 'left',
        [theme.breakpoints.down('sm')]: {
          textAlign: 'right',
        },
      },
    }),
  )

  const classes = useStyles()
  const hasSpecifications =
    assetAttributes && assetAttributes[0].attributedescription

  const commonTableCellProps = {
    className: classes.lastColumn,
  }

  return (
    <>
      {hasSpecifications ? (
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>
                <strong>Attribute</strong>
              </TableCell>
              <TableCell {...commonTableCellProps}>
                <strong>Value</strong>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {assetAttributes.map((attribute, index) => (
              <TableRow key={index}>
                <TableCell>{attribute.attributedescription}</TableCell>
                <TableCell {...commonTableCellProps}>
                  {attribute.value}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        <Alert severity="warning">
          There are no specifications to display for this asset.
        </Alert>
      )}
    </>
  )
}
const mapStateToProps = (state) => {
  return {
    assetAttributes: state.assetReducer.assetAttributes,
  }
}

export default connect(mapStateToProps, null)(AssetSpecifications)
