import moment from 'moment'
import { SET_ALL_CYCLE_COUNT_ITEMS } from './actionType'

export const initialState = {
  allCycleCountItems: {},
  lastUpdated: { allCycleCountItems: null },
}

export default function cycleCountReducer(state = initialState, action = {}) {
  const getDueList = (allItems, imCode, days) => {
    return allItems.filter(
      (a) =>
        a.imcode === imCode &&
        moment()
          .startOf('day')
          .diff(moment(a.physcntdate).startOf('day'), 'days') >= days,
    )
  }

  const getAllItemsDue = (items) => {
    const sevenDays = getDueList(items, 'I', 7)
    const thirtyDays = getDueList(items, 'H', 30)
    const sixtyDays = getDueList(items, 'M', 60)
    const ninetyDays = getDueList(items, 'L', 90)
    return sevenDays.concat(thirtyDays, sixtyDays, ninetyDays).sort(sortByBin)
  }

  const sortByBin = (a, b) =>
    !a.binnum ? 1 : !b.binnum ? -1 : a.binnum.toString().localeCompare(b.binnum)

  switch (action.type) {
    case SET_ALL_CYCLE_COUNT_ITEMS: {
      const allItems = action.allCycleCountItems.sort(sortByBin)

      const sortedObject = {
        allItems: allItems,
        allItemsDue: getAllItemsDue(allItems),
        sevenDays: getDueList(allItems, 'I', 7),
        thirtyDays: getDueList(allItems, 'H', 30),
        sixtyDays: getDueList(allItems, 'M', 60),
        ninetyDays: getDueList(allItems, 'L', 90),
      }
      const newState = {
        ...state,
        allCycleCountItems: sortedObject,
        lastUpdated: {
          ...state.lastUpdated,
          allCycleCountItems: moment().format('LLL'),
        },
      }
      return newState
    }
    default:
      return state
  }
}
