import { useState } from 'react'
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { useAuth } from '@praxis/component-auth'
import { setShouldRender } from '../ProgressOverlay/store/actionCreator'
import { putData } from '../../service/HttpService'
import { useNavigate } from 'react-router-dom'
import useHashChange from '../../utils/hooks/useHashChange'
import { createStyles, makeStyles } from '@mui/styles'
import { getURLSearchParams } from '../../windowManager'

const useStyles = makeStyles((theme) =>
  createStyles({
    cancelWODialogAction: {
      display: 'flex',
      justifyContent: 'space-around',
      paddingBottom: '20px',
    },
  }),
)
const CancelDialog = () => {
  const { wodetails = null, isDCUser } = useSelector((state) => ({
    ...state.workOrderDetailsReducer,
    ...state.userInfoReducer,
  }))

  const auth = useAuth()
  const userId = auth.session.userInfo?.lanId?.toUpperCase()
  const dispatch = useDispatch()
  const woId = wodetails?.workorderid
  const urlSearchParams = getURLSearchParams()
  const woNum = parseInt(urlSearchParams.get('wonum'))
  const navigate = useNavigate()
  const [cancelWOOpen, setCancelWOOpen] = useState(false)
  useHashChange('cancel_wo_open', setCancelWOOpen)
  const classes = useStyles()
  const [reasonError, setReasonError] = useState(false)
  const [cancelError, setCancelError] = useState(false)
  const cancelReasons = [
    'AGING',
    'ASSET INCORRECT',
    'DUPLICATE',
    'ERROR',
    'PROBLEM INCORRECT',
    'REQUEST DECLINED',
    'SYSTEM CANCELLED',
    'WORK NOT PERFORMED',
  ]
  const [selectedCancelReason, setSelectedCancelReason] = useState('')

  const cancelWorkOrder = async () => {
    try {
      dispatch(setShouldRender(true, 'Updating Cancel Reason...'))
      const requestBody = {
        'cancel-reason': selectedCancelReason,
      }

      await putData(`/maximo/workorder/${woNum}`, JSON.stringify(requestBody))
    } catch {
      setReasonError(true)
      return
    } finally {
      dispatch(setShouldRender(false))
    }

    try {
      dispatch(setShouldRender(true, 'Cancelling Work Order...'))
      const requestBody = {
        status: isDCUser ? 'CANCEL' : 'CAN',
        'change-by': userId,
        'workorder-id': woId,
        wonum: woNum,
      }

      await putData(
        '/maximo/workorderstatusupdate',
        JSON.stringify(requestBody),
      )
      navigate(`/home`)
    } catch {
      setCancelError(true)
    } finally {
      dispatch(setShouldRender(false))
    }
  }

  return (
    <Dialog open={cancelWOOpen} onClose={() => window.history.back()}>
      <DialogTitle>Select Cancel Reason</DialogTitle>
      <DialogContent>
        {reasonError && (
          <Alert severity="error">Unable to set cancel reason</Alert>
        )}
        {cancelError && (
          <Alert data-testid="cancel-error" severity="error">
            Unable to cancel work order
          </Alert>
        )}
        <FormControl margin="normal" fullWidth>
          <InputLabel>Cancel Reason</InputLabel>
          <Select
            value={selectedCancelReason}
            label="Cancel Reason"
            onChange={(e) => setSelectedCancelReason(e.target.value)}
            data-testid="cancel-reason-dropdown"
          >
            {cancelReasons.map((reason, index) => {
              return (
                <MenuItem key={index} value={reason}>
                  {reason}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions className={classes.cancelWODialogAction}>
        <Button variant="outlined" onClick={() => window.history.back()}>
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={cancelWorkOrder}
          disabled={selectedCancelReason === ''}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CancelDialog
