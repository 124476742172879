import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import WarningIcon from '@mui/icons-material/Warning'
import { useSelector } from 'react-redux'
import { COLOR_TARGET_RED, COLOR_WARNING_YELLOW } from '../../globalColors'
import { NOT_AVAILABLE } from '../../globalConstants'

const WorkOrderItem = ({ label, value, hours }) => {
  const betweenOneAndTwoDays = hours > 24 && hours <= 72 && value
  const lessThanOneDay = hours <= 24 && value
  const { isDCUser } = useSelector((state) => ({
    ...state.userInfoReducer,
  }))
  const workOrderValue = value ? value : NOT_AVAILABLE
  return (
    <Grid container alignItems="flex-end">
      <Grid item xs={5.5}>
        <Typography noWrap>
          <strong>{label}:</strong>
          {(lessThanOneDay || betweenOneAndTwoDays) && !isDCUser && (
            <WarningIcon
              fontSize="small"
              sx={{
                color: lessThanOneDay ? COLOR_TARGET_RED : COLOR_WARNING_YELLOW,
                verticalAlign: 'text-top',
              }}
            />
          )}
        </Typography>
      </Grid>
      <Grid item xs={6.5}>
        <Typography noWrap data-testid="wo-value">
          {workOrderValue}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default WorkOrderItem
