/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import { CardActionArea, Stack } from '@mui/material'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { makeStyles, createStyles } from '@mui/styles'
import ToggleScheduleButton from '../WorkOrderList/ToggleScheduleButton'
import WorkOrderItem from '../WorkOrderItem/WorkOrderItem'
import {
  VENDOR,
  WO_TYPE,
  ASSET_NAME,
  ASSET_TAG,
  ASSET_NUMBER,
  STATUS,
  REDIRECT_REASON,
  PROBLEM,
  REPAIR_BY_COMP_BY,
  JOB_PLAN,
  REG_HOURS,
  ASSIGNED_TO,
  CALCULATED_PRIORITY,
  globalRoutes,
  REPORTED_DATE,
  WORK_VALIDATION,
} from '../../globalConstants'
import { COLOR_TARGET_RED, COLOR_WARNING_YELLOW } from '../../globalColors'
import {
  formattedTime,
  setTimeString,
  convertTimestampToLocal,
} from '../../utils'
import { checkAttributeForNull, setLvm } from '../../utils/hooks/workOrderUtil'
import { getRepairByAlertDate } from '../../utils/hooks/workOrderUtil'
import { useSelector } from 'react-redux'
import { VirtuosoGrid } from 'react-virtuoso'
import styled from '@emotion/styled'

const ItemContainer = styled.div`
  padding: 0.5rem;
  width: 33.3%;
  display: flex;
  flex: none;
  align-content: stretch;
  box-sizing: border-box;

  @media (max-width: 929px) {
    width: 50%;
  }

  @media (max-width: 579px) {
    width: 100%;
  }
`

const StyledCardActionArea = styled(CardActionArea)(
  () => `
    .MuiCardActionArea-focusHighlight {
        background: transparent;
    }
`,
)

const ListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const useStyles = makeStyles((theme) =>
  createStyles({
    cardHeader: {
      background: theme.palette.grey[300],
      padding: theme.spacing(0.25, 1),
      fontWeight: 'bold',
    },
    cardHeaderRed: {
      backgroundColor: COLOR_TARGET_RED,
      color: 'white',
    },
    cardHeaderYellow: {
      backgroundColor: COLOR_WARNING_YELLOW,
    },
    actionIcon: {
      margin: theme.spacing(1, 0.5, 0, 0),
    },
    card: {
      overflow: 'hidden',
      width: '100%',
      border: `1px solid ${theme.palette.grey[300]}`,
    },
    cardContent: {
      padding: theme.spacing(1),
    },
  }),
)

const CommonCardList = (data) => {
  const classes = useStyles()
  const navigate = useNavigate()
  const ref = useRef()
  const {
    cardPreferences = {},
    collapse = false,
    isDCUser,
    areaOrLocation,
  } = useSelector((state) => state.userInfoReducer)

  const [cardHeight, setCardHeight] = useState(0)
  const pageName = window.location.pathname.replace(/\//g, '')
  const pageSavedScroll = `${pageName}-scrollPosition`
  const savedScrollPosition =
    parseInt(sessionStorage.getItem(pageSavedScroll)) || 0

  useEffect(() => {
    const LINE_HEIGHT = 24
    const DEFAULT_NUM_KEYS = isDCUser ? 3 : 4
    let keyCount = DEFAULT_NUM_KEYS
    if (collapse !== 'true' && cardPreferences) {
      // eslint-disable-next-line no-unused-vars
      for (const [key, value] of Object.entries(cardPreferences)) {
        value === true && keyCount++
      }
    }
    setCardHeight(keyCount * LINE_HEIGHT)
  }, [collapse, cardPreferences])

  ref?.current &&
    setTimeout(() => {
      ref.current?.scrollToIndex({
        index: savedScrollPosition,
        align: 'start',
      })
      sessionStorage.removeItem(pageSavedScroll)
    }, 100)

  const getItemContent = (index) => {
    const workorder = data.listData[index]
    const wonum = workorder.wonum
    const repairBy = workorder.repair_by
    const reportDate = workorder.reportdate
    const assetNum = workorder.woassetnum
    const assetTag = workorder.assettag
    const hasAsset = checkAttributeForNull(assetNum)
    const assignedToVendor = checkAttributeForNull(workorder.woassignvendor)
    const isExternal = workorder.external === '1'
    const hours = getRepairByAlertDate(repairBy)
    const onCardClick = () => {
      sessionStorage.setItem(pageSavedScroll, index)
      navigate(`${globalRoutes.workOrderDetails}#?wonum=${wonum}&showBack=true`)
    }
    const changeHeaderColor = (hours, repairBy) => {
      let headerProp = `${classes.cardHeader}`
      if (hours <= 24 && repairBy && !isDCUser) {
        headerProp = `${classes.cardHeaderRed}`
      } else if (hours <= 72 && hours > 24 && repairBy && !isDCUser) {
        headerProp = `${classes.cardHeaderYellow}`
      }
      return headerProp
    }

    return (
      <Card
        elevation={0}
        className={classes.card}
        style={{ height: cardHeight, minHeight: isDCUser ? 192 : 168 }}
      >
        <Stack
          direction="column"
          justifyContent="space-between"
          style={{ height: '100%' }}
        >
          <StyledCardActionArea onClick={onCardClick}>
            <CardHeader
              className={
                classes.cardHeader + ' ' + changeHeaderColor(hours, repairBy)
              }
              action={<ChevronRightIcon className={classes.actionIcon} />}
              title={<strong>WO # {wonum}</strong>}
              titleTypographyProps={{ variant: 'subtitle1' }}
            />
            <Divider />
            <CardContent className={classes.cardContent}>
              <Grid item xs={12}>
                <Typography noWrap>{workorder.wodesc}</Typography>
              </Grid>
              {(!isDCUser || isExternal) && (
                <WorkOrderItem
                  label={REPAIR_BY_COMP_BY}
                  value={convertTimestampToLocal(repairBy)}
                  hours={hours}
                />
              )}
              {isDCUser && !isExternal && (
                <WorkOrderItem
                  label={REPORTED_DATE}
                  value={convertTimestampToLocal(reportDate)}
                  hours={hours}
                />
              )}
              {isDCUser && hasAsset && collapse === 'true' && (
                <WorkOrderItem label={ASSET_TAG} value={assetTag} />
              )}

              {collapse === 'false' && (
                <>
                  {isDCUser && (
                    <WorkOrderItem label={ASSET_TAG} value={assetTag} />
                  )}
                  {isDCUser && cardPreferences.Area && (
                    <WorkOrderItem
                      label={areaOrLocation}
                      value={workorder.wolocation}
                    />
                  )}
                  {isDCUser && hasAsset && cardPreferences.AssetName && (
                    <WorkOrderItem
                      label={ASSET_NAME}
                      value={workorder.woassetname}
                    />
                  )}
                  {isDCUser && hasAsset && cardPreferences.AssetNumber && (
                    <WorkOrderItem label={ASSET_NUMBER} value={assetNum} />
                  )}
                  {cardPreferences.Time && (
                    <WorkOrderItem
                      label={REG_HOURS}
                      value={setTimeString(
                        formattedTime(workorder.ltreghrs) || {},
                      )}
                    />
                  )}
                  {cardPreferences.Type && (
                    <WorkOrderItem label={WO_TYPE} value={workorder.wotype} />
                  )}
                  {!isDCUser && hasAsset && cardPreferences.AssetName && (
                    <WorkOrderItem
                      label={ASSET_NAME}
                      value={workorder.woassetname}
                    />
                  )}
                  {!isDCUser && hasAsset && cardPreferences.AssetNumber && (
                    <WorkOrderItem label={ASSET_NUMBER} value={assetNum} />
                  )}
                  {!isDCUser && cardPreferences.Area && (
                    <WorkOrderItem
                      label={areaOrLocation}
                      value={workorder.wolocation}
                    />
                  )}
                  {cardPreferences.Problem && (
                    <WorkOrderItem
                      label={PROBLEM}
                      value={workorder.woproblemcode}
                    />
                  )}
                  {isDCUser && cardPreferences.CalcPriority && (
                    <WorkOrderItem
                      label={CALCULATED_PRIORITY}
                      value={workorder.calcpriority}
                    />
                  )}
                  {cardPreferences.Status && (
                    <WorkOrderItem label={STATUS} value={workorder.wostatus} />
                  )}
                  {!isDCUser && cardPreferences.RedirectReason && (
                    <WorkOrderItem
                      label={REDIRECT_REASON}
                      value={workorder.redirectreason}
                    />
                  )}
                  {isExternal && cardPreferences.LVM && !isDCUser && (
                    <WorkOrderItem
                      label="LVM"
                      value={setLvm(
                        workorder.tgt_lvm1,
                        workorder.tgt_lvm2,
                        workorder.tgt_lvm3,
                        workorder.tgt_lvm4,
                        workorder.tgt_lvm5,
                      )}
                    />
                  )}
                  {isExternal &&
                    cardPreferences.WorkValidation &&
                    !isDCUser && (
                      <WorkOrderItem
                        label={WORK_VALIDATION}
                        value={
                          workorder.wovendworkval === null
                            ? 'N/A'
                            : workorder.wovendworkval
                        }
                      />
                    )}
                  {cardPreferences.JobPlan && (
                    <WorkOrderItem label={JOB_PLAN} value={workorder.jpnum} />
                  )}

                  {!assignedToVendor && cardPreferences.AssignedToVendor && (
                    <WorkOrderItem
                      label={ASSIGNED_TO}
                      value={workorder.woassigncraft}
                    />
                  )}
                  {assignedToVendor && cardPreferences.AssignedToVendor && (
                    <WorkOrderItem label={VENDOR} value={workorder.name} />
                  )}
                </>
              )}
            </CardContent>
          </StyledCardActionArea>
          <ToggleScheduleButton
            workorder={workorder}
            woLength={data.filteredWorkOrdersLength}
            pageSavedScroll={pageSavedScroll}
            index={index}
          />
        </Stack>
      </Card>
    )
  }

  return (
    <VirtuosoGrid
      ref={ref}
      style={{ height: '100vh' }}
      totalCount={data.listData.length}
      data-testid="common-card-list"
      components={{
        Item: ItemContainer,
        List: ListContainer,
      }}
      itemContent={(index) => getItemContent(index)}
    />
  )
}

const mapStateToProps = (state) => {
  return {
    storeId: state.storeInfoReducer.storeId,
    toggle: state.workOrderListReducer.toggle,
  }
}

export default connect(mapStateToProps, null)(CommonCardList)
