/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, forwardRef, useRef } from 'react'
import { connect } from 'react-redux'
import MatAppBar from '../MatAppBar/MatAppBar'
import { useSelector } from 'react-redux'
import { setAssets, setSelectedAsset } from './AssetsSelectStore/actionCreator'
import { fetchData } from '../../service/HttpService'
import {
  Alert,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  Table,
  TableContainer,
} from '@mui/material'
import { makeStyles, createStyles } from '@mui/styles'
import { setShouldRender } from '../ProgressOverlay/store/actionCreator'
import { useNavigate } from 'react-router-dom'
import { getURLSearchParams } from '../../windowManager'
import useSearch from '../../utils/hooks/useSearch'
import { INPUT_PERFORMANCE_DELAY } from '../../globalConstants'
import { COLOR_GREY_CONTRAST_WEAK } from '../../globalColors'
import { TableVirtuoso } from 'react-virtuoso'

const AssetsSelect = ({
  assets,
  storeId,
  setAssets,
  setShouldRender,
  setSelectedAsset,
}) => {
  const useStyles = makeStyles(() =>
    createStyles({
      tableRow: {
        cursor: 'pointer',
      },
      headerCell: {
        fontWeight: 'bold',
        backgroundColor: COLOR_GREY_CONTRAST_WEAK,
      },
    }),
  )

  const classes = useStyles()
  const navigate = useNavigate()
  const URL_SEARCH_KEY = 'search'
  const [filteredAssets, onSearch] = useSearch(
    URL_SEARCH_KEY,
    assets,
    ['assetName', 'description', 'assetLocation', 'assetNum', 'assetTag'],
    true,
    INPUT_PERFORMANCE_DELAY,
  )
  const { areaOrLocation, isDCUser, shouldRender } = useSelector((state) => ({
    ...state.userInfoReducer,
    ...state.progressOverlayReducer,
  }))
  const urlSearchParams = getURLSearchParams()

  const columns = [
    {
      name: 'Name',
      selector: (row) => row.assetName,
    },
    {
      name: 'Description',
      selector: (row) => row.description,
    },
    {
      name: areaOrLocation,
      selector: (row) => row.assetLocation,
    },
    {
      name: 'Number',
      selector: (row) => row.assetNum,
    },
  ]

  const DCColumns = [
    {
      name: 'Name',
      selector: (row) => row.assetName,
    },
    {
      name: 'Asset Tag',
      selector: (row) => row.assetTag,
    },
    {
      name: 'Number',
      selector: (row) => row.assetNum,
    },
  ]

  const DCOrNormal = isDCUser ? DCColumns : columns
  const assetSelectFromhome = urlSearchParams.get('asset-list-route')
  const [error, setError] = useState(false)

  const handleRowClick = (asset) => {
    setSelectedAsset(asset)
    const baseURL = `/asset-information#?asset_id=${asset.assetNum}`
    const assetInfoURL = assetSelectFromhome
      ? `${baseURL}&asset-list-route=${assetSelectFromhome}`
      : baseURL
    navigate(assetInfoURL)
  }

  const [height, setHeight] = useState(0)
  const appBarRef = useRef(null)
  const PADDING_OFFSET = 8
  const PADDING_OFFSET_DOUBLE = PADDING_OFFSET * 2

  useEffect(() => {
    const getAssetData = async () => {
      try {
        setShouldRender(true, 'Loading Assets...')
        const response = await fetchData(`/mam/assets/siteid/${storeId}`)
        setAssets(response.assetsdata)
      } catch (error) {
        setError(true)
      } finally {
        setShouldRender(false)
      }
    }
    const assetStorage = JSON.parse(localStorage.getItem('assetList'))
    assetStorage ? setAssets(assetStorage) : getAssetData()
    const handleResize = () => {
      setHeight(
        window.innerHeight -
          (appBarRef.current.offsetHeight + PADDING_OFFSET_DOUBLE),
      )
    }
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const VirtuosoTableComponents = {
    Scroller: forwardRef((props, ref) => (
      <TableContainer {...props} ref={ref} />
    )),
    Table: (props) => (
      <Table
        {...props}
        sx={{ borderCollapse: 'separate', tableLayout: 'fixed' }}
      />
    ),
    TableHead,
    TableRow: ({ item: _item, ...props }) => (
      <TableRow
        {...props}
        hover
        className={classes.tableRow}
        onClick={() => handleRowClick(_item)}
      />
    ),
    TableBody: forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
  }

  const fixedHeaderContent = () => {
    return (
      <TableRow>
        {DCOrNormal.map((column) => (
          <TableCell
            className={classes.headerCell}
            key={column.name}
            variant="head"
          >
            {column.name}
          </TableCell>
        ))}
      </TableRow>
    )
  }

  const rowContent = (_index, row) => {
    return (
      <>
        {DCOrNormal.map((column) => (
          <TableCell
            key={column.name}
            style={{
              padding: PADDING_OFFSET,
              wordWrap: 'break-word',
            }}
          >
            {column.selector(row)}
          </TableCell>
        ))}
      </>
    )
  }
  return (
    <>
      <div ref={appBarRef}>
        <MatAppBar
          arrowBack
          text="Assets"
          search={{
            onSearch: (input) => onSearch(input),
            searchKey: URL_SEARCH_KEY,
          }}
          renderFollowUpInfoIfExists
          createWorkOrderFlow={!assetSelectFromhome}
        />
      </div>
      {filteredAssets.length && !error && !shouldRender ? (
        <TableVirtuoso
          data={filteredAssets}
          components={VirtuosoTableComponents}
          fixedHeaderContent={fixedHeaderContent}
          itemContent={rowContent}
          style={{ height: height, width: '100%' }}
        />
      ) : null}

      {!error && !shouldRender && !assets.length && (
        <Alert severity="warning">
          There are no assets to display for this site.
        </Alert>
      )}
      {error && !shouldRender && (
        <Alert severity="error">Unable to connect to Assets service.</Alert>
      )}
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    assets: state.assetSelectReducer.assets,
    storeId: state.storeInfoReducer.storeId,
  }
}
const mapDispatchToProps = {
  setAssets,
  setSelectedAsset,
  setShouldRender,
}

export default connect(mapStateToProps, mapDispatchToProps)(AssetsSelect)
