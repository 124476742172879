import MatAppBar from '../MatAppBar/MatAppBar'
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Stack,
  Button,
  Alert,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { makeStyles, createStyles } from '@mui/styles'
import { getURLSearchParams, setURLSearchParams } from '../../windowManager'
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt'
import { setShouldRender } from '../ProgressOverlay/store/actionCreator'
import AddTime from '../AddTime/AddTime'
import { putData, postData, fetchData } from '../../service/HttpService'
import { useAuth } from '@praxis/component-auth'
import {
  api,
  DEFAULT_ERROR_MESSAGE,
  TIME_TRACKING_MIN_TIME,
} from '../../globalConstants'
import { setStoreRequestsList } from '../StoreRequestsList/StoreRequestsListStore/actionCreator'
import useHashChange from '../../utils/hooks/useHashChange'

const cancelReasons = [
  'DUPLICATE',
  'ERROR',
  'NO LONGER NEEDED',
  'REPLACEMENT',
  'REQUEST DECLINED',
  'UNABLE TO DO WORK',
  'WORK NOT PERFORMED',
]

const useStyles = makeStyles((theme) =>
  createStyles({
    tablePrimary: {
      '& .MuiTableCell-root': {
        borderWidth: 0,
        padding: theme.spacing(0.9),
      },
    },
    cellPrimary: {
      width: '15%',
    },
    alert: {
      margin: theme.spacing(1, 0, 2, 0),
    },
    timeWarning: {
      padding: theme.spacing(0, 1),
    },
  }),
)

const DeclineStoreRequests = () => {
  const [hours, setHours] = useState(0)
  const [open, setOpen] = useState(false)
  const [minutes, setMinutes] = useState(0)
  const [selectedCancelReason, setSelectedCancelReason] = useState('')
  const [error, setError] = useState(false)
  useHashChange('time_over_limit_open', setOpen)
  const urlSearchParams = getURLSearchParams()
  const ticketId = urlSearchParams.get('ticketid')
  const ticketUId = urlSearchParams.get('ticketuid')
  const ticketClass = urlSearchParams.get('class')
  const siteId = urlSearchParams.get('siteid')
  const storeRequestsLength = parseInt(urlSearchParams.get('count'))
  const auth = useAuth()
  const userId = auth.session.userInfo.lanId?.toUpperCase()
  const classes = useStyles()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [userLoggedTime, setUserLoggedTime] = useState('')
  const [isUserTimeLimitReached, setIsUserTimeLimitReached] = useState(false)
  const [isOneMin, setIsOneMin] = useState(false)

  useEffect(() => {
    setIsOneMin(!hours && minutes === '1' ? false : true)
  }, [hours, minutes])

  const getUserLoggedTime = async (locationId) => {
    const userTimeResp = await fetchData(
      api.userLaborEntries(locationId, userId),
    )
    const formattedUserTime = userTimeResp.totalTime
      .replace('(Total Today: ', '')
      .slice(0, -1)
      .replace(',', '')
    setUserLoggedTime(formattedUserTime)
  }

  const timeLimitReached = () => {
    setIsUserTimeLimitReached(true)
    setURLSearchParams([{ name: 'time_over_limit_open', value: true }])
  }

  const saveDeclineReason = async () => {
    const time = (+hours || 0) + (minutes || 0) / 60
    if (ticketId && selectedCancelReason && (hours || minutes)) {
      try {
        dispatch(setShouldRender(true, 'Saving decline reason...'))
        await getUserLoggedTime(siteId)

        await postData(
          api.addTime,
          JSON.stringify({
            'regular-hrs': parseFloat(time.toFixed(2)),
            'trans-type': 'ADMIN',
            craft: 'SFT',
            ticketid: ticketId,
            'ticket-class': ticketClass,
            siteid: siteId,
            'labor-code': userId,
            ...(isUserTimeLimitReached && { 'api-update': 8 }),
          }),
        )

        await putData(
          api.statusUpdate,
          JSON.stringify({
            'ticket-id': ticketId,
            'ticket-uid': ticketUId,
            'cancel-reason': selectedCancelReason,
            status: 'CANCELLED',
          }),
        )

        setIsUserTimeLimitReached(false)
        dispatch(setShouldRender(false))

        const destination =
          storeRequestsLength > 1
            ? '/store-requests#?force_update=true'
            : '/home'
        navigate(destination)
      } catch (error) {
        dispatch(setShouldRender(false))

        error.response.data.includes('BMXZZ2087E')
          ? timeLimitReached()
          : setError(true)
      } finally {
        dispatch(setStoreRequestsList([]))
      }
    }
  }
  const cancelDeclineReason = () => {
    setIsUserTimeLimitReached(false)
    navigate(-1)
  }

  return (
    <>
      <MatAppBar arrowBack text="Decline Requests" />
      {error && (
        <Alert className={classes.alert} severity="error">
          Failed to save decline reason.
        </Alert>
      )}
      <Table className={classes.tablePrimary}>
        <TableBody>
          <TableRow>
            <TableCell className={classes.cellPrimary}>
              <Typography>Cancel Reason:</Typography>
            </TableCell>
            <TableCell align="left">
              <FormControl fullWidth size="small">
                <InputLabel>Cancel Reason</InputLabel>
                <Select
                  value={selectedCancelReason}
                  label="Cancel Reason"
                  onChange={(e) => setSelectedCancelReason(e.target.value)}
                >
                  {cancelReasons.map((reason, index) => {
                    return (
                      <MenuItem key={index} value={reason}>
                        {reason}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography>Add Time:</Typography>
            </TableCell>
            <TableCell>
              <AddTime
                hours={hours}
                minutes={minutes}
                onHoursChange={setHours}
                onMinutesChange={setMinutes}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={2}>
              <Stack direction="row" spacing={2} justifyContent={'flex-end'}>
                {!isOneMin && (
                  <Alert severity="warning" className={classes.timeWarning}>
                    {TIME_TRACKING_MIN_TIME}
                  </Alert>
                )}
                <Button
                  variant="contained"
                  disabled={
                    !selectedCancelReason || (!hours && !minutes) || !isOneMin
                  }
                  startIcon={<ThumbDownAltIcon />}
                  onClick={saveDeclineReason}
                >
                  Decline
                </Button>
              </Stack>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Dialog open={open} onClose={cancelDeclineReason}>
        <DialogTitle>Add Time</DialogTitle>
        {error && <Alert severity="error">{DEFAULT_ERROR_MESSAGE}</Alert>}
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography>Total Today:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>{userLoggedTime ?? '0 Hrs 0 Mins'}</Typography>
            </Grid>
            <Grid item>
              <Typography>
                You have exceeded 8 hours for this date. Do you want to
                Continue?
              </Typography>
              <Typography>If No, this record will not be saved.</Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={cancelDeclineReason}>
            No
          </Button>
          <Button variant="contained" onClick={saveDeclineReason}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default DeclineStoreRequests
