/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '@praxis/component-auth'
import clsx from 'clsx'
import { Container, Grid, Typography, Alert, Button } from '@mui/material'
import {
  DEFAULT_ERROR_MESSAGE,
  LOCAL_VENDOR_MANAGEMENT,
  WORK_LOG_NOTES,
  api,
  NOT_AVAILABLE,
  WO_TIMELINE_ARRAY,
  globalRoutes,
  LVM_NOTE,
  ADD_NOTE,
  VENDOR_CONTACT_OPEN_WORKLOG,
  DC_WO_TIMELINE_ARRAY,
} from '../../globalConstants'
import {
  VENDOR_EXPECTATION_NOTE_BUTTON_ACCESS_GROUPS,
  WORK_LOG_NOTE_BUTTON_ACCESS_GROUPS,
} from '../../globalPermissions'
import { makeStyles, createStyles } from '@mui/styles'
import { setShouldRender } from '../ProgressOverlay/store/actionCreator'
import useGetWorkOrderDetails from '../../utils/hooks/useGetWorkOrderDetails'
import { fetchData } from '../../service/HttpService'
import { setWorkLogDetails } from './store/actionCreator'
import WorkLogNotes from './WorkLogNotes/WorkLogNotes'

import WorkOrderTimeLine from '../TimeLine/TimeLine'
import { convertTimestampToLocal } from '../../utils'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import { getURLSearchParams } from '../../windowManager'
import WorkLogVisits from './WorkLogVisits'
import { AddCircle } from '@mui/icons-material'
import VendorContacts from '../VendorContacts/VendorContacts'
import { setLvm } from '../../utils/hooks/workOrderUtil'

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      margin: theme.spacing(2, 0),
      padding: theme.spacing(0),
    },
    headerBackground: {
      background: '#f5f8fd',
    },
    stickyHeader: {
      position: 'sticky',
      top: (stickyDivHeight) => stickyDivHeight + 10,
      zIndex: theme.zIndex.appBar,
      background: theme.palette.common.white,
      padding: theme.spacing(0, 3),
    },
    worklogNotes: {
      paddingTop: '0 !important',
    },
    addNoteButtonNoWrap: {
      minWidth: 'max-content',
    },
    endButton: {
      display: 'flex',
      marginLeft: 'auto',
      padding: theme.spacing(1),
    },
    textAlignStyle: {
      '@media(max-width:600px)': {
        textAlign: 'left',
      },
      '@media(min-width:500px)': {
        whiteSpace: 'nowrap',
      },
    },
  }),
)

const WorkOrderDetailsWorkLog = () => {
  const auth = useAuth()
  const navigate = useNavigate()
  const { session } = auth
  const urlSearchParams = getURLSearchParams()
  const woNum = parseInt(urlSearchParams.get('wonum'))
  const showBackButton = urlSearchParams.get('showBack') === 'true'
  const urlParams = `?wonum=${woNum}${
    showBackButton ? `&showBack=${showBackButton}` : ''
  }`
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const dispatch = useDispatch()
  const woDetailFetched = useGetWorkOrderDetails()
  const {
    wodetails = null,
    stickyDivHeight = 0,
    storeId = 0,
    isDCUser,
  } = useSelector((state) => ({
    ...state.workOrderDetailsReducer,
    ...state.storeInfoReducer,
    ...state.userInfoReducer,
  }))
  const isInternal = Number?.(wodetails?.external) === 0
  const timelineData =
    isDCUser && isInternal
      ? DC_WO_TIMELINE_ARRAY.map((val) => ({
          ...val,
          value: convertTimestampToLocal(wodetails?.[val.key]) || NOT_AVAILABLE,
        }))
      : WO_TIMELINE_ARRAY.map((val) => ({
          ...val,
          value: convertTimestampToLocal(wodetails?.[val.key]) || NOT_AVAILABLE,
        })).filter((val) =>
          isInternal && val.key === 'arrive_by' ? false : true,
        )

  const getSession = (adGroup) => session.userInfo?.memberOf?.includes(adGroup)
  const showVendorExpectationNoteButton =
    !isInternal && VENDOR_EXPECTATION_NOTE_BUTTON_ACCESS_GROUPS.some(getSession)
  const showWorkLogNote = WORK_LOG_NOTE_BUTTON_ACCESS_GROUPS.some(getSession)

  useEffect(() => {
    if (wodetails) {
      const woNum = wodetails?.wonum
      try {
        setShouldRender(true, 'Loading Work log details')
        const getWorkLogDetails = async () => {
          const response = await fetchData(api.worklogDetails(storeId, woNum))
          dispatch(setWorkLogDetails(response.worklogdata))
        }
        getWorkLogDetails()
      } catch (error) {
        setError(error)
      } finally {
        setLoading(false)
        setShouldRender(false)
      }
    }
  }, [wodetails])

  const classes = useStyles(stickyDivHeight)

  const sectionHeader = (headerText, button) => (
    <Grid
      container
      className={classes.headerBackground}
      sx={{ margin: 1 }}
      flexWrap="nowrap"
    >
      <Grid item xs={!button ? 12 : undefined}>
        <Typography variant="h5" className={classes.headerBackground}>
          {headerText}
        </Typography>
      </Grid>
      {button}
    </Grid>
  )

  return woDetailFetched === true && !error && !loading ? (
    <Container className={classes.container}>
      <Grid container>
        <>
          {!isInternal && (
            <>
              {sectionHeader(
                LOCAL_VENDOR_MANAGEMENT,
                <VendorContacts param={VENDOR_CONTACT_OPEN_WORKLOG} />,
              )}
              <Grid
                container
                alignItems="center"
                sx={{ padding: (theme) => theme.spacing(0, 1) }}
              >
                <Grid item xs={showVendorExpectationNoteButton ? 4 : 12}>
                  <Typography>
                    <strong>
                      LVM:{' '}
                      {setLvm(
                        wodetails.tgt_lvm1,
                        wodetails.tgt_lvm2,
                        wodetails.tgt_lvm3,
                        wodetails.tgt_lvm4,
                        wodetails.tgt_lvm5,
                      )}
                    </strong>
                  </Typography>
                </Grid>
                {showVendorExpectationNoteButton && (
                  <Grid item xs={8}>
                    <Button
                      startIcon={<AddCircleIcon />}
                      className={clsx(
                        classes.textAlignStyle,
                        classes.endButton,
                      )}
                      onClick={() =>
                        navigate({
                          pathname: globalRoutes.createWorkLog,
                          hash: `${urlParams}&from=${LVM_NOTE}`,
                        })
                      }
                    >
                      Add Vendor Expectation Note
                    </Button>
                  </Grid>
                )}
              </Grid>
            </>
          )}
          <WorkOrderTimeLine data={timelineData} />
          {sectionHeader(
            WORK_LOG_NOTES,
            <Grid item sx={{ marginLeft: 'auto' }}>
              {showWorkLogNote && (
                <Button
                  startIcon={<AddCircle />}
                  className={classes.addNoteButtonNoWrap}
                  onClick={() =>
                    navigate({
                      pathname: globalRoutes.createWorkLog,
                      hash: `${urlParams}&from=${ADD_NOTE}`,
                    })
                  }
                >
                  Add Note
                </Button>
              )}
            </Grid>,
          )}
          <Grid item xs={12}>
            <WorkLogNotes urlParams={urlParams} />
          </Grid>
          {!isInternal && (
            <>
              {sectionHeader('Visits')}
              <WorkLogVisits />
            </>
          )}
        </>
      </Grid>
    </Container>
  ) : (
    error && !loading && <Alert severity="error">{DEFAULT_ERROR_MESSAGE}</Alert>
  )
}

export default WorkOrderDetailsWorkLog
