import { useState } from 'react'
import { useAuth } from '@praxis/component-auth'
import { Button, Stack, TextField, Alert, AlertTitle } from '@mui/material'
import { useSelector } from 'react-redux'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CancelIcon from '@mui/icons-material/Cancel'
import { postData, putData } from '../../service/HttpService'
import { getURLSearchParams } from '../../windowManager'
import MatAppBar from '../MatAppBar/MatAppBar'
import { useDispatch } from 'react-redux'
import { setShouldRender } from '../ProgressOverlay/store/actionCreator'
import { useNavigate } from 'react-router-dom'
import {
  WO_FAIL_STATUS,
  WO_FAIL_MESSAGE,
  MIN_NOTE_LENGTH,
  WO,
} from '../../globalConstants'
import useGetWorkOrderDetails from '../../utils/hooks/useGetWorkOrderDetails'
import { setWorkOrder } from '../WorkOrderDetails/store/actionCreator'
import { setEmergencyMaintenanceWorkorderList } from '../WorkOrderList/WorkOrderListStore/actionCreator'

const ValidateWorkOrder = () => {
  const auth = useAuth()
  const userId = auth.session.userInfo?.lanId?.toUpperCase()
  const urlSearchParams = getURLSearchParams()
  const woNum = parseInt(urlSearchParams.get('wonum'))
  const [note, setNote] = useState('')
  const [error, setError] = useState(false)
  const [postNoteError, setPostNoteError] = useState(false)
  const [putValidationError, setPutValidationError] = useState(false)
  const [statusUpdateError, setStatusUpdateError] = useState(false)
  const [workflowError, setWorkflowError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const dispatch = useDispatch()
  const { session } = auth
  const userInfo = session.userInfo
  const navigate = useNavigate()
  const defaultHintText = `Log note requires at least ${MIN_NOTE_LENGTH} characters to save.`
  const woDetailFetched = useGetWorkOrderDetails()
  const { wodetails = null } = useSelector(
    (state) => state.workOrderDetailsReducer,
  )
  const isFailEnabled = WO_FAIL_STATUS?.indexOf?.(wodetails?.wostatus) !== -1
  const noteLength = note.length
  const onChange = (event) => {
    const input = event.target.value
    const inputLength = input.length
    if (inputLength < MIN_NOTE_LENGTH) {
      setError(true)
      setErrorMessage(`Minimum ${MIN_NOTE_LENGTH} characters required.`)
      // Checking if there's any character that is not a space, tab, or line break.
    } else if (inputLength >= 2 && !/\S/.test(input)) {
      setError(true)
      setErrorMessage('Field cannot be blank.')
    } else {
      setError(false)
    }
    setNote(event.target.value)
  }

  const resetWorkorder = () => {
    dispatch(setWorkOrder(null))
    dispatch(setEmergencyMaintenanceWorkorderList([]))
  }
  const triggerWorkflow = async () => {
    try {
      dispatch(setShouldRender(true, 'Initiating Work Flow'))
      await putData(`/maximo/workorder/${woNum}/workflow`)
      resetWorkorder()
      dispatch(setShouldRender(false))
      navigate('/home')
    } catch {
      setWorkflowError(true)
    } finally {
      dispatch(setShouldRender(false))
    }
  }

  const setStatus = async (fail) => {
    let hasFailed = false
    try {
      if (fail) {
        dispatch(setShouldRender(true, 'Updating Status'))
        const requestBody = {
          status: 'WVFAIL',
          'change-by': userId,
          'workorder-id': wodetails.workorderid,
          wonum: woNum,
        }
        await putData(
          '/maximo/workorderstatusupdate',
          JSON.stringify(requestBody),
        )
      }
    } catch {
      hasFailed = true
      setStatusUpdateError(true)
    } finally {
      dispatch(setShouldRender(false))
      !hasFailed && triggerWorkflow()
    }
  }

  const setWorkOrderValidation = async (fail) => {
    let hasFailed = false
    try {
      dispatch(setShouldRender(true, 'Validating Work Order'))
      const postBody = {
        'vendor-work-validation': fail ? 'Fail' : 'Pass',
      }
      await putData(
        `/maximo/workorder/${woNum}/validation`,
        JSON.stringify(postBody),
      )
    } catch {
      hasFailed = true
      setPutValidationError(true)
    } finally {
      dispatch(setShouldRender(false))
      !hasFailed && setStatus(fail)
    }
  }

  const onClick = async (fail) => {
    let hasFailed = false
    setPostNoteError(false)
    setPutValidationError(false)
    try {
      if (noteLength >= MIN_NOTE_LENGTH) {
        dispatch(setShouldRender(true, 'Creating Log Note'))
        const postWorkLogBody = {
          'create-by': userInfo.lanId,
          description: 'Work Validation Note',
          'record-key': woNum,
          'log-type': 'UPDATE',
          'long-description': note,
        }
        await postData(
          `/maximo/worklog/${woNum}`,
          JSON.stringify(postWorkLogBody),
        )
      }
    } catch {
      hasFailed = true
      setPostNoteError(true)
    } finally {
      dispatch(setShouldRender(false))
      !hasFailed && setWorkOrderValidation(fail)
    }
  }
  const getIncidentMessage = () => {
    return (
      <small>
        If issue persists, please create an incident and include work order #
        {woNum}
      </small>
    )
  }

  return (
    <>
      <MatAppBar arrowBack={true} text={`${WO} ${woNum}`}></MatAppBar>
      <Stack direction="column" spacing={2}>
        {postNoteError && (
          <Alert severity="error">Unable to Create Log Note</Alert>
        )}
        {putValidationError && (
          <Alert severity="error">Unable to validate work order.</Alert>
        )}
        {statusUpdateError && (
          <Alert
            severity="error"
            action={
              <Button variant="outlined" onClick={setStatus}>
                Retry
              </Button>
            }
          >
            <AlertTitle>
              Updating status failed. Please click retry to update the status.
            </AlertTitle>
            {getIncidentMessage()}
          </Alert>
        )}
        {workflowError && (
          <Alert
            severity="error"
            action={
              <Button variant="outlined" onClick={triggerWorkflow}>
                Retry
              </Button>
            }
          >
            <AlertTitle>
              Triggering workflow failed. Please click retry to trigger again.
            </AlertTitle>
            {getIncidentMessage()}
          </Alert>
        )}
        {woDetailFetched && (
          <>
            <Alert severity="info">{WO_FAIL_MESSAGE}</Alert>
            <TextField
              autoFocus
              label="Log Note"
              value={note}
              onChange={onChange}
              error={error}
              helperText={
                error
                  ? errorMessage
                  : noteLength < MIN_NOTE_LENGTH && defaultHintText
              }
            />
            <Stack direction="row" spacing={2} justifyContent="flex-end">
              <Button
                data-testid="fail-button"
                variant="outlined"
                startIcon={<CancelIcon />}
                disabled={
                  error || noteLength < MIN_NOTE_LENGTH || !isFailEnabled
                }
                onClick={() => onClick(true)}
              >
                Fail
              </Button>
              <Button
                variant="contained"
                startIcon={<CheckCircleIcon />}
                onClick={() => onClick()}
              >
                Pass
              </Button>
            </Stack>
          </>
        )}
      </Stack>
    </>
  )
}

export default ValidateWorkOrder
