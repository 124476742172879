import { SET_WORK_ORDER_DETAIL, SET_STICKY_DIV_HEIGHT } from './actionType'

export const initialState = {
  wodetails: null,
  stickyDivHeight: 0,
}

export default function workOrderDetailsReducer(
  state = initialState,
  action = {},
) {
  switch (action.type) {
    case SET_WORK_ORDER_DETAIL: {
      const workOrderData = action.workOrderData
      const newState = {
        ...state,
        wodetails: workOrderData,
      }
      return newState
    }
    case SET_STICKY_DIV_HEIGHT: {
      const newState = {
        ...state,
        stickyDivHeight: action.height,
      }
      return newState
    }

    default:
      return state
  }
}
