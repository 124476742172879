import { SET_STORE_ID } from './actionType'

export const initialState = {
  storeId: '',
}

export default function storeInfoReducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_STORE_ID: {
      const newState = {
        ...state,
        storeId: action.storeId,
      }
      return newState
    }
    default:
      return state
  }
}
