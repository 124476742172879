import { Alert } from '@mui/material'
import { makeStyles, createStyles } from '@mui/styles'
import { COLOR_DEFAULT_GREY } from '../../globalColors'
const useStyles = makeStyles((theme) =>
  createStyles({
    showingInfo: {
      margin: theme.spacing(1),
      borderColor: COLOR_DEFAULT_GREY,
      '& .MuiAlert-icon > svg': {
        fill: COLOR_DEFAULT_GREY,
      },
    },
  }),
)

const StatusAlert = ({ text }) => {
  const classes = useStyles()

  return (
    <Alert
      severity="info"
      variant="outlined"
      data-testid={'status-alert'}
      className={classes.showingInfo}
    >
      {text}
    </Alert>
  )
}

export default StatusAlert
