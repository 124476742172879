import Button from '@mui/material/Button'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Dialog from '@mui/material/Dialog'
import { getURLSearchParams } from '../../windowManager'
import { useEffect, useState } from 'react'
import { Typography } from '@mui/material'
import { useAuth } from '@praxis/component-auth'

const LogoutDialog = () => {
  const [open, setOpen] = useState(false)
  const auth = useAuth()
  const { session } = auth

  const onHashChange = () => {
    const logoutDialogOpen = Boolean(
      getURLSearchParams().get('logout_dialog_open'),
    )
    setOpen(logoutDialogOpen)
  }

  useEffect(() => {
    window.addEventListener('hashchange', onHashChange)
    onHashChange()
    return () => window.removeEventListener('hashchange', onHashChange)
  })

  const onClose = () => {
    window.history.back()
  }

  const onOk = () => {
    auth.logout()
  }

  return (
    <Dialog open={open} onBackdropClick={onClose}>
      <DialogTitle>Logout?</DialogTitle>
      <DialogContent>
        <Typography>
          Clicking OK will log {session.userInfo?.fullName} out of the app.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" onClick={onOk}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default LogoutDialog
