import { useCallback } from 'react'
import {
  Typography,
  TextField,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from '@mui/material'
import { makeStyles, createStyles } from '@mui/styles'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import IconButton from '@mui/material/IconButton'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'
import { isHoursInvalid, isMinutesInvalid } from '../../utils'

const useStyles = makeStyles((theme) =>
  createStyles({
    tablePrimary: {
      '& .MuiTableCell-root': {
        borderWidth: 0,
        padding: theme.spacing(0.9),
      },
    },
    cellPrimary: {
      width: '15%',
    },
    timeTable: {
      maxWidth: 60,
      '& .MuiTableCell-root': {
        borderWidth: 0,
        padding: theme.spacing(0.4),
      },
    },
    cellButton: {
      padding: theme.spacing(0, 2),
    },
    container: {
      margin: theme.spacing(3, 0),
      wordBreak: 'break-word',
    },
    field: {
      width: '100%',
    },
    inputStyle: {
      '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        display: 'none',
      },
    },
  }),
)

const AddTime = ({ hours, minutes, onHoursChange, onMinutesChange }) => {
  const classes = useStyles()

  const handleHoursChange = useCallback(
    (event) => {
      onHoursChange(event)
    },
    [onHoursChange],
  )

  const handleMinutesChange = useCallback(
    (event) => {
      onMinutesChange(event)
    },
    [onMinutesChange],
  )

  const clearAll = () => {
    handleHoursChange(0)
    handleMinutesChange(0)
  }

  return (
    <Table className={classes.timeTable}>
      <TableBody>
        <TableRow>
          <TableCell align="center" className={classes.cellButton}>
            <IconButton
              color="primary"
              onClick={() =>
                handleHoursChange(hours < 99 ? Number(hours) + 1 : 99)
              }
            >
              <AddCircleIcon data-testid="increase-hours" fontSize="large" />
            </IconButton>
          </TableCell>
          <TableCell></TableCell>
          <TableCell align="center" className={classes.cellButton}>
            <IconButton
              color="primary"
              onClick={() => {
                handleMinutesChange(minutes < 55 ? Number(minutes) + 5 : 59)
              }}
            >
              <AddCircleIcon data-testid="increase-minutes" fontSize="large" />
            </IconButton>
          </TableCell>
          <TableCell></TableCell>
        </TableRow>
        <TableRow>
          <TableCell className={classes.field}>
            <TextField
              className={classes.field}
              type="number"
              data-testid="hours-field"
              size="small"
              InputProps={{ classes: { input: classes.inputStyle } }}
              value={hours}
              error={isHoursInvalid(hours)}
              onChange={(event) => {
                const value = event.target.value
                const formattedValue = value.replace(/^0+/, '')
                handleHoursChange(formattedValue === '' ? 0 : formattedValue)
              }}
            />
          </TableCell>
          <TableCell>
            <Typography>Hrs</Typography>
          </TableCell>
          <TableCell>
            <TextField
              data-testid="minutes-field"
              type="number"
              size="small"
              InputProps={{ classes: { input: classes.inputStyle } }}
              value={minutes}
              error={isMinutesInvalid(minutes)}
              onChange={(event) => {
                const value = event.target.value
                const formattedValue = value.replace(/^0+/, '')
                handleMinutesChange(formattedValue === '' ? 0 : formattedValue)
              }}
            />
          </TableCell>
          <TableCell>
            <Typography>Mins</Typography>
          </TableCell>
          <TableCell align="center" className={classes.cellButton}>
            <IconButton
              color="primary"
              disabled={!hours && !minutes}
              onClick={clearAll}
            >
              <HighlightOffIcon data-testid="clear-time" fontSize="large" />
            </IconButton>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="center" className={classes.cellButton}>
            <IconButton
              color="primary"
              onClick={() => {
                hours > 0 && handleHoursChange(Number(hours) - 1)
              }}
            >
              <RemoveCircleIcon data-testid="decrease-hours" fontSize="large" />
            </IconButton>
          </TableCell>
          <TableCell></TableCell>
          <TableCell align="center" className={classes.cellButton}>
            <IconButton
              color="primary"
              onClick={() => {
                handleMinutesChange(minutes > 4 ? Number(minutes) - 5 : 0)
              }}
            >
              <RemoveCircleIcon
                data-testid="decrease-minutes"
                fontSize="large"
              />
            </IconButton>
          </TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}

export default AddTime
