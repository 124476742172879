/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useRef, Fragment } from 'react'
import { useAuth } from '@praxis/component-auth'
import { useSelector } from 'react-redux'
import {
  PendingActions,
  FlightLand,
  AssignmentTurnedIn,
} from '@mui/icons-material'
import { setShouldRender } from '../ProgressOverlay/store/actionCreator'
import { fetchData } from '../../service/HttpService'
import {
  Grid,
  Typography,
  Alert,
  Card,
  CardContent,
  CardHeader,
  Link,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
} from '@mui/material'
import {
  globalRoutes,
  VISITS_EMPTY,
  CHECK_OUT,
  CHECK_IN,
  ETA,
  EVENT_TYPE,
  DATE_TIME,
  GEOFENCE,
  NOT_AVAILABLE,
  FOLLOW_UP,
  WORK_ORDER_EXPECTATIONS,
  VISITS_ERROR_MESSAGE,
  api,
  VENDOR_CONTACT_OPEN_VISITS,
  XS_CUSTOM_BREAKPOINT,
  XS_CARD_MEDIA_QUERY,
} from '../../globalConstants'
import { makeStyles, createStyles } from '@mui/styles'
import VisitTimeLine from '../TimeLine/TimeLine'
import { convertTimestampToLocal } from '../../utils'
import PhoneEnabledIcon from '@mui/icons-material/PhoneEnabled'
import ExpandableDiv from '../common/ExpandableDiv'
import VendorContacts from '../VendorContacts/VendorContacts'
import WorkOrderTimeLine from '../TimeLine/TimeLine'
import { COLOR_BACKGROUND_GREY } from '../../globalColors'
import { Beenhere, PersonPinCircle, DepartureBoard } from '@mui/icons-material'
const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      margin: theme.spacing(2, 0),
    },
    headerBackground: {
      background: '#e3e5e8',
      padding: theme.spacing(1),
    },
    cardContent: {
      padding: theme.spacing(1),
      [XS_CUSTOM_BREAKPOINT]: XS_CARD_MEDIA_QUERY,
      '& .MuiCardContent-root': {
        padding: theme.spacing(1),
      },
    },
    dialogTitleContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    closeIcon: {
      marginLeft: 'auto',
    },
    dynamicFont: {
      '@media(max-width:600px)': {
        fontSize: '1.1rem',
      },
    },
    rightEndButton: {
      marginLeft: 'auto',
      marginRight: theme.spacing(3),
    },
    dynamicDiv: {
      overflow: 'auto',
      maxHeight: 200,
    },
    dynamicDivExpanded: {
      maxHeight: 'none',
    },
    timelineDiv: {
      marginTop: theme.spacing(-1),
      display: 'flex',
      width: '100%',
    },
    phoneIcon: {
      paddingLeft: theme.spacing(0.5),
      fontSize: '1.2rem',
    },
    technicianPhoneDiv: {
      display: 'flex',
      alignItems: 'center',
    },
    workOrderExpectationtext: {
      fontWeight: 'bold',
      cursor: 'pointer',
      textDecoration: 'underline',
      color: theme.palette.primary.main,
    },
    topPadding: {
      paddingTop: theme.spacing(0.5),
    },
    phoneLink: {
      display: 'flex',
      alignItems: 'center',
    },
  }),
)

function WorkOrderTimeLineInPopper({ timelineData, idx }) {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const anchorRef = useRef(null)

  const handleClick = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }

    setOpen(false)
  }

  return (
    <Fragment key={idx}>
      <Typography
        variant="body1"
        className={classes.workOrderExpectationtext}
        onClick={handleClick}
        ref={anchorRef}
        component="span"
      >
        {WORK_ORDER_EXPECTATIONS}
      </Typography>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        placement="bottom-start"
        transition
      >
        {({ TransitionProps, placement }) => (
          <Grow {...TransitionProps}>
            <Paper style={{ background: COLOR_BACKGROUND_GREY }}>
              <ClickAwayListener onClickAway={handleClose}>
                <div className={classes.topPadding}>
                  <WorkOrderTimeLine data={timelineData} popper />
                </div>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Fragment>
  )
}

const WorkLogVisits = () => {
  const classes = useStyles()
  const [visits, setVisits] = useState(null)
  const auth = useAuth()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const { wodetails = null } = useSelector((state) => ({
    ...state.workOrderDetailsReducer,
    ...state.storeInfoReducer,
    ...state.workLogDetailsReducer,
  }))
  const WO_TIMELINE_POPPER = [
    { label: 'Earliest start', key: 'earliest_start', icon: PendingActions },
    { label: 'Arrive by', key: 'arrive_by', icon: FlightLand },
    {
      label: 'Repair by',
      key: 'repair_by',
      icon: AssignmentTurnedIn,
    },
  ]
  const isInternal = Number?.(wodetails?.external) === 0
  const timelineData = WO_TIMELINE_POPPER.map((val) => ({
    ...val,
    value: convertTimestampToLocal(wodetails?.[val.key]) || NOT_AVAILABLE,
  })).filter((val) => (isInternal && val.key === 'arrive_by' ? false : true))

  const userId = auth.session.userInfo.lanId

  useEffect(() => {
    try {
      setShouldRender(true, 'Loading Visits...')
      const getWorkLogVisits = async () => {
        const workLogVisits = await fetchData(
          api.worklogVisits(
            userId,
            wodetails?.wonum,
            wodetails?.woassignvendor,
          ),
        )
        setVisits(
          workLogVisits?.visits?.sort(
            (a, b) =>
              new Date(b.created_timestamp) - new Date(a.created_timestamp),
          ),
        )
      }
      getWorkLogVisits()
    } catch (error) {
      setError(error)
    } finally {
      setLoading(false)
      setShouldRender(false)
    }
  }, [])

  function renderCard(data, idx) {
    const commonGridItemProps = {
      item: true,
      xs: 5,
    }

    const generateMapLink = (data) => {
      const urlSearchParams = new URL(window.location).searchParams
      const params = {
        visit_id: data.visit_id,
        location_id: data.location_id,
        check_in_lat:
          data.check_in_latitude !== null ? data.check_in_latitude : 0,
        check_in_long:
          data.check_in_longitude !== null ? data.check_in_longitude : 0,
        check_out_lat:
          data.check_out_latitude !== null ? data.check_out_latitude : 0,
        check_out_long:
          data.check_out_longitude !== null ? data.check_out_longitude : 0,
        check_in_geofence: data.check_in_geofence,
        check_out_geofence: data.check_out_geofence,
        reference_id: data.reference_id,
      }

      for (const [key, value] of Object.entries(params)) {
        urlSearchParams.append(key, value)
      }
      return `${globalRoutes.workLogVisitsMap}/#?${urlSearchParams}`
    }

    const itemPadding = {
      paddingTop: 1,
    }

    const visitTimelineData = [
      {
        label: CHECK_OUT,
        value: [
          convertTimestampToLocal(data?.check_out_time) || NOT_AVAILABLE,
          data?.check_out_geofence ?? NOT_AVAILABLE,
        ],
        icon: Beenhere,
      },
      {
        label: CHECK_IN,
        value: [
          convertTimestampToLocal(data?.check_in_time) || NOT_AVAILABLE,
          data?.check_in_geofence ?? NOT_AVAILABLE,
        ],
        icon: PersonPinCircle,
      },
      {
        label: ETA,
        value: [
          convertTimestampToLocal(data?.estimated_time_arrival) ||
            NOT_AVAILABLE,
          NOT_AVAILABLE,
        ],
        icon: DepartureBoard,
      },
    ].filter((val) => {
      return val?.value?.find((innerval) => innerval !== NOT_AVAILABLE)
    })

    return (
      <Fragment key={data.visit_id}>
        <Grid
          item
          xs={6}
          sm={6}
          md={4}
          className={classes.cardContent}
          data-testid="worklog-visits-list"
        >
          <Card>
            <CardHeader
              title={
                <Grid container alignItems="center">
                  <Grid item xs={10}>
                    <WorkOrderTimeLineInPopper
                      timelineData={timelineData}
                      idx={data.visit_id}
                      key={data.visit_id}
                    />
                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                      {data?.visitor_company_name}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <VendorContacts
                      param={`${VENDOR_CONTACT_OPEN_VISITS}-${data.visit_id}`}
                    />
                  </Grid>
                </Grid>
              }
              className={classes.headerBackground}
            />
            <CardContent>
              {data?.visit_status === FOLLOW_UP && (
                <Grid container sx={itemPadding}>
                  <Grid {...commonGridItemProps}>
                    <Typography>
                      <strong>Follow Up Reason:</strong>
                    </Typography>
                  </Grid>
                  <Typography>
                    {data?.follow_up_reason ?? NOT_AVAILABLE}
                  </Typography>
                </Grid>
              )}
              <Grid container sx={itemPadding}>
                <Grid {...commonGridItemProps}>
                  <Typography>
                    <strong>Status:</strong>
                  </Typography>
                </Grid>
                <Typography>{data?.visit_status ?? ''}</Typography>
              </Grid>
              <Grid container sx={itemPadding}>
                <Grid {...commonGridItemProps}>
                  <Typography>
                    <strong>Tech Name:</strong>
                  </Typography>
                </Grid>
                <Typography>{data?.technician_name ?? ''}</Typography>
              </Grid>
              <Grid container sx={itemPadding}>
                <Grid {...commonGridItemProps}>
                  <Typography>
                    <strong>Tech Notes:</strong>
                  </Typography>
                </Grid>
                <ExpandableDiv text={data?.visit_comments ?? null} />
              </Grid>
              <Grid container sx={itemPadding}>
                <Grid {...commonGridItemProps}>
                  <Typography>
                    <strong>Tech Contact:</strong>
                  </Typography>
                </Grid>
                <Grid item sx={{ alignItems: 'center' }}>
                  <Typography
                    component="div"
                    className={classes.technicianPhoneDiv}
                  >
                    {data?.visitor_phone ? (
                      <>
                        <Link
                          href={`tel:${data?.visitor_phone ?? ''}`}
                          className={classes.phoneLink}
                        >
                          {data?.visitor_phone ?? ''}
                          <PhoneEnabledIcon className={classes.phoneIcon} />
                        </Link>
                      </>
                    ) : (
                      NOT_AVAILABLE
                    )}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container sx={itemPadding}>
                {visitTimelineData?.length > 0 && (
                  <div className={classes.timelineDiv}>
                    <VisitTimeLine
                      data={visitTimelineData}
                      header={[EVENT_TYPE, DATE_TIME, GEOFENCE]}
                      mapUrl={generateMapLink(data)}
                      from="VisitTimeline"
                    />
                  </div>
                )}
              </Grid>
              <Grid container sx={itemPadding}>
                <Grid {...commonGridItemProps}>
                  <Typography>
                    <strong>Visit ID:</strong>
                  </Typography>
                </Grid>
                <Typography>{data?.visit_id ?? ''}</Typography>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Fragment>
    )
  }
  return (
    <>
      <Grid container>
        {visits?.length === 0 ? (
          <Alert severity="warning">{VISITS_EMPTY}</Alert>
        ) : (
          visits?.map((val, idx) => renderCard(val, idx))
        )}
      </Grid>
      {error && !loading && (
        <Alert severity="error">{VISITS_ERROR_MESSAGE}</Alert>
      )}
    </>
  )
}

export default WorkLogVisits
