export const SET_WORKORDERS_LIST = 'SET_WORKORDERS_LIST'
export const SET_DUPLICATE_WORKORDERS_LIST = 'SET_DUPLICATE_WORKORDERS_LIST'
export const SET_ASSIGNMENTS = 'SET_ASSIGNMENTS'
export const FLIP_TOGGLE = 'FLIP_TOGGLE'
export const SET_INTERNAL_WORKORDERS_LIST = 'SET_INTERNAL_WORKORDERS_LIST'
export const SET_EXTERNAL_WORKORDERS_LIST = 'SET_EXTERNAL_WORKORDERS_LIST'
export const SET_CM_EM_WORKORDERS_LIST = 'SET_CM_EM_WORKORDERS_LIST'
export const SET_REDIRECTED_WORKORDERS_LIST = 'SET_REDIRECTED_WORKORDERS_LIST'
export const SET_ACTION_NEEDED_VENDOR_WORKORDERS_LIST =
  'SET_ACTION_NEEDED_VENDOR_WORKORDERS_LIST'
export const SET_ACTION_NEEDED_WORKORDERS_LIST =
  'SET_ACTION_NEEDED_WORKORDERS_LIST'
export const SET_EMERGENCY_MAINTENANCE_WORKORDERS_LIST =
  'SET_EMERGENCY_MAINTENANCE_WOROKORDERS_LIST'
export const SET_VENDOR_VALIDATION_WORKORDERS_LIST =
  'SET_VENDOR_VALIDATION_WORKORDERS_LIST'
export const SET_FIELD_PROJECT_WORKORDERS_LIST =
  'SET_FIELD_PROJECT_WORKORDERS_LIST'
export const SET_PREVENTATIVE_MAINTENANCE_WORKORDERS_LIST =
  'SET_PREVENTATIVE_MAINTENANCE_WORKORDERS_LIST'
export const SET_CANCELLED_EXTERNAL_WORKORDERS_LIST =
  'SET_CANCELLED_EXTERNAL_WORKORDERS_LIST'
export const SET_SAFETY_WORKORDERS_LIST = 'SET_SAFETY_WORKORDERS_LIST'
export const SET_AUTO_WORKORDERS_LIST = 'SET_AUTO_WORKORDERS_LIST'
export const SET_AUTH = 'SET_AUTH'
