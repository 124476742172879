import PriorityHighIcon from '@mui/icons-material/PriorityHigh'
import { COLOR_TARGET_RED } from '../../globalColors'
import useGetSurveyAnswers from '../../utils/hooks/useGetSurveyAnswers'
import { makeStyles, createStyles } from '@mui/styles'
import { useSelector } from 'react-redux'
import useGetWorkOrderDetails from '../../utils/hooks/useGetWorkOrderDetails'

const useStyles = makeStyles((theme) =>
  createStyles({
    highPriority: {
      color: `${COLOR_TARGET_RED}`,
    },
  }),
)

const SurveyWarningIcon = () => {
  const classes = useStyles()
  const answersLoaded = useGetSurveyAnswers()
  useGetWorkOrderDetails()
  const { wodetails = null, surveyAnswers = null } = useSelector((state) => ({
    ...state.workOrderDetailsReducer,
    ...state.surveyAnswersReducer,
  }))
  const classStructureId = wodetails?.classstructureid
  const isInternal = Number?.(wodetails?.external) === 0

  return isInternal &&
    classStructureId &&
    answersLoaded &&
    surveyAnswers &&
    !surveyAnswers?.completedflag ? (
    <PriorityHighIcon
      data-testid={'surveyIcon'}
      className={classes.highPriority}
    />
  ) : null
}

export default SurveyWarningIcon
