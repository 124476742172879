import { SET_ASSETS, SET_SELECTED_ASSET, SET_AUTH } from './actionType'
import { filterAPAssets } from '../../../utils'

const storedItem = JSON.parse(sessionStorage.getItem('selectedAsset'))

export const initialState = {
  assets: [],
  selectedAsset: storedItem ? storedItem : {},
  userGroups: [],
}

export default function assetSelectReducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_AUTH: {
      const newState = {
        ...state,
        userGroups: action.userGroups,
      }
      return newState
    }
    case SET_ASSETS: {
      const APFilteredAssets = filterAPAssets(action.assets, state.userGroups)
      const newState = {
        ...state,
        assets: APFilteredAssets,
      }
      localStorage.setItem('assetList', JSON.stringify(APFilteredAssets))
      return newState
    }
    case SET_SELECTED_ASSET: {
      const selectedAsset = action.selectedAsset
      const newState = {
        ...state,
        selectedAsset: selectedAsset,
      }
      sessionStorage.setItem('selectedAsset', JSON.stringify(selectedAsset))
      return newState
    }

    default:
      return state
  }
}
