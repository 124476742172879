import moment from 'moment'
import { filterAPWorkorders } from '..'

export default function getcounts(workorders, userGroups) {
  const tomorrow = moment.utc().add(1, 'day').format()
  const workorderList = filterAPWorkorders(workorders.woDetails, userGroups)
  const total = workorderList.length
  const emcount = workorderList.filter((currentWO) => {
    return (
      currentWO.external === '1' &&
      currentWO.wotype === 'EM' &&
      currentWO.wovendworkval !== 'Pass' &&
      currentWO.wovendworkval !== 'Fail'
    )
  }).length
  const safetycount = workorderList.filter((currentWO) => {
    return currentWO.haswltypesafety === '1'
  }).length
  const autocount = workorderList.filter((currentWO) => {
    return (
      (currentWO.reportedby === 'SMRTMAXAWO' ||
        currentWO.reportedby === 'SVOPSTASKMANAGER') &&
      currentWO.wostatus !== 'CLOSED' &&
      currentWO.wostatus !== 'COMP' &&
      currentWO.wostatus !== 'CANCEL' &&
      currentWO.wovendworkval !== 'Pass' &&
      currentWO.wovendworkval !== 'Fail'
    )
  }).length
  const cmAndEmCount = workorderList.filter((currentWO) => {
    return (
      (currentWO.external === '0' && currentWO.wotype === 'CM') ||
      (currentWO.external === '0' && currentWO.wotype === 'EM')
    )
  }).length
  const pmcount = workorderList.filter((currentWO) => {
    return currentWO.external === '0' && currentWO.wotype === 'PM'
  }).length
  const fpcount = workorderList.filter((currentWO) => {
    return currentWO.external === '0' && currentWO.wotype === 'FP'
  }).length
  const intWoCount = workorderList.filter((currentWO) => {
    return currentWO.external === '0'
  }).length
  const intloscount = workorderList.filter((currentWO) => {
    return currentWO.repair_by < tomorrow && currentWO.external === '0'
  }).length
  const extloscount = workorderList.filter((currentWO) => {
    const venWorkVal = currentWO.wovendworkval
    return (
      currentWO.repair_by < tomorrow &&
      currentWO.external === '1' &&
      (venWorkVal === 'Fail' || venWorkVal === null) &&
      currentWO.wotype !== 'ENVR'
    )
  }).length
  const extcount = workorderList.filter((currentWO) => {
    return (
      currentWO.external === '1' &&
      currentWO.wovendworkval !== 'Pass' &&
      currentWO.wotype !== 'ENVR'
    )
  }).length
  const venValidationCount = workorderList.filter((currentWO) => {
    const woType = currentWO.wotype
    const status = currentWO.wostatus
    return (
      (status === 'VCOMP' || status === 'VREPAIR') &&
      woType !== 'ENVR' &&
      woType !== 'INSP' &&
      currentWO.external === '1' &&
      currentWO.wovendworkval !== 'Pass' &&
      currentWO.wovendworkval !== 'Fail'
    )
  }).length
  const redirectCount = workorderList.filter((currentWO) => {
    return (
      currentWO.redirectreason != null &&
      currentWO.wovendworkval !== 'Pass' &&
      currentWO.wovendworkval !== 'Fail'
    )
  }).length

  return {
    total,
    emcount,
    safetycount,
    cmAndEmCount,
    pmcount,
    fpcount,
    extcount,
    redirectCount,
    extloscount,
    intWoCount,
    intloscount,
    venValidationCount,
    autocount,
  }
}
