import { useAuth } from '@praxis/component-auth'
import { useState } from 'react'
import Alert from '@mui/material/Alert'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import TextField from '@mui/material/TextField'
import { setShouldRender } from '../ProgressOverlay/store/actionCreator'
import { setStoreId } from './store/actionCreator'
import config from '../../config/appConfig'
import useHashChange from '../../utils/hooks/useHashChange'
import { resetnavItemCounts } from '../../utils'
import { setNavItems } from '../NavigationItems/store/actionCreator'
import { IconButton, InputAdornment } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { makeStyles, createStyles } from '@mui/styles'
import { deleteURLSearchParams } from '../../windowManager'

const useStyles = makeStyles((theme) =>
  createStyles({
    inputAdornment: {
      marginRight: theme.spacing(-0.5),
    },
    dialogContent: {
      padding: theme.spacing(0, 2, 2, 2),
    },
  }),
)

export const SiteChangeDialog = () => {
  const auth = useAuth()
  const dispatch = useDispatch()
  const { session } = auth
  const userInfo = session.userInfo
  const [open, setOpen] = useState(false)
  useHashChange('site_change_open', setOpen)
  const [input, setInput] = useState('')
  const [storeroomSite, setStoreroomSite] = useState('')
  const [defaultStoreroom, setDefaultStoreroom] = useState('')
  const STORE_NUMBER_DIGIT_LENGTH = 5
  const [error, setError] = useState(false)
  const classes = useStyles()

  const { navItems, storeId, userStoreroom, isDCUser } = useSelector(
    (state) => ({
      ...state.navItemsReducer,
      ...state.storeInfoReducer,
      ...state.userInfoReducer,
    }),
  )

  const onChange = (event) => {
    const eventTargetValue = event.target.value.replace(/\D/g, '')
    eventTargetValue.length <= STORE_NUMBER_DIGIT_LENGTH - 1 &&
      setInput(`T${eventTargetValue}`)
  }

  const onStoreroomChange = (event) => {
    const eventTargetValue = event.target.value.replace(/\D/g, '')
    eventTargetValue.length <= STORE_NUMBER_DIGIT_LENGTH - 1 &&
      setDefaultStoreroom(`SR${eventTargetValue}`)
    eventTargetValue.length <= STORE_NUMBER_DIGIT_LENGTH - 1 &&
      setStoreroomSite(`T${eventTargetValue}`)
  }

  const onClose = () => {
    window.history.back()
    setError(false)
  }

  const changeSite = async () => {
    if (input || defaultStoreroom) {
      const configuration = await config()
      const requestConfig = {
        headers: {
          Authorization: userInfo.accessToken,
          'Content-Type': 'application/json',
        },
        crossDomain: true,
      }

      const requestBody = {
        defsite: input === '' ? storeId : input,
        defstoreroom:
          defaultStoreroom === '' ? userStoreroom : defaultStoreroom,
        storeroomsite: storeroomSite,
        lanid: userInfo.lanId,
      }

      try {
        dispatch(setShouldRender(true, 'Updating...'))
        setError(false)
        await axios.post(
          `${configuration.baseURL}/mam/changesite/?key=${configuration.auth.apiKey}`,
          requestBody,
          requestConfig,
        )
        deleteURLSearchParams([{ name: 'site_change_open' }], true)
        dispatch(setStoreId(input))
        dispatch(setNavItems(resetnavItemCounts(navItems)))
        sessionStorage.clear()
        window.location.replace(window.location.pathname)
      } catch (error) {
        setError(true)
      } finally {
        dispatch(setShouldRender(false))
      }
    } else {
      setError(true)
    }
  }

  const onSiteClear = () => {
    setInput('')
  }

  const onDefaultStoreroomClear = () => {
    setDefaultStoreroom('')
    setStoreroomSite('')
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Change Default Site</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {error && (
          <Alert data-testid={'error-message'} severity="error">
            Unable to change default site.
          </Alert>
        )}
        <TextField
          margin="dense"
          label={storeId}
          value={input}
          onChange={onChange}
          onKeyDown={(e) => e.key === 'Enter' && changeSite()}
          variant="outlined"
          fullWidth
          data-testid={'store-textbox'}
          InputProps={{
            endAdornment: (
              <InputAdornment
                className={classes.inputAdornment}
                // only show the adornment if there is input in the box
                style={{ visibility: input.length ? 'visible' : 'hidden' }}
                position="end"
              >
                <IconButton
                  data-testid={'store-clear-button'}
                  onClick={onSiteClear}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        {isDCUser && (
          <TextField
            margin="dense"
            data-testid={'storeroom-textbox'}
            label={
              userStoreroom === '' || userStoreroom === undefined
                ? 'Enter Storeroom'
                : userStoreroom
            }
            value={defaultStoreroom}
            onChange={onStoreroomChange}
            onKeyDown={(e) => e.key === 'Enter' && changeSite()}
            variant="outlined"
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment
                  className={classes.inputAdornment}
                  style={{
                    visibility: defaultStoreroom.length ? 'visible' : 'hidden',
                  }}
                  position="end"
                >
                  <IconButton
                    data-testid={'storeroom-clear-button'}
                    onClick={onDefaultStoreroomClear}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        )}
      </DialogContent>
      <DialogActions className={classes.dialogContent}>
        <Button variant="outlined" onClick={onClose}>
          Close
        </Button>
        <Button variant="contained" onClick={changeSite}>
          Change Site
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default SiteChangeDialog
