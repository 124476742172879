/* eslint-disable react-hooks/exhaustive-deps */
import { useNavigate, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { getURLSearchParams } from '../../windowManager'
import {
  FOLLOW_UP_SR_URL_PARAM,
  FOLLOW_UP_WO_URL_PARAM,
  PROBLEM_CODE_PARAM,
  globalRoutes,
} from '../../globalConstants'
import CacheListManager from '../CacheListManager/CacheListManager'

export default function CreateWorkOrderInfo({ createWorkOrderFlow }) {
  const navigate = useNavigate()
  const location = useLocation()
  const searchParams = getURLSearchParams()
  const locationPathname = location?.pathname
  const isFollowUpSr = searchParams.get(FOLLOW_UP_SR_URL_PARAM)
  const isFollowUpWo = searchParams.get(FOLLOW_UP_WO_URL_PARAM)
  const problem = searchParams.get(PROBLEM_CODE_PARAM)
  const isAreaOfStoreRoute = locationPathname === globalRoutes.areaOfStore
  const isAssetSelectRoute = locationPathname === globalRoutes.assetsSelect
  const isDuplicateWoRoute =
    locationPathname === globalRoutes.duplicateWorkOrderList
  const isCreateWoRoute = locationPathname === globalRoutes.createWorkOrder
  const {
    selectedAsset: { assetName } = {},
    createWorkOrderData: { areaOfStore } = {},
    areaOrLocation,
  } = useSelector((state) => ({
    ...state.assetSelectReducer,
    ...state.createWorkOrderReducer,
    ...state.userInfoReducer,
  }))

  const CREATE_WO_ASSET_AREA_OF_STORE_TEXT = () => {
    let infoText = `Creating new WO ${assetName ? `for Asset ${assetName}` : ''}
    ${assetName && areaOfStore && problem ? ',' : ''}
    ${
      areaOfStore
        ? `${
            !problem && assetName ? 'and' : ''
          } ${areaOrLocation} ${areaOfStore}`
        : ''
    }
    ${problem ? `and Problem ${problem}` : ''}
    `
    if (isAreaOfStoreRoute) {
      infoText = `Creating new WO for ${areaOrLocation}`
    }
    if (isAssetSelectRoute) {
      infoText = `Creating new WO for Asset`
    }
    return infoText
  }

  return createWorkOrderFlow &&
    !isFollowUpSr &&
    !isFollowUpWo &&
    !isDuplicateWoRoute ? (
    <CacheListManager
      listLength={true}
      clearMethod={() => navigate('/home')}
      customText={CREATE_WO_ASSET_AREA_OF_STORE_TEXT()}
      customButtonText="Cancel"
      noAction={
        isDuplicateWoRoute || isFollowUpSr || isFollowUpWo || isCreateWoRoute
          ? true
          : false
      }
    />
  ) : null
}
