export const getURLSearchParams = () => {
  return new URLSearchParams(window.location.hash.substring(1))
}

const setLocation = (searchParams, replaceHistoryEntry) => {
  const baseURL =
    window.location.protocol +
    '//' +
    window.location.host +
    window.location.pathname
  const formattedSearchUrl = searchParams.toString()
  const finalURL = `${baseURL}${
    formattedSearchUrl ? `#?${searchParams.toString()}` : ''
  }`
  if (replaceHistoryEntry) {
    /* If we are replacing history using window object, then use replace when there is an existing search param value and if not, use window.history.replaceState 
       as this would remove the trailing # and replace the url without reloading the page  */
    if (formattedSearchUrl) {
      window.location.replace(finalURL)
    } else {
      window.history.replaceState(null, '', finalURL)
    }
  } else {
    window.location.href = finalURL
  }
}

export const setURLSearchParams = (
  urlSearchParams = [],
  replaceHistoryEntry = false,
) => {
  const searchParams = getURLSearchParams()
  for (var i = 0; i < urlSearchParams.length; i++) {
    const urlSearchParam = urlSearchParams[i]
    // added logic to delete a url param key if value is null, undefined or empty string
    if (!urlSearchParam.value && urlSearchParam.value !== 0) {
      searchParams.delete(urlSearchParam.name)
    } else {
      searchParams.set(urlSearchParam.name, urlSearchParam.value)
    }
  }
  setLocation(searchParams, replaceHistoryEntry)
}

export const deleteURLSearchParams = (
  urlSearchParams = [],
  replaceHistoryEntry = false,
) => {
  const searchParams = getURLSearchParams()
  for (var i = 0; i < urlSearchParams.length; i++) {
    const urlSearchParam = urlSearchParams[i]
    searchParams.delete(urlSearchParam.name)
  }
  setLocation(searchParams, replaceHistoryEntry)
}
