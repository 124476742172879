export const ALLOWED_AD_GROUPS = [
  'APP-MXE-STR-TECH',
  'APP-MXE-STR-BP',
  'APP-MXE-HQ-TTS',
  'APP-MXE-HQ-ASSETMGR',
  'APP-MXE-STR-DIR',
  'APP-MXE-STR-ML',
  'APP-MXE-HQ-MAXADMIN',
  'APP-MXE-HQ-CALLCTR',
  'APP-MXE-AP-MGR',
  'APP-MXE-CRE-TECH',
  'APP-MXE-CRE-SUPERVISOR',
  'APP-MXE-CRE-PROJECTTEAM',
  'APP-MXE-HQ-GENUSER',
  'APP-MXE-CRE-BOC',
  'APP-MXE-CRE-PAINTER',
  'APP-MXE-CRE-AV',
  'APP-MXE-CRE-AVCONT',
  'APP-MXE-CRE-MOVERS',
  'APP-MXE-CRE-MAXADMIN',
  'APP-MXE-CRE-HOUSEKEEPING',
  'APP-MXE-DC-CMMSTECH',
  'APP-MXE-DC-EFADMIN',
  'APP-MXE-DC-FLEX-UA',
  'APP-MXE-DC-GL',
  'APP-MXE-DC-HQFIN',
  'APP-MXE-DC-HQUSER',
  'APP-MXE-DC-MAXADMIN',
  'APP-MXE-DC-MECH',
  'APP-MXE-DC-RED',
  'APP-MXE-DC-SGL',
  'APP-MXE-DC-UA',
]

export const DC_GROUP = [
  'APP-MXE-DC-CMMSTECH',
  'APP-MXE-DC-EFADMIN',
  'APP-MXE-DC-FLEX-UA',
  'APP-MXE-DC-GL',
  'APP-MXE-DC-HQFIN',
  'APP-MXE-DC-HQUSER',
  'APP-MXE-DC-MAXADMIN',
  'APP-MXE-DC-MECH',
  'APP-MXE-DC-RED',
  'APP-MXE-DC-SGL',
  'APP-MXE-DC-UA',
]

export const ADMIN_GROUP = ['APP-MXE-HQ-MAXADMIN', 'APP-MXE-HQ-TTS']

export const LVM_OPTIONS = [
  {
    label: 'LVM: PML to PMBP',
    value: 'LVM: PML to PMBP',
    lvmSelection: 'tgt-lvm1',
    access: [
      'APP-MXE-STR-TECH',
      'APP-MXE-HQ-TTS',
      'APP-MXE-STR-BP',
      'APP-MXE-STR-DIR',
      'APP-MXE-STR-ML',
      'APP-MXE-HQ-MAXADMIN',
    ],
  },
  {
    label: 'LVM: Vendor Action Plan',
    value: 'LVM: Vendor Action Plan',
    lvmSelection: 'tgt-lvm3',
    access: [
      'APP-MXE-STR-TECH',
      'APP-MXE-HQ-TTS',
      'APP-MXE-STR-BP',
      'APP-MXE-STR-DIR',
      'APP-MXE-STR-ML',
      'APP-MXE-HQ-ASSETMGR',
      'APP-MXE-HQ-CALLCTR',
      'APP-MXE-HQ-MAXADMIN',
    ],
  },
  {
    label: 'LVM: PMBP to HQ',
    value: 'LVM: PMBP to HQ',
    lvmSelection: 'tgt-lvm2',
    access: [
      'APP-MXE-HQ-TTS',
      'APP-MXE-STR-BP',
      'APP-MXE-STR-DIR',
      'APP-MXE-STR-ML',
      'APP-MXE-HQ-MAXADMIN',
    ],
  },
  {
    label: 'LVM: FMOC to HQ',
    value: 'LVM: FMOC to HQ',
    lvmSelection: 'tgt-lvm4',
    access: ['APP-MXE-HQ-TTS', 'APP-MXE-HQ-CALLCTR', 'APP-MXE-HQ-MAXADMIN'],
  },
  {
    label: 'LVM: HQ Acknowledgement',
    value: 'LVM: HQ Acknowledgement',
    lvmSelection: 'tgt-lvm5',
    access: ['APP-MXE-HQ-TTS', 'APP-MXE-HQ-ASSETMGR', 'APP-MXE-HQ-MAXADMIN'],
  },
]

export const VENDOR_EXPECTATION_NOTE_BUTTON_ACCESS_GROUPS = [
  'APP-MXE-STR-TECH',
  'APP-MXE-STR-BP',
  'APP-MXE-HQ-TTS',
  'APP-MXE-HQ-ASSETMGR',
  'APP-MXE-STR-DIR',
  'APP-MXE-STR-ML',
  'APP-MXE-HQ-CALLCTR',
  'APP-MXE-HQ-MAXADMIN',
]

export const WORK_LOG_NOTE_BUTTON_ACCESS_GROUPS = [
  'APP-MXE-STR-TECH',
  'APP-MXE-STR-BP',
  'APP-MXE-HQ-TTS',
  'APP-MXE-HQ-ASSETMGR',
  'APP-MXE-STR-DIR',
  'APP-MXE-STR-ML',
  'APP-MXE-HQ-MAXADMIN',
  'APP-MXE-DC-CMMSTECH',
  'APP-MXE-DC-EFADMIN',
  'APP-MXE-DC-FLEX-UA',
  'APP-MXE-DC-GL',
  'APP-MXE-DC-HQFIN',
  'APP-MXE-DC-HQUSER',
  'APP-MXE-DC-MAXADMIN',
  'APP-MXE-DC-MECH',
  'APP-MXE-DC-RED',
  'APP-MXE-DC-SGL',
  'APP-MXE-DC-UA',
]

export const WORKLOG_NOTE_TYPE_OPTIONS = [
  {
    value: 'ISSUE_MITIGATED',
    noteaccess: [
      'APP-MXE-STR-TECH',
      'APP-MXE-STR-BP',
      'APP-MXE-STR-DIR',
      'APP-MXE-STR-ML',
      'APP-MXE-HQ-MAXADMIN',
    ],
  },
  {
    value: 'PR_ASSETNOT_MSRG',
    noteaccess: [
      'APP-MXE-HQ-TTS',
      'APP-MXE-STR-BP',
      'APP-MXE-STR-DIR',
      'APP-MXE-STR-ML',
      'APP-MXE-ASSETMGR',
      'APP-MXE-HQ-ASSETMGR',
      'APP-MXE-HQ-MAXADMIN',
    ],
    expectationaccess: [
      'APP-MXE-HQ-TTS',
      'APP-MXE-STR-BP',
      'APP-MXE-STR-DIR',
      'APP-MXE-STR-ML',
      'APP-MXE-ASSETMGR',
      'APP-MXE-HQ-ASSETMGR',
      'APP-MXE-STR-TECH',
      'APP-MXE-HQ-MAXADMIN',
    ],
  },
  {
    value: 'PR_MATERIALS',
    noteaccess: [
      'APP-MXE-STR-TECH',
      'APP-MXE-HQ-TTS',
      'APP-MXE-STR-BP',
      'APP-MXE-STR-DIR',
      'APP-MXE-STR-ML',
      'APP-MXE-ASSETMGR',
      'APP-MXE-HQ-ASSETMGR',
      'APP-MXE-HQ-MAXADMIN',
    ],
    expectationaccess: [
      'APP-MXE-STR-TECH',
      'APP-MXE-HQ-TTS',
      'APP-MXE-STR-BP',
      'APP-MXE-STR-DIR',
      'APP-MXE-STR-ML',
      'APP-MXE-ASSETMGR',
      'APP-MXE-HQ-ASSETMGR',
      'APP-MXE-HQ-MAXADMIN',
    ],
  },
  {
    value: 'PR_PROPOSAL',
    noteaccess: [
      'APP-MXE-HQ-TTS',
      'APP-MXE-STR-BP',
      'APP-MXE-STR-DIR',
      'APP-MXE-STR-ML',
      'APP-MXE-ASSETMGR',
      'APP-MXE-HQ-ASSETMGR',
      'APP-MXE-HQ-MAXADMIN',
    ],
    expectationaccess: [
      'APP-MXE-HQ-TTS',
      'APP-MXE-STR-BP',
      'APP-MXE-STR-DIR',
      'APP-MXE-STR-ML',
      'APP-MXE-ASSETMGR',
      'APP-MXE-HQ-ASSETMGR',
      'APP-MXE-STR-TECH',
      'APP-MXE-HQ-MAXADMIN',
    ],
  },
  {
    value: 'PR_VENDOR',
    noteaccess: [
      'APP-MXE-HQ-TTS',
      'APP-MXE-STR-BP',
      'APP-MXE-STR-DIR',
      'APP-MXE-STR-ML',
      'APP-MXE-ASSETMGR',
      'APP-MXE-HQ-ASSETMGR',
      'APP-MXE-HQ-MAXADMIN',
      'APP-MXE-HQ-CALLCTR',
    ],
    expectationaccess: [
      'APP-MXE-HQ-TTS',
      'APP-MXE-STR-BP',
      'APP-MXE-STR-DIR',
      'APP-MXE-STR-ML',
      'APP-MXE-ASSETMGR',
      'APP-MXE-HQ-ASSETMGR',
      'APP-MXE-STR-TECH',
      'APP-MXE-HQ-MAXADMIN',
      'APP-MXE-HQ-CALLCTR',
    ],
  },
  {
    value: 'PR_WAITHQ',
    noteaccess: [
      'APP-MXE-STR-BP',
      'APP-MXE-STR-DIR',
      'APP-MXE-STR-ML',
      'APP-MXE-ASSETMGR',
      'APP-MXE-HQ-ASSETMGR',
      'APP-MXE-STR-TECH',
      'APP-MXE-HQ-TTS',
      'APP-MXE-HQ-MAXADMIN',
    ],
    expectationaccess: [
      'APP-MXE-STR-BP',
      'APP-MXE-STR-DIR',
      'APP-MXE-STR-ML',
      'APP-MXE-ASSETMGR',
      'APP-MXE-HQ-ASSETMGR',
      'APP-MXE-HQ-TTS',
      'APP-MXE-HQ-MAXADMIN',
    ],
  },
  {
    value: 'PR_WAITSTORE',
    noteaccess: [
      'APP-MXE-STR-TECH',
      'APP-MXE-HQ-TTS',
      'APP-MXE-STR-BP',
      'APP-MXE-STR-DIR',
      'APP-MXE-STR-ML',
      'APP-MXE-ASSETMGR',
      'APP-MXE-HQ-ASSETMGR',
      'APP-MXE-HQ-MAXADMIN',
    ],
    expectationaccess: [
      'APP-MXE-STR-TECH',
      'APP-MXE-HQ-TTS',
      'APP-MXE-STR-BP',
      'APP-MXE-STR-DIR',
      'APP-MXE-STR-ML',
      'APP-MXE-ASSETMGR',
      'APP-MXE-HQ-ASSETMGR',
      'APP-MXE-HQ-MAXADMIN',
    ],
  },
  {
    value: 'PR_WORKPRIORITY',
    noteaccess: [
      'APP-MXE-STR-BP',
      'APP-MXE-STR-DIR',
      'APP-MXE-STR-ML',
      'APP-MXE-STR-TECH',
      'APP-MXE-HQ-TTS',
      'APP-MXE-HQ-MAXADMIN',
    ],
    expectationaccess: [
      'APP-MXE-STR-BP',
      'APP-MXE-STR-DIR',
      'APP-MXE-STR-ML',
      'APP-MXE-HQ-ASSETMGR',
      'APP-MXE-HQ-TTS',
      'APP-MXE-HQ-MAXADMIN',
    ],
  },
  {
    value: 'PR_NOTCOMPLETED',
    noteaccess: [
      'APP-MXE-STR-BP',
      'APP-MXE-STR-DIR',
      'APP-MXE-STR-ML',
      'APP-MXE-HQ-TTS',
      'APP-MXE-HQ-MAXADMIN',
    ],
    expectationaccess: [
      'APP-MXE-STR-BP',
      'APP-MXE-STR-DIR',
      'APP-MXE-STR-ML',
      'APP-MXE-HQ-TTS',
      'APP-MXE-HQ-MAXADMIN',
    ],
  },
  {
    value: 'REDIRECT',
    noteaccess: ['APP-MXE-STR-TECH', 'APP-MXE-HQ-TTS', 'APP-MXE-HQ-MAXADMIN'],
    expectationaccess: ['APP-MXE-HQ-TTS', 'APP-MXE-HQ-MAXADMIN'],
  },
  {
    value: 'UPDATE',
    noteaccess: [
      'APP-MXE-STR-BP',
      'APP-MXE-STR-DIR',
      'APP-MXE-STR-ML',
      'APP-MXE-STR-TECH',
      'APP-MXE-ASSETMGR',
      'APP-MXE-HQ-ASSETMGR',
      'APP-MXE-HQ-TTS',
      'APP-MXE-HQ-MAXADMIN',
      'APP-MXE-HQ-CALLCTR',
    ],
    expectationaccess: [
      'APP-MXE-STR-BP',
      'APP-MXE-STR-DIR',
      'APP-MXE-STR-ML',
      'APP-MXE-ASSETMGR',
      'APP-MXE-HQ-TTS',
      'APP-MXE-HQ-MAXADMIN',
      'APP-MXE-HQ-CALLCTR',
    ],
  },
  {
    value: 'SAFETY',
    noteaccess: [
      'APP-MXE-STR-BP',
      'APP-MXE-STR-DIR',
      'APP-MXE-STR-ML',
      'APP-MXE-STR-TECH',
      'APP-MXE-HQ-TTS',
      'APP-MXE-HQ-MAXADMIN',
    ],
    expectationaccess: [
      'APP-MXE-STR-BP',
      'APP-MXE-STR-DIR',
      'APP-MXE-STR-ML',
      'APP-MXE-HQ-TTS',
      'APP-MXE-HQ-MAXADMIN',
    ],
  },
  {
    value: 'QE_NO',
    noteaccess: [
      'APP-MXE-STR-BP',
      'APP-MXE-STR-DIR',
      'APP-MXE-STR-ML',
      'APP-MXE-HQ-TTS',
      'APP-MXE-HQ-MAXADMIN',
    ],
    expectationaccess: [
      'APP-MXE-STR-BP',
      'APP-MXE-STR-DIR',
      'APP-MXE-STR-ML',
      'APP-MXE-HQ-TTS',
      'APP-MXE-HQ-MAXADMIN',
    ],
  },
  {
    value: 'QE_YES',
    noteaccess: [
      'APP-MXE-STR-BP',
      'APP-MXE-STR-DIR',
      'APP-MXE-STR-ML',
      'APP-MXE-HQ-TTS',
      'APP-MXE-HQ-MAXADMIN',
    ],
    expectationaccess: [
      'APP-MXE-STR-BP',
      'APP-MXE-STR-DIR',
      'APP-MXE-STR-ML',
      'APP-MXE-HQ-TTS',
      'APP-MXE-HQ-MAXADMIN',
    ],
  },
  {
    value: 'DC_LOGNOTE',
    noteaccess: [
      'APP-MXE-DC-CMMSTECH',
      'APP-MXE-DC-EFADMIN',
      'APP-MXE-DC-FLEX-UA',
      'APP-MXE-DC-GL',
      'APP-MXE-DC-HQFIN',
      'APP-MXE-DC-HQUSER',
      'APP-MXE-DC-MAXADMIN',
      'APP-MXE-DC-MECH',
      'APP-MXE-DC-RED',
      'APP-MXE-DC-SGL',
      'APP-MXE-DC-UA',
    ],
    expectationaccess: [
      'APP-MXE-DC-CMMSTECH',
      'APP-MXE-DC-EFADMIN',
      'APP-MXE-DC-FLEX-UA',
      'APP-MXE-DC-GL',
      'APP-MXE-DC-HQFIN',
      'APP-MXE-DC-HQUSER',
      'APP-MXE-DC-MAXADMIN',
      'APP-MXE-DC-MECH',
      'APP-MXE-DC-RED',
      'APP-MXE-DC-SGL',
      'APP-MXE-DC-UA',
    ],
  },
]
