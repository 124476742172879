import {
  SET_WORK_ORDER,
  RESET_WORK_ORDER,
  SET_FOLLOW_UP_FLOW,
} from './actionType'
import { FOLLOW_UP_WO_SESSION_STORAGE_KEY } from '../../globalConstants'

const storedItem = JSON.parse(sessionStorage.getItem('createWorkOrderData'))
export const initialState = {
  createWorkOrderData: storedItem
    ? storedItem
    : {
        asset: '',
        assetDescription: '',
        areaOfStore: '',
        problem: '',
        assignToCraft: 'PML',
      },
  followUpFlow: false,
}

export default function createWorkOrderReducer(
  state = initialState,
  action = {},
) {
  switch (action.type) {
    case SET_WORK_ORDER: {
      const createWorkOrderData = action.createWorkOrderData
      const newState = {
        ...state,
        createWorkOrderData: createWorkOrderData,
      }

      sessionStorage.setItem(
        'createWorkOrderData',
        JSON.stringify(createWorkOrderData),
      )
      return newState
    }
    case RESET_WORK_ORDER:
      sessionStorage.removeItem('createWorkOrderData')
      sessionStorage.removeItem('selectedAsset')
      sessionStorage.removeItem('area')
      return { ...state, createWorkOrderData: initialState.createWorkOrderData }
    case SET_FOLLOW_UP_FLOW:
      if (action.value) {
        sessionStorage.setItem(
          FOLLOW_UP_WO_SESSION_STORAGE_KEY,
          JSON.stringify(action.properties),
        )
      } else {
        sessionStorage.removeItem(FOLLOW_UP_WO_SESSION_STORAGE_KEY)
      }
      return { ...state, followUpFlow: action.value }
    default:
      return state
  }
}
