import { SET_COMPLETED_WORK_ORDER_LIST, SET_COMPLETED_AUTH } from './actionType'

export const setCompletedWorkOrderList = (completedWorkOrders) => {
  return {
    type: SET_COMPLETED_WORK_ORDER_LIST,
    completedWorkOrders: completedWorkOrders,
  }
}

export const setCompletedAuth = (userGroups) => {
  return {
    type: SET_COMPLETED_AUTH,
    userGroups: userGroups,
  }
}
