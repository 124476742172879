import {
  SET_ASSET,
  SET_ASSET_ATTRIBUTES,
  SET_ASSET_WORKORDERS_LIST,
} from './actionType'

export function setAsset(asset) {
  return {
    type: SET_ASSET,
    asset: asset,
  }
}

export function setAssetWorkOrderList(workorders) {
  return {
    type: SET_ASSET_WORKORDERS_LIST,
    workorders: workorders,
  }
}

export function setAssetAttributes(assetAttributes) {
  return {
    type: SET_ASSET_ATTRIBUTES,
    assetAttributes: assetAttributes,
  }
}
