import WorkOrderTabMenu from '../WorkOrderTabMenu/WorkOrderTabMenu'
import WorkOrderSpeedDial from '../WorkOrderSpeedDial/WorkOrderSpeedDial'
import CancelDialog from '../CancelDialog/CancelDialog'
import AddTimeModal from '../AddTimeModal/AddTimeModal'

import ChangePriorityDialog from '../ChangePriorityDialog/ChangePriorityDialog'
const WorkOrderlanding = () => {
  return (
    <>
      <WorkOrderTabMenu />
      <WorkOrderSpeedDial />
      <AddTimeModal />
      <CancelDialog />
      <ChangePriorityDialog />
    </>
  )
}

export default WorkOrderlanding
