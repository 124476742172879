import { SET_SHOULD_RENDER } from './actionType'

export function setShouldRender(shouldRender, headerText, bodyText) {
  return {
    type: SET_SHOULD_RENDER,
    shouldRender: shouldRender,
    headerText: headerText,
    bodyText: bodyText,
  }
}
