/* eslint-disable react-hooks/exhaustive-deps */
import { Alert } from '@mui/material'
import MatAppBar from '../MatAppBar/MatAppBar'
import { useState, useEffect } from 'react'
import { connect, useSelector } from 'react-redux'
import { makeStyles, createStyles } from '@mui/styles'
import { setShouldRender } from '../ProgressOverlay/store/actionCreator'
import { setCmWorkOrderList } from '../WorkOrderList/WorkOrderListStore/actionCreator'
import { setAssignments } from '../WorkOrderList/WorkOrderListStore/actionCreator'
import CacheListManager from '../CacheListManager/CacheListManager'
import {
  api,
  INPUT_PERFORMANCE_DELAY,
  SCHEDULE_OR_ASSIGNMENT,
  WORK_ORDER_FILTER_PROPERTIES,
} from '../../globalConstants'
import { fetchData } from '../../service/HttpService'
import { useAuth } from '@praxis/component-auth'
import StatusAlert from '../StatusAlert/StatusAlert'
import CommonCardList from '../CommonCardList/CommonCardList'
import { filterAssignmentsBy7Days } from '../../utils'
import useSearch from '../../utils/hooks/useSearch'

const useStyles = makeStyles((theme) =>
  createStyles({
    cardHeader: {
      background: theme.palette.grey[300],
    },
    actionIcon: {
      margin: theme.spacing(1, 0.5, 0, 0),
    },
    cardContent: {
      padding: theme.spacing(1),
    },
    warningError: {
      margin: theme.spacing(1),
    },
  }),
)

// Leaving this named as CMWorkOrder as the app page will remain named Corrective Maintenance;
// however, this list actually contains both CM and EM work orders
const CMWorkOrders = ({
  cmWorkOrders,
  storeId,
  lastUpdated,
  setAssignments,
  setShouldRender,
  setCmWorkOrderList,
  toggle,
}) => {
  const classes = useStyles()
  const auth = useAuth()
  const userId = auth.session.userInfo.lanId?.toUpperCase()
  const URL_SEARCH_KEY = 'search'
  const [error, setError] = useState(false)
  const { isDCUser, shouldRender } = useSelector((state) => ({
    ...state.userInfoReducer,
    ...state.progressOverlayReducer,
  }))

  const [filteredWorkOrders, onSearch] = useSearch(
    URL_SEARCH_KEY,
    cmWorkOrders || [],
    WORK_ORDER_FILTER_PROPERTIES(isDCUser),
    true,
    INPUT_PERFORMANCE_DELAY,
  )

  const cmWorkOrdersLength = cmWorkOrders.length
  const filteredWorkOrdersLength = filteredWorkOrders?.length

  useEffect(() => {
    const getOpenWorkOrders = async () => {
      if (!cmWorkOrdersLength && !shouldRender) {
        try {
          setShouldRender(true, 'Loading...', 'CM Work Orders')
          setError(false)
          const response = await fetchData(api.openWorkOrders(storeId))
          setCmWorkOrderList(response.woDetails)
        } catch (error) {
          setError(true)
        } finally {
          setShouldRender(false)
        }
      }
    }
    getOpenWorkOrders()
  }, [cmWorkOrdersLength])

  useEffect(() => {
    return () => setCmWorkOrderList([])
  }, [])

  const getAssignments = async () => {
    try {
      setShouldRender(true, 'Loading...', SCHEDULE_OR_ASSIGNMENT(isDCUser))
      const response = await fetchData(api.myAssignments(storeId, userId))
      const assigmentsScheduledThisWeek = filterAssignmentsBy7Days(
        response.assignmentsdata,
      )
      setAssignments(assigmentsScheduledThisWeek)
    } catch (error) {
      setError(true)
    } finally {
      setShouldRender(false)
    }
  }

  useEffect(() => {
    cmWorkOrdersLength && getAssignments()
  }, [toggle, cmWorkOrdersLength])

  const infoMessage = `${filteredWorkOrdersLength} / ${cmWorkOrdersLength} CM Work Order(s)`

  return (
    <>
      <MatAppBar
        arrowBack
        text="CM Work Orders"
        search={{
          onSearch: (input) => onSearch(input),
          searchKey: URL_SEARCH_KEY,
        }}
      />
      {!shouldRender && cmWorkOrdersLength > 0 && (
        <CacheListManager
          lastUpdated={lastUpdated}
          listLength={cmWorkOrdersLength}
          clearMethod={() => setCmWorkOrderList([])}
        />
      )}

      {!filteredWorkOrdersLength && !shouldRender && !error && (
        <Alert
          severity="warning"
          data-testid={'no-data-text'}
          className={classes.warningError}
        >
          There are no corrective maintenance work orders to display.
        </Alert>
      )}
      {error && !shouldRender && (
        <Alert severity="error" className={classes.warningError}>
          Unable to connect to work orders service.
        </Alert>
      )}

      {filteredWorkOrders && (
        <>
          <StatusAlert text={infoMessage} />
          <CommonCardList
            listData={filteredWorkOrders}
            filteredWorkOrdersLength={filteredWorkOrdersLength}
          />
        </>
      )}
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    cmWorkOrders: state.workOrderListReducer.cmWorkOrders,
    storeId: state.storeInfoReducer.storeId,
    lastUpdated: state.workOrderListReducer.lastUpdated.cmWorkOrders,
    toggle: state.workOrderListReducer.toggle,
  }
}

const mapDispatchToProps = {
  setShouldRender,
  setCmWorkOrderList,
  setAssignments,
}

export default connect(mapStateToProps, mapDispatchToProps)(CMWorkOrders)
