/* eslint-disable react-hooks/exhaustive-deps */
import { Alert } from '@mui/material'
import MatAppBar from '../MatAppBar/MatAppBar'
import { useState, useEffect } from 'react'
import { connect, useSelector } from 'react-redux'
import { makeStyles, createStyles } from '@mui/styles'
import { setShouldRender } from '../ProgressOverlay/store/actionCreator'
import CacheListManager from '../CacheListManager/CacheListManager'
import {
  api,
  WORK_ORDERS,
  SCHEDULE_OR_ASSIGNMENT,
  WORK_ORDER_FILTER_PROPERTIES,
  INPUT_PERFORMANCE_DELAY,
} from '../../globalConstants'
import { fetchData } from '../../service/HttpService'
import { setActionNeededVendorWorkOrderList } from '../WorkOrderList/WorkOrderListStore/actionCreator'
import { setAssignments } from '../WorkOrderList/WorkOrderListStore/actionCreator'
import { useAuth } from '@praxis/component-auth'
import StatusAlert from '../StatusAlert/StatusAlert'
import CommonCardList from '../CommonCardList/CommonCardList'
import { filterAssignmentsBy7Days } from '../../utils'
import useSearch from '../../utils/hooks/useSearch'

const useStyles = makeStyles((theme) =>
  createStyles({
    cardHeader: {
      background: theme.palette.grey[300],
    },
    actionIcon: {
      margin: theme.spacing(1, 0.5, 0, 0),
    },
    cardContent: {
      padding: theme.spacing(1),
    },
    warningError: {
      margin: theme.spacing(1),
    },
  }),
)

const ActionNeededVendorWorkOrders = ({
  actionNeededVendorWorkOrders,
  setShouldRender,
  lastUpdated,
  storeId,
  setActionNeededVendorWorkOrderList,
  setAssignments,
  toggle,
}) => {
  const URL_SEARCH_KEY = 'search'
  const classes = useStyles()
  const auth = useAuth()
  const userId = auth.session.userInfo.lanId?.toUpperCase()
  const { isDCUser } = useSelector((state) => ({
    ...state.userInfoReducer,
  }))

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  const [filteredWorkOrders, onSearch] = useSearch(
    URL_SEARCH_KEY,
    actionNeededVendorWorkOrders || [],
    WORK_ORDER_FILTER_PROPERTIES(isDCUser),
    true,
    INPUT_PERFORMANCE_DELAY,
  )

  const actionNeededVendorWorkOrdersLength =
    actionNeededVendorWorkOrders?.length
  const infoMessage = `${filteredWorkOrders.length} / ${actionNeededVendorWorkOrdersLength} ${WORK_ORDERS}`

  useEffect(() => {
    const getOpenWorkOrders = async () => {
      if (!actionNeededVendorWorkOrdersLength && !loading) {
        try {
          setShouldRender(
            true,
            'Loading...',
            `Action Needed Vendor ${WORK_ORDERS}`,
          )
          setLoading(true)
          setError(false)
          const response = await fetchData(api.openWorkOrders(storeId))
          setActionNeededVendorWorkOrderList(response.woDetails)
        } catch (error) {
          setError(true)
        } finally {
          setLoading(false)
          setShouldRender(false)
        }
      }
    }
    getOpenWorkOrders()
  }, [actionNeededVendorWorkOrdersLength])

  useEffect(() => {
    return () => setActionNeededVendorWorkOrderList([])
  }, [])

  const getAssignments = async () => {
    try {
      setShouldRender(true, 'Loading...', SCHEDULE_OR_ASSIGNMENT(isDCUser))
      const response = await fetchData(api.myAssignments(storeId, userId))
      const assigmentsScheduledThisWeek = filterAssignmentsBy7Days(
        response.assignmentsdata,
      )
      setAssignments(assigmentsScheduledThisWeek)
    } catch (error) {
      setError(true)
    } finally {
      setLoading(false)
      setShouldRender(false)
    }
  }

  useEffect(() => {
    actionNeededVendorWorkOrdersLength && getAssignments()
  }, [toggle, actionNeededVendorWorkOrdersLength])

  return (
    <>
      <MatAppBar
        arrowBack
        text="Action Needed"
        search={{
          onSearch: (input) => onSearch(input),
          searchKey: URL_SEARCH_KEY,
        }}
      />
      {actionNeededVendorWorkOrdersLength > 0 && !loading && (
        <CacheListManager
          lastUpdated={lastUpdated}
          listLength={actionNeededVendorWorkOrdersLength}
          clearMethod={() => setActionNeededVendorWorkOrderList([])}
        />
      )}

      {!filteredWorkOrders?.length && !loading && !error && (
        <Alert
          severity="warning"
          data-testid={'no-data-text'}
          className={classes.warningError}
        >
          `There are no action needed vendor ${WORK_ORDERS} to display.`
        </Alert>
      )}
      {error && !loading && (
        <Alert severity="error" className={classes.warningError}>
          `Unable to connect to action needed vendor ${WORK_ORDERS} service.`
        </Alert>
      )}
      {filteredWorkOrders && !loading ? (
        <>
          <StatusAlert text={infoMessage} />
          <CommonCardList
            listData={filteredWorkOrders}
            filteredWorkOrdersLength={actionNeededVendorWorkOrdersLength}
          />
        </>
      ) : null}
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    actionNeededVendorWorkOrders:
      state.workOrderListReducer.actionNeededVendorWorkOrders,
    storeId: state.storeInfoReducer.storeId,
    lastUpdated:
      state.workOrderListReducer.lastUpdated.actionNeededVendorWorkOrders,
    toggle: state.workOrderListReducer.toggle,
  }
}

const mapDispatchToProps = {
  setShouldRender,
  setActionNeededVendorWorkOrderList,
  setAssignments,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ActionNeededVendorWorkOrders)
