import { useEffect } from 'react'
import { getURLSearchParams } from '../../windowManager'

export default function useHashChange(property, cb) {
  const onHashChange = () => {
    const searchParams = getURLSearchParams()
    const open = Boolean(searchParams.get(property))
    cb(open)
  }

  useEffect(() => {
    window.addEventListener('hashchange', onHashChange)
    onHashChange()
    return () => window.removeEventListener('hashchange', onHashChange)
  })

  return null
}
