/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef } from 'react'
import { Alert } from '@mui/material'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import WorkOrderList from '../WorkOrderList/WorkOrderList'
import {
  OPEN_WORK_ORDERS,
  OPEN_WORK_ORDER_ERROR,
  OPEN_WORK_ORDER_EMPTY,
  WORK_ORDER_FILTER_PROPERTIES,
  INPUT_PERFORMANCE_DELAY,
  api,
  OPEN_WORK_ORDERS_PROP,
  ASSIGNMENTS_ERROR,
  SCHEDULE_OR_ASSIGNMENT,
} from '../../globalConstants'
import useSearch from '../../utils/hooks/useSearch'
import MatAppBar from '../MatAppBar/MatAppBar'
import {
  setWorkorderList,
  setAssignments,
} from '../WorkOrderList/WorkOrderListStore/actionCreator'
import { setShouldRender } from '../ProgressOverlay/store/actionCreator'
import { fetchData } from '../../service/HttpService'
import CacheListManager from '../CacheListManager/CacheListManager'
import { useAuth } from '@praxis/component-auth'
import { filterAssignmentsBy7Days } from '../../utils'

const AllOpenWorkOrders = () => {
  const dispatch = useDispatch()
  const initialLoad = useRef(true)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const {
    storeId,
    [OPEN_WORK_ORDERS_PROP]: allOpenWorkOrders,
    lastUpdated: { [OPEN_WORK_ORDERS_PROP]: openWorkOrdersLastUpdated },
    toggle,
  } = useSelector(
    (state) => ({
      ...state.workOrderListReducer,
      ...state.storeInfoReducer,
    }),
    shallowEqual, // Added shallowEqual here so that opening of the Hamburger menu doesnt re-render this custom hook
  )
  const openWorkOrdersLength = allOpenWorkOrders?.length || 0
  const auth = useAuth()
  const userId = auth.session.userInfo.lanId?.toUpperCase()
  const URL_SEARCH_KEY = 'search'
  const { isDCUser } = useSelector((state) => ({
    ...state.userInfoReducer,
  }))

  const [filteredOpenWorkOrders, onSearch] = useSearch(
    URL_SEARCH_KEY,
    allOpenWorkOrders || [],
    WORK_ORDER_FILTER_PROPERTIES(isDCUser),
    true,
    INPUT_PERFORMANCE_DELAY,
  )

  useEffect(() => {
    dispatch(setWorkorderList([]))
  }, [])

  useEffect(() => {
    async function getOpenWorkOrders() {
      if (
        (openWorkOrdersLength === 0 || allOpenWorkOrders === null) &&
        !loading
      ) {
        try {
          dispatch(setShouldRender(true, 'Loading...', 'Open Work Orders'))
          setLoading(true)
          setError(false)
          const result = await fetchData(api.openWorkOrders(storeId))
          const { woDetails = [] } = result
          dispatch(setWorkorderList(woDetails))
          initialLoad.current = false
          if (openWorkOrdersLength === 0) {
            await getAssignments()
          }
        } catch (e) {
          setError(OPEN_WORK_ORDERS)
        } finally {
          setLoading(false)
          dispatch(setShouldRender(false))
        }
      }
    }
    getOpenWorkOrders()
  }, [allOpenWorkOrders.length])

  async function getAssignments(loading) {
    try {
      dispatch(
        setShouldRender(true, 'Loading...', SCHEDULE_OR_ASSIGNMENT(isDCUser)),
      )
      const response = await fetchData(api.myAssignments(storeId, userId))
      const assigmentsScheduledThisWeek = filterAssignmentsBy7Days(
        response.assignmentsdata,
      )
      dispatch(setAssignments(assigmentsScheduledThisWeek))
    } catch (e) {
      setError(SCHEDULE_OR_ASSIGNMENT(isDCUser))
    } finally {
      if (loading) {
        dispatch(setShouldRender(false))
      }
    }
  }

  useEffect(() => {
    if (toggle !== null || openWorkOrdersLength) {
      !initialLoad.current && getAssignments(true)
    }
  }, [toggle])

  return (
    <>
      <MatAppBar
        arrowBack
        text={OPEN_WORK_ORDERS}
        search={{
          onSearch: (input) => onSearch(input),
          searchKey: URL_SEARCH_KEY,
        }}
      />
      {error && !loading && (
        <Alert severity="error">
          {error === OPEN_WORK_ORDERS
            ? OPEN_WORK_ORDER_ERROR
            : ASSIGNMENTS_ERROR(isDCUser)}
        </Alert>
      )}
      {!error && openWorkOrdersLength === 0 && !loading && (
        <Alert severity="warning">{OPEN_WORK_ORDER_EMPTY}</Alert>
      )}
      {openWorkOrdersLength > 0 && !loading && (
        <>
          <CacheListManager
            lastUpdated={openWorkOrdersLastUpdated}
            listLength={openWorkOrdersLength}
            clearMethod={() => dispatch(setWorkorderList([]))}
          />
          <WorkOrderList
            data={filteredOpenWorkOrders}
            from={OPEN_WORK_ORDERS}
            wolength={openWorkOrdersLength}
          />
        </>
      )}
    </>
  )
}

export default AllOpenWorkOrders
