import { useAuth } from '@praxis/component-auth'
import MatAppBar from '../MatAppBar/MatAppBar'
import NavigationItems from '../NavigationItems/NavigationItems'
import { HOME } from '../../globalConstants'
import { useSelector } from 'react-redux'
import { Alert, Button } from '@mui/material'
import { makeStyles, createStyles } from '@mui/styles'
import { setURLSearchParams } from '../../windowManager'

const useStyles = makeStyles((theme) =>
  createStyles({
    warningError: {
      marginBottom: theme.spacing(1),
      alignItems: 'center',
    },
  }),
)

const Home = () => {
  const auth = useAuth()
  const { session } = auth
  const classes = useStyles()
  const { isDCUser, userStoreroom } = useSelector((state) => ({
    ...state.navItemsReducer,
    ...state.userInfoReducer,
  }))

  const onClick = () => {
    setURLSearchParams([{ name: 'site_change_open', value: true }])
  }

  return (
    <>
      <MatAppBar text={session.userInfo?.fullName} changeSite />

      <>
        {(isDCUser && userStoreroom) || !isDCUser ? (
          <NavigationItems from={HOME} />
        ) : (
          <>
            <Alert
              severity="warning"
              className={classes.warningError}
              action={
                <Button variant="outlined" color="inherit" onClick={onClick}>
                  SET HERE
                </Button>
              }
            >
              Set a storeroom
            </Alert>
            <Alert severity="error">
              You must set a storeroom to continue.
            </Alert>
          </>
        )}
      </>
    </>
  )
}

export default Home
