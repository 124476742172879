/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, Fragment } from 'react'
import {
  Alert,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
} from '@mui/material'
import { makeStyles, createStyles } from '@mui/styles'
import { Box } from '@mui/system'
import CloseIcon from '@mui/icons-material/Close'
import useHashChange from '../../utils/hooks/useHashChange'
import { useSelector, useDispatch } from 'react-redux'
import { fetchData } from '../../service/HttpService'
import { DEFAULT_ERROR_MESSAGE } from '../../globalConstants'
import { setSavedVendor, setVendorContacts } from './store/actionCreator'
import { setShouldRender } from '../ProgressOverlay/store/actionCreator'
import { Contacts, Person } from '@mui/icons-material'
import { setURLSearchParams } from '../../windowManager'

const useStyles = makeStyles((theme) =>
  createStyles({
    label: {
      '@media(min-width:400px)': {
        flex: 1,
      },
    },
    component: {
      '@media(min-width:400px)': {
        flex: 2,
      },
    },
  }),
)

const VendorContacts = ({ param }) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  useHashChange(param, setOpen)
  const [error, setError] = useState(false)
  const {
    wodetails = null,
    technicianData = null,
    vendor,
  } = useSelector((state) => ({
    ...state.workOrderDetailsReducer,
    ...state.vendorContactsReducer,
  }))
  const companyId = wodetails?.woassignvendor
  const load = technicianData === null || vendor !== companyId
  const dispatch = useDispatch()

  const onClose = () => {
    window.history.back()
  }

  useEffect(() => {
    if (open && load) {
      try {
        dispatch(setShouldRender(true, 'Loading Contacts...'))
        const getVendorLookup = async () => {
          const response = await fetchData(
            `/mam/vendorlookup/orgId/TGTSTORE/company/${companyId}`,
          )
          dispatch(setSavedVendor(companyId))
          dispatch(setVendorContacts(response))
        }
        getVendorLookup()
      } catch {
        setError(true)
      } finally {
        dispatch(setShouldRender(false))
      }
    }
  }, [open])

  return (
    <>
      {wodetails.jpnum && wodetails.jpnum.match(/-EXT/) && (
        <Tooltip title="Vendor Contacts">
          <Button
            aria-label="Vendor Contacts"
            onClick={() => {
              setURLSearchParams([{ name: param, value: true }])
            }}
            data-testid="vendor-contact-dialog-button"
          >
            <Contacts />
          </Button>
        </Tooltip>
      )}
      <Dialog open={open} onClose={() => onClose()}>
        <DialogTitle>
          <Box display="flex" alignItems="center">
            <Box flexGrow={1}>Vendor Contacts</Box>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          {technicianData?.vendordata?.length === 0 && (
            <Alert severity="warning">No vendor contacts found</Alert>
          )}
          {error && <Alert severity="error">{DEFAULT_ERROR_MESSAGE}</Alert>}
          <List>
            {technicianData?.vendordata?.map((technician, index) => {
              return (
                <Fragment key={index}>
                  <ListItem key={index} disablePadding>
                    <ListItemIcon>
                      <Person />
                    </ListItemIcon>
                    <ListItemText
                      primary={technician.contact || 'No Name Found'}
                    />
                  </ListItem>
                  <List sx={{ '@media(min-width:500px)': { paddingLeft: 2 } }}>
                    {technician.position && (
                      <ListItemText
                        primary={
                          <Typography
                            component="div"
                            display={'flex'}
                            sx={{ flexWrap: 'wrap' }}
                          >
                            <div className={classes.label}>Position:</div>
                            <div className={classes.component}>
                              {technician.position}
                            </div>
                          </Typography>
                        }
                        inset
                      />
                    )}
                    {technician.email && (
                      <ListItemText
                        primary={
                          <Typography
                            component="div"
                            display={'flex'}
                            sx={{ flexWrap: 'wrap' }}
                          >
                            <div className={classes.label}>Email:</div>
                            <div className={classes.component}>
                              <Link
                                href={`mailto:${technician.email}`}
                                sx={{ display: 'flex', alignItems: 'center' }}
                                target="_blank"
                              >
                                {technician.email}
                              </Link>
                            </div>
                          </Typography>
                        }
                        inset
                      />
                    )}
                    {technician.voicePhone && (
                      <ListItemText
                        primary={
                          <Typography
                            component="div"
                            display={'flex'}
                            sx={{ flexWrap: 'wrap' }}
                          >
                            <div className={classes.label}>Phone:</div>
                            <div className={classes.component}>
                              <Link
                                href={`tel:${technician.voicePhone}`}
                                sx={{ display: 'flex', alignItems: 'center' }}
                              >
                                {technician.voicePhone}
                              </Link>
                            </div>
                          </Typography>
                        }
                        inset
                      />
                    )}
                    {technician.faxPhone && (
                      <ListItemText
                        primary={
                          <Typography
                            component="div"
                            display={'flex'}
                            sx={{ flexWrap: 'wrap' }}
                          >
                            <div className={classes.label}>Fax:</div>
                            <div className={classes.component}>
                              {technician.faxPhone}
                            </div>
                          </Typography>
                        }
                        inset
                      />
                    )}
                  </List>
                </Fragment>
              )
            })}
          </List>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default VendorContacts
