import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import { Fragment, useState } from 'react'
import { ExpandMore } from '@mui/icons-material'
import { setCollapsedInStore } from './store/actionCreator'
import { makeStyles, createStyles } from '@mui/styles'
import { useNavigate } from 'react-router-dom'
import { globalRoutes } from '../../globalConstants'
import { COLOR_BACKGROUND_GREY } from '../../globalColors'

const useStyles = makeStyles((theme) =>
  createStyles({
    accordionSummary: {
      backgroundColor: COLOR_BACKGROUND_GREY,
    },
    list: {
      padding: theme.spacing(0),
    },
    accordion: {
      borderWidth: '0px 1px',
      borderColor: theme.palette.divider,
      borderStyle: 'solid',
      marginTop: theme.spacing(1),
    },
  }),
)

const ScheduleShifts = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const { shiftKeys, myShifts, otherShifts, collapsedInStore } = useSelector(
    (state) => ({
      ...state.schedulesReducer,
    }),
  )
  const dispatch = useDispatch()
  const [collapsed, setCollapsed] = useState(
    collapsedInStore ||
      Object.fromEntries(Object.keys(shiftKeys).map((val) => [val, false])),
  )
  const handleClick = (val) => {
    const updatedCollapsed = { ...collapsed, [val]: !collapsed[val] }
    setCollapsed(updatedCollapsed)
    dispatch(setCollapsedInStore(updatedCollapsed))
  }

  const combinedShiftInfo = myShifts.concat(otherShifts)

  const renderShiftInfo = (shift) => {
    const thisShift = combinedShiftInfo.filter((a) => a.shiftNum === shift)
    return (
      <List className={classes.list}>
        {thisShift.map((val, index) => {
          return (
            <Fragment key={index}>
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() =>
                    navigate(
                      `${globalRoutes.schedules}#?user=${val.personId}&name=${val.displayName}`,
                    )
                  }
                >
                  <ListItemText
                    primary={<Typography>{val.displayName}</Typography>}
                    secondary={
                      <Typography>
                        {val.personId}
                        <br />
                        {val.title}
                      </Typography>
                    }
                  />
                </ListItemButton>
              </ListItem>
              <Divider />
            </Fragment>
          )
        })}
      </List>
    )
  }

  return shiftKeys.map((val, index) => {
    return (
      <Accordion
        key={index}
        expanded={collapsed?.[val] ?? false}
        onChange={() => handleClick(val)}
        className={classes.accordion}
        data-testid={`accordion-${index}`}
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          className={classes.accordionSummary}
        >
          <Typography>{val}</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: 0 }}>
          {renderShiftInfo(val)}
        </AccordionDetails>
      </Accordion>
    )
  })
}

export default ScheduleShifts
