import { SET_WORK_LOG_DETAILS, SET_WORK_LOG_NOTE_TYPES } from './actionType'

export function setWorkLogDetails(worklogDetails) {
  return {
    type: SET_WORK_LOG_DETAILS,
    worklogDetails: worklogDetails,
  }
}

export function setWorkLogNoteTypes(worklogNoteTypes) {
  return {
    type: SET_WORK_LOG_NOTE_TYPES,
    worklogNoteTypes: worklogNoteTypes,
  }
}
