/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useRef, useEffect } from 'react'
import { Button, Grid, TextField } from '@mui/material'
import { SaveSharp } from '@mui/icons-material'
import { makeStyles, createStyles } from '@mui/styles'
import ClickAwayListener from '@mui/base/ClickAwayListener'
import { getStringFromHtml } from '../../utils'
import { getNumberOfLinesInTextArea } from '../../utils'
const useStyles = makeStyles((theme) =>
  createStyles({
    saveButtonContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
  }),
)

export default function DynamicTextField({
  id,
  text,
  myRef,
  onChange,
  label,
  save,
  buttonAriaLabel,
  shouldScrollTo,
  rowsFromParent,
}) {
  const classes = useStyles()
  const [rows, setRows] = useState(rowsFromParent || null)
  const [value, setValue] = useState(getStringFromHtml(text))
  const textfieldRef = useRef(null)
  if (textfieldRef?.current) {
    textfieldRef.current.numOfLinesInText = getNumberOfLinesInTextArea(
      textfieldRef?.current,
    )
  }
  const { numOfLinesInText } = textfieldRef?.current ?? {}
  const scrollAndResize = () => {
    if (shouldScrollTo) {
      myRef?.current?.scrollIntoView?.({ behavior: 'smooth' })
    }
    setRows(
      rowsFromParent
        ? rowsFromParent > numOfLinesInText
          ? rowsFromParent
          : numOfLinesInText
        : null,
    )
  }

  useEffect(() => {
    setRows(
      rowsFromParent
        ? rowsFromParent > numOfLinesInText
          ? rowsFromParent
          : numOfLinesInText
        : numOfLinesInText < 4
          ? numOfLinesInText
          : 4,
    )
  }, [textfieldRef?.current])

  return (
    <>
      <Grid item xs={12}>
        <ClickAwayListener onClickAway={() => setRows(4)}>
          <TextField
            label={label}
            variant="outlined"
            id={id}
            data-testid="long-description-textfield"
            multiline
            fullWidth
            rows={numOfLinesInText < rows ? numOfLinesInText : rows}
            value={value}
            onChange={(e) => {
              setValue(getStringFromHtml(e.target.value))
              onChange?.(getStringFromHtml(e.target.value))
            }}
            onFocus={() => {
              scrollAndResize()
            }}
            inputRef={textfieldRef}
          />
        </ClickAwayListener>
      </Grid>
      {save && (
        <Grid item xs={12} className={classes.saveButtonContainer}>
          <Button
            startIcon={<SaveSharp />}
            onClick={() => {
              save('longDes', value)
            }}
            aria-label={buttonAriaLabel}
            disabled={!value || value.trim().length === 0 || text === value}
          >
            Save
          </Button>
        </Grid>
      )}
    </>
  )
}
