/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { getURLSearchParams } from '../../windowManager'
import {
  FOLLOW_UP_WO_URL_PARAM,
  WORK_ORDER_NUMBER_FIELD,
  FOLLOW_UP_WO_TEXT,
  globalRoutes,
  FOLLOW_UP_WO_SESSION_STORAGE_KEY,
  WORK_ORDER_DETAIL_ERROR,
  WO_DETAILS,
} from '../../globalConstants'
import { setFollowUpFlow } from '../CreateWorkOrder/actionCreator'
import CacheListManager from '../CacheListManager/CacheListManager'
import { Alert, CircularProgress, Box } from '@mui/material'
import { setShouldRender } from '../ProgressOverlay/store/actionCreator'
import { setWorkOrder } from '../WorkOrderDetails/store/actionCreator'
import { fetchData } from '../../service/HttpService'

export default function FollowUpWoForWorkOrders(renderFollowUpInfoIfExists) {
  const navigate = useNavigate()
  const urlLocation = useLocation()
  const dispatch = useDispatch()
  const routePathname = urlLocation?.pathname
  const routeHash = urlLocation?.hash
  const searchParams = getURLSearchParams()
  const isFollowUpWo = searchParams.get(FOLLOW_UP_WO_URL_PARAM)
  const isCreateWoRoute = urlLocation?.pathname === globalRoutes.createWorkOrder
  const woNumFromUrl = parseInt(searchParams.get(WORK_ORDER_NUMBER_FIELD))
  const { followUpFlow, wodetails, areaOrLocation } = useSelector((state) => ({
    ...state.createWorkOrderReducer,
    ...state.workOrderDetailsReducer,
    ...state.userInfoReducer,
  }))
  const propertiesFromSessionStorage =
    JSON?.parse?.(
      sessionStorage?.getItem?.(FOLLOW_UP_WO_SESSION_STORAGE_KEY) ?? '{}',
    ) ?? {}
  const {
    woassetname: asset = '',
    wolocation: location = '',
    wonum = '',
    from,
  } = propertiesFromSessionStorage ?? {}
  const fromWODetails = from === WO_DETAILS
  const sessionStorageExists =
    Object.keys(propertiesFromSessionStorage).length !== 0
  const sessionStorageCorrectWo =
    sessionStorageExists && parseInt(wonum) === woNumFromUrl ? true : false
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const shouldCallWoDetails = !sessionStorageCorrectWo && isFollowUpWo

  // this useEffect is to set the follow up WO URL for all pages in create Work order flow
  useEffect(() => {
    if (
      renderFollowUpInfoIfExists &&
      followUpFlow &&
      !isFollowUpWo &&
      !woNumFromUrl &&
      fromWODetails
    ) {
      navigate({
        pathname: routePathname,
        hash: `${routeHash}${routeHash ? '&' : '?'}${WORK_ORDER_NUMBER_FIELD}=${
          wonum ?? ''
        }&${FOLLOW_UP_WO_URL_PARAM}=true`,
      })
    }
  }, [followUpFlow])

  async function loadWoDetails() {
    try {
      setLoading(true)
      dispatch(setWorkOrder(null))
      dispatch(setShouldRender(true, 'Loading Work Order Details...'))
      const response = await fetchData(`/mam/workorder/wonum/${woNumFromUrl}`)
      const wodetails = response.woDetails[0]
      dispatch(setWorkOrder(wodetails))
      const woassetname = wodetails?.woassetname
      const wolocation = wodetails?.wolocation
      const woNum = wodetails?.wonum
      dispatch(
        setFollowUpFlow(true, {
          woassetname,
          wolocation,
          wonum: woNum,
          from: WO_DETAILS,
        }),
      )
    } catch (error) {
      setError(true)
    } finally {
      // do not setshouldrender to false as there is a delay in the follow up wo flow
      urlLocation.pathname !== globalRoutes.duplicateWorkOrderList &&
        dispatch(setShouldRender(false))
      setLoading(false)
    }
  }

  useEffect(() => {
    if (shouldCallWoDetails) {
      loadWoDetails()
    }
  }, [shouldCallWoDetails])

  // this useEffect is for the case when the page wih follow up work order is reloaded - this specifically handles flow from Work order details
  useEffect(() => {
    if (
      renderFollowUpInfoIfExists &&
      sessionStorageCorrectWo &&
      fromWODetails
    ) {
      dispatch(
        setFollowUpFlow(true, {
          woassetname: asset,
          wolocation: location,
          wonum,
          from: WO_DETAILS,
        }),
      )
    }
  }, [wodetails, sessionStorageCorrectWo])

  return (
    <>
      {loading && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <CircularProgress size="2rem" />
        </Box>
      )}
      {woNumFromUrl && isFollowUpWo && followUpFlow && fromWODetails ? (
        <CacheListManager
          listLength={true}
          clearMethod={() =>
            navigate(globalRoutes.getWoDetailsRoute(woNumFromUrl))
          }
          customText={FOLLOW_UP_WO_TEXT(
            woNumFromUrl,
            asset,
            location,
            areaOrLocation,
          )}
          customButtonText="Cancel"
          noAction={isCreateWoRoute ? true : false}
        />
      ) : null}
      {error && !loading && (
        <Alert severity="error">{WORK_ORDER_DETAIL_ERROR}</Alert>
      )}
    </>
  )
}
