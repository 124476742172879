/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Divider,
  Chip,
  Collapse,
  Stack,
  Alert,
  CircularProgress,
  Container,
} from '@mui/material'
import {
  api,
  HOME_PAGE_ERROR,
  INTERNAL_WORK,
  EXTERNAL_WORK,
  WORK_ORDER,
  HOME,
  LOG_MY_TIME,
} from '../../globalConstants'
import {
  fetchDataParallel,
  cancelHttpRequest,
  fetchData,
} from '../../service/HttpService'
import { setNavItems } from './store/actionCreator'
import { makeStyles, createStyles } from '@mui/styles'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import {
  buildNavigationList,
  checkIfNavItemCountsLoaded,
  resetnavItemCounts,
} from '../../utils'
import { useAuth } from '@praxis/component-auth'
import CacheListManager from '../CacheListManager/CacheListManager'
import { setFollowUpFlow } from '../CreateWorkOrder/actionCreator'
import {
  COLOR_TARGET_RED,
  COLOR_GREY_CONTRAST_WEAK,
  COLOR_THEME_ACCENT,
  COLOR_DEFAULT_GREY,
  COLOR_BLACK,
} from '../../globalColors'
import LogoutListItem from '../LogoutListItem/LogoutListItem'
import { useEnv } from '@praxis/component-runtime-env'

const useStyles = makeStyles((theme) =>
  createStyles({
    chipError: {
      backgroundColor: COLOR_TARGET_RED,
      color: COLOR_GREY_CONTRAST_WEAK,
    },
    chipPrimary: {
      backgroundColor: COLOR_THEME_ACCENT,
      color: COLOR_GREY_CONTRAST_WEAK,
    },
    chipNoValue: {
      backgroundColor: COLOR_DEFAULT_GREY,
      color: COLOR_GREY_CONTRAST_WEAK,
    },
    subHeader: {
      background: theme.palette.grey[100],
      cursor: 'pointer',
    },
    listItemTextPriority: {
      '& .Action': {
        color: theme.palette.error.main,
        marginRight: theme.spacing(1),
      },
    },
    listItemTextPrimaryNavigation: {
      color: 'inherit',
    },
    header: {
      padding: theme.spacing(1.5, 0),
    },
    listItemLabel: {
      marginLeft: theme.spacing(2),
    },
    listItemText: {
      display: 'flex',
      alignItems: 'center',
      '& > svg': {
        marginRight: theme.spacing(1.5),
      },
    },
    listItemTextDisabled: {
      opacity: 0.7,
      '& > svg': {
        '& > path': {
          fill: COLOR_BLACK,
          opacity: 0.7,
        },
      },
    },
    container: {
      paddingBottom: theme.spacing(6),
    },
  }),
)

const NavigationItems = ({ from, setAppDrawerOpen }) => {
  const classes = useStyles()
  const { navItems, storeId, lastUpdated, userRoles, isDCUser } = useSelector(
    (state) => ({
      ...state.navItemsReducer,
      ...state.storeInfoReducer,
      ...state.userInfoReducer,
    }),
  )
  const env = useEnv()

  const inAppMessage = env?.inAppMessage
  const appMessage = env?.appMessage
  const navItemsLoaded = checkIfNavItemCountsLoaded(navItems)
  const [loading, setLoading] = useState(false)
  const [laborEntries, setLaborEntries] = useState({
    value: null,
    loading: false,
  })
  const [storeRequestEntries, setStoreRequestEntries] = useState({
    value: null,
    loading: false,
  })
  const [error, setError] = useState(false)
  const dispatch = useDispatch()
  const [open, setOpen] = useState({
    [WORK_ORDER]: true,
    [INTERNAL_WORK]: true,
    [EXTERNAL_WORK]: true,
  })
  const auth = useAuth()
  const { session } = auth
  const userInfo = session.userInfo
  const userGroups = auth.session.userInfo.memberOf
  const homeRoute = from === HOME

  useEffect(() => {
    const accordionSession = sessionStorage.getItem('accordionStatus')
    if (accordionSession) {
      const parsedStatus = JSON.parse(accordionSession)
      setOpen({
        [WORK_ORDER]: parsedStatus?.[WORK_ORDER],
        [INTERNAL_WORK]: parsedStatus?.[INTERNAL_WORK],
        [EXTERNAL_WORK]: parsedStatus?.[EXTERNAL_WORK],
      })
    }
  }, [from])

  useEffect(() => {
    let isMounted = true
    async function getHomePageData() {
      if (!loading) {
        try {
          setError(false)
          dispatch(setNavItems(buildNavigationList(storeId, userRoles)))
          setLoading(true)
          const dayCount = isDCUser ? 10 : 30
          const result = await fetchDataParallel([
            api.userAssignmentsCancelCount(storeId),
            api.userAssignmentCompWoCount(storeId, dayCount),
            api.openWorkOrders(storeId),
          ])
          //prevent memory leak if component is unmounted before this completes - like closing hamburger menu or navigatng away from home page
          if (isMounted) {
            const items = buildNavigationList(
              storeId,
              userRoles,
              result[0],
              result[1],
              result[2],
              userGroups,
            )
            dispatch(setNavItems(items))
          }
        } catch (error) {
          isMounted && setError(true)
        } finally {
          isMounted && setLoading(false)
        }
      }
    }
    if (homeRoute) {
      getHomePageData()
      return () => {
        isMounted = false
        // Cancel requests if pending on unmount
        cancelHttpRequest()
      }
    } else {
      dispatch(setNavItems(buildNavigationList(storeId, userRoles)))
    }
  }, [])

  // seperate call for laborEntries API
  useEffect(() => {
    if (storeId && homeRoute) {
      callLaborEntriesApi()
    }
  }, [storeId])

  async function callLaborEntriesApi() {
    try {
      setError(false)
      setLaborEntries((laborEntries) => ({ ...laborEntries, loading: true }))
      const result = await fetchData(
        api.userLaborEntries(storeId, userInfo?.lanId?.toUpperCase()),
      )
      setLaborEntries((laborEntries) => ({
        loading: false,
        value: result?.totalTime ?? '',
      }))
    } catch {
      setError(true)
    }
  }
  // seperate call for store requests API
  useEffect(() => {
    if (storeId && homeRoute) {
      callStoreRequestsApi()
    }
  }, [storeId])

  async function callStoreRequestsApi() {
    try {
      setError(false)
      setStoreRequestEntries((storeRequestEntries) => ({
        ...storeRequestEntries,
        loading: true,
      }))
      const result = await fetchData(api.userAssignments(storeId))
      setStoreRequestEntries((storeRequests) => ({
        loading: false,
        value: result?.srCount,
      }))
    } catch {
      setError(true)
    }
  }

  const handleAccordionToggle = (header, expanded) => {
    const newStatus = { ...open, [header]: !expanded }
    setOpen(newStatus)
    sessionStorage.setItem('accordionStatus', JSON.stringify(newStatus))
  }

  function renderHomePageList(items) {
    const renderListItems = (data) => {
      return data.map((val, index) => {
        const count = val?.value
        const noCount = count === 0 || count === '0'
        const hasCount = Object.prototype.hasOwnProperty.call(val, 'value')
        const listItemRoute = val?.route
        const listItemLabel = val.label
        const listItemHighlight = val?.highlight
          ? classes.chipError
          : classes.chipPrimary
        const listItemIcon = val?.icon
        const hasAccess = val?.access
        const routeObj = listItemRoute && {
          component: Link,
          to: listItemRoute,
        }
        return (
          hasAccess && (
            <ListItem
              key={listItemLabel}
              button
              {...routeObj}
              onClick={() => {
                // Close the App Drawer when we click the same navigation item again when we are on that same page
                listItemRoute && !homeRoute && setAppDrawerOpen(false)
                // reset the follow Up WO reducer value when clicking on any navigation item
                dispatch(setFollowUpFlow(false))
              }}
              divider={index < data.length - 1}
              secondaryAction={
                listItemLabel === 'Store Requests' && homeRoute ? (
                  storeRequestEntries.loading ? (
                    <CircularProgress size="1rem" />
                  ) : storeRequestEntries.value === 0 ? (
                    <Chip
                      className={classes.chipNoValue}
                      label={storeRequestEntries.value}
                    />
                  ) : (
                    storeRequestEntries.value && (
                      <Chip
                        className={listItemHighlight}
                        label={storeRequestEntries.value}
                      />
                    )
                  )
                ) : hasCount && homeRoute ? (
                  loading ? (
                    <CircularProgress size="1rem" />
                  ) : noCount ? (
                    <Chip className={classes.chipNoValue} label={count} />
                  ) : (
                    count && (
                      <Chip className={listItemHighlight} label={count} />
                    )
                  )
                ) : undefined
              }
            >
              <ListItemText
                primary={
                  <div
                    className={`${classes.listItemText} ${
                      from === HOME &&
                      listItemLabel === HOME &&
                      classes.listItemTextDisabled
                    }`}
                  >
                    {listItemIcon && listItemIcon}
                    <Typography
                      className={listItemIcon && classes.listItemLabel}
                    >
                      {listItemLabel}
                    </Typography>
                    {listItemLabel === LOG_MY_TIME && homeRoute && (
                      <>
                        {laborEntries?.loading ? (
                          <CircularProgress size="1rem" />
                        ) : (
                          laborEntries.value
                        )}
                      </>
                    )}
                  </div>
                }
                className={
                  listItemHighlight
                    ? classes.listItemTextPriority
                    : val?.primaryNavigation
                      ? classes.listItemTextPrimaryNavigation
                      : undefined
                }
              />
            </ListItem>
          )
        )
      })
    }

    return Object.keys(items).map((listName, key) => {
      const header = isNaN(listName) && listName // check if header is required. Checking if this is not a number, which would mean a valid header as per the logic
      const listItems = items[listName]
      const expanded = header && open[listName]
      return (
        <List
          key={listName}
          disablePadding
          subheader={
            header && (
              <>
                <ListSubheader
                  data-testid={`nested-list-subheader-${key}`}
                  className={classes.subHeader}
                  onClick={() => handleAccordionToggle(header, expanded)}
                >
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography className={classes.header}>{header}</Typography>
                    {expanded ? <ExpandLess /> : <ExpandMore />}
                  </Stack>
                </ListSubheader>
                <Divider />
              </>
            )
          }
        >
          {header ? (
            <Collapse in={expanded} timeout="auto" unmountOnExit>
              {renderListItems(listItems)}
            </Collapse>
          ) : (
            renderListItems(listItems)
          )}
        </List>
      )
    })
  }

  return (
    <Container className={classes.container} disableGutters>
      {inAppMessage && <Alert severity="warning">{appMessage}</Alert>}
      {homeRoute && (
        <CacheListManager
          lastUpdated={lastUpdated}
          listLength={navItemsLoaded}
          clearMethod={() =>
            dispatch(setNavItems(resetnavItemCounts(navItems)))
          }
        />
      )}
      {error && <Alert severity="error">{HOME_PAGE_ERROR(isDCUser)}</Alert>}
      {!error && navItems && (
        <>
          {renderHomePageList(navItems)}
          <LogoutListItem />
        </>
      )}
    </Container>
  )
}

export default NavigationItems
