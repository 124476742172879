import { SET_AREAS_OF_STORE, SET_SELECTED_AREA } from './actionType'

export function setAreasOfStore(areas) {
  return {
    type: SET_AREAS_OF_STORE,
    areas: areas,
  }
}

export function setSelectedArea(area) {
  return {
    type: SET_SELECTED_AREA,
    area: area,
  }
}
