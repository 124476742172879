import {
  SET_CARD_COLLAPSE,
  SET_CARD_PREFERENCES,
  SET_USER_ROLES,
  SET_USER_INFO,
  SET_IS_DC_USER,
  SET_AREA_OR_LOCATION,
  SET_USER_STOREROOM,
} from './actionType'

export function setUserInfo(userInfo) {
  return {
    type: SET_USER_INFO,
    userInfo: userInfo,
  }
}
export function setUserStoreroom(userStoreroom) {
  return {
    type: SET_USER_STOREROOM,
    userStoreroom: userStoreroom,
  }
}
export function setCardPreferences(cardPreferences) {
  return {
    type: SET_CARD_PREFERENCES,
    cardPreferences: cardPreferences,
  }
}
export function setCardCollapse(collapse) {
  return {
    type: SET_CARD_COLLAPSE,
    collapse: collapse,
  }
}
export function setUserRoles(userRoles) {
  return {
    type: SET_USER_ROLES,
    userRoles: userRoles,
  }
}
export function setIsDCUser(isDCUser) {
  return {
    type: SET_IS_DC_USER,
    isDCUser: isDCUser,
  }
}
export function setAreaOrLocation(areaOrLocation) {
  return {
    type: SET_AREA_OR_LOCATION,
    areaOrLocation: areaOrLocation,
  }
}
