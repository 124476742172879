import {
  SET_WORKORDERS_LIST,
  SET_DUPLICATE_WORKORDERS_LIST,
  SET_ASSIGNMENTS,
  FLIP_TOGGLE,
  SET_INTERNAL_WORKORDERS_LIST,
  SET_ACTION_NEEDED_VENDOR_WORKORDERS_LIST,
  SET_ACTION_NEEDED_WORKORDERS_LIST,
  SET_EMERGENCY_MAINTENANCE_WORKORDERS_LIST,
  SET_EXTERNAL_WORKORDERS_LIST,
  SET_VENDOR_VALIDATION_WORKORDERS_LIST,
  SET_CM_EM_WORKORDERS_LIST,
  SET_FIELD_PROJECT_WORKORDERS_LIST,
  SET_PREVENTATIVE_MAINTENANCE_WORKORDERS_LIST,
  SET_REDIRECTED_WORKORDERS_LIST,
  SET_CANCELLED_EXTERNAL_WORKORDERS_LIST,
  SET_SAFETY_WORKORDERS_LIST,
  SET_AUTO_WORKORDERS_LIST,
  SET_AUTH,
} from './actionType'

export function setAuth(userGroups) {
  return {
    type: SET_AUTH,
    userGroups: userGroups,
  }
}

export function setWorkorderList(workorders) {
  return {
    type: SET_WORKORDERS_LIST,
    workorders: workorders,
  }
}
export function setSafetyWorkorderList(safetyWorkorders) {
  return {
    type: SET_SAFETY_WORKORDERS_LIST,
    safetyWorkorders: safetyWorkorders,
  }
}

export function setAutoWorkorderList(autoWorkorders) {
  return {
    type: SET_AUTO_WORKORDERS_LIST,
    autoWorkorders: autoWorkorders,
  }
}

export function setDuplicateWorkorderList(dupWorkOrders) {
  return {
    type: SET_DUPLICATE_WORKORDERS_LIST,
    duplicateWorkorders: dupWorkOrders,
  }
}

export function setAssignments(assignments) {
  return {
    type: SET_ASSIGNMENTS,
    assignments: assignments,
  }
}

export function setInternalWorkorderList(internalWorkorders) {
  return {
    type: SET_INTERNAL_WORKORDERS_LIST,
    internalWorkorders: internalWorkorders,
  }
}
export function setActionNeededWorkorderList(actionNeededWorkorders) {
  return {
    type: SET_ACTION_NEEDED_WORKORDERS_LIST,
    actionNeededWorkorders: actionNeededWorkorders,
  }
}
export function setEmergencyMaintenanceWorkorderList(
  emergencyMaintenanceWorkorders,
) {
  return {
    type: SET_EMERGENCY_MAINTENANCE_WORKORDERS_LIST,
    emergencyMaintenanceWorkorders: emergencyMaintenanceWorkorders,
  }
}

export function setPreventativeMaintenanceWorkorderList(
  preventativeMaintenanceWorkorders,
) {
  return {
    type: SET_PREVENTATIVE_MAINTENANCE_WORKORDERS_LIST,
    preventativeMaintenanceWorkorders: preventativeMaintenanceWorkorders,
  }
}

export function setExternalWorkOrderList(externalWorkOrders) {
  return {
    type: SET_EXTERNAL_WORKORDERS_LIST,
    externalWorkOrders: externalWorkOrders,
  }
}

export function setRedirectedWorkOderList(redirectedWorkOrders) {
  return {
    type: SET_REDIRECTED_WORKORDERS_LIST,
    redirectedWorkOrders: redirectedWorkOrders,
  }
}

export function setCmWorkOrderList(cmWorkOrders) {
  return {
    type: SET_CM_EM_WORKORDERS_LIST,
    cmWorkOrders: cmWorkOrders,
  }
}

export function setFieldProjectWorkorderList(fieldProjectWorkorders) {
  return {
    type: SET_FIELD_PROJECT_WORKORDERS_LIST,
    fieldProjectWorkorders: fieldProjectWorkorders,
  }
}

export function setActionNeededVendorWorkOrderList(
  actionNeededVendorWorkOrders,
) {
  return {
    type: SET_ACTION_NEEDED_VENDOR_WORKORDERS_LIST,
    actionNeededVendorWorkOrders: actionNeededVendorWorkOrders,
  }
}
export function setVendorWorkValidationWorkOrderList(vendorWorkValidationWOs) {
  return {
    type: SET_VENDOR_VALIDATION_WORKORDERS_LIST,
    vendorWorkValidationWOs: vendorWorkValidationWOs,
  }
}
export function setCancelledExtWorkOrderList(cancelledExtWorkOrders) {
  return {
    type: SET_CANCELLED_EXTERNAL_WORKORDERS_LIST,
    cancelledExtWorkOrders: cancelledExtWorkOrders,
  }
}

export function flipToggle() {
  return {
    type: FLIP_TOGGLE,
  }
}
